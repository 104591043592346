<template>
  <align-container>
    <align-panel align="all" style="overflow-y: auto" :gap="3">
      <div style="display: flex;">
        <a for="transport_type" style="margin-left: 5px; margin-top: 13px">{{ $t("nsi.TsPanel.TransportTypeLabel") }}</a>
        <transport-type-selector
            id="transport_type"
            class="form-control"
            :model-value="selectedTransportType"
            @change="setSelectedTransportType"
            style="margin-top: 5px; margin-left: 5px; margin-bottom: 5px; width: 250px"
        >
        </transport-type-selector>

        <input-checkbox
            id="isNotDeleted"
            v-model="isNotDeleted"
            style="margin-bottom: 0px; margin-left: 10px; margin-top: 0px; margin-top: 13px;"
        >{{ $t("nsi.TsPanel.ChechboxIsNotDeleted") }}
        </input-checkbox>

        <!--        <input-checkbox-->
        <!--            id="isAsmppOnly"-->
        <!--            v-model="isAsmppOnly"-->
        <!--            style="margin-bottom: 0px; margin-left: 10px; margin-top: 0px; margin-top: 13px"-->
        <!--        >Только АСМПП-->
        <!--        </input-checkbox>-->
      </div>
      <ts-table
          :selectedRow="selectedTS"
          :isNotDeleted="isNotDeleted"
          :isAsmppOnly="isAsmppOnly"
          @setSelectedRow="setSelectedTS"
      >
      </ts-table>
    </align-panel>
    <align-panel align="right" width="20%" style="min-width: 350px !important; overflow-x: hidden; overflow-y: hidden;">
      <ts-dialog
          :item="selectedTS"
          @onSave="onSaveTS"
          @onEdit="onEditTS"
          @onLinkRs="onLinkRsTs"
          @onDeleteLinkRs="onDeleteLinkRs"
      >
      </ts-dialog>
    </align-panel>
  </align-container>
</template>

<script>

import TsDialog from "@/components/references/ts/TsDialog";
import TsTable from "@/components/references/ts/TsTable";
import TransportTypeSelector from "@/components/references/trans_type/TransportTypeSelector";
import InputCheckbox from "@/components/ui/custom/InputCheckbox";

export default {
  name: "TsPanel",
  components: {InputCheckbox, TransportTypeSelector, TsTable, TsDialog},
  emits: ['setSelectedDir'],
  data() {
    return {
      isNotDeleted: false,
      isAsmppOnly: false,
    }
  },
  computed: {

    // выбранный тип транспорта
    selectedTransportType() {
      return this.$store.getters['ref/getSelectedTransportType'];
    },

    selectedTS() {
      return this.$store.getters['ref/getSelectedTS'];
    },

  },
  methods: {

    async setSelectedTransportType(value) {
      this.setSelectedTS(null);
      this.$store.commit('ref/setSelectedTransportType', value);

      this.$store.commit('ref/setModels', []);
      await this.$store.dispatch('ref/doGetModels', {
        park: this.$store.getters['ref/getParks'].find(park => park.parkId == this.$store.getters['ref/getUserPark']),
        trans_type: this.$store.getters['ref/getTransTypes'].find(value => value.tt_id == this.$store.getters['ref/getSelectedTransportType']),
        capacity: this.$store.getters['ref/getCapacities'].find(value => value.cap_id == this.$store.getters['ref/getSelectedCapacity']),
      })
    },

    setSelectedTS(value) {
      this.$store.commit('ref/setSelectedTS', value);
    },

    async onSaveTS(ts) {
      try {
        this.isLoading = true;
        let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.$store.getters['ref/getUserPark']);

        // проверяем можно ли добавлять ТС
        let isExist = await this.$store.dispatch('ref/doIsInsertTSAvailable', {
          identity: park.identity,
        });
        if (!isExist) {
          this.errorMessageText = this.$t("nsi.TsPanel.ErrorMessageText");
          return;
        }

        // добавляем тс
        await this.$store.dispatch('ref/doAddTS', {
          park: park,
          ts: ts,
        });

      } finally {
        this.isLoading = false;
      }
    },

    async onEditTS(ts) {
      try {
        this.isLoading = true;
        // редактируем тс
        await this.$store.dispatch('ref/doEditTS', {
          ts: ts,
        });

      } finally {
        this.isLoading = false;
      }
    },

    // добавление связи ТС - р/с
    async onLinkRsTs(values) {
      try {
        this.isLoading = true;
        let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.$store.getters['ref/getUserPark']);

         // добавляем связь
        await this.$store.dispatch('ref/doInsertRadioTransLink', {
          ts: values.ts,
          rs_num: values.rs_num,
          park: park
        });

        //перезагружаем массив ТС
        await this.$store.dispatch('ref/doGetAllTsArr', {
          park: park
        });
      } finally {
        this.isLoading = false;
      }
    },

    // удаление связи ТС - р/с
    async onDeleteLinkRs(ts) {
      try {
        this.isLoading = true;
        let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.$store.getters['ref/getUserPark']);

        // удаляем связь
        await this.$store.dispatch('ref/doDeleteRadioTransLink', {
          ts: ts,
          rs_num: ts.rsNum,
          park: park
        });

        //перезагружаем массив ТС
        await this.$store.dispatch('ref/doGetAllTsArr', {
          park: park
        });
      } finally {
        this.isLoading = false;
      }
    },


    created() {
      if (this.$store.getters['ref/getTransTypes'].length == 1) {
        this.$store.commit('ref/setSelectedTransportType', this.$store.getters['ref/getTransTypes'][0].tt_id);
      } else {
        this.$store.commit('ref/setSelectedTransportType', null);
      }

    },
  }
}
</script>

<style scoped>

</style>