// ХРАНИЛИЩЕ НАВИГАЦИОННЫХ ОТМЕТОК ИСТОРИИ ДВИЖЕНИЯ
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список агрегированных объектов NavFlowItems
            navFlowItems: [],
        }
    },
    getters: {
        // вернуть список NavFlowItems
        getNavFlowItems(state) {
            return state.navFlowItems;
        },
    },
    mutations: {
        // установить новый список объектов
        setNavFlowItems(state, payload) {
            state.navFlowItems = payload;
        },

    },
    actions: {

        // добавление объекта
        async doFetchNavFlowItems(context, payload) {
            const params = new URLSearchParams();
            params.append("dateIndexMin", String(payload.startDate));
            params.append("dateIndexMax", String(payload.endDate));
            params.append("jsonPark", JSON.stringify(payload.park));
            params.append("jsonTS", JSON.stringify(payload.ts));
            // кодируем данные для корректного отображения русского текста на сервере
            const encoded = encodeURI(params.toString());
            await DoPost(store.getters['settings/getBaseUrl']
                + '/NavRestController/getNavFlowItemRemote',
                encoded,
                'umkr/setNavFlowItems',
                'Получение истории движения ТС'
            );
        },
    }
}
