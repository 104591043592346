import {CRS, LineUtil, latLng} from "leaflet";
import {toLatLng} from "leaflet/src/geo/LatLng";
import {Point} from "leaflet/dist/leaflet-src.esm";

// Возвращает индекс ближайшего отрезка
export function getCloseLineIndex(latlng, latlngs, crs = CRS.EPSG3857) {
    let minIndex = -1;
    let minDistance = Number.MAX_SAFE_INTEGER;
    const p = crs.project(latLng(latlng));
    for (let i = 0; i < latlngs.length - 1; i++) {
        const p1 = crs.project(latLng(latlngs[i]));
        const p2 = crs.project(latLng(latlngs[i + 1]));
        const distance = LineUtil.pointToSegmentDistance(p, p1, p2);
        if (distance < minDistance) {
            minIndex = i;
            minDistance = distance;
        }
    }
    return minIndex;
}

// Увеличить широту на указанное количество метров
export function addLat(latLngExp, distance)  {
    const latLng = toLatLng(latLngExp);
    const latAccuracy = (180 * distance) / 40075017;
    latLng.lat = latLng.lat + 2 * latAccuracy;
    return [latLng.lat, latLng.lng];
}

// Увеличить долготу на указанное количество метров
export function addLong(latLngExp, distance) {
    const latLng = toLatLng(latLngExp);
    const latAccuracy = (180 * distance) / 40075017;
    const lngAccuracy = latAccuracy / Math.cos((Math.PI / 180) * latLng.lat);
    latLng.lng = latLng.lng + 2 * lngAccuracy;
    return [latLng.lat, latLng.lng];
}

// Преващает структуру LatLng в массив
export function toLatLongArr(latLngExp) {
    const latlng = toLatLng(latLngExp);
    return [latlng.lat, latlng.lng];
}

// Увеличить координаты на указанное количество метров
export function addCoord(latLngExp, distance) {
    return addLong(this.addLat(latLngExp, distance), distance);
}

// сдвигает координаты линии на указанное количество пикселей в сторону
export function shiftLine(pixels, p1, p2) {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    const l = Math.sqrt(dx * dx + dy * dy);
    const rx = -dy / l;
    const ry = dx / l;
    const r1 = new Point((p1.x + rx * pixels), (p1.y + ry * pixels), true);
    const r2 = new Point((r1.x + dx), (r1.y + dy), true);
    return [r1, r2]
}
