// МИКСИН ДЛЯ ГРУППЫ

export default {
    data() {
        return {
            // готовность группы
            isGroupReady: false,
        }
    },
    methods: {
        // ждет запуска родителя
        parentReady() {
            return new Promise((resolve => {
                this.getParent().parentReady().then((parent_list) => {
                    if (this.isGroupReady) {
                        resolve([...parent_list, this])
                    }
                    else {
                        this.$watch(() => this.isGroupReady, () => {
                            if (this.isGroupReady) {
                                resolve([...parent_list, this])
                            }
                        })
                    }
                })
            }))
        }
    }
}
