export default {
    props: {
        // при щелчке найти элемент по ref
        targetRef: {
            type: String
        },
        // и вызвать метод
        targetMethod: {
            type: String
        }
    },
    methods: {
        // вызываем метод по ref
        execTargetMethod(targetMethod) {
            const method = targetMethod || this.targetMethod;
            // ищем элемент по ссылке
            if (this.targetRef && method) {
                let c = this.$parent;
                while (c) {
                    if (c.$refs[this.targetRef]) {
                        if (c.$refs[this.targetRef][method]) {
                            // вызываем метод
                            c.$refs[this.targetRef][method](this);
                            // выходим
                            return true;
                        }
                    }
                    c = c.$parent;
                }
            }
            return false;
        }
    }
}