// ХРАНИЛИЩЕ РС
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            rsNums: [],
            // // список р/с
            rsNumsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            rsNumsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список р/с
        getRsNums(state) {
            return state.rsNums;
        },
    },
    mutations: {
        // установить новый список объектов
        setRsNums(state, payload) {
            let i = 0;
            state.rsNums = payload.map(item => {
                i = i+1;
                return {
                    rs_stationnum: item.rs_stationnum,
                    rst_stationtype: item.rst_stationtype,
                    pk_id: item.pk_id,
                    rs_channel: item.rs_channel,
                    rs_phonenum: item.rs_phonenum,
                    rs_sim: item.rs_sim,
                    rst_typename: item.rst_typename,
                    identity: item.identity,
                    id: i
                }
            });
            // state.rsNums = payload;
        },

        // добавить объект
        addRsNum(state, payload) {
            let new_rs_num = {
                rs_stationnum: payload.rs_stationnum,
                rst_stationtype: payload.rst_stationtype,
                pk_id: payload.pk_id,
                rs_channel: payload.rs_channel,
                rs_phonenum: payload.rs_phonenum,
                rs_sim: payload.rs_sim,
                rst_typename: payload.rst_typename,
                identity: payload.identity,
                id: state.rsNums.length + 1
            }
            state.rsNums.push(new_rs_num)
        },

    },
    actions: {

        // получение объектов
        async doGetRsNums(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.park));
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/RSRestController/getRSNumsRemote',
                params.toString(),
                'ref/setRsNums',
                'Получение р/с'
            );

        },

        // получение объектов
        async doCheckRSNum(context, payload) {
            const params = new URLSearchParams();
            params.append("rsNum", JSON.stringify(payload.rs_num));
            params.append("rsType", JSON.stringify(payload.rs_type));
            params.append("jsonFormation", JSON.stringify(payload.formation));
            return await DoPost(
                store.getters['settings/getBaseUrl'] + '/RSRestController/checkRSNumRemote',
                params.toString(),
                null,
                'Проверка существования р/с'
            );

        },



        // добавление нового объекта
        async doAddRsNum(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonRSNum", JSON.stringify(payload.rs_num));
            params.append("jsonPark", JSON.stringify(payload.park));
            // кодируем данные для корректного отображения русского текста на сервере
            const encoded = encodeURI(params.toString())
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/RSRestController/insertRSNumRemote',
                encoded,
                null,
                'Добавление р/с'
            );

            context.commit('addRsNum', payload.rs_num);
        },
    }
}
