import {DoPost} from "@/store/Requests";
import router from "@/router/Router";
import axios from "axios";
import store from "@/store/Store";
// import {DoGetRaw} from "@/store/Actions";

// ГЛАВНОЕ ХРАНИЛИЩЕ MAIN

export default {
    state() {
        return {
            // логин
            login: '',
            // пароль
            password: '',
            // токен
            tokenId: '',
            // признак успешной загрузки приложения
            isLoaded: false,
            // изначальный путь
            loadedUrl: null,
            // критическая ошибка
            // isPanic: false,
            // текст критической ошибки
            // panicMessage: '',
        }
    },
    getters: {
        // доступ к логину
        getLogin(state) {
            return state.login;
        },
        getPassword(state) {
            return state.password;
        },
        // доступ к токену
        getTokenId(state) {
            return state.tokenId
        },
        // признак авторизации
        getIsAuth(state) {
            return state.login !== '' && state.tokenId !== '';
        },
        // признак загрузки настроект
        getIsLoaded(state) {
            return state.isLoaded;
        },
        // изначальный url-адрес
        getLoadedUrl(state) {
            return state.loadedUrl;
        },
    },
    mutations: {
        // установка параметров авторизации
        setAuth(state, payload) {
            state.login = payload.login;
            state.password = payload.password;
            state.tokenId = payload.tokenId;
            localStorage.setItem('login', payload.login);
            localStorage.setItem('password', payload.password);
            localStorage.setItem('tokenId', payload.tokenId);
        },
        // сбрасываем параметры авторизации
        clearAuth(state) {
            state.login = '';
            state.password = '';
            state.tokenId = '';
            localStorage.removeItem('login');
            localStorage.removeItem('password');
            localStorage.removeItem('tokenId');
        },
        // устанавливаем изначальный url-адрес
        setLoadedUrl(state, payload) {
            state.loadedUrl = payload.loadedUrl;
        },
        // фиксируем успешную загрузку
        setLoaded(state) {
            state.isLoaded = true;
        },
    },
    actions: {
        // вход в систему
        doLogin: async function(context, payload) {
            context.dispatch('resetUserParams');

            // базовый адрес
            const baseUrl = context.getters['settings/getBaseUrl'];
            const params = new URLSearchParams();
            params.append("app_name", "asmppclient");
            params.append("login", payload.login);
            params.append("pwd", payload.password);

            try {
                // выполняем запрос
                const token = await axios.post(baseUrl + '/AccountSessionRestController/authorizationRemote',
                    params.toString());

                if (token && token.data.length == 36) {
                    // сохраняем данные авторизации
                    context.commit('setAuth', {
                        login: payload.login,
                        password: payload.password,
                        tokenId: token.data
                    });

                    // переходим в корень или по изначальному пути
                    await router.replace(context.getters.getLoadedUrl ?  context.getters.getLoadedUrl : '/');

                    // фиксируем успех
                    return true;
                } else {
                    // ругаемся
                    await context.dispatch('doAddMessage', {
                        caption: 'Вход в систему',
                        message: 'Ошибка авторизации',
                        type: 'error'
                    });

                    // выходим с ошибкой
                    return false;
                }
            }
            catch(e) {
                // ругаемся
                await context.dispatch('doAddMessage', {
                    caption: 'Вход в систему',
                    message: e.message,
                    type: 'error'
                });

                // выходим с ошибкой
                return false;
            }
        },

        // выход из системы
        doLogout: async function(context) {
            // базовый адрес
            const baseUrl = context.getters['settings/getBaseUrl'];

            try {
                // отправляем запрос
                const ureg_result = await DoPost(
                    baseUrl + '/AccountSessionRestController/unregisterRemote',
                    null,
                    null
                )
                console.log("auth_store 52: "  + ureg_result);

                // сбрасываем данные авторизации
                await context.commit('clearAuth');

                // отправляем сообщение об успешном выходе
                await context.dispatch('doAddMessage', {
                    caption: 'Выход из системы',
                    message: 'Успешный выход из системы',
                    type: 'ok'
                })

                // переходим в авторизацию
                await router.replace('/auth');

                // фиксируем успех
                return true;
            }
            catch(e) {
                // сбрасываем данные авторизации
                await context.commit('clearAuth');

                // ругаемся
                await context.dispatch('doAddMessage', {
                    caption: 'Выход из системы',
                    message: e.message,
                    type: 'error'
                })

                // переходим в авторизацию
                await router.replace('/auth');

                // выходим с ошибкой
                return false;
            }
        },

        // обновление сессии
        doUpdateSession: async function(context) {

            // базовый адрес
            const baseUrl = context.getters['settings/getBaseUrl'];

            try {

                // вызываем запрос
                // отправляем запрос
                const result = await DoPost(
                    baseUrl + '/AccountSessionRestController/reAuthorizationRemote',
                    null,
                    null
                );
                if (result) {
                    // фиксируем успех
                    return true;
                } else {
                    // //ругаемся
                    // await context.dispatch('doAddMessage', {
                    //     caption: 'Обновление сессии',
                    //     message: 'Сервер недоступен',
                    //     type: 'error'
                    // });
                    // сбрасываем данные авторизации
                    await context.commit('clearAuth');
                    // направляем роутер на страницу авторизации
                    await router.replace('/auth');

                    // выходим с ошибкой
                    return false;
                }

            }
            catch(e) {

                //ругаемся
                await context.dispatch('doAddMessage', {
                    caption: 'Обновление сессии',
                    message: e.message,
                    type: 'error'
                });

                // выходим с ошибкой
                return false;
            }
        },

        // автоматический вход в систему
        doAutoLogin: async function(context) {
            context.dispatch('resetUserParams');

            // запускаем таймер автообновления (раз в минуту)
            setInterval(() => {
                if (context.getters.getIsAuth) {
                    context.dispatch('doUpdateSession');
                }
            }, 60000)

            // извлаекаем данные из локального хранилища
            const login = localStorage.getItem('login');
            const password = localStorage.getItem('password');
            const tokenId = localStorage.getItem('tokenId');

            // есть данные в локальном хранилище
            if (login && tokenId) {

                // сохраняем их в нашем хранилище
                context.commit('setAuth', {
                    login,
                    password,
                    tokenId
                });

                // вызываем обновление сессии
                return await context.dispatch('doUpdateSession');

            }

            return false;
        },

        // устанавливаем признак загрузки
        doSetLoaded:  (context) => {
            context.commit('setLoaded');
        },

        // устанавливаем начальный путь
        doSetLoadedUrl:  (context, payload) => {
            context.commit('setLoadedUrl', payload);
        },

        // сбрасываем пользовательские параметры
        resetUserParams: () => {
            store.commit('ref/resetAllUserParams');
            store.commit('umkr/setStatInit', []);
            store.commit('umkr/setStatInitItog', []);
            store.commit('rep/setInspReisesPassFlowIndicators', []);
        }

    }
}