// ХРАНИЛИЩЕ ТИПОВ ТРАНСПОРТА
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            transTypes: [],
        }
    },
    getters: {
        // вернуть список парков
        getTransTypes(state) {
            return state.transTypes;
        },
    },
    mutations: {
        // установить новый список объектов
        setTransTypes(state, payload) {
            state.transTypes = payload;
        },

    },
    actions: {

        // добавление объекта
        async doGetTransTypes(context, payload) {

            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.park));

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/TransTypeRestController/getTrTypesRemote',
                params.toString(),
                'ref/setTransTypes',
                'Получение типов транспорта'
             );

        },
    }
}
