<template>
    <a
        :href="to"
        :class="{ active: isActive, disabled: isDisabled }"
        @click="onClick"
    >
      <slot></slot>
    </a>    
</template>

<script>
import DisabledMixin from "@/components/ui/bootstrap/mixins/DisabledMixin";
import ActiveMixin from "@/components/ui/bootstrap/mixins/ActiveMixin";
import ChildMixin from "@/components/ui/bootstrap/mixins/ChildMixin";
import TargetMixin from "@/components/ui/bootstrap/mixins/TargetMixin";

export default {
  mixins: [DisabledMixin, ActiveMixin, ChildMixin, TargetMixin],
  props: {
    // сама ссылка
    to: {
      type: String,
      default: '#'
    },
    // вместо ссылки - роутер
    routerLink: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    // срабатывает при щелчке на ссылку
    onClick(e) {

      // вызываем родительский компонент
      this.select();

      // ищем элемент по ссылке
      if (this.execTargetMethod()) {
        // отменяем стандартный обработчик
        e.preventDefault();
        // выходим
        return;
      }

      // переходим по ссылке
      if (this.routerLink) {
        // отменяем стандартный обработчик
        e.preventDefault();
        // переходим
        this.$router.push(this.to);
        // выходим
        return;
      }
    }
  }
}
</script>
