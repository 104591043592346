<template>
  <template
      v-for="(layer, index) in baseLayers"
      :key="layer.title"
  >
    <leaflet-tile-layer
        v-if="!!layer.urlTemplate"
        :urlTemplate="layer.urlTemplate"
        :layer_control_title="layer.title"
        :options="layer.options"
        :visible="index === 0"
        :logTitle="layer.title"
    ></leaflet-tile-layer>
<!--    <leaflet-yandex-layer-->
<!--        v-if="layer.type === 'yandex'"-->
<!--        :layer_control_title="layer.title"-->
<!--        :options="layer.options"-->
<!--        :visible="index === 0"-->
<!--        :logTitle="layer.title"-->
<!--    >-->
<!--    </leaflet-yandex-layer>-->
  </template>
</template>
<script>
export default {
  props: ['baseLayers'],
  data() {
    return {
      // тип объекта
      leafletObjectType: 'LeafletBaseLayerGroup',
    }
  }
}
</script>
