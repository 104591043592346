// ХРАНИЛИЩЕ ВЫБРАННЫХ ПОЛЬЗОВАТЕЛЕМ ПАРАМЕТРОВ

import {dateToString, getDateMinusMonth} from "@/lib";

export default {
    state() {
        return {
            // выбранное тер. обр-е
            formation: null,
            // выбранный парк
            park: null,
            //выбранные дни недели
            weekday: 0,
            //выбранные даты
            startDate: dateToString(getDateMinusMonth()),
            endDate: dateToString(),

            //выбранный вариант расчета
            inspPassFlowSqlVariant: 'Park',
            //вариант отображения колонок таблицы
            tableInspPassFlowColumnVarian: 'Park',

            //Вариант отображения таблицы в УМКР
            tableUmkrViewVariant: 'Disb',
            //выбранный dateIndex в таблице в УМКР
            dateIndexUmkr: null,
            //выбранное ts_id в таблице в УМКР
            tsIdUmkr: null,

            //Вариант отображения таблицы в Процессинге
            tableProcessingViewVariant: 'Nariad',

            // массив в котором храниться история перемещений по выпадающему списку
            // при каждом новом запросе на сервер payload кладется в массив
            // соответственно, если пользователь хочет вернуться через выпадающее меню назад
            // мы берем предпоследний payload и делаем повторный запрос
            historyArr: [],
            // выбранная дата на панельке обработки исходных данных
            selectedProcessingDateIndex: null,
            // выбранное значение на панельке обработки исходных данных
            selectedProcessingValue: null,

            // данныне для страницы Справочники, необходимы чтобы можно было их сбросить из панельки <params-chooser-panel>
            selectedDir: 'marshes',
            // выбранный маршрут на странице справочников
            selectedMarsh: null,
            // выбранный маршрут для вкладки на странице справочников
            selectedMarshForTab: null,
            // выбранный период действия
            selectedMarshVariant: null,
            // выбранный период действия для вкладки на странице справочников
            selectedMarshVariantForTab: null,
            // выбранная трасса
            selectedRace: null,
            // выбранный период действия для вкладки на странице справочников
            selectedRaceForTab: null,
            // выбранная остановка трассы
            selectedRaceCard: null,
            // т.к. в зависимости от режима отображения в диалоге raceCard меняется отображение остановок на карте, выносим переменную сюда
            raceCardDialogMode: 'add',
            // выбранная остановка (на странице всех остановок)
            selectedParkStation: null,
            // выбранный тип транспорта на странице
            selectedTransportType: null,
            // выбранное ТС
            selectedTS: null,
            // выбранный тип вместимости
            selectedCapacity: null,
        }
    },
    getters: {
        // вернуть список парков
        getUserFormation(state) {
            return state.formation;
        },
        // вернуть список парков
        getUserPark(state) {
            return state.park;
        },
        getUserWeekday(state) {
            return state.weekday;
        },
        getUserStartDate(state) {
            return state.startDate;
        },
        getUserEndDate(state) {
            return state.endDate;
        },
        getUserInspPassFlowSqlVariant(state) {
            return state.inspPassFlowSqlVariant;
        },
        getUserInspPassFlowTableColumnVariant(state) {
            return state.tableInspPassFlowColumnVarian;
        },

        getUserTableUmkrViewVariant(state) {
            return state.tableUmkrViewVariant;
        },
        getUserUmkrDateIndex(state) {
            return state.dateIndexUmkr;
        },
        getUserUmkrTsId(state) {
            return state.tsIdUmkr;
        },

        getUserTableProcessingViewVariant(state) {
            return state.tableProcessingViewVariant;
        },

        getHistoryArr(state) {
            return state.historyArr;
        },

        getSelectedProcessingDateIndex(state) {
            return state.selectedProcessingDateIndex
        },

        getSelectedProcessingValue(state) {
            return state.selectedProcessingValue
        },

        // вернуть выбранный справочник на странице справочников
        getSelectedDir(state) {
            return state.selectedDir;
        },

        // вернуть выбранный маршрут на странице справочников
        getSelectedMarsh(state) {
            return state.selectedMarsh;
        },

        // вернуть выбранный маршрут для вкладки на странице справочников
        getSelectedMarshForTab(state) {
            return state.selectedMarshForTab;
        },

        // вернуть выбранный период действия на странице справочников
        getSelectedMarshVariant(state) {
            return state.selectedMarshVariant;
        },

        // вернуть выбранный период действия для вкладки на странице справочников
        getSelectedMarshVariantForTab(state) {
            return state.selectedMarshVariantForTab;
        },

        // вернуть выбранную трассу на странице справочников
        getSelectedRace(state) {
            return state.selectedRace;
        },

        // вернуть выбранную трассу для вкладки на странице справочников
        getSelectedRaceForTab(state) {
            return state.selectedRaceForTab;
        },

        // вернуть выбранную остановку трассы на странице справочников
        getSelectedRaceCard(state) {
            return state.selectedRaceCard;
        },

        // вернуть режим отображения в диалоге raceCard
        getRaceCardDialogMode(state) {
            return state.raceCardDialogMode;
        },

        // вернуть выбранную остановку (на странице всех остановок)
        getSelectedParkStation(state) {
            return state.selectedParkStation;
        },

        // вернуть выбранный тип трансопрта
        getSelectedTransportType(state) {
            return state.selectedTransportType;
        },

        // вернуть выбранное ТС
        getSelectedTS(state) {
            return state.selectedTS
        },

        // вернуть тип вместимости
        getSelectedCapacity(state) {
            return state.selectedCapacity
        }

    },
    mutations: {
        resetAllUserParams(state) {
            state.formation = null;
            state.park = null;
            state.weekday = 0;
            state.inspPassFlowSqlVariant = 'Park';
            state.tableInspPassFlowColumnVarian = 'Park';
            state.tableUmkrViewVariant = 'Disb';
            state.tableProcessingViewVariant = 'Nariad';
            state.startDate = dateToString(getDateMinusMonth());
            state.endDate = dateToString();
            state.dateIndexUmkr = null;
            state.tsIdUmkr = null;
        },

        // установить новый список объектов
        setUserFormation(state, payload) {
            state.formation = payload;
        },
        setUserPark(state, payload) {
            state.park = payload;
        },
        setUserWeekday(state, payload) {
            state.weekday = payload;
        },
        setUserStartDate(state, payload) {
            state.startDate = payload;
        },
        setUserEndDate(state, payload) {
            state.endDate = payload;
        },
        setUserInspPassFlowSqlVariant(state, payload) {
            state.inspPassFlowSqlVariant = payload;
        },
        setUserInspPassFlowTableColumnVariant(state, payload) {
            state.tableInspPassFlowColumnVarian = payload;
        },

        setUserUmkrViewVariant(state, payload) {
            state.tableUmkrViewVariant = payload;
        },
        setUserUmkrDateIndex(state, payload) {
            state.dateIndexUmkr = payload;
        },
        setUserUmkrTsId(state, payload) {
            state.tsIdUmkr = payload;
        },

        setUserProcessingViewVariant(state, payload) {
            state.tableProcessingViewVariant = payload;
        },

        setHistoryArrStep(state, payload) {
            state.historyArr = payload;
        },

        // добавить "шаг" в историю
        addHistoryArrStep(state, payload) {
            state.historyArr.push(payload);
        },

        // удалить последний шаг из истории
        deleteHistoryArrStep(state) {
            state.historyArr.splice(state.historyArr.length - 1, 1);
        },

        setSelectedProcessingDateIndex(state, payload) {
            state.selectedProcessingDateIndex = payload;
        },

        setSelectedProcessingValue(state, payload) {
            state.selectedProcessingValue = payload;
        },

        // установить выбранный справочник на странице справочников
        setSelectedDir(state, payload) {
            state.selectedDir = payload;
        },

        // установить выбранный маршрут на странице справочников
        setSelectedMarsh(state, payload) {
            state.selectedMarsh = payload;
        },

        // установить выбранный маршрут для отображения на вкладке слева на странице справочников
        setSelectedMarshForTab(state, payload) {
            state.selectedMarshForTab = payload;
        },

        // установить выбранный период действия на странице справочников
        setSelectedMarshVariant(state, payload) {
            state.selectedMarshVariant = payload;
        },

        // установить выбранный период действия для отображения на вкладке слева на странице справочников
        setSelectedMarshVariantForTab(state, payload) {
            state.selectedMarshVariantForTab = payload;
        },

        // установить выбранную трассу на странице справочников
        setSelectedRace(state, payload) {
            state.selectedRace = payload;
        },

        // установить в выбранную трассу для отображения на вкладке слева на странице справочников
        setSelectedRaceForTab(state, payload) {
            state.selectedRaceForTab = payload;
        },

        // установить выбранную остановку трассы на странице справочников
        setSelectedRaceCard(state, payload) {
            state.selectedRaceCard = payload;
        },

        // установить режим отображения в диалоге raceCard
        setRaceCardDialogMode(state, payload) {
            state.raceCardDialogMode = payload;
        },

        // установить выбранную остановку (на странице всех остановок)
        setSelectedParkStation(state, payload) {
            state.selectedParkStation = payload;
        },

        // установить выбранный тип транспорта
        setSelectedTransportType(state, payload) {
            state.selectedTransportType = payload;
        },

        setSelectedTS(state, payload) {
            state.selectedTS = payload;
        },

        // вернуть тип вместимости
        setSelectedCapacity(state, payload) {
            state.selectedCapacity = payload;
        }
    }
}