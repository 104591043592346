<template>

  <!--  Полигон -->
  <leaflet-polygon
      v-if="isCorrect"
      :latlngs="points"
      :options="options"
      :contextMenu="contextMenu"
      :tooltip="tooltip"
      :events="events + ' drag dragend dblclick'"
      :targetComponent="this"
      :logTitle="logTitle"
      @onLeafletEvent="onLeafletPolygonEvent($event)"
  >
  </leaflet-polygon>

  <!--  Точки редактирования-->
  <template
      v-if="isCorrect && isEdited"
  >
    <leaflet-marker
        v-for="(point, index) in points"
        :key="index"
        :latlng="point"
        :icon="editPointIcon"
        :options="editPointOptions"
        :meta="index"
        :logTitle="logTitle+'-EditPoint('+index+')'"
        events="drag dragend dblclick"
        @onLeafletEvent="onLeafletMarkerEvent($event, index)"
    >
    </leaflet-marker>
  </template>

</template>

<script>

// ЭЛЕМЕНТ ЗОНЫ В ВИДЕ МНОГОУГОЛЬНИКА

// interface ZONE_ELEMENT {
//   zone_element_id: number;
//   zone_element_type_id: number;
//   zone_id: number;
//   order_num: number;
//   parameter_size: number;
//   points: ZONE_ELEMENT_POINT[];
// }

// interface ZONE_ELEMENT_POINT {
//   zone_element_id: number;
//   order_num: number;
//   latitude: number;
//   longitude: number;
// }

// опции линии
import LeafletMapMixin from "@/components/ui/leaflet/mixins/LeafletMapMixin";
import {divIcon} from "leaflet";
import {getCloseLineIndex, toLatLongArr} from "@/components/ui/leaflet/LeafletHelpers";
import LeafletEventedMixin from "@/components/ui/leaflet/mixins/LeafletEventedMixin";

export default {
  mixins: [LeafletMapMixin, LeafletEventedMixin],
  emits: ['onZoneElementChanged'],
  props: {
    // состояние элемента зоны: original - обычное, active - активное, edit - редактируемое, disabled - отключенное
    state: {
      type: String,
      default: 'original'
    },
    // описание элемента зоны
    zoneElement: {
      required: true,
    },
    // всплывающая подсказка слоя
    tooltip: {
      type: String
    },
    // контекстное меню слоя
    contextMenu: {
      type: Object
    },
    // опции элемента зоны в стандартном режиме
    originalOptions: {
      type: Object,
      default: () => {
        return {
          color: 'red',
          weight: 0.0,
          opacity: 0.0,
          fill: true,
          fillColor: 'red',
          fillOpacity: 0.3,
          draggable: false
        }
      }
    },
    // опции элемента заны в активном режиме
    activeOptions: {
      type: Object,
      default: () => {
        return {
          color: 'black',
          weight: 1.0,
          opacity: 1.0,
          fill: true,
          fillColor: 'red',
          fillOpacity: 0.6,
          draggable: false
        }
      }
    },
    // опции элемента зоны в режиме редактирования
    editOptions: {
      type: Object,
      default: () => {
        return {
          color: 'black',
          weight: 2.0,
          opacity: 1.0,
          fill: true,
          fillColor: 'red',
          fillOpacity: 0.6,
          draggable: true
        }
      }
    },
    // опции элемента заны в отключенном режиме
    disabledOptions: {
      type: Object,
      default: () => {
        return {
          color: 'grey',
          weight: 0.0,
          opacity: 0.0,
          fill: true,
          fillColor: 'grey',
          fillOpacity: 0.3,
          draggable: false
        }
      }
    },
    // иконка маркеров редактирования
    editPointIcon: {
      type: Object,
      default: () => {
        return divIcon({
          html: '<div style="width: 100%; height: 100%; border: 0px black solid; margin : 0px; padding: 0px; background-color: white"></div>',
          iconSize: [10, 10],
          iconAnchor: [5, 5]
        })
      }
    },
    // опции маркеров редактирования
    editPointOptions: {
      type: Object,
      default: () => {
        return {
          draggable: true,
          clickable: true
        }
      }
    },
  },

  data() {
    return {
      // тип объекта
      leafletObjectType: 'PolygonZoneElement',
      // карта
      leafletMap: null,
    }
  },

  computed: {
    // логирование
    logTitle() {
      return `PolygonZoneElement(#${this.zoneElement.zone_element_id})`
    },
    // корректность данных
    isCorrect() {
      return this.leafletMap &&
          this.zoneElement &&
          this.zoneElement.points &&
          Array.isArray(this.zoneElement.points) &&
          this.zoneElement.zone_element_type_id === 3
    },
    // точки элемента зоны
    points() {
      return this.zoneElement.points.map((item) => [item.latitude, item.longitude])
    },
    // опции элемента зоны
    options() {
      if (this.state === 'disabled') return this.disabledOptions
        else if (this.state === 'edit') return this.editOptions
          else if (this.state === 'active') return this.activeOptions
            else return this.originalOptions;
    },
    // признак режима редактирования
    isEdited() {
      return this.state === 'edit';
    }
  },

  methods: {
    // события от маркеров редактирования
    onLeafletMarkerEvent(event, index) {
      // конец перемещения
      if (event.type === 'drag' && this.isEdited) {
        // перемещаем угол зоны
        const points = [...this.points];
        points[index] = toLatLongArr(event.target.getLatLng());
        // информируем об изменении
        this.$emit('onZoneElementChanged', this.getZoneElement(points), false);
      }
      else  if (event.type === 'dragend' && this.isEdited) {
        // перемещаем угол зоны
        const points = [...this.points];
        points[index] = toLatLongArr(event.target.getLatLng());
        // информируем об изменении
        this.$emit('onZoneElementChanged', this.getZoneElement(points), true);
      }
      // двойной щелчок на точку - удаляем её
      else if (event.type === 'dblclick' && this.isEdited) {
        this.onDelPoint(index);
      }
    },
    // события от самого элемента зоны
    onLeafletPolygonEvent(event) {
      // перетаскивание фигуры
      if (event.type === 'drag' && this.isEdited) {
        // информируем об изменении
        this.$emit('onZoneElementChanged', this.getZoneElement(event.target.getLatLngs()[0].map(point => toLatLongArr(point))), false);
      }
      else if (event.type === 'dragend' && this.isEdited) {
        // информируем об изменении
        this.$emit('onZoneElementChanged', this.getZoneElement(event.target.getLatLngs()[0].map(point => toLatLongArr(point))), true);
      }

      // проверяем и вызываем внешние события
      if (this.eventsToArray(this.events).includes(event.type)) {
        this.$emit('onLeafletEvent', event);
      }
    },
    // вызываем для удаления точки
    onDelPoint(index) {
      //мы не можем оставить меньше 3-х точек
      if (this.points.length <= 3) return;
      //удаляем точку
      const points = [...this.points];
      points.splice(index, 1);
      // информируем об изменении
      this.$emit('onZoneElementChanged', this.getZoneElement(points), true);
    },
    // вызывается для вставки точки
    onInsertPoint(event) {
      // запрашиваем координату
      const latlng = event.latlng;
      // формируем список точек
      const points = [...this.points];
      // неполноценный многоугольник
      if (points.length < 3) {
        points.push(toLatLongArr(latlng))
        // информируем об изменении
        this.$emit('onZoneElementChanged', this.getZoneElement(points), true);
        // выходим
        return;
      }
      // замыкаем цепочку
      points.push(points[0]);
      // ищем индекс точки за которой надо вставлять
      const index = getCloseLineIndex(latlng, points);
      // индекс нашли
      if (index >= 0) {
        // добавляем точку
        points.splice(index + 1, 0, toLatLongArr(latlng));
        // информируем об изменении
        this.$emit('onZoneElementChanged', this.getZoneElement(points), true);
      }
    },
    // возвращает структуру элемента зоны
    getZoneElement(points) {
      if (!points) points = this.points;

      return {
          zone_element_id: this.zoneElement.zone_element_id,
          zone_element_type_id: 3,
          zone_id: this.zoneElement.zone_id,
          order_num: this.zoneElement.order_num,
          parameter_size: 0,
          points: points.map((point, index) => {
            return {
              zone_element_id: this.zoneElement.zone_element_id,
              order_num: Number(index) + 1,
              latitude: Number(point[0]),
              longitude: Number(point[1]),
            }
          })
      }
    },
  },

  // монтируем слой
  mounted() {
    // ждем создание родителя
    this.getParent().parentReady().then((parent_list) => {
      this.leafletMap = this.getMap(parent_list);
      const map = this.leafletMap.origin;

      // следим за изменением режима редактирования
      this.$watch(() => this.isEdited, (newValue) => {
        if (newValue) {
          // подписываемся на событие карты
          map.on('dblclick', this.onInsertPoint);
        }
        else  {
          // отписываемся от события карты
          map.off('dblclick', this.onInsertPoint);
        }
      }, {immediate: true});
    })
  },
  // демонтируем слой
  unmounted() {
      if (this.leafletMap) {
        // отписываемся от события
        this.leafletMap.origin.off('dblclick', this.onInsertPoint);
      }
  },
}
</script>
