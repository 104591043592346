<script>
import {Polyline} from 'leaflet'
import LeafletPathMixin from "@/components/ui/leaflet/mixins/LeafletPathMixin";
import {toRaw} from "vue";

// ЛИНИЯ

export default {
  mixins: [LeafletPathMixin],
  props: {
    // точки линии
    latlngs: {
      type: Array,
      required: true
    },
    // опции слоя
    options: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      // тип объекта
      leafletObjectType: 'LeafletPolyline',
    }
  },
  methods: {
    // создание слоя
    createLayer() {
      const layer = new Polyline(toRaw(this.latlngs), {
        ...toRaw(this.options),
        bubblingMouseEvents: false
      });
      layer.__meta = this.meta;
      if (this.tooltip) {
        // подключаем подсказку к слою
        layer.bindTooltip(toRaw(this.tooltip));
      }
      // контекстное меню
      if (this.contextMenu) {
        layer.bindContextMenu(toRaw(this.contextMenu));
      }
      this.subscribe(layer);
      this.proxyOrigin = layer;
      return layer;
    },
    destroyLayer() {
      // удаляем подписку на события
      this.unsubscribe(this.origin);
      // отключаем контекстное меню от слоя
      this.origin.unbindContextMenu();
      // отключаем подсказку от слоя
      this.origin.unbindTooltip();
      // убиваем ссылку
      this.proxyOrigin = null;
    },
  },
  // монтируем слой
  mounted() {
    // ждем создание родителя
    this.getParent().parentReady().then(() => {
      // наблюдатель за координатами
      this.$watch(() => this.latlngs, (after, before) => {
        if (this.reCreateTimer) return;
        if (this.isLatLngsEqual(before, after)) return;
        //console.log('Требуется изменить точки линии <'+this.curComponent.leafletObjectType+'>: '+this.logTitle)
        this.origin.setLatLngs(toRaw(this.latlngs));
      }, {deep: false});
    });
  },
}
</script>
