<script>
import {toRaw} from "vue";
import { Control } from 'leaflet';
import LeafletControlMixin from "@/components/ui/leaflet/mixins/LeafletControlMixin";

export default {
  mixins: [LeafletControlMixin],
  data() {
    return {
      // тип объекта
      leafletObjectType: 'LeafletScaleControl',
    }
  },
  methods: {
    // создание элемента управления
    createControl() {
      const ctl = new Control.Scale(toRaw(this.options));
      ctl.__meta = this.meta;
      this.proxyOrigin = ctl;
      return ctl;
    },
    // уничтожение элемента управления
    destroyControl() {
      // уничтожаем элемент
      this.proxyOrigin = null;
    },
  },
}
</script>
