<template>
 <universal-selector v-model="value" :caption="this.$t('nsi.ModelSelectorCaption')" :itemList="itemList"></universal-selector>
</template>

<script>
import UniversalSelector from "@/components/ui/custom/UniversalSelector";
export default {
  name: "ModelSelector",
  components: {UniversalSelector},
  props: ['model-value'],
  watch: {

    itemList(newList) {
      if (newList.length == 0) {
        this.$emit('update:model-value', null)
      }
    }

  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },

    itemList() {
      return this.$store.getters['ref/getModels'].map(item => {
        return {
          id: item.md_id,
          name: item.md_title
        }
      });
    }
  },
}
</script>

<style scoped>

</style>