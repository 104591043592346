<template>
  <align-container>
    <align-panel align="all" :gap="3" style="overflow-y: auto;">
      <align-container>
        <align-panel align="top" :gap="3" style="overflow-y: auto; max-height: 40%">
          <race-card-table
              :selectedRow="selectedRaceCard"
              @setSelectedRow="setSelectedRaceCard"
          ></race-card-table>
        </align-panel>
        <align-panel align="all">
          <race-cards-map
              :activeStop="selectedRaceCard"
              :raceCardDialogMode="raceCardDialogMode"
              @onMapCreated="onMapCreated"
              @addRaceCard="addRaceCard"
          >
          </race-cards-map>
        </align-panel>
      </align-container>
    </align-panel>
    <align-panel align="right" :gap="3" style="min-width: 350px !important; overflow-x: hidden; overflow-y: hidden;">
      <race-card-dialog
          :item="selectedRaceCard ? {...selectedRaceCard} : null"
          :raceCardMode="raceCardDialogMode"
          @onEdit="onEditRaceCard"
          @onDelete="onDeleteRaceCard"
          @onDeleteAll="onDeleteAllRaceCard"
          @setRaceCardMode="setRaceCardMode"
      >
      </race-card-dialog>
    </align-panel>
  </align-container>
</template>

<script>
import RaceCardTable from "@/components/references/race_card/RaceCardTable";
import RaceCardsMap from "@/components/references/race_card/RaceCardsMap";
import {latLngBounds} from "leaflet";
import RaceCardDialog from "@/components/references/race_card/RaceCardDialog";
import {getDist} from "@/lib";

export default {
  name: "RaceCardPanel",
  components: {RaceCardDialog, RaceCardsMap, RaceCardTable},
  data() {
    return {
      // карта
      leaflet: null,
    }
  },
  computed: {

    // выбранная трасса
    selectedRace() {
      return this.$store.getters['ref/getSelectedRace'];
    },

    // выбранная остановка трассы
    selectedRaceCard() {
      return this.$store.getters['ref/getSelectedRaceCard'];
    },

    raceCardDialogMode() {
      return this.$store.getters['ref/getRaceCardDialogMode'];
    },

    parkStationsLength() {
      return this.$store.getters['ref/getParkStations'].length
    },
  },

  watch: {

    parkStationsLength() {
      this.correctingMapPosition();
    },

    selectedRaceCard(newValue) {
      if(newValue) {
        this.leaflet.setView([newValue.stopStationLatitude, newValue.stopStationLongitude], this.$store.getters['settings/getDefaultVehicleZoom']);
      }
    },
  },

  methods: {

    setRaceCardMode(newValue) {
      this.$store.commit('ref/setRaceCardDialogMode', newValue);
    },

// добавление остановки в рейс
    async addRaceCard(parkStation) {
      if (this.$store.getters['ref/getRaceCards'].length == 0) {
        let raceCard = {
          raceId: this.selectedRace.raceId,
          raceCardOrderby: 1,
          parkStationId: parkStation.ss_id,
          raceCardControlPointType: 1,
          raceCardDistanceAsKm: 0,
          stopStationLongname: parkStation.ss_longname,
          stopStationLatitude: parkStation.ss_latitude,
          stopStationLongitude: parkStation.ss_longitude,
          gisOrderby: 1,
          isRaceCardBelong: true,
          isRaceCardBelongInBase: true,
          tarifZoneID: 0,
          identity: 0,
          raceCardId: 0,
          sectionList: [],
        }
        let resultArr = [];
        resultArr.push(raceCard);
        // запрос на добавление сущности raceCard
        await this.$store.dispatch('ref/doAddRaceCard', {
          race: this.selectedRace,
          raceCards: resultArr
        });
      } else {
        let length = this.$store.getters['ref/getRaceCards'].length;
        let dist = getDist(this.$store.getters['ref/getRaceCards'][length-1].stopStationLatitude,this.$store.getters['ref/getRaceCards'][length-1].stopStationLongitude,
            parkStation.ss_latitude, parkStation.ss_longitude);
        let raceCard = {
          raceId: this.selectedRace.raceId,
          raceCardOrderby: length + 1,
          parkStationId: parkStation.ss_id,
          raceCardControlPointType: 1,
          raceCardDistanceAsKm: dist,
          stopStationLongname: parkStation.ss_longname,
          stopStationLatitude: parkStation.ss_latitude,
          stopStationLongitude: parkStation.ss_longitude,
          gisOrderby: length + 1,
          isRaceCardBelong: true,
          isRaceCardBelongInBase: true,
          tarifZoneID: 0,
          identity: 0,
          raceCardId: 0,
          sectionList: [],
        }
        let resultArr = [];
        resultArr.push(raceCard);
        // запрос на добавление сущности raceCard
        await this.$store.dispatch('ref/doAddRaceCard', {
          race: this.selectedRace,
          raceCards: resultArr
        });
      }
    },

    // создана карта
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    setSelectedRaceCard(value) {
      this.$store.commit('ref/setSelectedRaceCard', value);
    },

    correctingMapPosition() {
      let stops = this.$store.getters['ref/getParkStations'];
      if (stops.length === 1) {
        this.leaflet.setView([stops[0].ss_latitude, stops[0].ss_longitude], this.$store.getters['settings/getDefaultVehicleZoom']);
      } else if (stops.length > 1) {
        const bounds = latLngBounds(
            stops.map(vehicle => [vehicle.ss_latitude, vehicle.ss_longitude])
        )
        this.leaflet.fitBounds(bounds);
      }
    },

    async onEditRaceCard(raceCard) {
      try {
        this.isLoading = true;
        // редактируем трассу
        await this.$store.dispatch('ref/doEditRaceCard', {
          raceCard: raceCard,
          race: this.selectedRace,
        });

      } finally {
        this.isLoading = false;
      }
    },

    async onDeleteRaceCard() {
      let selectedIndex = this.$store.getters['ref/getRaceCards'].findIndex(value => this.selectedRaceCard.raceCardOrderby == value.raceCardOrderby);
      // удаляем raceCard-ы ро race
      await this.$store.dispatch('ref/doDeleteRaceCards', {
        race: this.selectedRace,
        raceCard: this.selectedRaceCard
      });
      this.selectedRace.raceLengthAsKm = await this.$store.dispatch('ref/doGetReisLength', {race: this.selectedRace});
      // пересчитываем карточки
      if (selectedIndex != (this.$store.getters['ref/getRaceCards'].length) && selectedIndex != 0) {
        this.recalculateDistance(selectedIndex);
      }
      if (selectedIndex == 0 && this.$store.getters['ref/getRaceCards'].length != 0 ) {
        this.$store.getters['ref/getRaceCards'][0].raceCardDistanceAsKm = 0;
      }

      // сохраняем остановки на сервере
      await this.$store.dispatch('ref/doAddRaceCards',{
        raceCards: this.$store.getters['ref/getRaceCards'],
        race: this.selectedRace
      })

      this.$store.commit('ref/setSelectedRaceCard', null);
      // в случае, если остановок в рейсе нет переключаем режим на добавление
      if(this.$store.getters['ref/getRaceCards'].length == 0 ) {
        this.$store.commit('ref/setRaceCardDialogMode', 'add');
      }
    },

    async onDeleteAllRaceCard() {
      // удаляем raceCard-ы ро race
      await this.$store.dispatch('ref/doDeleteRaceCards', {
        race: this.selectedRace,
      });
      this.selectedRace.raceLengthAsKm = await this.$store.dispatch('ref/doGetReisLength', {race: this.selectedRace});
      this.$store.commit('ref/setSelectedRaceCard', null);
      // так как остановок в рейсе нет переключаем режим на добавление
      if(this.$store.getters['ref/getRaceCards'].length == 0 ) {
        this.$store.commit('ref/setRaceCardDialogMode', 'add');
      }
    },

    // перерасчет расстояния
    recalculateDistance(selectedIndex) {
      this.$store.getters['ref/getRaceCards'][selectedIndex].raceCardDistanceAsKm = getDist(
          this.$store.getters['ref/getRaceCards'][selectedIndex-1].stopStationLatitude,
          this.$store.getters['ref/getRaceCards'][selectedIndex-1].stopStationLongitude,
          this.$store.getters['ref/getRaceCards'][selectedIndex].stopStationLatitude,
          this.$store.getters['ref/getRaceCards'][selectedIndex].stopStationLongitude);
    },


  },

}
</script>

<style scoped>

</style>