// ХРАНИЛИЩЕ МАРШРУТОВ
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            marshes: [],
        }
    },
    getters: {
        // вернуть список парков
        getMarshes(state) {
            return state.marshes;
        },
    },
    mutations: {
        // установить новый список объектов
        setMarshes(state, payload) {
            state.marshes = payload;
        },

        // добавляем список объектов
        addMarshes(state, payload) {
            payload.forEach(value => {
                state.marshes.push(value);
            })
        },

        // добавить объект
        addMarsh(state, payload) {
            state.marshes.push(payload)
        },

        // изменить объект
        editMarsh(state, payload) {
            const index = state.marshes.findIndex(depot => depot.routeId == payload.routeId);
            if (index >= 0) {
                state.marshes[index] = payload;
            }
        },

        delMarsh(state, payload) {
            const index = state.marshes.findIndex(depot => depot.routeId == payload.routeId);
            state.marshes.splice(index, 1);
        },

    },

    actions: {

        // получение объекта (полученные значения не заменяют собой уже сохраненный массив, а добавляются к нему)
        async doGetMarshes(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.park));
            params.append("transportTypeFilter", JSON.stringify(payload.transportType));

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/MarshRestController/getMarshesRemote',
                params.toString(),
                'ref/addMarshes',
                'Получение маршрутов'
            );
        },

        async isExistsLinks(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonMarsh", JSON.stringify(payload.marsh));

            return await DoPost(
                store.getters['settings/getBaseUrl'] + '/MarshRestController/isExistsLinksRemote',
                params.toString(),
                null,
                'Проверяем ссылки на другие объекты'
            );
        },

        // добавление объекта
        async doAddMarsh(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonMarsh", JSON.stringify(payload.marsh));
            params.append("jsonTransportType", JSON.stringify(payload.transportType));
            // кодируем данные для корректного отображения русского текста на сервере
            const encoded = encodeURI(params.toString())
            let id = await DoPost(
                store.getters['settings/getBaseUrl'] + '/MarshRestController/insertMarshRemote',
                encoded,
                null,
                'Добавление маршрута'
            );
            payload.marsh.routeId = id;
            context.commit('addMarsh', payload.marsh);
        },

        // редактирование объекта
        async doEditMarsh(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonMarsh", JSON.stringify(payload.marsh));
            params.append("jsonTransportType", JSON.stringify(payload.transportType));
            // кодируем данные для корректного отображения русского текста на сервере
            const encoded = encodeURI(params.toString())
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/MarshRestController/updateMarshRemote',
                encoded,
                null,
                'Редактирование маршрута'
            );

            context.commit('editMarsh', payload.marsh);
        },

        // удаление объекта
        async doDeleteMarsh(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonMarsh", JSON.stringify(payload.marsh));
            params.append("jsonTransportType", JSON.stringify(payload.transportType));

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/MarshRestController/deleteMarshRemote',
                params.toString(),
                null,
                'Удаление маршрута'
            );

            context.commit('delMarsh', payload.marsh);
        },

    }
}
