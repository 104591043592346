import {createRouter, createWebHistory} from 'vue-router';

import store from '../store/Store';
import AuthPage from '../pages/auth/AuthPage.vue';
import UmkrPage from "@/pages/umkr/UmkrPage";
import ProcessingPage from "@/pages/processing/ProcessingPage";
import ReportsPage from "@/pages/reports/ReportsPage";
import MainPage from "@/pages/MainPage";
import TsNavHistoryPage from "@/pages/umkr/TsNavHistoryPage";
import ReferencesPage from "@/pages/ref/ReferencesPage";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/auth',
            name: 'auth',
            component: AuthPage
        },
        {
            path: '/',
            redirect: '/umkr',
            component: MainPage,
            // name: 'main-page',
            children: [
            {
                path: '/references',
                component: ReferencesPage,
                name: 'references'
            },
            {
                path: '/umkr/:formation_id(\\d+)?/:park_id(\\d+)?/:ts_id(\\d+)?/:date_index(\\d+)?/tsNavHistory',
                component: TsNavHistoryPage,
                props: true,
                name: 'tsNavHistory'
            },
            {
                path: '/umkr',
                component: UmkrPage,
                name: 'umkr'
            },
            {
                path: '/processing',
                component: ProcessingPage,
                name: 'processing'
            },
            {
                path: '/reports',
                component: ReportsPage,
                name: 'reports'
            },
            {
                path: '/:pathMatch(.*)*',
                component: MainPage,
            }
        ]},
    ]
});

// процедура загрузки приложения
async function loading(to) {
    // // загружаем файл настроек
    // const result = await store.dispatch('settings/doGetSettings');
    // // ошибка настроек
    // if (!result) {
    //     // устанавливаем панику
    //     await store.dispatch('doSetPanic', {
    //         panicMessage: 'Файл настроек не найден или имеет неверный формат.'
    //     })
    //     // переходим на страницу паники
    //     return ('/panic')
    // }

    // фиксируем успешную загрузку
    await store.dispatch('doSetLoaded');

    // пытаемся обновить сессию
    await store.dispatch('doAutoLogin');

    // возвращаем исходный путь
    return to;
}

// проверяем пути перед входом
router.beforeEach( (to) => {

    // запускаем загрузку приложения
    if (!store.getters.getIsLoaded) {
        // если мы идем в окно паники во время паники - все хорошо
        // if (to.name === 'panic' && store.getters.getIsPanic)
        //     return (true)
        // запускаем загрузку приложения
        return loading(to);
    }

    // // не разрешаем заходить в панику напрямую
    // if (to.name === 'panic') return ('/');

    // авторизация есть, и пытаемся зайти на авторизацию
    if (store.getters.getIsAuth && to.name === 'auth') return ('/');

    // авторизации нет, но пытаемся зайти куда-то ещё
    if (!store.getters.getIsAuth && to.name !== 'auth') {
        // запоминаем откуда уходим
        store.dispatch('doSetLoadedUrl', {
            loadedUrl: to
        });
        // идем авторизироваться
        return ('/auth')
    }

    // разрешаем
    return true;
})

export default router;
