<template>
  <leaflet-zoom-control logTitle="ZoomControl"></leaflet-zoom-control>
<!--  <leaflet-attribution-control logTitle="AttributionControl"></leaflet-attribution-control>-->
  <leaflet-scale-control logTitle="ScaleControl"></leaflet-scale-control>
  <leaflet-layers-control logTitle="LayersControl">
    <slot></slot>
  </leaflet-layers-control>
</template>

<script>
export default {
  data() {
    return {
      // тип объекта
      leafletObjectType: 'LeafletControlGroup',
    }
  }
}
</script>

<style scoped>

</style>
