<template>
  <base-polyline
      :originalOptions="routingLineOptions"
      :activeOptions="routingLineOptions"
      :editOptions="routingLineOptions"
      :disabledOptions="routingLineOptions"
  >
  </base-polyline>
</template>

<script>
// ПУНКТИРНАЯ ЛИНИЯ ДЛЯ ПРОКЛАДКИ МАРШРУТА
export default {
  props: {

    // настройки базовой линии типа рейса
    routingLineOptions: {
      type: Object,
      default: () => {
        return {
          color: 'red',
          opacity: 1.0,
          draggable: false,
          weight: 3.0,
          dashArray: '10, 10',
          dashOffset: '0'
        }
      }
    },
  },

  data() {
    return {
      // тип объекта
      leafletObjectType: 'RoutingLinePolyline',
    }
  },
}
</script>
