// ХРАНИЛИЩЕ ТЕР. ОБРАЗОВАНИЙ
import {DoPost} from "@/store/Requests";
import store from '@/store/Store';

export default {
    state() {
        return {
            // список тер. образований
            formations: [],
        }
    },
    getters: {
        // вернуть список тер. образований
        getFormations(state) {
            return state.formations;
        },
    },
    mutations: {
        // установить новый список объектов
        setFormations(state, payload) {
            state.formations = payload;
            state.formationsCache = new Date().getTime()
        },
    },
    actions: {

        // добавление объекта
        async doGetFormations() {

            // чтобы получить formation, нужно сначала получить роль пользователя
            let payload = {
                user_name: store.getters.getLogin
            }
            let userRole = await DoPost(store.getters['settings/getBaseUrl'] +
                '/UserRestController/getUserRoleRemote',
                payload,
                null,
                'Получение роли пользователя'
            )

            //чтобы получить доступные парки, нужно сначала получить formation
            let json = {};
            json.jsonUser = {
                id_user: 0,
                login: store.getters.getLogin,
                password: store.getters.getPassword,
                isMaster: false,
                identity:13524685,
                user_role: userRole
            }

            const paramsF = new URLSearchParams();
            paramsF.append("jsonUser", JSON.stringify(json.jsonUser));
            return await DoPost(store.getters['settings/getBaseUrl'] +
                '/ParkRestController/getAccessFormationsRemote',
                 paramsF.toString(),
                'ref/setFormations',
                'Получение тер. образований'
            )
        },
    }
}
