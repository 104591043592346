<template>
  <align-container>
    <align-panel align="top" height="50%" :gap="3" style="overflow: auto">
      <table class="table">
        <thead class="thead-custom">
          <tr>
            <th scope="col" class="fix-head-cell-index" v-for="(value,index) in columns" :key="index"
                :class=" index== 0 ? 'first-col' : ( index== 1 ? 'second-col' : ( index== 2 ? 'third-col' : ( index== 3 ? 'fourth-col' :
                        ( index== 4 ? 'fifth-col' : ( index== 5 ? 'sixth-col' : '')))))"
                :style=" index== 0 ? 'padding-right: 1px; padding-left: 1px;':''">{{ value }}
            </th>
            <th scope="col" v-for="value in getColums" :key="value" >{{
                getDateFormat(value)
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in getRows" :key="index">
            <td class="first-col fix-cell-index"
                :style="selectedTsId == value.ts_id ? 'background: #76bafc' : ''">
              {{ index + 1 }}
            </td>
            <td class="second-col fix-cell-index"
                :style="selectedTsId == value.ts_id ? 'background: #76bafc' : ''">
              {{ value.ts_garagnum }}
            </td>
            <td class="third-col fix-cell-index"
                :style="selectedTsId == value.ts_id ? 'background: #76bafc' : ''">
              {{ value.ts_statenum }}
            </td>
            <td class="fourth-col fix-cell-index"
                :style="selectedTsId == value.ts_id ? 'background: #76bafc;' : ''">
              {{ value.mk_name }}
            </td>
            <td class="fifth-col fix-cell-index"
                :style="selectedTsId == value.ts_id ? 'background: #76bafc;' : ''">
              {{ value.rsNum.rs_stationnum }}
            </td>
            <td class="sixth-col fix-cell-index"
                :style="selectedTsId == value.ts_id ? 'background: #76bafc;' : ''">
              {{ value.apcTypeName }}
            </td>
            <td
                v-for="(date, index) in getColums"
                :key="index"
                :style="[this.selectedTsId == value.ts_id && this.selectedDate == date ? 'background: #76bafc;' : ''] + 'color:' + returnColor(value,date)+ ';'"
                @click="setSelectedCell(value, date)"
            >
              {{ getValueByViewMode(value, date) }}
            </td>
          </tr>
        </tbody>
      </table>
    </align-panel>

    <align-panel align="all" style="overflow: auto">
      <table class="table">
        <thead class="thead-custom">
        <tr>
          <th scope="col" v-for="(value, index) in bottomColumns" :key="index"
              :class="index== 0 ? 'fix-head-cell-index ts-col' : ''"
              :style="index == 16 ? 'min-width: 85px !important;' : ''">
            {{ value }}
          </th>
        </tr>
        </thead>
        <tbody v-if="selectedDate">
          <tr v-for="(value, index) in getRows" :key="index" :id="'ts_'+ value.ts_id"
              :style="value.ts_id == this.selectedTsId ? 'background-color: #76bafc;' : 'background-color: #fff;'">
            <td class="ts-col fix-ts-cell" style="min-width: 80px; max-width: 80px;">
              {{ value.ts_garagnum }}
            </td>
            <td style="min-width: 60px; max-width: 60px;">
              {{ value.info[selectedDate].door_count ? value.info[selectedDate].door_count[1] : '' }}
            </td>
            <td style="min-width: 60px; max-width: 60px;">
              {{ value.info[selectedDate].door_count ? value.info[selectedDate].door_count[2] : '' }}
            </td>
            <td style="min-width: 60px; max-width: 60px;">
              {{ value.info[selectedDate].door_count ? value.info[selectedDate].door_count[3] : '' }}
            </td>
            <td style="min-width: 60px; max-width: 60px;">
              {{ value.info[selectedDate].door_count ? value.info[selectedDate].door_count[4] : '' }}
            </td>
            <td>
              {{ value.info[selectedDate].door_count ? value.info[selectedDate].blackbox_count : '' }}
            </td>
            <td>
              {{ value.info[selectedDate].door_count ? value.info[selectedDate].door_pin[1] : '' }}
            </td>
            <td>
              {{ value.info[selectedDate].door_count ? value.info[selectedDate].door_pout[1] : '' }}
            </td>
            <td>
              {{ value.info[selectedDate].door_count ? value.info[selectedDate].door_pin[2] : '' }}
            </td>
            <td>
              {{ value.info[selectedDate].door_count ? value.info[selectedDate].door_pout[2] : '' }}
            </td>
            <td>
              {{ value.info[selectedDate].door_count ? value.info[selectedDate].door_pin[3] : '' }}
            </td>
            <td>
              {{ value.info[selectedDate].door_count ? value.info[selectedDate].door_pout[3] : '' }}
            </td>
            <td>
              {{ value.info[selectedDate].door_count ? value.info[selectedDate].door_pin[4] : '' }}
            </td>
            <td>
              {{ value.info[selectedDate].door_count ? value.info[selectedDate].door_pout[4] : '' }}
            </td>
            <td style="min-width: 85px !important;">
              {{ getWorkTime(value) }}
            </td>
            <td>
              {{ value.info[selectedDate].bad_nav }}
            </td>
            <td>
              {{ value.info[selectedDate].jumps_nav }}
            </td>
            <td>
              {{ (value.info[selectedDate].door_irmafault ? value.info[selectedDate].door_irmafault[1] : '') + (value.info[selectedDate].door_sabotate ? value.info[selectedDate].door_sabotate[1] : '')}}
            </td>
            <td>
              {{ (value.info[selectedDate].door_irmafault ? value.info[selectedDate].door_irmafault[2] : '') + (value.info[selectedDate].door_sabotate ? value.info[selectedDate].door_sabotate[2] : '')}}
            </td>
            <td>
              {{ (value.info[selectedDate].door_irmafault ? value.info[selectedDate].door_irmafault[3] : '') + (value.info[selectedDate].door_sabotate ? value.info[selectedDate].door_sabotate[3] : '')}}
            </td>
            <td>
              {{ (value.info[selectedDate].door_irmafault ? value.info[selectedDate].door_irmafault[4] : '') + (value.info[selectedDate].door_sabotate ? value.info[selectedDate].door_sabotate[4] : '')}}
            </td>
            <td>
              {{ value.info[selectedDate].disb }}
            </td>
            <td style="min-width: 120px; max-width: 120px;">
              {{ value.info[selectedDate].message }}
            </td>
          </tr>
        </tbody>
      </table>
    </align-panel>
  </align-container>



</template>

<script>
import {dateToND, getTimeFormat, secondsToDate, stringToDate} from "@/lib";

export default {

  // emits: ['set-selected-date-index-umkr'],

  data() {
    return {
      // выбранная дата
      // selectedDate: null,
      // выбранное значение
      // selectedValue: null,

      PSGRS_COUNT_IN_UNDETECTED_REISES: 30,
      DISB_PSGRS: 2,
    }
  },

  computed: {

    selectedDate() {
      return this.$store.getters['ref/getUserUmkrDateIndex'];
    },
    selectedTsId() {
      return this.$store.getters['ref/getUserUmkrTsId'];
    },

    columns() {
      return [
        this.$t('UmkrPage.OrderNum'), //"№",
        this.$t('UmkrPage.GaragNum'), //"Гар.№",
        this.$t('UmkrPage.StateNum'), //"Гос.№",
        this.$t('UmkrPage.Model'), //"Модель",
        this.$t('UmkrPage.RadioStation'), //"№р/с",
        this.$t('UmkrPage.Equipment'), //"Оборуд."
      ]
    },

    bottomColumns() {
      return [
        this.$t('UmkrPage.Ts'), //"ТС",
        this.$t('UmkrPage.1DoorRec'), //"Зап. 1я",
        this.$t('UmkrPage.2DoorRec'), //"Зап. 2я",
        this.$t('UmkrPage.3DoorRec'), //"Зап. 3я",
        this.$t('UmkrPage.4DoorRec'), //"Зап. 4я",
        this.$t('UmkrPage.BlackBox'), //"ЧЯ",
        this.$t('UmkrPage.1DoorIn'), //"Вх. 1я",
        this.$t('UmkrPage.1DoorOut'), //"Вых. 1я",
        this.$t('UmkrPage.2DoorIn'), //"Вх. 2я",
        this.$t('UmkrPage.2DoorOut'), //"Вых. 2я",
        this.$t('UmkrPage.3DoorIn'), //"Вх. 3я",
        this.$t('UmkrPage.3DoorOut'), //"Вых. 3я",
        this.$t('UmkrPage.4DoorIn'), //"Вх. 4я",
        this.$t('UmkrPage.4DoorOut'), //"Вых. 4я",
        this.$t('UmkrPage.WorkTime'), //"Время работы",
        this.$t('UmkrPage.ZeroNav'), //"Нул. Нав.",
        this.$t('UmkrPage.HopNav'), //"Скач. Нав.",
        this.$t('UmkrPage.1DoorFailure'), //"Сбой 1я",
        this.$t('UmkrPage.2DoorFailure'), //"Сбой 2я",
        this.$t('UmkrPage.3DoorFailure'), //"Сбой 3я",
        this.$t('UmkrPage.4DoorFailure'), //"Сбой 4я",
        this.$t('UmkrPage.Disbalance'), //"Дисб.",
        this.$t('UmkrPage.Reason'), //"Причина"
      ]
    },

    getColums() {
      let resultArr = [];
      let startDate = dateToND(stringToDate(this.$store.getters['ref/getUserStartDate']));
      let endDate = dateToND(stringToDate(this.$store.getters['ref/getUserEndDate']));
      for (let i = startDate; i <= endDate; i++) {
        resultArr.push(i);
      }
      return resultArr;
    },

    getRows() {
      return this.$store.getters['umkr/getStatInitItog'];
      // let ts = this.$store.getters['umkr/getTS'];
      // let statInitAr = null;
      // statInitAr = this.$store.getters['umkr/getStatInit'];
      // let resultArr = [];
      // if (statInitAr.length != 0) {
      //   ts.map(value => {
      //     let statInit = statInitAr.find(statInit => value.ts_id == statInit.ts_id);
      //     if (statInit) {
      //       value['info'] = statInit['info'];
      //     }
      //     let apcType = this.$store.getters['rep/getApcTypes'].find(apc => apc.apcType_id == value.apcTypeID);
      //     if (apcType) {
      //       value.apcTypeName = apcType.apcTypeName;
      //     } else {
      //       value.apcTypeName = '-';
      //     }
      //     resultArr.push(value);
      //   })
      // };
      // return resultArr;
    }
  },

  watch: {
    // getRows() {
    //   this.selectedDate = null;
    //   this.selectedValue = null;
    //   this.updateSelectedDateIndex();
    //   this.updateSelectedTSID();
    // }
  },

  methods: {

    updateSelectedDateIndex(dateIndex) {
      this.$store.commit('ref/setUserUmkrDateIndex', dateIndex);
      //this.$emit('set-selected-date-index-umkr', this.selectedDate);
    },

    updateSelectedTSID(ts_id) {
      if (ts_id) {
        this.$store.commit('ref/setUserUmkrTsId', ts_id);
        //this.$emit('set-selected-ts-id-umkr', this.selectedValue.ts_id);
      }
    },

    getDateFormat(value) {
      let date = secondsToDate(value * 60 * 60 * 24).substring(5);
      let month = date.substring(0, 2);
      let day = date.substring(3);
      return day + '.' + month;
    },

    setSelectedCell(value, date) {
      // this.selectedValue = value;
      // this.selectedDate = date;
      this.updateSelectedDateIndex(date);
      this.updateSelectedTSID(value.ts_id);
      this.$nextTick(() =>{
        let el = document.getElementById('ts_' + value.ts_id);
        if (el) {
          // Use el.scrollIntoView() to instantly scroll to the element
          el.scrollIntoView({behavior: 'auto', block: 'center'});
        }
      })
    },

    returnColor(value, date) {
      if (value && value.info && value.info[date]) {
        let info = value.info[date];
        if (info.message && info.message != '') {
          return '#ff0000';
        } else {
          return '';
        }
      }
    },

    getValueByViewMode(value, date) {
      if (value && value.info && value.info[date]) {
        let info = value.info[date];
        let viewMode = this.$store.getters['ref/getUserTableUmkrViewVariant'];
        if (viewMode == 'Disb') {
          return info['disb'];
        } else if (viewMode == 'Transportation') {
          return info['psgrs_count'];
        } else if (viewMode == 'Nariad') {
          if(info['comm'] == '') {
            return 'X';
          } else {
            return info['comm'];
          }
        } else if (viewMode == 'ASMPP') {
          return info['psgrs_count'] + '/' + info['asop'] + '/' + (info['psgrs_count'] - info['asop']);
        } else if (viewMode == 'InOut') {
          let in_sum = (info['door_pin'][1] + info['door_pin'][2] + info['door_pin'][3] + info['door_pin'][4]);
          let out_sum = (info['door_pout'][1] + info['door_pout'][2] + info['door_pout'][3] + info['door_pout'][4]);
          return in_sum + '/' + out_sum + '/' + (in_sum - out_sum);
        }
      } else {
        return '';
      }
    },

    getWorkTime(value) {
      return getTimeFormat(value.info[this.selectedDate].timeIndexStart) + '-' + getTimeFormat(value.info[this.selectedDate].timeIndexEnd)
    },

    // получаем диагностическое сообщение
    getCommProc(value, date) {
      let comm = '';
      if (value.info && value.info[date] && value.info[date].proc_diagn_msgs) {
        let statistic = value.info[date];
        let arr = Object.entries(statistic.proc_diagn_msgs);
        for (let i = 0; i < arr.length; i++) {
          if(arr[i][1] == this.$store.getters['getValues'].ERROR1_TITLE) {
            comm += this.$store.getters['getValues'].ERROR1_VALUE.replace(/%s/,statistic.passCountInUndetectedReises).replace(/%d/, this.PSGRS_COUNT_IN_UNDETECTED_REISES);
            comm += '; '
          }
          if(arr[i][1] == this.$store.getters['getValues'].ERROR2_TITLE) {
            comm += this.$store.getters['getValues'].ERROR2_VALUE.replace(/%s/, statistic.avg_reis_disb_pos).replace(/%d/, statistic.avg_reis_disb_neg).replace(/%f/,statistic.avg_reis_disb_pos)
                .replace(/%g/, this.DISB_PSGRS).replace(/%h/, statistic.avg_reis_disb_neg);
          } else if (arr[i][1] == this.$store.getters['getValues'].ERROR3_TITLE) {
            comm += this.$store.getters['getValues'].ERROR3_VALUE.replace(/%s/, statistic.avg_reis_disb_pos).replace(/%d/, statistic.avg_reis_disb_neg).replace(/%f/,statistic.avg_reis_disb_neg)
                .replace(/%g/, this.DISB_PSGRS).replace(/%h/, statistic.avg_reis_disb_pos);
          }
        }
      }
      return comm;
    },
  }

}
</script>

<style scoped>

.table {
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.8rem;
}

.table .thead-custom th {
  color: #fff;
  background-color: #17a2b8;
  border: 1px solid #dee2e6;
  background-clip: padding-box;
  text-align: center;
  vertical-align: middle;
  position: sticky;
  top: 0px;
}

td {
  border: 1px solid #dee2e6;
  padding: 0 6px;
  cursor: default;
}

.fix-head-cell-index {
  /*position: sticky;*/
  z-index: 10;
}

.fix-cell-index {
  position: sticky;
  z-index: 5;
  border: 1px solid #dee2e6;
  background-clip: padding-box;
  background-color: #fff;
}

.first-col {
  left: 0px;
  max-width: 26px;
  min-width: 26px;
}

.second-col {
  left: 26px;
  max-width: 60px;
  min-width: 60px;
}

.third-col {
  left: 86px;
  max-width: 80px;
  min-width: 80px;
}

.fourth-col {
  left: 166px;
  max-width: 112px;
  min-width: 112px;
}

.fifth-col {
  left: 278px;
  max-width: 80px;
  min-width: 80px;
}

.sixth-col {
  left: 358px;
  max-width: 90px;
  min-width: 90px;
}

/* фиксация колонки ТС у нижней таблицы */
.fix-ts-cell {
  position: sticky;
  z-index: 5;
  border: 1px solid #dee2e6;
  background-clip: padding-box;
}

.ts-col {
  left: 0px;
  max-width: 60px;
  min-width: 60px;
}

</style>