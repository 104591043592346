// ХРАНИЛИЩЕ ПАРКОВ
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            parks: [],
        }
    },
    getters: {
        // вернуть список парков
        getParks(state) {
            return state.parks;
        },
    },
    mutations: {
        // установить новый список объектов
        setParks(state, payload) {
            state.parks = payload;
        },

    },
    actions: {

        // добавление объекта
        async doGetParks(context, payload) {

            const params = new URLSearchParams();
            params.append("jsonFormation", JSON.stringify(payload.formation));
            params.append("app_user", store.getters.getLogin);
            params.append("app_pswd", store.getters.getPassword);
            params.append("withUnactiveParks", "true");

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/ParkRestController/getAccessParksRemote',
                params.toString(),
                'ref/setParks',
                'Получение парков'
             );

        },
    }
}
