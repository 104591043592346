// РАБОТА С ПРИЗНАКОМ ОТКЛЮЧЕННОСТИ
export default {
    props: {
        // признак отключенности
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            // текущее состояния отключенности
            isDisabled: this.disabled
        }
    },
    watch: {
        // следим за изменением свойства
        disabled() {
            this.isDisabled = this.disabled
        },
    },
    methods: {
        // включить компонент
        enable() {
            this.isDisabled = false
        },
        // выключить компонент
        disable() {
            this.isDisabled = true
        }
    }
}