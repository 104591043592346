<template>
<table-component :columnList="columnList" :rowList="rowList"></table-component>
</template>

<script>
import TableComponent from "@/components/ui/custom/TableComponent";
import {dateToString, ndToDate} from "@/lib";

export default {
  components: {TableComponent},
  computed: {
    // колонки таблицы
    columnList(){
      return [
        {
          columnName: "__routeVariantIndexBegin",
          columnTitle: this.$t("nsi.MarshVariantTable.RouteVariantIndexBegin"),
          isVisible: true
        },
        {
          columnName: "__routeVariantIndexEnd",
          columnTitle: this.$t("nsi.MarshVariantTable.RouteVariantIndexEnd"),
          isVisible: true
        },
      ]
    },
    // строки таблицы
    rowList(){
      return this.$store.getters['ref/getMarshVariants'].map(value => {
        value.__routeVariantIndexBegin = dateToString(ndToDate(value.routeVariantIndexBegin));
        value.__routeVariantIndexEnd = dateToString(ndToDate(value.routeVariantIndexEnd));
        return value;
      })
    }
  }
}
</script>

<style scoped>

</style>