import GeneralLink from "@/components/ui/bootstrap/general/GeneralLink";

import NavTogglerLink from './nav/NavTogglerLink.vue';

import Dropdown from './dropdown/Dropdown.vue';
import DropdownMenu from './dropdown/DropdownMenu.vue';
import DropdownItem from './dropdown/DropdownItem.vue';
import DropdownDivider from './dropdown/DropdownDivider.vue';

import Modal from './modal/Modal.vue';
import ModalDialog from './modal/ModalDialog.vue';
import ModalContent from './modal/ModalContent.vue';
import ModalHeader from './modal/ModalHeader.vue';
import ModalTitle from './modal/ModalTitle.vue';
import ModalBody from './modal/ModalBody.vue';
import ModalFooter from './modal/ModalFooter.vue';

import ButtonToggler from './button/ButtonToggler.vue';

import ButtonClose from './button/ButtonClose.vue';
import BootstrapModal from "@/components/ui/bootstrap/modal/BootstrapModal";
import NavMenu from "@/components/ui/bootstrap/nav/NavMenu";
import NavItem from "@/components/ui/bootstrap/nav/NavDropdownItem";
import NavDropdownItem from "@/components/ui/bootstrap/nav/NavItem";
import NavLink from "@/components/ui/bootstrap/nav/NavLink";
import TabContent from "@/components/ui/bootstrap/nav/TabContent";
import TabPane from "@/components/ui/bootstrap/nav/TabPane";


export default function(app) {
    app.component('general-link', GeneralLink);

    app.component('nav-menu', NavMenu);
    app.component('nav-link', NavLink);
    app.component('nav-item', NavItem);
    app.component('nav-toggler-link', NavTogglerLink);
    app.component('nav-dropdown-item', NavDropdownItem);
    app.component('tab-content', TabContent);
    app.component('tab-pane', TabPane);

    // eslint-disable-next-line vue/multi-word-component-names
    app.component('dropdown', Dropdown);
    app.component('dropdown-menu', DropdownMenu);
    app.component('dropdown-item', DropdownItem);
    app.component('dropdown-divider', DropdownDivider);

    // eslint-disable-next-line vue/multi-word-component-names
    app.component('modal', Modal);
    app.component('modal-dialog', ModalDialog);    
    app.component('modal-content', ModalContent);
    app.component('modal-header', ModalHeader);
    app.component('modal-title', ModalTitle);
    app.component('modal-body', ModalBody);
    app.component('modal-footer', ModalFooter);
    app.component('modal-modal', BootstrapModal)

    app.component('button-toggler', ButtonToggler);
    app.component('button-close', ButtonClose);

}
