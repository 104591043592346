// ХРАНИЛИЩЕ ОБОРУДОВАНИЯ
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            apcTypesArr: [],
        }
    },
    getters: {
        // вернуть список объектов
        getApcTypes(state) {
            return state.apcTypesArr;
        },
    },
    mutations: {
        // установить новый список объектов
        setApcTypes(state, payload) {
            state.apcTypesArr = payload;
        },
    },
    actions: {

        // добавление объекта
        async doGetApcTypes(state,payload) {
            //формируем параметра запроса
            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.curPark));

            return await DoPost(store.getters['settings/getBaseUrl'] +
                '/ApcTypeRestController/getApcTypeRemote',
                params.toString(),
                'rep/setApcTypes',
                'Получение оборудования'
            );
        },
    }
}