<template>
  <align-container>
    <align-panel align="all" style="overflow-y: auto" :gap="3">
      <race-table
          :selectedRow="selectedRace"
          @setSelectedRow="setSelectedRace"
          @setDblClick="setSelectedRaceForTab"
      >
      </race-table>
    </align-panel>
    <align-panel align="right" style="min-width: 350px !important; overflow-x: hidden; overflow-y: hidden;">
      <race-dialog
          style="overflow-y: auto"
          :item="selectedRace ? {...selectedRace} : null"
          :selectedMarshVar="selectedMarshVariant"
          @onSave="onSaveRace"
          @onEdit="onEditRace"
          @onDelete="onDeleteRace"
      >
      </race-dialog>
    </align-panel>
  </align-container>
</template>

<script>

import RaceDialog from "@/components/references/race/RaceDialog";
import RaceTable from "@/components/references/race/RaceTable";

export default {
  name: "RacePanel",
  components: {RaceTable, RaceDialog},
  emits: ['setSelectedDir'],
  computed: {

    // выбранная трасса
    selectedRace() {
      return this.$store.getters['ref/getSelectedRace'];
    },

    // выбранный период действия
    selectedMarshVariant() {
      return this.$store.getters['ref/getSelectedMarshVariant'];
    },

    // выбранная трасса
    selectedRaceForTab() {
      return this.$store.getters['ref/getSelectedRaceForTab'];
    },

  },
  methods: {

    setSelectedRace(value) {
      this.$store.commit('ref/setSelectedRace', value);
    },

    async onSaveRace(race) {
      try {
        this.isLoading = true;
        // добавляем трассу
        await this.$store.dispatch('ref/doAddRace', {
          marshVariant: this.selectedMarshVariant,
          race: race,
        });

      } finally {
        this.isLoading = false;
      }
    },

    // по даблклику на таблице Периодов действия делаем запрос Трасс
    async setSelectedRaceForTab(race) {
      // загружаем все остановки по парку
      this.$store.commit('ref/setSelectedRaceForTab', race);
      this.$emit('setSelectedDir', 'raceCards');
      let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.$store.getters['ref/getUserPark']);

      // this.$store.commit('ref/setRaceCards', []);
      // загружаем остановки трассы
      if (this.selectedRaceForTab.fl_composit) {
        let compReises = await this.$store.dispatch('ref/doGetCompRaces', {
          race: this.selectedRaceForTab,
        });
        for (const race of compReises) {
          await this.$store.dispatch('ref/doGetAllRaceCards', {
            park: park,
            race: race,
          })
        }
      } else {
        await this.$store.dispatch('ref/doGetAllRaceCards', {
          park: park,
          race: this.selectedRaceForTab,
        })
      }
    },

    async onEditRace(race) {
      try {
        this.isLoading = true;
        // редактируем трассу
        await this.$store.dispatch('ref/doEditRace', {
          marshVariant: this.selectedMarshVariant,
          race: race,
        });

      } finally {
        this.isLoading = false;
      }
    },

    async onDeleteRace() {
      try {
        this.isLoading = true;
        //проверяем, есть ли ссылки на другие объекты
        let isExist = await this.$store.dispatch('ref/isExistsRacesLinks', {
          race: this.selectedRace,
        });
        if (isExist) {
          this.errorMessageText = this.$t("nsi.RacePanelErrorText");
          return;
        }
        //  если можно удалить нитку маршута, удаляем её
        await this.$store.dispatch('ref/doDeleteMarsec', {
          race: this.selectedRace,
        });
        // если можно удалить трассу, удаляем его
        await this.$store.dispatch('ref/doDeleteRace', {
          marshVariant: this.selectedMarshVariant,
          race: this.selectedRace,
        });
        this.selectedRace = null;
      } finally {
        this.isLoading = false;
      }
    },

  }
}
</script>

<style scoped>

</style>