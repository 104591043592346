<template>
  <!-- Основное окно -->
  <div>
    <align-container>
      <!-- Меню с параметрами -->
      <align-panel align="top">
        <div class="container" style="max-width: 100%; max-height: 10%;">
          <div class="row">
<!--            :selected-date-index-umkr="selectedDateIndexUmkr"-->
<!--            :selected-ts-id-umkr="selectedTsIdUMKR"-->
            <params-chooser-panel
                v-if="this.$route.name !== '//'">
            </params-chooser-panel>
          </div>
        </div>
      </align-panel>
      <!-- Таблицы -->
      <align-panel align="all" style="overflow: auto">
<!--        @set-selected-date-index-umkr='setSelectedDateIndexUmkr'-->
<!--        @set-selected-ts-id-umkr='setSelectedTsIdUMKR'-->
        <router-view></router-view>
      </align-panel>
    </align-container>
  </div>
</template>

<script>
import ParamsChooserPanel from "@/components/ui/custom/ParamsChooserPanel.vue";

export default {
  components: {
    ParamsChooserPanel
  },

  data() {
    return {
      // isv: 0,
      // видимость авторизации
      showAuth: true,
      // загрузка
      isLoading: false,
      // статус аутентификации
      isError: false,
      // выбранный в таблице umkr dateIndex
      // selectedDateIndexUmkr: null,
      // выбранное в таблице umkr ts_id
      // selectedTsIdUMKR: null,
    }
  },

  methods: {

    // setSelectedDateIndexUmkr(dateIndex) {
    //   this.selectedDateIndexUmkr = dateIndex;
    // },
    //
    // setSelectedTsIdUMKR(ts_id) {
    //   this.selectedTsIdUMKR = ts_id;
    // },

  },

  async mounted() {
    await this.$store.dispatch('ref/doGetFormations');
  }
}
</script>

<style>

.green-radio {
  accent-color: #17a2b8 !important;
}

</style>