import apc_types_store from "@/store/rep/apc_types_store";
import insp_reis_store from "@/store/rep/insp_reis_store";


export default {
    namespaced: true,
    modules: {
        apc_types_store,
        insp_reis_store,
    }
}