// ХРАНИЛИЩЕ STAT_INIT
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            statInit_arr: [],
            // список объектов по ТС
            statInit_arr_byTS: [],
            //вычисленный массив с тс и аппаратурой
            statInitItog: []
        }
    },
    getters: {
        // вернуть список объектов
        getStatInit(state) {
            return state.statInit_arr;
        },

        // вернуть список объектов
        getStatInitByTs(state) {
            return state.statInit_arr_byTS;
        },

        getStatInitItog(state) {
            return state.statInitItog;
        },
    },
    mutations: {
        // установить новый список объектов
        setStatInit(state, payload) {
            state.statInit_arr = payload;
        },

        // установить новый список объектов
        setStatInitByTs(state, payload) {
            state.statInit_arr_byTS = payload;
        },

        setStatInitItog(state, payload) {
            state.statInitItog = payload;
        }
    },
    actions: {

        // получение объектов
        async doGetStatInitByTSArray(state,payload) {
            //формируем параметра запроса
            //const curPark = localStorage.getItem('curPark');   jsonPark  jsonTSArr  dateIndexMin dateIndexMax
            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.curPark));
            params.append("jsonTSArr", JSON.stringify(payload.tsArr));
            params.append("dateIndexMin", String(payload.startDate));
            params.append("dateIndexMax", String(payload.endDate));

            await DoPost(store.getters['settings/getBaseUrl'] +
                '/StatInitRestController/getStatInit_byTSArrayRemote',
                params.toString(),
                'umkr/setStatInitByTs',
                ''
            );
        },

        // получение объекта
        async doGetStatInitByTS(state,payload) {
            //формируем параметра запроса
            const params = new URLSearchParams();
            params.append("jsonTS", JSON.stringify(payload.jsonTS));
            params.append("dateIndexMin", String(payload.dateIndexMin));
            params.append("dateIndexMax", String(payload.dateIndexMax));

            return await DoPost(store.getters['settings/getBaseUrl'] +
                '/StatInitRestController/getStatInit_byTSRemote',
                params.toString(),
                null,
                ''
            );
        },


        // получение объектов
        async doGetProcessedStatInfo(state,payload) {
            //формируем параметра запроса
            //const curPark = localStorage.getItem('curPark');   jsonPark  jsonTSArr  dateIndexMin dateIndexMax
            const params = new URLSearchParams();
            params.append("identityID", String(payload.identityID));
            params.append("jsonTS_IDs", JSON.stringify(payload.jsonTS_IDs));
            params.append("dateIndexMin", String(payload.startDate));
            params.append("dateIndexMax", String(payload.endDate));

            await DoPost(store.getters['settings/getBaseUrl'] +
                '/ProcStatInitRestController/getProcessedInfoWithoutBlobRemote',
                params.toString(),
                'umkr/setStatInit',
                ''
            );
        },

        // расчет
        async updateTSNariadInfo(state,payload) {
            //формируем параметра запроса .replaceAll('\\','')
            const params = new URLSearchParams();
            params.append("jsonTSArr", JSON.stringify(payload.jsonTSArr));
            params.append("jsonPark", JSON.stringify(payload.curPark));
            params.append("dateIndex", String(payload.dateIndex));
            // кодируем данные для корректного отображения русского текста на сервере
            const encoded = encodeURI(params.toString());
            await DoPost(store.getters['settings/getBaseUrl'] +
                '/StatInitRestController/updateTSNariadInfoRemote',
                encoded,
                null,
                ''
            );
        },
    }
}