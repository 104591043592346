<template>
  <base-polyline>
  </base-polyline>
</template>

<script>

export default {
  data() {
    return {
      // тип объекта
      leafletObjectType: 'TripTypeLinePolyline',
    }
  }
}
</script>
