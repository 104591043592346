//ЗАПРОС СИСТЕМНЫХ ПАРАМЕТРОВ
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    actions: {
        // добавление объекта
        async doGetSystemParams(state,payload) {
            //формируем параметра запроса
            //const curPark = localStorage.getItem('curPark');
            const params = new URLSearchParams();
            params.append("identity", JSON.stringify(payload.identity));

            return await DoPost(store.getters['settings/getBaseUrl'] +
                '/SystemParamRestController/getSystemParamsRemote',
                params.toString(),
                null,
                'Получение системных параметров'
            );
        },
    }
}