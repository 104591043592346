<template>

  <!-- Окружность -->
  <leaflet-circle-marker
      v-if="isCorrect"
      :latlng="point"
      :radius="radius"
      :options="options"
      :contextMenu="contextMenu"
      :tooltip="tooltip"
      :events="events + ' drag dragend'"
      :targetComponent="this"
      :logTitle="logTitle"
      @onLeafletEvent="onLeafletCircleEvent($event)"
  >
  </leaflet-circle-marker>

</template>

<script>

// ЭЛЕМЕНТ ЗОНЫ В ВИДЕ ТОЧКИ

// interface ZONE_ELEMENT {
//   zone_element_id: number;
//   zone_element_type_id: number;
//   zone_id: number;
//   order_num: number;
//   parameter_size: number;
//   points: ZONE_ELEMENT_POINT[];
// }

// interface ZONE_ELEMENT_POINT {
//   zone_element_id: number;
//   order_num: number;
//   latitude: number;
//   longitude: number;
// }

// опции линии
import LeafletMapMixin from "@/components/ui/leaflet/mixins/LeafletMapMixin";
import LeafletEventedMixin from "@/components/ui/leaflet/mixins/LeafletEventedMixin";
import {toLatLongArr} from "@/components/ui/leaflet/LeafletHelpers";

export default {
  inheritAttrs: false,
  mixins: [LeafletMapMixin, LeafletEventedMixin],
  emits: ['onZoneElementChanged'],
  props: {
    // состояние элемента зоны: original - обычное, active - активное, edit - редактируемое, disabled - отключенное
    state: {
      type: String,
      default: 'original'
    },
    // описание элемента зоны
    zoneElement: {
      required: true,
    },
    // всплывающая подсказка слоя
    tooltip: {
      type: String
    },
    // контекстное меню слоя
    contextMenu: {
      type: Object
    },
    // опции элемента зоны в стандартном режиме
    originalOptions: {
      type: Object,
      default: () => {
        return {
          color: 'red',
          weight: 0.0,
          opacity: 0.0,
          fill: true,
          fillColor: 'red',
          fillOpacity: 0.3,
          draggable: false
        }
      }
    },
    // опции элемента заны в активном режиме
    activeOptions: {
      type: Object,
      default: () => {
        return {
          color: 'black',
          weight: 1.0,
          opacity: 1.0,
          fill: true,
          fillColor: 'red',
          fillOpacity: 0.6,
          draggable: false
        }
      }
    },
    // опции элемента зоны в режиме редактирования
    editOptions: {
      type: Object,
      default: () => {
        return {
          color: 'black',
          weight: 2.0,
          opacity: 1.0,
          fill: true,
          fillColor: 'red',
          fillOpacity: 0.6,
          draggable: true
        }
      }
    },
    // опции элемента заны в отключенном режиме
    disabledOptions: {
      type: Object,
      default: () => {
        return {
          color: 'grey',
          weight: 0.0,
          opacity: 0.0,
          fill: true,
          fillColor: 'grey',
          fillOpacity: 0.3,
          draggable: false
        }
      }
    },
    // радиус точки
    radius: {
      type: Number,
      default: 5
    },
  },

  data() {
    return {
      // тип объекта
      leafletObjectType: 'PointZoneElement',
    }
  },

  computed: {
    // логирование
    logTitle() {
      return `PointZoneElement(#${this.zoneElement.zone_element_id})`
    },
    // корректность данных
    isCorrect() {
      return this.zoneElement &&
          this.zoneElement.points &&
          this.zoneElement.points[0] &&
          this.zoneElement.zone_element_type_id === 4
    },
    // цент точки
    point() {
      return [this.zoneElement.points[0].latitude,this.zoneElement.points[0].longitude];
    },
    // опции элемента зоны
    options() {
      if (this.state === 'disabled') return this.disabledOptions
        else if (this.state === 'edit') return this.editOptions
          else if (this.state === 'active') return this.activeOptions
            else return this.originalOptions;
    },
    // признак режима редактирования
    isEdited() {
      return this.state === 'edit';
    }
  },

  methods: {
    // события от самого элемента зоны
    onLeafletCircleEvent(event) {
      // перетаскивание фигуры
      if (event.type === 'drag' && this.isEdited) {
        // информируем об изменении
        this.$emit('onZoneElementChanged', this.getZoneElement(toLatLongArr(event.target.getLatLng())), false);
      }
      else if (event.type === 'dragend' && this.isEdited) {
        // информируем об изменении
        this.$emit('onZoneElementChanged', this.getZoneElement(toLatLongArr(event.target.getLatLng())), true);
      }

      // проверяем и вызываем внешние события
      if (this.eventsToArray(this.events).includes(event.type)) {
        this.$emit('onLeafletEvent', event);
      }
    },
    // возвращает структуру элемента зоны
    getZoneElement(point) {
      if (!point) point = this.point;

      return {
        zone_element_id: this.zoneElement.zone_element_id,
        zone_element_type_id: 4,
        zone_id: this.zoneElement.zone_id,
        order_num: this.zoneElement.order_num,
        parameter_size: 0,
        points: [
          {
            zone_element_id: this.zoneElement.zone_element_id,
            order_num: 1,
            latitude: Number(point[0]),
            longitude: Number(point[1]),
          }
        ]
      }
    }
  },
}
</script>
