<template>
 <universal-selector v-model="value" :caption="this.$t('nsi.TSSelectorCaption')" :itemList="itemList"></universal-selector>
</template>

<script>
import UniversalSelector from "@/components/ui/custom/UniversalSelector";
export default {
  name: "TSSelector",
  components: {UniversalSelector},
  props: ['model-value', 'selectedTs'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },

    itemList() {
      let result = [];
      let tsArr = this.$store.getters['ref/getTsArr'];
      for(let i = 0; i < tsArr.length; i++) {
        if(this.selectedTs && tsArr[i].ts_id == this.selectedTs.ts_id) {
          continue;
        }
        if(!tsArr[i].ts_deleted){
          result.push( {
            id: tsArr[i].ts_id,
            name: tsArr[i].ts_garagnum,
          })
        }
      }
      return result;
    }
  },
}
</script>

<style scoped>

</style>