<template>
  <div class="align-panel" :style="style">
    <slot></slot>
  </div>
</template>

<script>
export default {

  props: {
    // тип выравнивания: left (по левому краю), right (по правому краю), top (по верхнему краю), bottom (по нижнему краю), all (занимает все пространство)
    align: {
      type: String,
      default: 'none'
    },
    // ширина панели
    width: {
      type: String,
      default: 'auto'
    },
    // высота панели
    height: {
      type: String,
      default: 'auto'
    },
    // расстояние между панелями
    gap: {
      type: Number,
      default: 0
    },
    // имя панели (для отладки)
    name: {
      type: String,
      default: 'panel'
    }
  },

  data() {
    return {
      style: {
        top: null,
        left: null,
        bottom: null,
        right: null,
        width: null,
        height: null,
    },
      // таймер контроля высоты
      heightTimer: null,
    }
  },

  computed: {
    // определяем родительский контейнер
    container() {
      let container;
      if (this.$parent && this.$parent.isAlignContainer) {
        container = this.$parent;
      }
      else if (this.$parent && this.$parent.$parent && this.$parent.$parent.isAlignContainer) {
        container = this.$parent.$parent
      }
      return container
    }
  },

  mounted() {
    // регистрируем в контейнере
    if (this.container) {
      this.container.registerPanel(this);
      // запоминаем текущее значение высоты
      let curHeight = this.$el.offsetHeight;
      // запускаем таймер контроля высоты
      if ((this.align === 'top' || this.align === 'bottom') && (this.height === 'auto')) {
        this.heightTimer = setInterval(() => {
          if (curHeight !== this.$el.offsetHeight) {
            // обновляем панели
            this.container.updatePanel(this);
            // запоминаем новое значение высоты
            curHeight = this.$el.offsetHeight;
          }
        }, 100)
      }
    }
  },

  unmounted() {
    if (this.container) {
      // убиваем таймер
      if (this.heightTimer) {
        clearInterval(this.heightTimer);
        this.heightTimer = null;
      }
      // убираем регистрацию в контейнере
      this.container.unregisterPanel(this)
    }
  },
}
</script>

<style scoped>
.align-panel {
  position: absolute;
  padding: 0px;
  margin: 0px;
  border: 0px;
}
</style>