<template>
<table-component :columnList="columnList" :rowList="rowList"></table-component>
</template>

<script>
import TableComponent from "@/components/ui/custom/TableComponent";

export default {
  name: "MarshTable",
  components: {TableComponent},
  computed: {
    // колонки таблицы
    columnList(){
      return [
        {
          columnName: "tt_title",
          columnTitle: this.$t("nsi.MarshTable.TransTypeTitle"), //"Тип транспорта",
          isVisible: true
        },
        {
          columnName: "routeName",
          columnTitle: this.$t("nsi.MarshTable.RouteName"), //"Номер маршрута",
          isVisible: true
        },
        {
          columnName: "ermRegNum",
          columnTitle: this.$t("nsi.MarshTable.ErmRegNum"), //"Код ЭРМ",
          isVisible: true
        },
      ]
    },
    // строки таблицы
    rowList(){
      return this.$store.getters['ref/getMarshes'].map(value => {
        value.__id = value.routeId;
        let tr_type = this.$store.getters['ref/getTransTypes'].find(trType => value.transportTypeId == trType.tt_id);
        if (tr_type){
          value.tt_title = tr_type.tt_title
        } else {
          value.tt_title = '';
        }
        return value;
      })
    }
  }
}
</script>

<style scoped>

</style>