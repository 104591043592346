import App from './App.vue';
import { createApp } from 'vue';
import Store from "./store/Store";
import { createI18n } from 'vue-i18n'
import messages from './lang/index'

const i18n = createI18n({
    locale: 'ru',
    fallbackLocale: 'en',
    messages,
})

// Шрифты
import '@fortawesome/fontawesome-free/css/all.css';

// Контекстное меню
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css';
import ContextMenu from '@imengyu/vue3-context-menu';

import BootstrapModal from './components/ui/bootstrap/modal/BootstrapModal';
import MessageList from "@/components/ui/custom/MessageList";
import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import router from '@/router/Router';

// Leaflet
import 'leaflet/dist/leaflet.css';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';


import RegisterUI from "@/components/ui/RegisterUI";

//создаем приложение
const app = createApp(App);

app.config.unwrapInjectedRef = true;
// регистрируем маршрутизацию
app.use(router);
// регистрируем перевод
app.use(i18n)

app.component('bootstrap-modal', BootstrapModal);
app.component('message-list', MessageList);
app.component('align-container', AlignContainer);
app.component('align-panel', AlignPanel);

//регистрируем глобальное хранилище данных
app.use(Store);
// регистрируем контекстное меню
app.use(ContextMenu);

// регистрируем служебные компоненты
RegisterUI(app);

// запускаем главный компонент
app.mount('#app');
