<template>
  <!-- Карта-->
  <leaflet-map
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      @onMapCreated="onMapCreated"
  >

    <!-- Элементы управления-->
    <leaflet-control-group>
      <!-- Базовые слои-->
      <leaflet-base-layer-group :baseLayers="baseLayers"></leaflet-base-layer-group>
    </leaflet-control-group>

    <!--    &lt;!&ndash;  Зона остановки &ndash;&gt;-->
    <!--    <base-zone-->
    <!--        v-for="(zone) in zones"-->
    <!--        :key="zone.zone_id"-->
    <!--        :zone="zone.stop_zone"-->
    <!--    >-->
    <!--    </base-zone>-->

    <!--    &lt;!&ndash; Линия типа рейса&ndash;&gt;-->
    <!--    <trip-type-line-polyline-->
    <!--        v-for="(line) in marshLine"-->
    <!--        :key="line.trip_type_line.polyline_id"-->
    <!--        :polyline="line.trip_type_line"-->
    <!--    >-->
    <!--    </trip-type-line-polyline>-->

    <leaflet-circle-marker
        v-if="activeStop"
        :latlng="[activeStop.stopStationLatitude, activeStop.stopStationLongitude]"
        :radius="15"
        :options="activeOptions"
        position="front"
    >
    </leaflet-circle-marker>

    <!--  Обычные остановки-->
    <stop-marker
        v-for="stop in allStops"
        :key="stop.ss_id"
        :stop="stop"
        :tooltip="stop.ss_longname + ' (#'+ stop.ss_id+')'"
        :type="0"
        :state=" activeStop && activeStop.parkStationId == stop.ss_id ? 'active' : 'original'"
        :events="'dblclick'"
        @dblclick="onDblClick(stop)"
    ></stop-marker>

    <!-- Отметка авто -->
    <!--    <vehicle-marker-->
    <!--        v-for="(vehicle, index) in vehicles"-->
    <!--        :vehicle="vehicle"-->
    <!--        :key="index"-->
    <!--        :tab="1"-->
    <!--        color="2"-->
    <!--        events="dblclick"-->
    <!--        :titleType="'none'"-->
    <!--    ></vehicle-marker>-->

    <!--    <leaflet-circle-marker-->
    <!--        v-for="(vehicle, index) in vehicles"-->
    <!--        :key="index"-->
    <!--        :latlng="[vehicle.latitude,vehicle.longitude]"-->
    <!--        :radius="5"-->
    <!--        :options="originalOptions"-->
    <!--        -->
    <!--    >-->
    <!--    </leaflet-circle-marker>-->

  </leaflet-map>
</template>

<script>

import StopMarker from "@/components/ui/leaflet/layers/markers/stop/StopMarker";

export default {
  components: {StopMarker},
  props: [
    'activeStop',
    'raceCardDialogMode'
  ],
  computed: {

    // масштаб по умолчанию
    zoom() {
      return this.$store.getters['settings/getZoom'];
    },

    // центр карты по умолчанию
    center() {
      return this.$store.getters['settings/getCenter'];
    },

    // список базовых слоев
    baseLayers() {
      return this.$store.getters['settings/getBaseLayers'];
    },

    // получаем список всех остановок для отображения на карте
    allStops() {
      if(this.$store.getters['ref/getSelectedRace']){
        if (this.raceCardDialogMode == 'add') {
          return this.$store.getters['ref/getParkStations'];
        } else {
          let resultArr = [];
          this.$store.getters['ref/getRaceCards'].forEach(value => {
            let parkStation = this.$store.getters['ref/getParkStations'].find(ps => ps.ss_id == value.parkStationId);
            if(parkStation){
              if (!resultArr.find(vl => vl.ss_id == parkStation.ss_id)){
                resultArr.push(parkStation);
              }
            }

          })
          return resultArr;
        }
      }
      return [];
    },
  },

  data() {
    return {
      // карта
      leaflet: null,
      // настройки карты
      mapOptions: {
        doubleClickZoom: false
      },
      // границы карты
      minLat: 0,
      maxLat: 0,
      minLong: 0,
      maxLong: 0,
      // текущий масштаб
      curZoom: 0,
      // объект со слоями для элемента управления
      baseLayersObj: {},
      // нет контекстного меню остановки
      noContextMenu: {
        contextmenu: false,
      },
      vehicle: {
        latitude: 55.725937300000005,
        longitude: 37.65464410000001,
        angle: 45
      },
      // опции элемента зоны в стандартном режиме
      originalOptions: {
        color: 'blue',
        weight: 0.0,
        opacity: 0.0,
        fill: true,
        fillColor: 'blue',
        fillOpacity: 0.8,
        draggable: false
      },
      activeOptions: {
        color: 'red',
        weight: 0.0,
        opacity: 0.0,
        fill: true,
        fillColor: 'red',
        fillOpacity: 0.8,
        draggable: false
      },
    }
  },

  methods: {

    // вызывается при создании базового слоя
    onBaseLayerCreated(layer) {
      this.$nextTick(() => {
        this.baseLayersObj = {
          ...this.baseLayersObj,
          [layer.title]: layer.origin
        }
      })
    },

    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;

      // функция определяет границы карты и текущий zoom
      const getMapValues = () => {
        // определяем границы карты
        const bounds = this.leaflet.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        this.minLat = southWest.lat;
        this.maxLat = northEast.lat;
        this.minLong = southWest.lng;
        this.maxLong = northEast.lng;
        // определяем текущий zoom
        this.curZoom = leaflet.getZoom();
      }

      getMapValues();
      leaflet.on('moveend', () => {
        getMapValues();
      });
    },

    onDblClick(raceCard) {
      this.$emit('addRaceCard', raceCard)
    }

  },
}
</script>
