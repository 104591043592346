<template>
  <div class="card bg-light"
       style="height: 50%; overflow-x: hidden; overflow-y: hidden;">
    <form style="height: 95%; overflow-x: hidden; overflow-y: auto;">
      <div class="form-group row">
        <div class="col" style="margin-left:10px; margin-top:10px;">
          <input type="radio" class="green-radio" name="inlineRadioOptions" id="inlineRadio1" value="add"
                 v-model="raceCardDialogMode" style="height:16px; width:16px; vertical-align: middle; margin-right:5px">
          <label for="inlineRadio1" style="margin-bottom: 0; margin-top: 5px">{{ $t("nsi.NsiRadioButtonAdd") }}</label>
        </div>
        <div class="col" style="text-align: right; margin-right:10px; margin-top:10px">
          <input type="radio" class="green-radio" name="inlineRadioOptions" id="inlineRadio2" value="edit"
                 v-model="raceCardDialogMode" style="height:16px; width:16px; vertical-align: middle; margin-right:5px">
          <label for="inlineRadio2" style="margin-bottom: 0; margin-top: 5px">{{ $t("nsi.NsiRadioButtonEdit") }}</label>
        </div>
      </div>

      <div class="form-group" style="padding-right: 20px; padding-left: 20px; min-height: 64px; margin-bottom: 0">
        <label for="stopStationLongname" style="font-weight: bold; margin-bottom: 0">{{ $t("nsi.RaceCardDialog.StationLongNameLabel") }}</label>
        <br/>
        <label for="stopStationLongname">{{ stopStationLongname }}</label>
      </div>

      <div class="form-group" style="padding-right: 20px; padding-left: 20px;">
        <label for="raceCardOrderby" style="font-weight: bold;margin-right: 5px">{{ $t("nsi.RaceCardDialog.RaceCardOrderByLabel") }}</label><label
          for="raceCardOrderby">{{ raceCardOrderby }}</label>
      </div>

      <div class="form-group" style="padding-right: 20px; padding-left: 20px">
        <label>{{ $t("nsi.RaceCardDialog.RaceCardTypeLabel") }}</label>
        <universal-selector
            class="form-control"
            v-model="raceCardControlPointType"
            :disabled="this.raceCardDialogMode == 'add'"
            :itemList="
            [
              {
                id: '0',
                name: this.$t('nsi.RaceCardDialog.RaceCardType_CP')
              },
              {
                id: '1',
                name: this.$t('nsi.RaceCardDialog.RaceCardType_Ordinary')
              },
            ]"
        >
        </universal-selector>
      </div>

      <div class="form-group" style="padding-right: 20px; padding-left: 20px">
        <label for="tarifZoneID">{{ $t("nsi.RaceCardDialog.TarifZoneLabel") }}</label>
        <tarif-zone-selector
            id="tarifZoneID"
            class="form-control"
            v-model="tarifZoneID"
            :disabled="this.raceCardDialogMode == 'add'"
        >
        </tarif-zone-selector>
      </div>
    </form>
    <div class="form-group row">
      <div class="col" style="margin-left: 20px">
        <button @click="onSave" class="btn btn-info" :disabled="this.raceCardDialogMode == 'add'">{{ $t("nsi.NsiButtonSave") }}</button>
      </div>
      <div class="col" style="text-align: right; margin-right:20px">
        <button @click="onDelete" class="btn btn-info" :disabled="isDeleteButtonDisabled">{{ $t("nsi.NsiButtonDelete") }}</button>
      </div>
    </div>

  </div>

  <div class="card bg-light"
       style="height: 50%; overflow-x: hidden; overflow-y: hidden;">
    <form style="height: 95%; overflow-x: hidden; overflow-y: auto;">

      <!--      <div class="form-group row">-->
      <!--        <div class="col" style="margin-left: 20px; margin-top: 20px; padding-right: 35px;">-->
      <!--          <button @click="onSave" class="btn btn-primary" style="width: 100%;">Сохранить изменения вкл. остановок-->
      <!--          </button>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="form-group row">-->
      <!--        <div class="col" style="margin-left: 20px; padding-right: 35px;">-->
      <!--          <button @click="onSave" class="btn btn-primary" style="width: 100%;">Привязка остановок к рейсу</button>-->
      <!--        </div>-->
      <!--      </div>-->
    </form>

    <div class="form-group row">
      <div class="col" style="margin-left: 20px; padding-right: 35px;">
        <button @click="onDeleteAll" class="btn btn-info" style="width: 100%;"
                :disabled="this.raceCardDialogMode == 'add'">
          {{ $t("nsi.RaceCardDialog.ButtonDelete") }}
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import UniversalSelector from "@/components/ui/custom/UniversalSelector";
import TarifZoneSelector from "@/components/references/tarif_zone/TarifZoneSelector";

export default {
  components: {TarifZoneSelector, UniversalSelector},
  props: ['item', 'raceCardMode'],
  emits: ['onEdit', 'onDelete', 'onDeleteAll', 'setRaceCardMode'],
  computed: {

    isDeleteButtonDisabled() {
      return (this.item ? false : true) || (this.raceCardDialogMode == 'add');
    },

    raceCardDialogMode: {
      get() {
        return this.raceCardMode;
      },
      set(value) {
        this.$emit('setRaceCardMode', value);
      }
    }

  },
  watch: {

    item(newValue) {
      this.stopStationLongname = newValue ? newValue.stopStationLongname : '';
      this.raceCardOrderby = newValue ? newValue.raceCardOrderby : '';
      this.raceCardControlPointType = newValue ? newValue.raceCardControlPointType : null;
      this.tarifZoneID = newValue ? newValue.tarifZoneID : null;
    }

  },
  data() {
    return {
      stopStationLongname: null,
      raceCardOrderby: '',
      raceCardControlPointType: '',
      tarifZoneID: null,
    }
  },

  methods: {

    onSave() {
      if (this.raceCardDialogMode != 'add') {
        let item = this.item;
        item.tarifZoneID = this.tarifZoneID;
        item.raceCardControlPointType = this.raceCardControlPointType;

        this.$emit('onEdit', item);
      }
    },

    onDelete() {
      this.$emit('onDelete');
    },

    onDeleteAll(){
      this.$emit('onDeleteAll');
    }

  }
}
</script>

<style scoped>

</style>