import LeafletEventedMixin from "@/components/ui/leaflet/mixins/LeafletEventedMixin";
import LeafletMapMixin from "@/components/ui/leaflet/mixins/LeafletMapMixin";
import {toRaw} from "vue";

// УПРАВЛЕНИЕ БАЗОВЫМИ СЛОЯМИ

export default {
    mixins: [LeafletEventedMixin, LeafletMapMixin],
    props: {
        // видимость слоя
        visible: {
            type: Boolean,
            default: false
        },
        // опции слоя
        options: {
            type: Object,
            default: () => {}
        },
        // произвольные данные - добавляются к leaflet-компоненту в поле __meta
        meta: {
            required: false
        },
        // заголовок слоя в элементе управления слоями
        layer_control_title: {
            type: String,
            default: ""
        },
        // заголовк элемента (для отладки)
        logTitle: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            // тип объекта
            leafletObjectType: 'LeafletBaseLayer',
            // оригинальный объект (проксированный)
            proxyOrigin: null,
            // слой для вставки оверлеев
            groupLayer: null,
            // элемент управления слоями
            layerControl: null,
            // таймер для пересоздания
            reCreateTimer: null
        }
    },
    computed: {
        // оригинальный объект
        origin() {
            return toRaw(this.proxyOrigin)
        },
    },
    methods: {
        // создание слоя (реализуется в потомках)
        createLayer() {},
        // уничтожения слоя  (реализуется в потомках)
        destroyLayer() {},
        // установить видимость слоя
        setVisibility(visible) {
            // отображаем на карте
            if (visible && !this.groupLayer.origin.hasLayer(this.origin)) {
                // вставляем слой
                this.groupLayer.origin.addLayer(this.origin);
                // фиксируем отображение базового слоя
                //console.log('Отображен базовый слой <'+this.curComponent.leafletObjectType+'>: '+this.logTitle);
            }
            // скрываем с карты
            else
            if (!visible && this.groupLayer.origin.hasLayer(this.origin)) {
                // удаляем слой из карты
                this.groupLayer.origin.removeLayer(this.origin);
                // фиксируем скрытие базового слоя
                //console.log('Скрыт базовый слой <'+this.curComponent.leafletObjectType+'>: ' + this.logTitle)
            }
        },
        // пересоздание элемента управления
        reCreateLayer() {
            // таймер уже создан
            if (this.reCreateTimer) return;

            this.reCreateTimer = setTimeout(() => {
                // скрываем элемент
                this.setVisibility(false);
                this.reCreateTimer = setTimeout(() => {
                    // отображаем элемент заново
                    this.setVisibility(true)
                    this.reCreateTimer = null
                }, 0)
            }, 0)
        },
    },
    // монтируем слой
    mounted() {
        // ждем создание родителя
        this.getParent().parentReady().then((parent_list) => {
            // ищем слой для вставки
            this.groupLayer = this.getGroupLayer(parent_list);
            // ищем элемент управления слоями
            this.layerControl = this.getLayerControl(parent_list);
            // создаем слой
            const layer = this.createLayer()
            // регистрируем слой
            if (this.layer_control_title && this.layerControl) {
                this.layerControl.registerBaseLayer(this.layer_control_title, layer)
            }
            // наблюдатель видимости
            this.$watch(() => this.visible, () => {
                this.$nextTick(() => {
                    this.setVisibility(this.visible);
                });
            });
            // логируем
            //console.log('Создан базовый слой <'+this.curComponent.leafletObjectType+'>: '+this.logTitle);
            // отображаем базовый слой
            if (this.visible) this.setVisibility(true)
        })
    },
    unmounted() {
        // отменяем таймер перерисовки
        if (this.reCreateTimer) {
            clearTimeout(this.reCreateTimer);
            this.reCreateTimer = null
        }
        // убираем регистрацию
        if (this.layer_control_title && this.layerControl) {
            this.layerControl.unregisterBaseLayer(this.layer_control_title)
        }
        // скрываем слой
        this.setVisibility(false)
        // удаляем слой
        this.destroyLayer()
        // логируем
        //console.log('Удален базовый слой <'+this.curComponent.leafletObjectType+'>: '+this.logTitle);
    },
    // формируем пустой шаблон
    render() {
        return null
    }
}
