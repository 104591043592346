// РАБОТА С ПРИЗНАКОМ АКТИВНОСТИ
export default {
    props: {
        // признак активности
        active: {
            type: Boolean,
            default: false
        },
        // auto-active - автоматическая активация:
        //   'route' - выделять при полном совпадении маршрута
        //   'exact-route' - выделять при частичном совпадении маршрута
        autoActive: {
            type: String
        },
    },
    data() {
        return {
            // текущее состояние активности
            isActive: this.active,
        }
    },
    watch: {
        active() {
            // следим за активностью
            this.isActive = this.active
        }
    },
    methods: {
        // активация
        activate() {
            this.isActive = true;
        },
        // деактивация
        deactivate() {
            this.isActive = false;
        },
        // переключение
        toggle() {
            this.isActive = !this.isActive;
        },
    },
    created() {
        // следим за активным роутером
        if (this.$watch(() => this.$route, () => {
            if (this.autoActive === 'route') {
                if (this.$route.path === this.to) {
                    this.activate()
                }
                else {
                    this.deactivate()
                }
            }
            else if (this.autoActive === 'exact-route') {
                if (this.$route.path.substring(0, this.to.length) === this.to) {
                    this.activate()
                }
                else {
                    this.deactivate()
                }
            }
        }));
    }
}