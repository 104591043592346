// ДОЧЕРНИЙ КОМПОНЕНТ
export default {
    computed: {
        // определяем родительский компонент
        childContent() {
            let content;
            if (this.$parent && this.$parent.isChildContent) {
                content = this.$parent;
            }
            else if (this.$parent && this.$parent.$parent && this.$parent.$parent.isChildContent) {
                content = this.$parent.$parent
            }
            else if (this.$parent && this.$parent.$parent && this.$parent.$parent.$parent && this.$parent.$parent.$parent.isChildContent) {
                content = this.$parent.$parent.$parent
            }
            return content
        }
    },
    methods: {
        // передать родителю вызов дочернего копонента
        select() {
            // вызываем родительский компонент
            if (this.childContent && this.childContent.childSelected(this)) {
                // выходим
                return true
            }
            return false;
        }
    },
    created() {
        // регистрируемся у родителя
        if (this.childContent) {
            this.childContent.registerChild(this)
        }
    },
    unmounted() {
        // разрегистрируемся у родителя
        if (this.childContent) {
            this.childContent.unregisterChild(this)
        }
    }
}