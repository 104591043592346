<template>
  <input
      type="date"
      v-model.trim="value"
      v-bind="$attrs"
      :class="{ 'is-invalid': isInvalid }"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
      @change="onInput"
  >
  <div class="invalid-feedback" v-if="!!errorText">{{ errorText }}</div>
  <div class="invalid-feedback" v-else>{{ $t("InputDateTitle") }}</div>
</template>

<script>
const regex = new RegExp("^([0-2][0-9]{3})-([0-9]{2})-([0-9]{2})$");

export default {
  // !! компонент возвращает дату string в формате ГГГГ-мм-дд !!
  props: {
    'model-value': {
      required: true
    },
    'error': {
      type: String
    },
    'required': {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:model-value'],
  data() {
    return {
      isInvalid: false,
      errorText: this.error
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        if (typeof(value) === 'string' && regex.test(String(value))) {
          this.$emit('update:model-value', value)
        }
        else {
          this.$emit('update:model-value', null)
        }
      }
    },
  },
  methods: {
    // проверка валидности
    isValid() {
      // разраешаем пустые строки, если допускается пустое значение
      if (this.modelValue === null) {
        return !this.required
      }
      // все остальное можно
      return true;
    },
    // при получении фокуса
    onFocus() {
      // сбрасываем инвалидность
      this.isInvalid = false;
      this.errorText = this.error;
    },
    // при потере фокуса
    onBlur() {
      this.validate()
    },
    // при вводе значений
    onInput() {
      this.validate();
    },
    // вызывается для проверки формы
    validate() {
      const isValid = this.isValid()
      this.isInvalid = !isValid;
      return isValid
    },
    alarm(message) {
      this.errorText = message;
      this.isInvalid = true;
    }
  },
  created() {
    // на всякий случай - меняем undefined на ''
    this.$watch(() => this.modelValue, ()=> {

      if (typeof(this.modelValue) === 'string' && !regex.test(String(this.modelValue))) {
        this.$emit('update:model-value', null);
      }
      else
      if (this.modelValue === undefined)  {
        this.$emit('update:model-value', null);
      }
    }, {immediate: true});
    // следим за изменением текста
    this.$watch(()=> this.error, () => {
      this.errorText = this.error;
    })
  }
}
</script>
