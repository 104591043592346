<template>
<table-component :columnList="columnList" :rowList="rowList"></table-component>
</template>

<script>
import TableComponent from "@/components/ui/custom/TableComponent";

export default {
  components: {TableComponent},
  computed: {
    // колонки таблицы
    columnList(){
      return [
        {
          columnName: "__inc",
          type:'boolean',
          columnTitle: this.$t("nsi.RaceCardTable.Include"), //"Вкл.",
          isVisible: true,
          displayFn(row) {
            if (row.__inc) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          columnName: "gisOrderby",
          columnTitle: this.$t("nsi.RaceCardTable.Orderby"), //"№",
          isVisible: true
        },
        {
          columnName: "stopStationLongname",
          columnTitle: this.$t("nsi.RaceCardTable.Longname"), //"Название",
          isVisible: true
        },
        {
          columnName: "stopStationLongitude",
          columnTitle: this.$t("nsi.RaceCardTable.Longitude"), //"Дол.",
          isVisible: true
        },
        {
          columnName: "stopStationLatitude",
          columnTitle: this.$t("nsi.RaceCardTable.Latitude"), //"Шир.",
          isVisible: true
        },
        {
          columnName: "raceCardDistanceAsKm",
          columnTitle: this.$t("nsi.RaceCardTable.DistanceAsKm"), //"Расст.",
          isVisible: true
        },
        {
          columnName: "parkStationId",
          columnTitle: this.$t("nsi.RaceCardTable.ParkStationId"), //"Ид.",
          isVisible: true
        },
        {
          columnName: "__section",
          columnTitle: this.$t("nsi.RaceCardTable.Section"), //"Секция",
          isVisible: true
        },
      ]
    },
    // строки таблицы
    rowList(){
      let i = 1;
      let raceOrderBy = 1;
      return this.$store.getters['ref/getRaceCards'].map(value => {
        let sectionList = value.sectionList.length == 0 ? 0 : value.sectionList[0];
        value.__inc = sectionList == 0 ? false : value.isRaceCardBelong;
        value.__section = sectionList;

        if (value.raceCardControlPointType == 0) {
          value.__rowStyle = "background-color: #a9a8a8;";
        } else {
          if(value.__rowStyle){
            delete value.__rowStyle;
          }
        }
        value.raceCardOrderby = raceOrderBy;
        value.__id = raceOrderBy;
        raceOrderBy++;
        if (value.isRaceCardBelong) {
          value.gisOrderby = i;
          i++;
        } else {
          value.gisOrderby = 0;
        }
        // console.log('value', value)
        return value;
      });
    }
  }
}
</script>

<style scoped>

</style>