<template>
  <div>
    <!-- Форма авторизации -->
    <auth-form @onEnter="onEnter"></auth-form>
    <!-- Спинер -->
    <loading-modal v-if="isLoading"></loading-modal>
  </div>
</template>

<script>
import AuthForm from "@/components/main/AuthForm";
import LoadingModal from "@/components/ui/custom/LoadingModal";

// import PageMixin from "@/pages/share/PageMixin";
// mixin: [PageMixin],

export default {

  components: {
    AuthForm,
    LoadingModal
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    async onEnter(data) {
      this.isLoading = true;
      try {

        await this.$store.dispatch("doLogin", data)

      }
      finally {
        this.isLoading = false;
      }
    }
  },
};
</script>
