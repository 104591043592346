<template>
  <slot></slot>
</template>

<script>
import { Control } from 'leaflet';
import {toRaw} from "vue";
import LeafletGroupMixin from "@/components/ui/leaflet/mixins/LeafletGroupMixin";
import LeafletControlMixin from "@/components/ui/leaflet/mixins/LeafletControlMixin";

export default {
  mixins: [LeafletGroupMixin, LeafletControlMixin],
  data() {
    return {
      // тип объекта
      leafletObjectType: 'LeafletLayersControl',
      // список базовых слоев (проксированные)
      baseLayers: {},
      // список оверлеев (проксированные)
      overlays: {},
    }
  },
  methods: {
    // создание элемента управления
    createControl() {
      // подсчитываем количество элементов
      let count = 0
      for (let key in this.baseLayers) {
        if (key) count++
      }
      for (let key in this.overlays) {
        if (key) count++
      }
      // создаем элемент управления (если есть элементы)
      let ctl = null;
      if (count > 0) {
        ctl = new Control.Layers(
            toRaw(this.baseLayers),
            toRaw(this.overlays),
            toRaw(this.options)
        );
        ctl.__meta = this.meta;
      }
      this.proxyOrigin = ctl;
      return ctl;
    },
    // уничтожение элемента управления
    destroyControl() {
      // уничтожаем элемент
      this.proxyOrigin = null;
    },
    // регистрируем базовый слой
    registerBaseLayer(title, layer) {
      this.baseLayers[title] = layer
      // пересоздаем элемент
      this.reCreateControl()
    },
    // убираем регистрацию базового слоя
    unregisterBaseLayer(title) {
      delete this.baseLayers[title]
      // пересоздаем элемент
      this.reCreateControl()
    },
    // регистрируем оверлей
    registerOverlay(title, layer) {
      this.overlays[title] = layer
      // пересоздаем элемент
      this.reCreateControl()
    },
    // убираем регистрацию оверлея
    unregisterOverlay(title) {
      delete this.overlays[title]
      // пересоздаем элемент
      this.reCreateControl()
    },
  },
}
</script>
