<template>
  <teleport to="body">
    <div class="modal show" style="display: block">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header text-white" style="background-color: #343a40">
            <h5 class="modal-title">{{ $t("AuthForm.authTitle") }}</h5>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="login">{{ $t("AuthForm.authLogin") }}:</label>
                <input
                    id="login"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': isFormValidated && !isLoginValid }"
                    @focus="onFocus"
                    @keydown.enter="onSubmit"
                    autocomplete="username"
                    v-model.trim="login"
                />
                <div class="invalid-feedback">{{ $t("AuthForm.loginError") }}</div>
              </div>
              <div class="form-group">
                <label for="password">{{ $t("AuthForm.authPwd") }}:</label>
                <input
                    id="password"
                    type="password"
                    class="form-control"
                    :class="{ 'is-invalid': isFormValidated && !isPasswordValid }"
                    @focus="onFocus"
                    @keydown.enter="onSubmit"
                    autocomplete="current-password"
                    v-model.trim="password"
                />
                <div class="invalid-feedback">{{ $t("AuthForm.pwdError") }}</div>
              </div>
              <div class="form-group" style="margin: 0">
                <universal-selector
                    id="locale"
                    class="form-control"
                    v-model="selectedLocale"
                    :itemList="locales"
                    @change="changeLocale"
                ></universal-selector>
                <div class="invalid-feedback">{{ $t("AuthForm.pwdError") }}</div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button @click="onSubmit" class="btn btn-info">{{ $t("AuthForm.authButton") }}</button>
          </div>
        </div>
      </div>
    </div>
<!--    <div>-->
<!--        v-if="backdropType !== 'none'"-->
<!--        :style="backdropStyle"-->
<!--        class="modal-backdrop show"-->
<!--    ></div>-->
  </teleport>
</template>

<script>
import UniversalSelector from "@/components/ui/custom/UniversalSelector";
export default {
  components: {UniversalSelector},
  emits: ['onEnter'],

  data() {
    return {
      login: "",
      password: "",
      isFormValidated: false,
      isLoginValid: true,
      isPasswordValid: true,
      selectedLocale: 'ru',
      locales: [
        {
          id: 'ru',
          name: 'Русский'
        },
        {
          id: 'en',
          name: 'English'
        },
      ]
    };
  },

  methods: {
    // проверить форму на ошибки
    validateForm() {
      this.isLoginValid = true;
      this.isPasswordValid = true;
      this.isFormValidated = true;
      if (this.login === "") {
        this.isLoginValid = false;
        return false;
      }
      if (this.password === "") {
        this.isPasswordValid = false;
        return false;
      }
      return true;
    },
    onFocus() {
      // сбрасываем признак проверки (при фокусировки любого элемента)
      this.isFormValidated = false;
    },
    onSubmit() {
      // проверяем форму
      if (this.validateForm()) {
        localStorage.setItem('locale', this.selectedLocale);
        // формируем событие
        this.$emit('onEnter', {
          login: this.login,
          password: this.password,
        });
        // сбрасываем значения
        this.login = "";
        this.password = "";
      }
    },

    //меняем локаль
    changeLocale(){
      this.$root.$i18n.locale = this.selectedLocale;
    }
  },

  created() {
    this.selectedLocale = this.$root.$i18n.locale;
  }
};
</script>
