<template>
  <ul class="nav">
    <slot></slot>
  </ul>
</template>

<script>
import ChildContentMixin from "@/components/ui/bootstrap/mixins/ChildContentMixin";

export default {
  mixins: [ChildContentMixin]
}
</script>
