<template>
<table-component :columnList="columnList" :rowList="rowList"></table-component>
</template>

<script>
import TableComponent from "@/components/ui/custom/TableComponent";
// import {dateToND, dateToString, ndToDate, quickSort, statInitFn, stringToDate} from "@/lib";

export default {
  name: "InspReisesByParksTable",
  components: {TableComponent},
  props: ['rowList'],
  computed: {
    // колонки таблицы
    columnList(){
      return [
        {
          columnName: "naryad",
          columnTitle: this.$t("InspReisesByParksTable.Nariad"), //"Наряд"
          isVisible: true
        },
        {
          columnName: "date_as_str",
          columnTitle: this.$t("InspReisesByParksTable.Date"), //"Дата",
          isVisible: true
        },
        {
          columnName: "ts_name",
          columnTitle: this.$t("InspReisesByParksTable.TS"), //"ТС",
          isVisible: true
        },
        {
          columnName: "psgrs_umkr",
          columnTitle: this.$t("InspReisesByParksTable.TransUmkr"), //"Перев. УМКР",
          isVisible: true
        },
        {
          columnName: "psgrs_processed",
          columnTitle: this.$t("InspReisesByParksTable.TransProc"), //"Перев. Обработка",
          isVisible: true
        },
        {
          columnName: "difference_processed_percent",
          columnTitle: this.$t("InspReisesByParksTable.DiffUmkrProcPercent"), //"Разн. УМКР/Обр. (%)",
          isVisible: true
        },
        {
          columnName: "difference_processed",
          columnTitle: this.$t("InspReisesByParksTable.DiffUmkrProc"), //"Разн. УМКР/Обр.",
          isVisible: true
        },
        {
          columnName: "psgrs_asop",
          columnTitle: this.$t("InspReisesByParksTable.PaidByAsop"), //"Оплачено по АСОП",
          isVisible: true
        },
        {
          columnName: "difference_psgrs_percent",
          columnTitle: this.$t("InspReisesByParksTable.DiffUmkrAsopPercent"), //"Разн. УМКР/АСОП (%)",
          isVisible: true
        },
        {
          columnName: "difference_psgrs",
          columnTitle: this.$t("InspReisesByParksTable.DiffUmkrAsop"), //"Разн. УМКР/АСОП",
          isVisible: true
        },
      ]
    },
    // // строки таблицы
    // rowList(){
    //
    //   let resultArr = [];
    //   // обрабатываем данные УМКР
    //   this.$store.getters['umkr/getStatInitItog'].forEach(val => {
    //     Object.keys(val.info).forEach(infoKey => {
    //
    //       if(dateToND(stringToDate(this.$store.getters['ref/getUserStartDate'])) <= infoKey && infoKey <= dateToND(stringToDate(this.$store.getters['ref/getUserEndDate']))) {
    //         resultArr.push({
    //           date_as_int: infoKey,
    //           date_as_str: dateToString(ndToDate(infoKey)),
    //           ts_id: val.ts_id,
    //           ts_garagnum: val.ts_garagnum,
    //           ts_statenum: val.ts_statenum,
    //           ts_name: val.ts_garagnum + ' / ' + val.ts_statenum,
    //           naryad: val.info[infoKey].comm,
    //           psgrs_umkr: val.info[infoKey].psgrs_count,
    //           psgrs_asop: val.info[infoKey].asop,
    //           difference_psgrs_percent: this.getPercent(val.info[infoKey].psgrs_count, val.info[infoKey].asop),
    //           difference_psgrs: val.info[infoKey].psgrs_count - val.info[infoKey].asop
    //         });
    //       }
    //     });
    //   });
    //   // обрабатываем загруженные данные
    //   this.$store.getters['umkr/getStatInitInspReisesByParks'].forEach(val => {
    //     val[1].forEach(v => {
    //       let result = resultArr.find(resVal => v.dispatchdate_asInt == resVal.date_as_int && resVal.ts_id == v.tsunique_id);
    //       if(result) {
    //         if(!result.psgrs_processed) {
    //           result.psgrs_processed = 0;
    //         }
    //         result.psgrs_processed += v.sumpsgrs;
    //
    //         result.difference_processed_percent = this.getPercent(result.psgrs_umkr, result.psgrs_processed);
    //         result.difference_processed = result.psgrs_umkr - result.psgrs_processed;
    //
    //       }
    //     })
    //   });
    //
    //   quickSort(resultArr, (leftItem, rightItem) => {
    //     return 1 * statInitFn(leftItem, rightItem);
    //   });
    //
    //   return resultArr;
    // }
  },


}
</script>

<style scoped>

</style>