import apc_types_store from "@/store/rep/apc_types_store";
import system_params_store from "@/store/umkr/system_params_store";
import processing_store from "@/store/proc/processing_store";
import progress_status_store from "@/store/proc/progress_status_store";
import proc_statistic_store from "@/store/proc/proc_statistic_store";
import disp_store from "@/store/proc/disp_store";

export default {
    namespaced: true,
    modules: {
        apc_types_store,
        system_params_store,
        processing_store,
        progress_status_store,
        proc_statistic_store,
        disp_store,
    }
}