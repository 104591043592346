<template>
  <bootstrap-modal backdrop-type="transparent">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-body text-center bg-bg-light border rounded" style="padding: 0">
          <h6 class="text-primary" style="padding-top:5px; background-color: #17a2b8; color: white !important; text-align: left; padding-left: 5px; padding-bottom: 5px">
            {{ loadingText }}
          </h6>
          <h6 class="text-primary" style="margin: 10px 10px 10px;">
            {{ progressbarText }}
          </h6>
          <div class="progress" style="margin: 0px 16px 16px;">
            <div class="progress-bar" role="progressbar" :style="'width:' + progressbarValue + '%'"
                 :aria-valuenow="progressbarValue" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
    </div>
  </bootstrap-modal>
</template>

<script>


export default {

  props: ['loadingText', 'progressbarValue', 'progressbarText'],

}

</script>
