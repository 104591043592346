<script>
import {icon, Marker} from 'leaflet'
import LeafletOverlayMixin from "@/components/ui/leaflet/mixins/LeafletOverlayMixin";
import {toRaw} from "vue";
import marker_icon from 'leaflet/dist/images/marker-icon.png';
import marker_icon_2x from 'leaflet/dist/images/marker-icon-2x.png';

// иконка по умолчанию
export const DefaultIcon = icon({
  iconUrl:       marker_icon,
  iconRetinaUrl: marker_icon_2x,
  shadowUrl:     marker_icon_2x,
  iconSize:      [25, 41],
  iconAnchor:    [12, 41],
  popupAnchor:   [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize:    [41, 41]
});

// МАРКЕР

export default {
  mixins: [LeafletOverlayMixin],
  props: {
    // координаты маркера
    latlng: {
      type: Array,
      required: true,
    },
    // иконка маркера
    icon: {
      type: Object,
      default: () => DefaultIcon
    },
  },
  data() {
    return {
      // тип объекта
      leafletObjectType: 'LeafletMarker',
    }
  },
  methods: {
    // создание слоя
    createLayer() {
      // создаем слой
      const layer = new Marker(toRaw(this.latlng), {
        ...toRaw(this.options),
        icon: toRaw(this.icon),
      });
      layer.__meta = this.meta;
      if (this.tooltip) {
        // подключаем подсказку к слою
        layer.bindTooltip(toRaw(this.tooltip));
      }
      if (this.contextMenu) {
        // подключаем контекстное меню к слою
        layer.bindContextMenu(toRaw(this.contextMenu));
      }
      this.subscribe(layer);
      this.proxyOrigin = layer;
      return layer;
    },
    destroyLayer() {
      // удаляем подписку на события
      this.unsubscribe(this.origin);
      // отключаем контекстное меню от слоя
      this.origin.unbindContextMenu();
      // отключаем подсказку от слоя
      this.origin.unbindTooltip();
      // убиваем ссылку
      this.proxyOrigin = null;
    },
  },
  // монтируем слой
  mounted() {
    // ждем создания родителя
    this.getParent().parentReady().then(() => {

      // наблюдатель за координатами
      this.$watch(() => this.latlng, (after, before) => {
        if (this.reCreateTimer) return;
        if (this.isLatLngEqual(before, after)) return;
        //console.log('Требуется сместить маркер: '+ this.logTitle)
        this.origin.setLatLng(toRaw(this.latlng));
      }, {deep: false});

      // наблюдатель за иконкой
      this.$watch(() => this.icon, (after, before) => {
        if (this.reCreateTimer) return;
        if (this.isIconEqual(before, after)) return;
        //console.log('Требуется изменить иконку маркера: '+ this.logTitle)
        this.origin.setIcon(toRaw(this.icon));
      }, {deep: false});

    })
  },
}
</script>
