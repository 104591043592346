<template>
    <select
        v-model="value"
        v-bind="$attrs"
        :disabled="readonly"
    >
      <option
          v-for="item in items"
          :key="item.id"
          :value="item.id"
      >{{ item.name }}
      </option>
    </select>
</template>

<script>
export default {
  props: {
    //выдранное в списке значение
    'model-value': {
      required: true
    },
    //набор данных в выпадающем списке {id : any, name : string }
    itemList: {
      required: true,
      type: Array,
    },
    // заголовок по-умолчанию
    caption: {
      type: String
    },
    // только для чтения
    'readonly': {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:model-value'],

  computed: {

    items() {
      let arr = [];
      this.itemList.forEach(value => {
        arr.push({...value})
      })
      if (this.caption) {
        // формируем нулевое значение
        arr.unshift({
          id: null,
          name: this.caption
        })
      }
      return arr
    },

    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value);
      }
    }

  },

}

</script>

<style scoped>

</style>