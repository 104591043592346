// ХРАНИЛИЩЕ Insp Reis и агрегированной информации
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    actions: {

        // получение значения для прогресбара объекта
        async doGetProgrVal() {
            // кодируем данные для корректного отображения русского текста на сервере
            return await DoPost(store.getters['settings/getBaseUrl']
                + '/ProcessingRestController/getProgrValRemote',
                {},
                null,
                ''
            );
        },
    }
}
