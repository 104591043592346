<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable"
                  style="max-width: 84% !important; max-height: 96% !important;">
      <modal-content style=" height: 100%">
        <modal-header class="bg-primary text-white">
          <modal-title>{{ $t("ProcessingResultDialogTitle") }}</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body style="padding: 0; overflow-y: hidden">
          <align-container>
            <align-panel align="top" :gap="5" height="50%" style="overflow-y: auto">
              <processing-result-reises-table
                  :selectedRow="selectedRow"
                  @setSelectedRow="setSelectedRow"
              >
              </processing-result-reises-table>
            </align-panel>
            <align-panel align="all" style="overflow-y: auto">
              <processing-result-stations-table
                  :selectedReis="selectedRow"
              >
              </processing-result-stations-table>
            </align-panel>
          </align-container>
        </modal-body>
<!--        <modal-footer v-if="op !== 'show'">-->
<!--          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>-->
<!--        </modal-footer>-->
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>



import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import ProcessingResultReisesTable from "@/components/processing_result/ProcessingResultReisesTable";
import ProcessingResultStationsTable from "@/components/processing_result/ProcessingResultStationsTable";

export default {
  components: {
    ProcessingResultReisesTable,
    ProcessingResultStationsTable,
    AlignPanel,
    AlignContainer,

  },
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  data() {
    return {
      selectedRow: null,
    };
  },
  methods: {
    setSelectedRow(row) {
      this.selectedRow = row;
    }
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}

</style>