<template>
  <div class="card bg-light"
       style="height: 100%; overflow-x: hidden; overflow-y: hidden;">
    <form style="height: 95%; overflow-x: hidden; overflow-y: auto;">
      <div class="form-group row">
        <div class="col" style="margin-left:10px; margin-top:10px;">
          <input type="radio" class="green-radio" name="inlineRadioOptions" id="inlineRadio1" value="add"
                 v-model="selectedMode" style="height:16px; width:16px; vertical-align: middle; margin-right:5px">
          <label for="inlineRadio1" style="margin-bottom: 0; margin-top: 5px">{{ $t("nsi.NsiRadioButtonAdd") }}</label>
        </div>
        <div class="col" style="text-align: right; margin-right:10px; margin-top:10px">
          <input type="radio" class="green-radio" name="inlineRadioOptions" id="inlineRadio2" value="edit"
                 v-model="selectedMode" style="height:16px; width:16px; vertical-align: middle; margin-right:5px">
          <label for="inlineRadio2" style="margin-bottom: 0; margin-top: 5px">{{ $t("nsi.NsiRadioButtonEdit") }}</label>
        </div>
      </div>

      <div class="form-group" style="padding-right: 20px; padding-left: 20px">
        <label for="routeVariantIndexBegin">{{ $t("nsi.MarshVariantDialog.RouteVariantIndexBegin") }}</label>
        <input-date
            id="routeVariantIndexBegin"
            class="form-control"
            v-model="routeVariantIndexBegin"
        ></input-date>
      </div>

      <div class="form-group" style="padding-right: 20px; padding-left: 20px">
        <label for="routeVariantIndexEnd">{{ $t("nsi.MarshVariantDialog.RouteVariantIndexEnd") }}</label>
        <input-date
            id="routeVariantIndexEnd"
            class="form-control"
            v-model="routeVariantIndexEnd"
        ></input-date>
      </div>

      <div class="form-group" style="padding-right: 20px; padding-left: 20px">
        <label ащк="routeVariantNote">{{ $t("nsi.MarshVariantDialog.RouteVariantNote") }}</label>
        <input-string
            class="form-control"
            id="routeVariantNote"
            v-model="routeVariantNote"
        />
      </div>

      <div class="form-group" style="padding-right: 20px; padding-left: 20px">
        <input-checkbox
            id="notSynchronize"
            v-model="notSynchronize"
            style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px; accent-color: #17a2b8 !important;"
        >{{ $t("nsi.MarshVariantDialog.CheckboxNotSynchronize") }}
        </input-checkbox>
      </div>

    </form>
    <div class="form-group row">
      <div class="col" style="margin-left: 20px">
        <button @click="onSave" class="btn btn-info">{{ $t("nsi.NsiButtonSave") }}</button>
      </div>
      <div class="col" style="text-align: right; margin-right:20px">
        <button @click="onDelete" class="btn btn-info" :disabled="isDeleteButtonDisabled">{{ $t("nsi.NsiButtonDelete") }}</button>
      </div>
    </div>

  </div>
</template>

<script>

import InputString from "@/components/ui/custom/InputString";
import InputDate from "@/components/ui/custom/InputDate";
import InputCheckbox from "@/components/ui/custom/InputCheckbox";
import {dateToND, dateToString, ndToDate, stringToDate} from "@/lib";

export default {
  components: {InputCheckbox, InputDate, InputString},
  props: ['item', 'selectedMarsh'],
  emits:['onSave', 'onEdit', 'onDelete'],
  computed: {

    isDeleteButtonDisabled() {
      return this.item ? false : true;
    }

  },
  watch: {

    item(newValue) {
      if(newValue){
        this.selectedMode = 'edit'
      } else {
        this.selectedMode = 'add'
      }
      this.routeVariantIndexBegin = dateToString(ndToDate(newValue.routeVariantIndexBegin));
      this.routeVariantIndexEnd = dateToString(ndToDate(newValue.routeVariantIndexEnd));
      this.routeVariantNote = newValue.routeVariantNote
      this.notSynchronize = newValue.notSynchronize
    }

  },
  data() {
    return {
      selectedMode: 'add',
      routeVariantIndexBegin: '',
      routeVariantIndexEnd: '',
      routeVariantNote: null,
      notSynchronize: false,
    }
  },

  methods: {

    onSave() {
      if(this.selectedMode == 'add'){
        this.$emit('onSave', {
          routeVariantId: 0,
          routeId: this.selectedMarsh.routeId,
          parkId: this.$store.getters['ref/getUserPark'].parkId,
          routeVariantNote: this.routeVariantNote,
          routeVariantIndexBegin: dateToND(stringToDate(this.routeVariantIndexBegin)),
          routeVariantIndexEnd: dateToND(stringToDate(this.routeVariantIndexEnd)),
          notSynchronize: this.notSynchronize,
          ermRegNum: 0,
          identity: this.$store.getters['ref/getParks'].find(value => value.parkId == this.$store.getters['ref/getUserPark']).identity,
        })
      } else {
        this.$emit('onEdit', {
          routeVariantId: this.item.routeVariantId,
          routeId: this.item.routeId,
          parkId: this.item.parkId,
          routeVariantNote: this.routeVariantNote,
          routeVariantIndexBegin: dateToND(stringToDate(this.routeVariantIndexBegin)),
          routeVariantIndexEnd:dateToND( stringToDate(this.routeVariantIndexEnd)),
          notSynchronize: this.notSynchronize,
          ermRegNum: this.item.ermRegNum,
          identity: this.item.identity,
        })
      }
    },

    onDelete() {
      this.$emit('onDelete');
      this.notSynchronize = null;
      this.routeVariantIndexBegin = '';
      this.routeVariantIndexEnd = '';
      this.routeVariantNote = '';
    }

  }
}
</script>

<style scoped>

</style>