<template>
  <input
      type="text"
      v-bind="$attrs"
      :class="{ 'is-invalid': isInvalid && !readonly && !disabled }"
      :readonly="readonly"
      :disabled="disabled"
      ref="inputRef"
      autocomplete="off"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
  >
  <div class="invalid-feedback">{{ error }}</div>
</template>

<script>
export default {
  props: {
    'model-value': {
      required: true
    },
    // требуется ввод значения
    'required': {
      type: Boolean,
      default: false
    },
    // только для чтения
    'readonly': {
      type: Boolean,
      default: false,
    },
    // отключен
    'disabled': {
      type: Boolean,
      default: false,
    },
    // текст ошибки
    'error': {
      type: String,
      default: 'Введите значение'
    },
  },

  emits: ['update:model-value'],

  data() {
    return {
      // признак ошибки
      isInvalid: false,
    }
  },

  methods: {
    // проверка валидности
    isValid() {
      // разраешаем пустые строки, если допускается пустое значение
      if (this.modelValue === '') {
        return !this.required
      }
      // все остальное можно
      return true;
    },

    // при получении фокуса
    onFocus() {
      // сбрасываем инвалидность
      this.isInvalid = false;
    },

    // при потере фокуса
    onBlur() {
      // проверяем значение
      this.validate();
    },

    // при вводе значений
    onInput() {
      // запрашиваем текущее значение
      const value = this.$refs.inputRef.value.trim();
      // вызываем изменение модели
      this.$emit('update:model-value', value);
    },

    // вызывается для проверки формы
    validate() {
      const isValid = this.isValid()
      this.isInvalid = !isValid;
      return isValid
    },

  },

  mounted() {

    // следим за изменением модели
    this.$watch(() => this.modelValue, (value) => {

      // пустое значение меняем на пустую строку
      if (value == null) {
        this.$emit('update:model-value', '');
        return
      }

      // все остальное преобразуем в текст
      if (typeof(value) !== 'string') {
        this.$emit('update:model-value', String(value));
        return
      }

      // текст принимаем
      this.$refs.inputRef.value = value;

    }, {immediate: true})
  }
}
</script>
