// ХРАНИЛИЩЕ ТРАСС
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            races: [],
        }
    },
    getters: {
        // вернуть список парков
        getRaces(state) {
            return state.races;
        },
    },
    mutations: {
        // установить новый список объектов
        setRaces(state, payload) {
            state.races = payload;
        },

        // добавить объект
        addRace(state, payload) {
            state.races.push(payload)
        },

        // изменить объект
        editRace(state, payload) {
            const index = state.races.findIndex(depot => depot.raceId == payload.raceId);
            if (index >= 0) {
                state.races[index] = payload;
            }
        },

        delRace(state, payload) {
            const index = state.races.findIndex(depot => depot.raceId == payload.raceId);
            state.races.splice(index, 1);
        },
    },
    actions: {

        // получение объектов
        async doGetRaces(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.park));
            params.append("jsonMarsh", JSON.stringify(payload.marsh));
            params.append("jsonMarshVariantFilter", JSON.stringify(payload.marshVariant));
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/RaceRestController/getRacesByMarshVarFilterRemote',
                params.toString(),
                'ref/setRaces',
                'Получение списка трасс'
            );
        },

        // добавление объекта
        async doAddRace(context, payload) {

            const params = new URLSearchParams();
            params.append("jsonMarshVariant", JSON.stringify(payload.marshVariant));
            params.append("jsonRace", JSON.stringify(payload.race));

            let id = await DoPost(
                store.getters['settings/getBaseUrl'] + '/RaceRestController/insertRaceRemote',
                params.toString(),
                null,
                'Добавление трассы'
            );
            if(id) {
                payload.race.raceId = id;
                context.commit('addRace', payload.race);
            }
        },

        // редактирование объекта
        async doEditRace(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonRace", JSON.stringify(payload.race));
            params.append("jsonMarshVariant", JSON.stringify(payload.marshVariant));

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/RaceRestController/updateRaceRemote',
                params.toString(),
                null,
                'Редактирование трассы'
            );

            context.commit('editRace', payload.race);
        },

        // удаление объекта
        async doDeleteRace(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonRace", JSON.stringify(payload.race));
            params.append("jsonMarshVariant", JSON.stringify(payload.marshVariant));

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/RaceRestController/deleteRaceRemote',
                params.toString(),
                null,
                'Удаление трассы'
            );

            context.commit('delRace', payload.race);
        },

        async isExistsRacesLinks(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonRace", JSON.stringify(payload.race));

            return await DoPost(
                store.getters['settings/getBaseUrl'] + '/RaceRestController/checkLinksBeforeDeleteRemote',
                params.toString(),
                null,
                'Проверяем ссылки на другие объекты'
            );
        },

        // получение объекта
        async doGetCompRaces(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonRace", JSON.stringify(payload.race));
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/RaceRestController/getCompReisesRemote',
                params.toString(),
                null,
                'Получение рейсов'
             );
        },

        // добавление объекта
        async doGetReisLength(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonRace", JSON.stringify(payload.race));
            let length =  await DoPost(
                store.getters['settings/getBaseUrl'] + '/RaceRestController/getReisLengthRemote',
                params.toString(),
                null,
                'Получение длинны рейсов'
            );
            if(length == false) {
                return 0;
            } else {
                return length;
            }
        },

    }
}
