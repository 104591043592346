<template>
  <!-- Карта-->
  <leaflet-map
      :zoom="zoom"
      :center="center"
      :contextMenu="mapContextMenu"
      :options="mapOptions"
      @onMapCreated="onMapCreated"
      @click.prevent="coordinatesUpdate"
  >

    <!-- Элементы управления-->
    <leaflet-control-group>
      <!-- Базовые слои-->
      <leaflet-base-layer-group :baseLayers="baseLayers"></leaflet-base-layer-group>
    </leaflet-control-group>

    <!--  Обычные остановки-->
    <stop-marker
        v-for="stop in allStops"
        :key="stop.ss_id"
        :stop="stop"
        :tooltip="stop.ss_longname + ' (#'+ stop.ss_id+')'"
        :type="0"
        state="original"
        :contextMenu="mapContext"
    ></stop-marker>

    <!--  Обычные остановки-->
    <stop-marker
        v-if="tempStop"
        :stop="tempStop"
        :tooltip="tempStop.ss_longname + ' (#'+ tempStop.ss_id+')'"
        :type="0"
        state="active"

    ></stop-marker>

    <leaflet-circle-marker
        v-if="actStop"
        :latlng="[actStop.ss_latitude, actStop.ss_longitude]"
        :radius="15"
        :options="activeOptions"
        :position="'front'"
    >
    </leaflet-circle-marker>

  </leaflet-map>
</template>

<script>

import StopMarker from "@/components/ui/leaflet/layers/markers/stop/StopMarker";

export default {
  components: {StopMarker},
  props: ['activeStop'],
  emits: ['openAddDialog', 'openEditDialog', 'editCoordinates'],
  computed: {

    mapContextMenu() {
      if (!this.tempStop) {
       return  {
          contextmenu: true,
          contextmenuWidth: 120,
          contextmenuItems: [
            {
              text: this.$t('nsi.ParkStationsMap.Add'),
              callback: this.addParkStop
            }
          ]
        }
      } else {
        return {
          contextmenu: true,
          contextmenuWidth: 120,
          contextmenuItems: [
            {
              text: this.$t('nsi.ParkStationsMap.Cancel'),
              callback: this.cancelCoordChange
            }
          ]
        }
      }
    },

    mapContext() {
      let contextmenuItems = [];
      if (!this.tempStop) {
        contextmenuItems = [
          {
            text: this.$t('nsi.ParkStationsMap.Edit'),
            callback: this.editParkStop
          },
          {
            text: this.$t('nsi.ParkStationsMap.Change'),
            callback: this.changeCoordinates
          },
          {
            text: this.$t('nsi.ParkStationsMap.Delete'),
            callback: this.deleteCoordinates
          }
        ];
      }
      return {
        contextmenu: true,
        contextmenuWidth:120,
        contextmenuItems: contextmenuItems,
      }
    },

    // масштаб по умолчанию
    zoom() {
      return this.$store.getters['settings/getZoom'];
    },

    // центр карты по умолчанию
    center() {
      return this.$store.getters['settings/getCenter'];
    },

    // список базовых слоев
    baseLayers() {
      return this.$store.getters['settings/getBaseLayers'];
    },

    // получаем список всех остановок для отображения на карте
    allStops() {
      if (this.$store.getters['ref/getParkStations'].length > 0) {
        return this.$store.getters['ref/getParkStations'];
      }
      return [];
    },
  },
  watch: {
    activeStop(newValue) {
      if (newValue) {
        this.actStop = newValue;
      } else {
        this.actStop = null;
      }
    }
  },
  data() {
    return {
      tempStop: null,
      // активная остановка
      actStop: null,
      // карта
      leaflet: null,
      // настройки карты
      mapOptions: {
        doubleClickZoom: false
      },
      // границы карты
      minLat: 0,
      maxLat: 0,
      minLong: 0,
      maxLong: 0,
      // текущий масштаб
      curZoom: 0,
      // объект со слоями для элемента управления
      baseLayersObj: {},
      // нет контекстного меню остановки
      noContextMenu: {
        contextmenu: false,
      },
      vehicle: {
        latitude: 55.725937300000005,
        longitude: 37.65464410000001,
        angle: 45
      },
      // опции элемента зоны в стандартном режиме
      originalOptions: {
        color: 'blue',
        weight: 0.0,
        opacity: 0.0,
        fill: true,
        fillColor: 'blue',
        fillOpacity: 0.8,
        draggable: false
      },
      activeOptions: {
        color: 'red',
        weight: 0.0,
        opacity: 0.0,
        fill: true,
        fillColor: 'red',
        fillOpacity: 0.8,
        draggable: false
      },
    }
  },

  methods: {

    // вызывается при создании базового слоя
    onBaseLayerCreated(layer) {
      this.$nextTick(() => {
        this.baseLayersObj = {
          ...this.baseLayersObj,
          [layer.title]: layer.origin
        }
      })
    },

    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;

      // функция определяет границы карты и текущий zoom
      const getMapValues = () => {
        // определяем границы карты
        const bounds = this.leaflet.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        this.minLat = southWest.lat;
        this.maxLat = northEast.lat;
        this.minLong = southWest.lng;
        this.maxLong = northEast.lng;
        // определяем текущий zoom
        this.curZoom = leaflet.getZoom();
      }

      getMapValues();
      leaflet.on('moveend', () => {
        getMapValues();
      });

    },

    addParkStop(event) {
      const latlng = [event.latlng.lat, event.latlng.lng];
      this.$emit('openAddDialog', latlng)
    },

    editParkStop(event) {
      // из-за особенностей tooltip непонятно как определить по какой остановке вызвано редактирование поэтому чтобы найти остановку сравниваем текст в тултипе
      let stop = this.allStops.find(value => event.relatedTarget._tooltip._content == (value.ss_longname + ' (#' + value.ss_id + ')'));
      this.$emit('openEditDialog', stop);
    },

    changeCoordinates(event) {
      let stop = this.allStops.find(value => event.relatedTarget._tooltip._content == (value.ss_longname + ' (#' + value.ss_id + ')'));
      this.tempStop = stop;
    },

    cancelCoordChange() {
      this.tempStop = null;
    },

    coordinatesUpdate(event) {
      if (this.tempStop) {
        let latlng = this.leaflet.mouseEventToLatLng(event);

        this.tempStop.ss_latitude = latlng.lat;
        this.tempStop.ss_longitude = latlng.lng;
        this.$emit('editCoordinates', this.tempStop);
        // вызываем сохранение координат
        this.tempStop = null;
      }
    },

    deleteCoordinates(event) {
      let stop = this.allStops.find(value => event.relatedTarget._tooltip._content == (value.ss_longname + ' (#' + value.ss_id + ')'));
      this.$emit('deleteParkStation', stop);
    },

  },
}
</script>