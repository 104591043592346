
// ГЛАВНОЕ ХРАНИЛИЩЕ SETTINGS

// import {DoGetRaw} from "@/store/Actions";

export default {
    namespaced: true,
    state() {
        return {
            // url для билда, для работы над проектом использовать либо localhost, либо первый закомментированный url "/api/ru.trnsoft.domain.web/rest"
            baseUrl: 'https://asmpp.srv.transnavi.ru/api/ru.trnsoft.domain.web/rest', //"https://asmpp.srv.transnavi.ru/api/ru.trnsoft.domain.web/rest",//"http://192.168.28.24:8080/ru.trnsoft.domain.web/rest",//'http://localhost:8080/ru.trnsoft.domain.web/rest',
            // список базовых слоев
            baseLayers: [
                {
                    title: "OpenStreetMap",
                    urlTemplate: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                    options: { attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'},
                    type: "",
                },
                {
                    title: "Яндекс.Карты",
                    options: {},
                    type: "yandex",
                },
                // {
                //     title: "Спутник.Карты",
                //     urlTemplate: "http://{s}.tiles.maps.sputnik.ru/{z}/{x}/{y}.png",
                //     options: { attribution: "&copy; <a href='http://corp.sputnik.ru/maps'>Спутник</a> contributors"},
                //     type: "",
                // },
            ],
            // начальный масштаб
            zoom: 15,
            // центр карты
            //center: [56.859847, 35.911995],
            center: [55.725937300000005, 37.65464410000001],
        }
    },
    getters: {
        getBaseUrl(state) {
            return state.baseUrl;
        },
        getBaseLayers(state) {
            return state.baseLayers;
        },
        getZoom(state){
            return state.zoom;
        },
        getCenter(state){
           return state.center
        }
    },
    mutations: {
        setSettings(state, payload) {
            if (payload.baseUrl)
                state.baseUrl = payload.baseUrl;
        }
    },
    // actions: {
    //     // запрос настроек из json-файла
    //     async doGetSettings({state}) {
    //         const result = await DoGetRaw(
    //             '/settings.json',
    //             'settings/setSettings',
    //             'Загрузка настроек',
    //             false
    //         );
    //         // должно быть подключение
    //         return result && state.baseUrl;
    //     }
    // }
}
