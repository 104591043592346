<template>
  <align-container>
    <!-- Правая панель  -->
    <align-panel align="left" width="27rem" :gap="3">
      <align-container>
        <align-panel align="top" :gap="3">
          <universal-selector
              v-model="viewType"
              :itemList="[
                  {id:0, name: this.$t('TsNavHistoryPage.ViewTypeAllData')},
                  {id:1, name: this.$t('TsNavHistoryPage.ViewTypeByDoor')},
                  {id:2, name: this.$t('TsNavHistoryPage.ViewTypeSpeed')},
                  {id:3, name: this.$t('TsNavHistoryPage.ViewTypeDoor')},
              ]"
              style="margin: 5px 5px 5px 0px"
              @change="recalculateSelection"
          >
          </universal-selector>

          <div v-if="viewType == 1" style="display:flex; right: 0; left: 0;">
            <input-checkbox
                id="isFirstDoor"
                v-model="isFirstDoor"
                style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px"
                @change="recalculateSelection"
            >{{ $t("TsNavHistoryPage.FirstDoor") }}
            </input-checkbox>

            <input-checkbox
                id="isSecondDoor"
                v-model="isSecondDoor"
                style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px"
                @change="recalculateSelection"
            >{{ $t("TsNavHistoryPage.SecondDoor") }}
            </input-checkbox>

            <input-checkbox
                id="isThirdDoor"
                v-model="isThirdDoor"
                style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px"
                @change="recalculateSelection"
            >{{ $t("TsNavHistoryPage.ThirdDoor") }}
            </input-checkbox>

            <input-checkbox
                id="isFourthDoor"
                v-model="isFourthDoor"
                style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px"
                @change="recalculateSelection"
            >{{ $t("TsNavHistoryPage.FourthDoor") }}
            </input-checkbox>

            <input-checkbox
                id="isNullData"
                v-model="isNullData"
                style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px"
                @change="recalculateSelection"
            >{{ $t("TsNavHistoryPage.NullData") }}
            </input-checkbox>
          </div>

          <div v-if="viewType == 1" style="display:flex; right: 0; left: 0;">
            <universal-selector
                v-model="nullData"
                :itemList="[
                    {id:0, name: this.$t('TsNavHistoryPage.ViewTypeNullData')},
                    {id:1, name: this.$t('TsNavHistoryPage.ViewTypeWithoutNullData')},
                 ]"
                style="margin: 5px 5px 5px 5px"
                :readonly="!isNullData"
                @change="recalculateSelection"
            >
            </universal-selector>
          </div>

        </align-panel>
        <align-panel align="all" style="overflow: auto">
          <hist-panel
              :navTsArray="navTsArray"
              :isLoading="isLoading"
              @onUpdateSelectedNavs="onUpdateSelectedNavs"
          >
          </hist-panel>
        </align-panel>
      </align-container>
    </align-panel>

    <!-- Карта -->
    <align-panel align="all" style="z-index: 0 !important;">
      <ts-nav-history-map
          :vehicles="vehicleHistoryArrToMap"
          :active-vehicle="activeVehicle"
          @onMapCreated="onMapCreated"
      >
      </ts-nav-history-map>
    </align-panel>
  </align-container>

</template>

<script>

import {latLngBounds} from "leaflet";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import AlignContainer from "@/components/ui/custom/AlignContainer";
import TsNavHistoryMap from "@/components/ts_nav_history/TsNavHistoryMap";
import UniversalSelector from "@/components/ui/custom/UniversalSelector";
import InputCheckbox from "@/components/ui/custom/InputCheckbox";
import HistPanel from "@/components/ts_nav_history/HistPanel";


export default {

  components: {
    HistPanel,
    TsNavHistoryMap,
    AlignContainer,
    AlignPanel,
    UniversalSelector,
    InputCheckbox,
  },

  props: [
    // выбранное тер. образование
    "formation_id",
    // выбранный парк
    "park_id",
    // выбранное ТС
    "ts_id",
    // выбранноя дата
    "date_index",
  ],

  data() {
    return {
      isFirstDoor: true,
      isSecondDoor: true,
      isThirdDoor: true,
      isFourthDoor: true,
      isNullData: false,
      nullData: 0,

      // карта
      leaflet: null,
      // первый вызов после команды
      firstWatchAfterCommand: false,
      // История движения транспортного средства за указанное время
      vehicle_history: [],
      // список выбранных навигационных отметок
      // vehicleHistoryArr: [],
      activeVehicle: null,
      // список 500 навигационных отметок на карте
      vehicleHistoryArrToMap: [],
      // переменная в которой хранится индекс строки (для корректной работы выделения строк с удержанием клавиши Shift)
      selectedRowNavId: null,
      // отображение спинера при загрузке навигации по ТС
      isLoading: false,
      // выбранное тер. оброазование
      selectedFormation: null,
      // выбранный парк
      selectedPark: null,
      // ыбранное ТС
      selectedTS: null,
      // режим отображения
      newValue: 0,
      // тип отображения
      viewType:0,

      //массив отметок для таблицы
      navTsArray: [],
    }
  },

  computed: {

    // vehiclesOnMap() {
    //   return this.historyVehiclesOnMap;
    // },

    //   // возвращает массив отметок для отображения на карте
    //   historyVehiclesOnMap() {
    //     let arr = [];
    //     for (let i = 0; i < this.vehicleHistoryArr.length; i++) {
    //       let row = this.rowList.find(nav => nav.nav_id == this.vehicleHistoryArr[i]);
    //       if(row){
    //         // для корректного отображения иконки нужно добавить к навигационной отметке тип транспорта
    //         row.transport_type_id = this.selectedTS.transport_type_id;
    //         arr.push(row);
    //       }
    //     }
    //     return arr;
    //   },
  },

  methods: {

    recalculateSelection() {
      //результирующий массив в таблице
      let arr = [];
      this.$store.getters['umkr/getNavFlowItems'].map(value => {
        if (this.viewType == 0) {
          arr.push(value);
        } else if (this.viewType == 1) {
          if (value.doornum != 0) {
            if (this.isFirstDoor && value.doornum == 1) {
              if (this.isNullData && this.nullData == 0) {
                if (value.pin == 0 && value.pout == 0) {
                  arr.push(value);
                }
              } else if (this.isNullData && this.nullData == 1) {
                if (value.pin != 0 || value.pout != 0) {
                  arr.push(value);
                }
              } else {
                arr.push(value);
              }
            } else if (this.isSecondDoor && value.doornum == 2) {
              if (this.isNullData && this.nullData == 0) {
                if (value.pin == 0 && value.pout == 0) {
                  arr.push(value);
                }
              } else if (this.isNullData && this.nullData == 1) {
                if (value.pin != 0 || value.pout != 0) {
                  arr.push(value);
                }
              } else {
                arr.push(value);
              }
            } else if (this.isThirdDoor && value.doornum == 3) {
              if (this.isNullData && this.nullData == 0) {
                if (value.pin == 0 && value.pout == 0) {
                  arr.push(value);
                }
              } else if (this.isNullData && this.nullData == 1) {
                if (value.pin != 0 || value.pout != 0) {
                  arr.push(value);
                }
              } else {
                arr.push(value);
              }
            } else if (this.isFourthDoor && value.doornum == 4) {
              if (this.isNullData && this.nullData == 0) {
                if (value.pin == 0 && value.pout == 0) {
                  arr.push(value);
                }
              } else if (this.isNullData && this.nullData == 1) {
                if (value.pin != 0 || value.pout != 0) {
                  arr.push(value);
                }
              } else {
                arr.push(value);
              }
            }

          }
        } else if (this.viewType == 2) {
          if (Number(value.speed) < 5) {
            arr.push(value);
          }
        } else if (this.viewType == 3) {
          if (value.typeofrecord.typeofrecord === 5) {
            arr.push(value);
          }
        }
      })
      //запоминаем массив для таблицы
      this.navTsArray = arr;

      //формируем массив для карты (не более 500 отметок)
      let arrMap = [];
      if (arr.length > 500) {
        let i = Math.round(arr.length / 500);
        let z = 0;
        arr.map(value => {
          if (z < 1 || z == i) {
            if (value.latitude != 0 && value.longitude != 0) {
              arrMap.push(value);
            }
            z = 1;
          } else {
            z++;
          }
        });
      } else {
        arr.map(value => {
          if (value.latitude != 0 && value.longitude != 0) {
            arrMap.push(value);
          }
        });
      }

      this.vehicleHistoryArrToMap = arrMap;
    },

    onUpdateSelectedNavs(newValue){
      this.activeVehicle = newValue;
      // this.setMapPosition();
    },

    // сетаем карту так, чтобы все транспортные средства попадали в карту
    // setMapPosition(){
    //   // отслеживаем, чтобы все транспортные средства попадали в карту
    //   if (!this.isVehiclesInTheMap()) {
    //     if (this.vehicleHistoryArrToMap.length === 1) {
    //       this.leaflet.setView([this.vehicleHistoryArrToMap[0].latitude, this.vehicleHistoryArrToMap[0].longitude], this.leaflet.getZoom())
    //     } else if (this.vehicleHistoryArrToMap.length > 1) {
    //       const bounds = latLngBounds(
    //           this.vehicleHistoryArrToMap.map(vehicle => [vehicle.latitude, vehicle.longitude])
    //       )
    //       this.leaflet.fitBounds(bounds);
    //     }
    //   }
    // },

    // создана карта
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    // проверяем, что все транспортные средства попадают в зону карты
    isVehiclesInTheMap() {
      const bounds = this.leaflet.getBounds()
      return this.vehicleHistoryArrToMap.every(v => bounds.contains([v.latitude, v.longitude]))
    },

    // запускаем обновление данных за указанный день
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        // определяем парк по которому сделан запрос
        // проверяем загружен ли этот парк
        let formation = this.$store.getters['ref/getFormations'].find(formation => formation.tw_id == this.formation_id);
        if(formation){
          this.selectedFormation = formation;
        } else {
          // если парк не найден делаем запрос
          await this.$store.dispatch('ref/doGetFormations',);
          let frm = this.$store.getters['ref/getFormations'].find(formation => formation.tw_id == this.formation_id);
          this.selectedFormation = frm;
        }
        // определяем парк по которому будет сделан запрос
        // проверяем загружен ли этот парк
        let park = this.$store.getters['ref/getParks'].find(park => park.parkId == this.park_id);
        if(park){
          this.selectedPark = park;
        } else {
          // если парк не найден делаем запрос
          await this.$store.dispatch('ref/doGetParks', {formation:  this.selectedFormation});
          let pk = this.$store.getters['ref/getParks'].find(park => park.parkId == this.park_id);
          this.selectedPark = pk;
        }

        // определяем ТС по которому будет сделан запрос
        // проверяем загружено ли это ТС
        let ts = this.$store.getters['umkr/getTS'].find(ts => ts.ts_id == this.ts_id);
        if(ts){
          this.selectedTS = ts;
        } else {
          // если ТС не найдено делаем запрос
          await this.$store.dispatch('umkr/doGetActiveASMPPTSByPark', {curPark:  this.selectedPark});
          let ts = this.$store.getters['umkr/getTS'].find(ts => ts.ts_id == this.ts_id);
          this.selectedTS = ts;
        }

        // загружаем историю движения NavFlowItems
        await this.$store.dispatch('umkr/doFetchNavFlowItems', {
          startDate: this.date_index,
          endDate: this.date_index,
          park: this.selectedPark,
          ts: this.selectedTS,
        });
        let arr = [];
        // получаем все навигационные отметки
        let navFlowItemsArr = this.$store.getters['umkr/getNavFlowItems'];
        this.navTsArray = navFlowItemsArr;
        if (navFlowItemsArr.length > 500) {
          // определяем, какую отметку мы выводим на карту
          let i = Math.round(navFlowItemsArr.length/500);
          let z = 0;
          navFlowItemsArr.map(value => {
            value.__id = value.nav_id + value.doornum;
            if(z < 1 || z == i){
              if(value.latitude != 0 && value.longitude != 0) {
                arr.push(value);
              }
              z = 1;
            } else {
              z++;
            }
          })
        } else {
          navFlowItemsArr.map(value => {
            value.__id = value.nav_id + value.doornum;
            if (value.latitude != 0 && value.longitude != 0) {
              arr.push(value);
            }
          });
        }
        this.vehicleHistoryArrToMap = arr;
        this.correctingMapPosition();
      } finally {
        this.isLoading = false;
        if (this.op != 'edit') {
          this.selectedRow = null;
        }
      }
    },

    correctingMapPosition(){
      // первый вызов после команды - позиционируем карту, чтобы влезли транспортные средства
      // if (this.firstWatchAfterCommand) {
      if (this.vehicleHistoryArrToMap.length === 1) {
        this.leaflet.setView([this.vehicleHistoryArrToMap[0].latitude, this.vehicleHistoryArrToMap[0].longitude], this.$store.getters['settings/getDefaultVehicleZoom'])
      } else if (this.vehicleHistoryArrToMap.length > 1) {
        const bounds = latLngBounds(
            this.vehicleHistoryArrToMap.map(vehicle => [vehicle.latitude, vehicle.longitude])
        )
        this.leaflet.fitBounds(bounds);
      }
      // }
    }

  },

  watch: {

    activeVehicle(newValue) {
      this.leaflet.setView([newValue.latitude, newValue.longitude], this.$store.getters['settings/getDefaultVehicleZoom']);
    }

    // // срабатывает, когда меняется список транспортных средств на карте
    // vehicleHistoryArr(newList) {
    //   // первый вызов после команды - позиционируем карту, чтобы влезли транспортные средства
    //   if (this.firstWatchAfterCommand) {
    //     if (newList.length === 1) {
    //       this.leaflet.setView([newList[0].latitude, newList[0].longitude], this.$store.getters['settings/getDefaultVehicleZoom'])
    //     } else if (newList.length > 1) {
    //       const bounds = latLngBounds(
    //           newList.map(vehicle => [vehicle.latitude, vehicle.longitude])
    //       )
    //       this.leaflet.fitBounds(bounds);
    //     }
    //     // сбрасываем флаг первого вызова
    //     this.firstWatchAfterCommand = false
    //     // выходим
    //     return
    //   }
    //
    //   // отслеживаем, чтобы все транспортные средства попадали в карту
    //   if (!this.isVehiclesInTheMap()) {
    //     if (newList.length === 1) {
    //       this.leaflet.setView([newList[0].latitude, newList[0].longitude], this.leaflet.getZoom())
    //     } else if (newList.length > 1) {
    //       const bounds = latLngBounds(
    //           newList.map(vehicle => [vehicle.latitude, vehicle.longitude])
    //       )
    //       this.leaflet.fitBounds(bounds);
    //     }
    //   }
    // },

    // смещаем карту для отрисовки всех ТС истории
    // vehicleHistoryArr(newList) {
    //   if (newList.length === 1) {
    //     this.leaflet.setView([newList[0].latitude, newList[0].longitude], this.leaflet.getZoom())
    //   } else if (newList.length > 1) {
    //     const bounds = latLngBounds(
    //         newList.map(vehicle => [vehicle.latitude, vehicle.longitude])
    //     )
    //     this.leaflet.fitBounds(bounds);
    //   }
    // },
  },

  // вызывается при создании компонента
  async created() {
    // перезапрашиваются данные
    await this.refreshData();
  },

};

</script>

<style scoped>

</style>
