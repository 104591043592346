<template>
    <button 
        type="button"
        @click="execTargetMethod('toggle')"
        v-click-away="onClickAway"
    ><slot></slot></button>  
</template>

<script>
import { directive } from "vue3-click-away";
import TargetMixin from "@/components/ui/bootstrap/mixins/TargetMixin";

export default {
    directives: {
        ClickAway: directive
    },
    mixins: [TargetMixin],
    methods: {
      onClickAway() {
        this.execTargetMethod('deactivate');
      }
    }
}
</script>
