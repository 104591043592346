// ОБРАБОТКА УМКР
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {

    actions: {

        // расчет
        async doСalcCarUMKStatistic(state,payload) {
            //формируем параметра запроса .replaceAll('\\','')
            const params = new URLSearchParams();
            params.append("jsonTSArr", JSON.stringify(payload.jsonTSArr));
            params.append("jsonCommArr", JSON.stringify(payload.jsonCommArr));
            params.append("jsonPark", JSON.stringify(payload.curPark));
            params.append("jsonAsopArr", JSON.stringify(payload.jsonAsopArr));
            params.append("dateIndex", String(payload.dateIndex));
            // кодируем данные для корректного отображения русского текста на сервере
            const encoded = encodeURI(params.toString());
            await DoPost(store.getters['settings/getBaseUrl'] +
                '/UMKRRestController/calcCarUMKStatisticRemote',
                encoded,
                null,
                ''
            );
        },



        // расчет
        async doСalcCarUMKStatisticByTs(state,payload) {
            //формируем параметра запроса .replaceAll('\\','')
            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.jsonPark));
            params.append("jsonTSArr", JSON.stringify(payload.jsonTSArr));
            params.append("dateIndex", String(payload.dateIndex));
            params.append("jsonCommArr", JSON.stringify(payload.comm));
            // кодируем данные для корректного отображения русского текста на сервере
            const encoded = encodeURI(params.toString());
            await DoPost(store.getters['settings/getBaseUrl'] +
                '/UMKRRestController/calcCarUMKStatisticRemote',
                encoded,
                null,
                ''
            );
        },
    }
}
