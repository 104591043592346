<template>
  <div ref="map" class="map">
    <slot></slot>
  </div>
</template>

<script>
import {Map} from 'leaflet';
import {toRaw} from "vue";
import LeafletEventedMixin from "@/components/ui/leaflet/mixins/LeafletEventedMixin";

export default {
  mixins: [LeafletEventedMixin],
  emits: ['onMapCreated', 'onMapDestroyed', 'onLeafletContextMenuItemClick'],
  props: {
    // масштаб
    zoom: {
      required: true
    },
    // центр карты
    center: {
      required: true
    },
    // контекстное меню
    contextMenu: {
      type: Object
    },
    // опции карты
    options: {
      type: Object
    },
    // заголовок для отладки
    logTitle: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      // тип объекта
      leafletObjectType: 'LeafletMap',
      // оригинальный объект (проксированный)
      proxyOrigin: null,
      // следит за изменением размера
      resizeObserver: null,
      // признак готовности карты
      isMapReady: false,
    }
  },
  computed: {
    // оригинальный объект
    origin() {
      return toRaw(this.proxyOrigin)
    },
    // итоговые настройки карты
    optionsValue() {
      if (this.contextMenu)
        return {
          ...toRaw(this.options),
          center: this.center,
          zoom: this.zoom,
          // запрещаем отображать элементы управления
          zoomControl: false,
          attributionControl: false,
          ...this.contextMenu
        }
      else
        return {
          ...toRaw(this.options),
          center: this.center,
          zoom: this.zoom,
          // запрещаем отображать элементы управления
          zoomControl: false,
          attributionControl: false,
        }
    }
  },
  methods: {
    // создание карты
    createMap() {
      const map = new Map(this.$refs.map, this.optionsValue);
      this.proxyOrigin = map;
      return map;
    },

    // уничтожение карты
    destroyMap() {
      // удаляем объект - Карта
      this.origin.remove();
      // удаляем ссылку
      this.proxyOrigin = null;
    },

    // родитель создан Proxy
    parentReady() {
      return new Promise((resolve) => {
        if (this.isMapReady) {
          resolve([this])
        } else {
          this.$watch(() => this.isMapReady, () => {
            if (this.isMapReady) {
              resolve([this])
            }
          })
        }
      })
    },
  },
  mounted() {
    // создаем объект карты
    const map = this.createMap();
    // ждем создание карты
    map.whenReady(() => {
      // фиксируем запуск карты
      this.isMapReady = true;
      // следим за изменением контекстного меню
      this.$watch(() => this.contextMenu, (menu) => {
        //console.log('Требуется изменить контекстное меню карты: '+ this.origin);
        // условие изменение меню
        if (map.contextmenu && map.options.contextmenu && Array.isArray(menu.contextmenuItems)) {
          // удаляем старые элементы
          map.contextmenu.removeAllItems();
          // добавляем новые элементы
          menu.contextmenuItems.forEach(item => {
            map.contextmenu.addItem(item);
          });
        }
      }, {deep: false});
      // следим за изменением размера
      this.resizeObserver = new ResizeObserver(() => {
        map.invalidateSize();
      });
      this.resizeObserver.observe(this.$el);
      // формируем событие о создании карты
      this.$emit('onMapCreated', map);
      // логируем
      //console.log('СОЗДАНА КАРТА')
    })
  },
  unmounted() {
    // отключаем слежение за элементом
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$el);
    }
    // формируем событие об удалении карты
    this.$emit('onMapDestroyed', this.origin);
    // уничтожаем карту
    this.destroyMap();
    // логируем
    //console.log('УДАЛЕНА КАРТА')
  },
}
</script>

<style scoped>
.map {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
</style>
