<template>
  <leaflet-marker
      v-if="isCorrect"
      :latlng="latlng"
      :events="events + ' dragend'"
      :icon="icon"
      :options="options"
      :targetComponent="this"
      :logTitle="stop.long_name + '(#'+stop.stop_id+')'"
      @onLeafletEvent="onLeafletEvent($event)"
  ></leaflet-marker>
</template>

<script>

// МАРКЕР ОСТАНОВОЧНОГО ПУНКТА

// interface STOP {
//  stop_id: number;
//  short_name: string;
//  long_name: string;
//  stop_type_id: number;
//  latitude: number;
//  longitude: number;
//  reg_num: string;
//  note: string;
//  area_classifier_id: string;
//  address: string;
//  is_even: boolean;
//  stopstation_id: number;
//  depot_id: number;
// }

import icons from "@/components/ui/leaflet/layers/markers/stop/StopIcon";
import LeafletMapMixin from "@/components/ui/leaflet/mixins/LeafletMapMixin";
import LeafletEventedMixin from "@/components/ui/leaflet/mixins/LeafletEventedMixin";

export default {
  mixins: [LeafletMapMixin, LeafletEventedMixin],
  emits: [
    // вызывается при изменении остановки
    'onStopChanged'
  ],
  props: {
    // тип остановки: 0 - тип 0 (автобусы - по-умолчанию), 1 - тип 1 (троллейбусы), 2 - тип 2 (трамваи), 3 - тип 3 (прочее)
    type: {
      default: 0
    },
    // состояния остановки: original - обычное, active - активное, edit - редактируемое, disabled - отключенное
    state: {
      type: String,
      default: 'original'
    },
    // описание остановки
    stop: {
      type: Object,
      required: true
    },
    // опции маркера в обычном состоянии
    originalOptions: {
      type: Object,
      default: () => {
        return {
          draggable: false,
          clickable: true
        }
      }
    },
    // опции маркера в активном состоянии
    activeOptions: {
      type: Object,
      default: () => {
        return {
          draggable: false,
          clickable: true,
          zIndexOffset: 1000
        }
      }
    },
    // опции маркера в редактируемом состоянии
    editOptions: {
      type: Object,
      default: () => {
        return {
          draggable: true,
          clickable: true,
          zIndexOffset: 1010
        }
      }
    },
    // опции маркера в отключенном состоянии
    disabledOptions: {
      type: Object,
      default: () => {
        return {
          draggable: false,
          clickable: false
        }
      }
    },
  },
  data() {
    return {
      // тип объекта
      leafletObjectType: 'StopMarker',
    }
  },
  computed: {
    isCorrect() {
      return this.stop && Number.isFinite(this.stop.ss_latitude) && Number.isFinite(this.stop.ss_longitude)
    },
    latlng() {
      return [this.stop.ss_latitude, this.stop.ss_longitude];
    },
    yIndex() {
      if (this.type == 1) return 1
        else if (this.type == 2) return 2
          else if (this.type == 3) return 3
            else return 0
    },
    xIndex() {
      if (this.state === 'disabled') return 3
        else if (this.state === 'edit') return 2
         else if (this.state === 'active') return 1
          else return 0;
    },
    // иконка остановки
    icon() {
      return icons[this.yIndex][this.xIndex]
    },
    // опции остановки
    options() {
      if (this.state === 'disabled') return this.disabledOptions
        else if (this.state === 'edit') return this.editOptions
          else if (this.state === 'active') return this.activeOptions
            else return this.originalOptions;
    },
    // признак режима редактирования
    isEdited() {
      return this.state === 'edit';
    }
  },
  methods: {
    // события маркера
    onLeafletEvent(event) {
      if (event.type === 'dblclick') {
        this.$emit('dblclick');
      }

      // завершение перетаскивание
      if (event.type === 'dragend' && this.isEdited) {
        const latlng = event.target.getLatLng()
        // информируем об изменении
        this.$emit('onStopChanged', {
          ...this.stop,
          latitude: latlng.lat,
          longitude: latlng.lng,
        });
      }

      // проверяем и вызываем внешние события
      if (this.eventsToArray(this.events).includes(event.type)) {
        this.$emit('onLeafletEvent', event);
      }
    },
  },
};

</script>

<style>
/* Иконка остановки */
.stop-icon {
  border: 0px;
  margin: 0px;
  padding: 0px;
  background: url("/src/components/ui/leaflet/img/stop.png");
}
</style>
