// ХРАНИЛИЩЕ ВАРИАНТОВ МАРШРУТОВ
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            marshVariants: [],
        }
    },
    getters: {
        // вернуть список парков
        getMarshVariants(state) {
            return state.marshVariants;
        },
    },
    mutations: {
        // установить новый список объектов
        setMarshVariants(state, payload) {
            state.marshVariants = payload;
        },

        // добавляем список объектов
        addMarshVariants(state, payload) {
            payload.forEach(value => {
                state.marshVariants.push(value);
            })
        },

        setMarshVariantsEmpty(state) {
            state.marshVariants = [];
        },

        // добавить объект
        addMarshVariant(state, payload) {
            state.marshVariants.push(payload)
        },

        // изменить объект
        editMarshVariant(state, payload) {
            const index = state.marshVariants.findIndex(depot => depot.routeVariantId == payload.routeVariantId);
            if (index >= 0) {
                state.marshVariants[index] = payload;
            }
        },

        delMarshVariant(state, payload) {
            const index = state.marshVariants.findIndex(depot => depot.routeVariantId == payload.routeVariantId);
            state.marshVariants.splice(index, 1);
        },

    },

    actions: {

        // получение объекта (полученные значения не заменяют собой уже сохраненный массив, а добавляются к нему)
        async doGetMarshVariants(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonMarsh", JSON.stringify(payload.marsh));

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/MarshVariantRestController/getMarshVariantRemote',
                params.toString(),
                'ref/setMarshVariants',
                'Получение периодов действия'
            );
        },

        // добавление объекта
        async doAddMarshVariant(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonMarshVariant", JSON.stringify(payload.marshVariant));
            params.append("jsonMarsh", JSON.stringify(payload.marsh));
            // кодируем данные для корректного отображения русского текста на сервере
            const encoded = encodeURI(params.toString())
            let id = await DoPost(
                store.getters['settings/getBaseUrl'] + '/MarshVariantRestController/insertMarshVariantRemote',
                encoded,
                null,
                'Добавление периода действия'
            );
            if (id) {
                payload.marshVariant.routeVariantId = id;
                context.commit('addMarshVariant', payload.marshVariant);
            }
        },

        // редактирование объекта
        async doEditMarshVariant(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonMarsh", JSON.stringify(payload.marsh));
            params.append("jsonMarshVariant", JSON.stringify(payload.marshVariant));
            // кодируем данные для корректного отображения русского текста на сервере
            const encoded = encodeURI(params.toString())
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/MarshVariantRestController/updateMarshVariantRemote',
                encoded,
                null,
                'Редактирование периода действия'
            );

            context.commit('editMarshVariant', payload.marshVariant);
        },

        // удаление объекта
        async doDeleteMarshVariant(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonMarsh", JSON.stringify(payload.marsh));
            params.append("jsonMarshVariant", JSON.stringify(payload.marshVariant));

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/MarshVariantRestController/deleteMarshVariantRemote',
                params.toString(),
                null,
                'Удаление периода действия'
            );

            context.commit('delMarshVariant', payload.marshVariant);
        },

        async isExistsMarshVariantsLinks(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonMarshVariant", JSON.stringify(payload.marshVariant));

            return await DoPost(
                store.getters['settings/getBaseUrl'] + '/MarshVariantRestController/isExistsLinksRemote',
                params.toString(),
                null,
                'Проверяем ссылки на другие объекты'
            );
        },
    }
}
