<template>
  <div class="custom-control custom-checkbox">
    <input type="checkbox" class="custom-control-input" :id="id" :disabled="readonly" v-model="value">
    <label class="custom-control-label" :for="id"><slot></slot></label>
  </div>
</template>

<script>
export default {
  props: {
    'id': {
      type: String,
      required: true
    },
    'model-value': {
      required: true
    },
    'readonly': {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
  },
  methods: {
    // вызывается для проверки формы
    validate() {
      return true
    },
  },
  created() {
    this.$watch(() => this.modelValue, ()=> {
      if (this.modelValue !== true) {
        this.$emit('update:model-value', false);
      }
    }, {immediate: true});
  }
}
</script>

<style scoped>

.custom-control-input {
  accent-color: #17a2b8 !important;
}

</style>
