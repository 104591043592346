// ХРАНИЛИЩЕ НИТКИ РЕЙСА
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    actions: {

        // добавление объекта
        async doDeleteMarsec(context, payload) {

            const params = new URLSearchParams();
            params.append("jsonRace", JSON.stringify(payload.race));

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/MarsecRestController/deleteMarsecRemote',
                params.toString(),
                'ref/setTransTypes',
                'Удаление нитки маршрута'
             );

        },
    }
}
