<template>
  <!-- Главное Меню -->
  <div>
    <main-menu v-if="this.$route.name !== 'auth'" id="menu"></main-menu>
  </div>
  <!-- Основное окно -->
  <div id="main-div">
    <!--    <main-page></main-page>-->
    <router-view></router-view>
  </div>

  <!-- Окно с сообщениями -->
  <message-list></message-list>

</template>

<script>
import MainMenu from "./components/main/MainMenu"
// import MainPage from "@/pages/MainPage";

export default {
  name: 'App',
  components: {
    MainMenu,
    // MainPage
  },
  computed: {
    token() {
      return localStorage.getItem("token") ? localStorage.getItem("token") : "";
    }
  },

  async created() {
    //хардкодим base_url
    // localStorage.setItem("base_url","http://192.168.28.24:8080/ru.trnsoft.domain.web/rest");
    // localStorage.setItem("base_url","http://localhost:8080/ru.trnsoft.domain.web/rest");

    // если сохранена локаль, подтягиваем её
    if(localStorage.getItem('locale')) {
      this.$root.$i18n.locale = localStorage.getItem('locale');
    }
  },

}
</script>

<style>
* {
  box-sizing: border-box;
}

html, body {
  height: 100vh;
  overflow: hidden;
}

#main-div {
  position: fixed;
  top: 60px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}
</style>
