<script>
import { TileLayer } from 'leaflet';
import LeafletBaseLayerMixin from "@/components/ui/leaflet/mixins/LeafletBaseLayerMixin";
import {toRaw} from "vue";

// ТАЙЛОВЫЙ БАЗОВЫЙ СЛОЙ

export default {
  mixins: [LeafletBaseLayerMixin],
  props: {
    // url-слоя
    urlTemplate: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      // тип объекта
      leafletObjectType: 'LeafletTileLayer',
    }
  },
  methods: {
    // создание слоя
    createLayer() {
      const layer = new TileLayer(toRaw(this.urlTemplate), toRaw(this.options));
      layer.__meta = this.meta;
      this.subscribe(layer);
      this.proxyOrigin = layer;
      return layer
    },
    // уничтожения слоя
    destroyLayer() {
      // удаляем подписку на события
      this.unsubscribe(this.origin);
      // убиваем ссылку
      this.proxyOrigin = null;
    },
  },
}
</script>
