<template>
  <nav class="navbar fixed-top navbar-light bg-light navbar-expand">
    <div class="collapse navbar-collapse" :class="{show: isActive}">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <general-link class="nav-link" router-link to="/references" auto-active="route">
            {{ $t("MainMenu.NSI") }}</general-link>
        </li>
        <li class="nav-item">
          <general-link class="nav-link" router-link to="/umkr" auto-active="route">
            {{ $t("MainMenu.UMKR") }}</general-link>
        </li>
        <li class="nav-item">
          <general-link class="nav-link" router-link to="/processing" auto-active="route" @click="onClick">
            {{ $t("MainMenu.Processing") }}</general-link>
        </li>
        <li class="nav-item">
          <general-link class="nav-link" router-link to="/reports" auto-active="route">
            {{ $t("MainMenu.Reports") }}</general-link>
        </li>
      </ul>

      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <nav-toggler-link target-ref="userMenu">
            <i class="fas fa-user mr-2"></i>{{ login }}
          </nav-toggler-link>
          <dropdown-menu ref="userMenu" class="dropdown-menu-right">
<!--            <dropdown-item router-link to="/about">О программе</dropdown-item>-->
<!--            <dropdown-divider></dropdown-divider>-->
            <general-link class="dropdown-item" @click="onLogout" @contextmenu.prevent>
              {{ $t("MainMenu.LogOut") }}</general-link>
          </dropdown-menu>
        </li>
      </ul>
    </div>


  </nav>
</template>

<script>

import ActiveMixin from "@/components/ui/bootstrap/mixins/ActiveMixin";

export default {
  mixins: [ActiveMixin],

  computed: {
    login() {
      // console.error(this.$store.getters.getLogin);
      return this.$store.getters.getLogin;
    },
  },

  methods: {
    onLogout() {
      this.$store.dispatch('doLogout');
    },

    // для корректного отображения данных в окне "Обработка исходных данных" необходимо очистить данный сторик
    // чтобы пользователь запросил их по кнопке "загрузить"
    onClick() {
      this.$store.commit('umkr/setStatInitByTs', []);
    }
  }
}


</script>

<style>

dropdown-item {
  cursor: pointer;
}

</style>