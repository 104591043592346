<template>
  <table-component :columnList="columnList" :rowList="rowList"></table-component>
</template>

<script>
import TableComponent from "@/components/ui/custom/TableComponent";

export default {
  components: {TableComponent},
  props: ['isNotDeleted', 'isAsmppOnly'],
  computed: {
    // колонки таблицы
    columnList(){
      return [
        {
          columnName: "ts_garagnum",
          columnTitle: this.$t('nsi.TsTable.GaragNum'), //"Гаражн.№",
          isVisible: true
        },
        {
          columnName: "ts_statenum",
          columnTitle: this.$t('nsi.TsTable.StateNum'), //"Гос.№",
          isVisible: true
        },
        {
          columnName: "__tt_title",
          columnTitle: this.$t('nsi.TsTable.TransTypeTitle'), //"Тип тр-та",
          isVisible: true
        },
        {
          columnName: "cap_name",
          columnTitle: this.$t('nsi.TsTable.CapacityName'), //"Тип вм-ти",
          isVisible: true
        },
        {
          columnName: "mk_name",
          columnTitle: this.$t('nsi.TsTable.ModelName'), //"Модель",
          isVisible: true
        },
        {
          columnName: "rsNum",
          columnTitle: this.$t('nsi.TsTable.RsNum'), //"Р/с",
          subName: "rs_stationnum",
          isVisible: true
        },
        {
          columnName: "rsNum",
          columnTitle: this.$t('nsi.TsTable.RsType'), //"Тип р/с",
          subName: "rst_typename",
          isVisible: true
        }
        // {
        //   columnName: "raceType",
        //   columnTitle: "Откр. дв.",
        //   isVisible: true
        // },
        // {
        //   columnName: "dispTsId",
        //   columnTitle: "Код АСДУ",
        //   isVisible: true
        // },
      ]
    },
    // строки таблицы
    rowList(){
      let arr = this.$store.getters['ref/getTsArr'];
      let resArr = [];
       for (let i = 0; i < arr.length; i++){

         arr[i].__id = arr[i].ts_id;

         if (arr[i].ts_deleted) {
           arr[i].__rowStyle = "background-color: #a9a8a8;";
         } else {
           if(arr[i].__rowStyle){
             delete arr[i].__rowStyle;
           }
         }

         if(this.$store.getters['ref/getSelectedTransportType'] != null && this.$store.getters['ref/getSelectedTransportType'] != arr[i].tt_id) {
           continue;
         }

        if (this.isNotDeleted || this.isAsmppOnly) {
          if(this.isNotDeleted && arr[i].ts_deleted) {
            continue;
          }
          if(this.isAsmppOnly && arr[i].asmpp_fl) {
            continue;
          }

          let transType = this.$store.getters['ref/getTransTypes'].find(value => value.tt_id == arr[i].tt_id);
          if(transType) {
            arr[i].__tt_title = transType.tt_title;
          } else {
            arr[i].__tt_title = "";
          }

          resArr.push(arr[i]);
        } else {
          let transType = this.$store.getters['ref/getTransTypes'].find(value => value.tt_id == arr[i].tt_id);
          if(transType) {
            arr[i].__tt_title = transType.tt_title;
          } else {
            arr[i].__tt_title = "";
          }

          resArr.push(arr[i]);
        }
      }
      return resArr;
    }
  }
}
</script>

<style scoped>

</style>