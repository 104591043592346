import park_store from "@/store/ref/park_store";
import formation_store from "@/store/ref/formation_store";
import user_params_store from "@/store/main/user_params_store"
import marsh_store from "@/store/ref/marsh_store";
import trans_type_store from "@/store/ref/trans_type_store";
import marsh_variant_store from "@/store/ref/marsh_variant_store";
import race_store from "@/store/ref/race_store";
import marsec_store from "@/store/ref/marsec_store";
import park_station_store from "@/store/ref/park_station_store";
import race_card_store from "@/store/ref/race_card_store";
import tarif_zone_store from "@/store/ref/tarif_zone_store";
import capacity_store from "@/store/ref/capacity_store";
import model_store from "@/store/ref/model_store";
import ts_store from "@/store/ref/ts_store";
import rs_num_store from "@/store/ref/rs_num_store";
import rs_type_store from "@/store/ref/rs_type_store";


export default {
    namespaced: true,
    modules: {
        park_store,
        formation_store,
        user_params_store,
        trans_type_store,
        marsh_store,
        marsh_variant_store,
        race_store,
        marsec_store,
        park_station_store,
        race_card_store,
        tarif_zone_store,
        capacity_store,
        model_store,
        ts_store,
        rs_num_store,
        rs_type_store,
    }
}