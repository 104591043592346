// ХРАНИЛИЩЕ Insp Reis и агрегированной информации
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    actions: {

        // получение объекта
        async doGetDispNariadByTs(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonPark", String(payload.jsonPark));
            params.append("dispTsID", String(payload.dispTsID));
            params.append("dateIndex", String(payload.dateIndex));
            // кодируем данные для корректного отображения русского текста на сервере
            const encoded = encodeURI(params.toString());
            await DoPost(store.getters['settings/getBaseUrl']
                + '/DispatcherRestController/getDispNariadByTsRemote',
                encoded,
                null,
                ''
            );
        },
    }
}
