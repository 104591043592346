<template>
  <template
      v-if="isCorrect"
  >
    <template
        v-for="(element, index) in elements"
        :key="index"
    >
      <circle-zone-element
          v-if="element.zone_element_type_id === 1"
          :zoneElement="element"
          :contextMenu="isEdited ? elementContextMenu : elementNoContextMenu"
          :originalOptions="originalOptions"
          :activeOptions="activeOptions"
          :editOptions="editOptions"
          :disabledOptions="disabledOptions"
          :state="index === selectedElementIndex && isEdited ? 'edit' : index === selectedElementIndex && isActive ? 'active': 'original'"
          events="click"
          @onLeafletEvent="onLeafletEvent($event, index)"
          @onZoneElementChanged="(...args) => onZoneElementChanged(index, ...args)"

      ></circle-zone-element>
      <polyline-zone-element
          v-else-if="element.zone_element_type_id === 2"
          :zoneElement="element"
          :contextMenu="isEdited ? elementContextMenu : elementNoContextMenu"
          :originalOptions="originalOptions"
          :activeOptions="activeOptions"
          :editOptions="editOptions"
          :disabledOptions="disabledOptions"
          :state="index === selectedElementIndex && isEdited ? 'edit' : index === selectedElementIndex && isActive ? 'active': 'original'"
          events="click"
          @onLeafletEvent="onLeafletEvent($event, index)"
          @onZoneElementChanged="(...args) => onZoneElementChanged(index, ...args)"
      ></polyline-zone-element>
      <polygon-zone-element
          v-else-if="element.zone_element_type_id === 3"
          :zoneElement="element"
          :contextMenu="isEdited ? elementContextMenu : elementNoContextMenu"
          :originalOptions="originalOptions"
          :activeOptions="activeOptions"
          :editOptions="editOptions"
          :disabledOptions="disabledOptions"
          :state="index === selectedElementIndex && isEdited ? 'edit' : index === selectedElementIndex && isActive ? 'active': 'original'"
          events="click"
          @onLeafletEvent="onLeafletEvent($event, index)"
          @onZoneElementChanged="(...args) => onZoneElementChanged(index, ...args)"
      ></polygon-zone-element>
    </template>
  </template>
</template>

<script>
import LeafletMapMixin from "@/components/ui/leaflet/mixins/LeafletMapMixin";
import {addLat, addLong} from "@/components/ui/leaflet/LeafletHelpers";

// БАЗОВАЯ ЗОНА

// interface ZONE {
//   zone_id: number;
//   zone_type_id: number;
//   zone_title: string;
//   latitude: number;
//   longitude: number;
//   note: string;
//   elements: ZONE_ELEMENT[];
// }

// interface ZONE_ELEMENT {
//   zone_element_id: number;
//   zone_element_type_id: number;
//   zone_id: number;
//   order_num: number;
//   parameter_size: number;
//   points: ZONE_ELEMENT_POINT[];
// }

export default {
  mixins: [LeafletMapMixin],

  emits: [
    // вызывается при изменении зоны
    'onZoneChanged'
  ],

  props: {
    // видимость
    visible: {
      type: Boolean,
      default: false
    },
    // описание зоны
    zone: {
      required: true,
    },
    // состояние зоны: original - обычное, active - активное, edit - редактируемое, disabled - отключенное
    state: {
      type: String,
      default: 'original'
    },
    // опции элемента зоны в стандартном режиме
    originalOptions: {
      type: Object,
      default: () => {
        return {
          color: 'red',
          weight: 0.0,
          opacity: 0.0,
          fill: true,
          fillColor: 'red',
          fillOpacity: 0.3,
          draggable: false
        }
      }
    },
    // опции элемента заны в активном режиме
    activeOptions: {
      type: Object,
      default: () => {
        return {
          color: 'black',
          weight: 1.0,
          opacity: 1.0,
          fill: true,
          fillColor: 'red',
          fillOpacity: 0.6,
          draggable: false
        }
      }
    },
    // опции элемента зоны в режиме редактирования
    editOptions: {
      type: Object,
      default: () => {
        return {
          color: 'black',
          weight: 2.0,
          opacity: 1.0,
          fill: true,
          fillColor: 'red',
          fillOpacity: 0.6,
          draggable: true
        }
      }
    },
    // опции элемента заны в отключенном режиме
    disabledOptions: {
      type: Object,
      default: () => {
        return {
          color: 'grey',
          weight: 0.0,
          opacity: 0.0,
          fill: true,
          fillColor: 'grey',
          fillOpacity: 0.3,
          draggable: false
        }
      }
    },
  },

  data() {
    return {
      // тип объекта
      leafletObjectType: 'BaseZone',
      // карта
      leafletMap: null,
      // выбранный элемент
      selectedElementIndex: 0,
      // элементы контекстного меню
      addPolygonItem: null,
      addLineItem: null,
      addCircleItem: null,
      // контекстное меню элемента зоны
      elementContextMenu: {
        contextmenu: true,
        contextmenuItems: [
            {
              text: 'Удалить элемент зоны',
              index: 0,
              callback: () => {
                if (this.elements.length <= 1) return;

                // отфильтровываем текущий элемент
                const elements = this.elements.filter((element, index) => this.selectedElementIndex !== index)
                // информируем об изменении зоны
                this.$emit('onZoneChanged', this.getZone(elements), true);
              }
            },
            {
              separator: true,
              index: 1
            }
        ]
      },
      // нет контекстного меню элемента зоны
      elementNoContextMenu: {
        contextmenu: true,
        contextmenuItems: []
      }
    }
  },

  computed: {
    // корректность зоны
    isCorrect() {
      return this.leafletMap &&
          this.zone &&
          this.zone.elements &&
          Array.isArray(this.zone.elements)
    },
    // список элементов
    elements() {
      return [...this.zone.elements]
    },
    // признак режима редактирования
    isEdited() {
      return this.state === 'edit';
    },
    // признак активного режима
    isActive() {
      return this.state === 'active';
    }
  },

  methods: {
    // обработка событий элемента зоны
    onLeafletEvent(event, index) {
      // при щелчке на элемент зоны - он выделяется
      if (event.type === 'click' && (this.state === 'active' || this.state === 'edit')) {
        this.selectedElementIndex = index;
      }
    },

    // добавляем полигон
    addPolygon(event) {
      // текущие координаты
      const coord = [event.latlng.lat, event.latlng.lng];
      // координаты точек
      const point1 = addLong(addLat(coord, -50), -50);
      const point2 = addLong(addLat(coord, 50), -50);
      const point3 = addLong(addLat(coord, 50), 50);
      const point4 = addLong(addLat(coord, -50), 50);
      // создаем новый элемент
      const newElement = {
        zone_element_id: null,
        zone_element_type_id: 3,
        zone_id: this.zone.zone_id,
        order_num: null,
        parameter_size: 0,
        points: [
          {
            zone_element_id: null,
            order_num: 1,
            latitude: point1[0],
            longitude: point1[1],
          },
          {
            zone_element_id: null,
            order_num: 2,
            latitude: point2[0],
            longitude: point2[1],
          },
          {
            zone_element_id: null,
            order_num: 3,
            latitude: point3[0],
            longitude: point3[1],
          },
          {
            zone_element_id: null,
            order_num: 4,
            latitude: point4[0],
            longitude: point4[1],
          }
        ]
      }

      // добавляем новый элемент к списку
      const elements = [...this.elements]
      elements.push(newElement);
      this.selectedElementIndex = elements.length - 1;
      // информируем об изменении зоны
      this.$emit('onZoneChanged', this.getZone(elements), true);
    },

    // добавляем окружность
    addCircle(event) {
      // текущие координаты
      const coord = [event.latlng.lat, event.latlng.lng];
      // создаем новый элемент
      const newElement = {
        zone_element_id: null,
        zone_element_type_id: 1,
        zone_id: this.zone.zone_id,
        order_num: null,
        parameter_size: 50,
        points: [
          {
            zone_element_id: null,
            order_num: 1,
            latitude: coord[0],
            longitude: coord[1],
          },
        ]
      }

      // добавляем новый элемент к списку
      const elements = [...this.elements]
      elements.push(newElement);
      this.selectedElementIndex = elements.length - 1;
      // информируем об изменении зоны
      this.$emit('onZoneChanged', this.getZone(elements), true);
    },

    // добавить линию
    addLine(event) {
      // текущие координаты
      const coord = [event.latlng.lat, event.latlng.lng];
      const point2 = addLong(coord, 2 * 50);
      // создаем новый элемент
      const newElement = {
        zone_element_id: null,
        zone_element_type_id: 2,
        zone_id: this.zone.zone_id,
        order_num: null,
        parameter_size: 50,
        points: [
          {
            zone_element_id: null,
            order_num: 1,
            latitude: coord[0],
            longitude: coord[1],
          },
          {
            zone_element_id: null,
            order_num: 2,
            latitude: point2[0],
            longitude: point2[1],
          },
        ]
      }

      // добавляем новый элемент к списку
      const elements = [...this.elements]
      elements.push(newElement);
      this.selectedElementIndex = elements.length - 1;
      // информируем об изменении зоны
      this.$emit('onZoneChanged', this.getZone(elements), true);
    },

    // вызывается при изменении элементов зоны
    onZoneElementChanged(index, newElement, isEnd) {
      const elements = [...this.elements];
      elements[index] = newElement
      // информируем об изменении зоны
      this.$emit('onZoneChanged', this.getZone(elements), isEnd);
    },

    // возвращает структуру зоны
    getZone(elements) {
      if (!elements) elements = this.elements;

      return {
        zone_id: this.zone.zone_id,
        zone_type_id: this.zone.zone_type_id,
        zone_title: this.zone.zone_title,
        latitude: this.zone.latitude,
        longitude: this.zone.longitude,
        note: this.zone.note,
        elements: elements.map((element, index) => {
          return {
            zone_element_id: element.zone_element_id,
            zone_element_type_id: element.zone_element_type_id,
            zone_id: element.zone_id,
            order_num: Number(index) + 1,
            parameter_size: element.parameter_size,
            points: element.points
          }
        })
      }
    }
  },

  // монтируем слой
  mounted() {
    // ждем создание родителя
    this.getParent().parentReady().then((parent_list) => {
      this.leafletMap = this.getMap(parent_list);
      const map = this.leafletMap.origin;

      // реагируем на изменение настроек элемента зоны извне
      this.$watch(() => this.zone, (newValue) => {
        if (
            newValue &&
            newValue.elements &&
            Array.isArray(newValue.elements)
        ) {
          // сбрасываем выбранный элемент (если он пропал в списке)
          if (this.selectedElementIndex >= newValue.elements.length) {
            this.selectedElementIndex = newValue.elements.length - 1;
          }
        }
      }, {immediate: true});

      // следим за изменением режима редактирования
      this.$watch(() => this.isEdited, (newValue) => {

        if (newValue) {
          // добавляем контекстное меню к карте
          this.addPolygonItem = map.contextmenu.addItem({
            text: 'Добавить Полигон',
            callback: this.addPolygon
          });
          this.addCircleItem = map.contextmenu.addItem({
            text: 'Добавить Окружность',
            callback: this.addCircle
          });
          this.addLineItem = map.contextmenu.addItem({
            text: 'Добавить Линию',
            callback: this.addLine
          });
        }
        else  {
          // удаляем контекстное меню из карты
          if (this.addPolygonItem) {
            map.contextmenu.removeItem(this.addPolygonItem);
            this.addPolygonItem = null;
          }
          if (this.addCircleItem) {
            map.contextmenu.removeItem(this.addCircleItem)
            this.addCircleItem = null;
          }
          if (this.addLineItem) {
            map.contextmenu.removeItem(this.addLineItem)
            this.addLineItem = null;
          }
        }
      }, {immediate: true});
    })
  },

  // демонтируем слой
  unmounted() {
    if (this.leafletMap) {
      // удаляем контекстное меню из карты
      if (this.addPolygonItem)
        this.leafletMap.origin.contextmenu.removeItem(this.addPolygonItem)
      if (this.addCircleItem)
        this.leafletMap.origin.contextmenu.removeItem(this.addCircleItem)
      if (this.addLineItem)
        this.leafletMap.origin.contextmenu.removeItem(this.addLineItem)
    }
  },
}
</script>
