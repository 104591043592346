<template>
  <base-polyline
      :polyline="polyline"
      :originalOptions="baseLineOptions"
      :activeOptions="baseLineOptions"
      :editOptions="baseLineOptions"
      :disabledOptions="baseLineOptions"
      :editPointIconList="editPointIconList"
      @onPolylineChanged="onPolylineChanged"
  >
  </base-polyline>
</template>

<script>
// БАЗОВАЯ ЛИНИЯ ДЛЯ ПРОКЛАДКИ МАРШРУТА
import {icon} from "leaflet";
import marker_icon_a from "../../../img/marker-icon-a.png";
import marker_icon_b from "../../../img/marker-icon-b.png";
import marker_shadow from"leaflet/dist/images/marker-shadow.png";

export default {
  props: {

    // описание линии
    polyline: {
      type: Object,
      required: true,
    },

    // настройки базовой линии типа рейса
    baseLineOptions: {
      type: Object,
      default: () => {
        return {
          color: 'black',
          opacity: 0.4,
          draggable: false,
          weight: 4.0
        }
      }
    },

    // первая иконка редактирования
    editPointStartIcon: {
      type: Object,
      default: () => {
        return icon({
          iconUrl:        marker_icon_a,
          shadowUrl:      marker_shadow,
          iconSize:       [25, 41],
          iconAnchor:     [12, 41],
          popupAnchor:    [1, -34],
          tooltipAnchor:  [16, -28],
          shadowSize:     [41, 41]
        })
      }
    },

    // последняя иконка редактирования
    editPointEndIcon: {
      type: Object,
      default: () => {
        return icon({
          iconUrl:        marker_icon_b,
          shadowUrl:      marker_shadow,
          iconSize:       [25, 41],
          iconAnchor:     [12, 41],
          popupAnchor:    [1, -34],
          tooltipAnchor:  [16, -28],
          shadowSize:     [41, 41]
        })
      }
    },
  },

  computed: {
    // иконки для отображения
    editPointIconList() {
      const arr = []
      arr[this.pointCount - 1] = this.editPointEndIcon;
      arr[0] = this.editPointStartIcon;
      return arr;
    },
  },

  data() {
    return {
      // тип объекта
      leafletObjectType: 'BaseLinePolyline',
      // количество точек
      pointCount: this.polyline.points.length,
    }
  },

  methods: {
    // изменяем количество точек
    onPolylineChanged(polyline) {
      this.pointCount = polyline.points.length;
    },
  }
}
</script>
