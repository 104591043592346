// ХРАНИЛИЩЕ Insp Reis и агрегированной информации
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список агрегированных объектов InspReisesPassFlowIndicators
            inspReisPassFlowIndicators: [],
            // время обновления кэша new Date().getTime()
            // insp_reis_pass_flow_indicatorsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            // insp_reis_pass_flow_indicatorsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список InspReisesPassFlowIndicators
        getInspReisesPassFlowIndicators(state) {
            return state.inspReisPassFlowIndicators;
        },
    },
    mutations: {
        // установить новый список объектов
        setInspReisesPassFlowIndicators(state, payload) {
            state.inspReisPassFlowIndicators = payload;
            // state.insp_reis_pass_flow_indicatorsCache = new Date().getTime()
            // console.log('setParks', state.parks)
        },

    },
    actions: {

        // добавление объекта
        async doGetInspReisesPassFlowIndicators(context, payload) {
            const params = new URLSearchParams();
            params.append("dateIndex_st", String(payload.startDate));
            params.append("dateIndex_end", String(payload.endDate));
            params.append("weekdayType", String(payload.weekdayIndex));
            params.append("jsonParksArr", JSON.stringify(payload.parkArr));
            params.append("agregationType", String(payload.inspPassFlowSqlVariant));
            params.append("filterValue", String(payload.filterValue ? payload.filterValue : '0'));
            // кодируем данные для корректного отображения русского текста на сервере
            const encoded = encodeURI(params.toString());
            await DoPost(store.getters['settings/getBaseUrl']
                + '/InspReisRestController/getReisesPassFlowIndicators_ByParksRemote',
                encoded,
                'rep/setInspReisesPassFlowIndicators',
                'Получение агрегированной информации inspReisPassFlowIndicators'
            );
        },
    }
}
