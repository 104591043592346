<template>
  <!--  <align-container>-->
  <!--    <align-panel align="all">-->
  <!--      <div class="card h-100 vehicle-button-panel">-->
  <!--        <align-container>-->
  <!--            <div class="card-body">-->
  <!--              <align-panel id="table" align="all" style="overflow-y: auto; overflow-x: hidden"-->
  <!--                           @keydown.down="console.log(1231231)">-->
  <table class="table table-sm">
    <thead class="thead-light sticky-top" style="font-size: small;">
    <tr>
      <th class="text-center" scope="col">{{ $t("HistPanel.Time") }}</th>
      <th class="text-center" scope="col">{{ $t("HistPanel.Door") }}</th>
      <th class="text-center" scope="col">{{ $t("HistPanel.In") }}</th>
      <th class="text-center" scope="col">{{ $t("HistPanel.Out") }}</th>
      <th class="text-center" scope="col">{{ $t("HistPanel.Lat") }}</th>
      <th class="text-center" scope="col">{{ $t("HistPanel.Lon") }}</th>
    </tr>
    </thead>
    <tbody>
    <tr
        v-for="(nav) in navTsArray"
        :id="nav.__id"
        :key="nav.__id"
        :class="{'bg-primary text-white': include(nav)}"
        @click="select($event, nav)"
        :style="getRowStyle(nav)"
    >
      <!--                      Время     -->
      <td class="text-center" style=" padding-top: 1px !important; padding-bottom: 1px !important;"
      >{{ getTime(nav.time_asInt) }}
      </td>
      <!--                      Дверь     -->
      <td class="text-center" style=" padding-top: 1px !important; padding-bottom: 1px !important;"
      >{{ nav.doornum }}
      </td>
      <!--                      Вход     -->
      <td class="text-center" style=" padding-top: 1px !important; padding-bottom: 1px !important;"
      >{{ nav.pin }}
      </td>
      <!--                      Выход     -->
      <td class="text-center" style=" padding-top: 1px !important; padding-bottom: 1px !important;"
      >{{ nav.pout }}
      </td>
      <!--                      Широта     -->
      <td class="text-center" style=" padding-top: 1px !important; padding-bottom: 1px !important;"
      >{{ nav.latitude }}
      </td>
      <!--                      Долгота     -->
      <td class="text-center" style=" padding-top: 1px !important; padding-bottom: 1px !important;"
      >{{ nav.longitude }}
      </td>
    </tr>
    </tbody>
  </table>
  <!--              </align-panel>-->
  <!--            </div>-->
  <!--        </align-container>-->
  <!--      </div>-->
  <!--    </align-panel>-->
  <!--  </align-container>-->
</template>

<script>

import {dateToString, getTimeWithSecondsFormat} from "@/lib";
// import AlignContainer from "@/components/ui/custom/AlignContainer";
// import AlignPanel from "@/components/ui/custom/AlignPanel";
// import UniversalSelector from "@/components/ui/custom/UniversalSelector";
// import InputCheckbox from "@/components/ui/custom/InputCheckbox";
import {preventDefault} from "leaflet/src/dom/DomEvent";


export default {
  name: "HistPanel",
  // components: {AlignPanel, AlignContainer},
  // props: ['selectedNavs', 'isLoading'],
  props: ['navTsArray', 'isLoading'],
  data() {
    return {
      date: dateToString(),
      viewType: 0,
      isFirstDoor: true,
      isSecondDoor: true,
      isThirdDoor: true,
      isFourthDoor: true,
      isNullData: false,
      nullData: 0,
      // переменная, в которой хранится выбранная навигационная отметка (для корректной работы выбора через Ctrl и Shift)
      selectedNavIndex: null,
      selectedNavPoint: null,
    }
  },

  watch: {},

  computed: {

    // selectedNavsArr: {
    //   get() {
    //     return this.selectedNav
    //   },
    //   set(value) {
    //     console.log(value);
    //     // this.$emit('onUpdateSelectedNavs', value);
    //   }
    // }

  },

  methods: {

    updatePointOnMap() {
      this.$emit('onUpdateSelectedNavs', this.selectedNavPoint);
    },

    // получаем стиль строк
    getRowStyle(nav) {
      if (nav.typeofrecord.typeofrecord === 1) {
        return 'cursor: pointer; font-size: small; user-select: none;';
      } else {
        return 'color: red !important; cursor: pointer; font-size: small; user-select: none;';
      }
    },

    select(e, nav) {
      if (!this.isLoading) {
        // if (e.ctrlKey && !e.shiftKey) {
        //   this.onCtrlSelect(nav);
        // } else if (!e.ctrlKey && e.shiftKey) {
        //   this.onShiftSelect(nav);
        // } else if ((!e.ctrlKey && !e.shiftKey)) {
        this.onSelect(nav);
        // } else {
        //   this.onClearSelect(nav);
        // }
      }
    },

    // клик с удержанием клавиши Ctrl
    // onCtrlSelect(nav) {
    //   // если массив выбранных строк содержит nav_id, то удаляем его оттуда
    //   let arr = this.selectedNavsArr;
    //   if (this.selectedNavIndex != null) {
    //     arr.splice(this.selectedNavIndex, 1);
    //     // добавляем, если индекса нет
    //   } else {
    //     arr.push(nav);
    //     this.selectedNavsArr = arr;
    //     let ind = this.selectedNavsArr.findIndex(value => (value.nav_id == nav.nav_id) && (value.doornum == nav.doornum));
    //     // запоминаем выбранную нав. отметку
    //     this.selectedNavIndex = ind;
    //   }
    // },

    // клик с удержанием клавиши Shift
    // onShiftSelect(nav) {
    //   let arr = [];
    //   // если уже есть выбранная(сохраненная) строка -> делаем выбранными все строки между выбранными
    //   if (this.selectedNavIndex != null) {
    //     // ищем индекс выбранной строки сохраненной
    //     let ind = this.selectedNavIndex;
    //     // ищем индекс выбранной второй строки
    //     let index = this.rowList.findIndex(value => (value.nav_id == nav.nav_id) && (value.doornum == nav.doornum));
    //     // если индекс выбранной строки больше индекса сохраненной строки
    //     if (index > ind) {
    //       for (let i = ind; i <= index; i++) {
    //         let value = this.rowList[i];
    //         if (value.latitude != 0 && value.longitude != 0) {
    //           arr.push(value);
    //         }
    //       }
    //       // если индекс выбранной строки меньше индекса сохраненной строки
    //     } else if (ind > index) {
    //       for (let i = index; i <= ind; i++) {
    //         let value = this.rowList[i];
    //         if (value.latitude != 0 && value.longitude != 0) {
    //           arr.push(value);
    //         }
    //       }
    //     }
    //     this.selectedNavsArr = arr;
    //   } else {
    //     let arr = [];
    //     arr.push(nav);
    //     this.selectedNavsArr = arr;
    //     let index = this.rowList.findIndex(value => (value.nav_id == nav.nav_id) && (value.doornum == nav.doornum));
    //     // запоминаем выбранную нав. отметку
    //     this.selectedNavIndex = index;
    //   }
    // },

    // клик без удержания каких-либо клавиш
    onSelect(nav) {
      // this.selectedNavsArr = [];
      // let arr = [];
      // arr.push(nav);
      // this.selectedNav = arr;
      this.selectedNavPoint = nav;
      let index = this.navTsArray.findIndex(value => (value.nav_id == nav.nav_id) && (value.doornum == nav.doornum));
      // запоминаем выбранную нав. отметку
      this.selectedNavIndex = index;

      this.updatePointOnMap();
    },

    // очистка выделения в таблице навигации
    // onClearSelect() {
    //   this.selectedNavsArr = [];
    // },

    getTime(time_nav) {
      return getTimeWithSecondsFormat(time_nav)
    },

    include(nav) {
      if (nav) {
        // let row = this.navTsArray.find(value => (value.nav_id == nav.nav_id) && (value.doornum == nav.doornum));
        // if (row) {
        if (nav == this.selectedNavPoint) {
          return true;
        }
        return false;
      }
      return false;
    },

    arrowDownPressed() {
      // если есть выбранная строка, переходим к следующей
      if (this.selectedNavIndex != null && this.selectedNavIndex != (this.navTsArray.length - 1)) {
        // // обычно должна быть выбрана следующая строка т.е. index+1, однако иногда следующая строчка может иметь тот же nav_id
        // // чтобы это не вызывало ошибку делаем также проверку по двери
        this.selectedNavPoint = this.navTsArray[this.selectedNavIndex + 1];
        // запоминаем выбранную нав. отметку
        this.selectedNavIndex = this.selectedNavIndex + 1;
        // Скроллим до выбранной строки
        this.scrollToSelectedRowNavId(this.selectedNavPoint);

        this.updatePointOnMap();
      }
    },

    arrowUpPressed() {
      // если есть выбранная строка, переходим к следующей
      if (this.selectedNavIndex != null && this.selectedNavIndex != 0) {
        // // обычно должна быть выбрана следующая строка т.е. index+1, однако иногда следующая строчка может иметь тот же nav_id
        // // чтобы это не вызывало ошибку делаем также проверку по двери
        this.selectedNavPoint = this.navTsArray[this.selectedNavIndex - 1];
        // запоминаем выбранную нав. отметку
        this.selectedNavIndex = this.selectedNavIndex - 1;
        // Скроллим до выбранной строки
        this.scrollToSelectedRowNavId(this.selectedNavPoint);

        this.updatePointOnMap();
      }
    },

    // отменяем скролл на странице при нажатии стрелочек
    preventDefaultForScrollKeys(e) {
      let keys = {37: 1, 38: 1, 39: 1, 40: 1};
      if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    },

    scrollToSelectedRowNavId(value) {
      let el = document.getElementById(value.__id);
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    }

  },

  mounted() {
    // добавляем eventListener, который отменяет скролл на странице при нажатии стрелочек
    window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
    // переопределяем действия при нажатии стрелочек
    window.addEventListener('keydown', (event) => {
      // нажата стрелочка вниз
      if (event.code == 'ArrowDown') {
        this.arrowDownPressed();
      } else if (event.code == 'ArrowUp') {
        // нажата стрелочка вверх
        this.arrowUpPressed();
      }
    });
  },

  unmounted() {
    // убираем eventListener -ы
    window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
    window.removeEventListener('keydown', (event) => {
      // нажата стрелочка вниз
      if (event.code == 'ArrowDown') {
        this.arrowDownPressed();
      } else if (event.code == 'ArrowUp') {
        // нажата стрелочка вверх
        this.arrowUpPressed();
      }
    });
  }
}
</script>

<style scoped>

table {
  border-collapse: separate;
  border-spacing: 0;
}

td {
  border-top: 0;
  border-bottom: 1px solid #dee2e6;
}

tbody {
  display: block;
}

thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

</style>