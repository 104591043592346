import LeafletMap from './LeafletMap';

import LeafletZoomControl from "@/components/ui/leaflet/controls/LeafletZoomControl";
import LeafletAttributionControl from "@/components/ui/leaflet/controls/LeafletAttributionControl";
import LeafletScaleControl from "@/components/ui/leaflet/controls/LeafletScaleControl";
import LeafletLayersControl from "@/components/ui/leaflet/controls/LeafletLayersControl";
import LeafletControlGroup from "@/components/ui/leaflet/controls/LeafletControlGroup";

import LeafletTileLayer from "@/components/ui/leaflet/layers/baselayers/LeafletTileLayer";
// import LeafletYandexLayer from "@/components/ui/leaflet/layers/baselayers/LeafletYandexLayer";
import LeafletBaseLayerGroup from "@/components/ui/leaflet/layers/baselayers/LeafletBaseLayerGroup";

import LeafletMarker from "@/components/ui/leaflet/layers/markers/LeafletMarker";
import StopMarker from "@/components/ui/leaflet/layers/markers/stop/StopMarker";
import StopstationMarker from "@/components/ui/leaflet/layers/markers/stopstation/StopstationMarker";
import StopPavilionMarker from "@/components/ui/leaflet/layers/markers/stop_pavilions/StopPavilionMarker";
import GraphNodeMarker from "@/components/ui/leaflet/layers/markers/graph_node/GraphNodeMarker";
import VehicleMarker from "@/components/ui/leaflet/layers/markers/vehicle/VehicleMarker";

import LeafletCircle from "@/components/ui/leaflet/layers/path/LeafletCircle";
import LeafletCircleMarker from "@/components/ui/leaflet/layers/path/LeafletCircleMarker";
import LeafletPolyline from "@/components/ui/leaflet/layers/path/LeafletPolyline";
import LeafletPolylineDecorator from "@/components/ui/leaflet/layers/path/LeafletPolylineDecorator";
import LeafletPolygon from "@/components/ui/leaflet/layers/path/LeafletPolygon";

import BasePolyline from "@/components/ui/leaflet/layers/path/polyline/BasePolyline";
import BaseLinePolyline from "@/components/ui/leaflet/layers/path/polyline/BaseLinePolyline";
import TripTypeLinePolyline from "@/components/ui/leaflet/layers/path/polyline/TripTypeLinePolyline";
import RoutingLinePolyline from "@/components/ui/leaflet/layers/path/polyline/RoutingLinePolyline";


import PolygonZoneElement from "@/components/ui/leaflet/layers/path/zone_elements/PolygonZoneElement";
import CircleZoneElement from "@/components/ui/leaflet/layers/path/zone_elements/CircleZoneElement";
import PolylineZoneElement from "@/components/ui/leaflet/layers/path/zone_elements/PolylineZoneElement";
import PointZoneElement from "@/components/ui/leaflet/layers/path/zone_elements/PointZoneElement";
import BaseZone from "@/components/ui/leaflet/layers/path/zone/BaseZone";

import * as L from 'leaflet';
import 'leaflet-contextmenu';
import 'leaflet.path.drag';
import 'leaflet-polylinedecorator';
// import 'leaflet-plugins/layer/tile/Yandex'


// исправляем ошибку в исходном коде карты
L.Tooltip.prototype._updatePosition = function () {
    if (!this._map) return;
    var pos = this._map.latLngToLayerPoint(this._latlng);
    this._setPosition(pos);
}

// исправляем ошибку в исходном коде карты
L.Tooltip.prototype._animateZoom =  function (e) {
    if (!this._map) return;
    var pos = this._map._latLngToNewLayerPoint(this._latlng, e.zoom, e.center);
    this._setPosition(pos);
}

export default function(app) {
    // карта
    app.component('leaflet-map', LeafletMap);

    // элементы управления
    app.component('leaflet-zoom-control', LeafletZoomControl);
    app.component('leaflet-attribution-control', LeafletAttributionControl);
    app.component('leaflet-scale-control', LeafletScaleControl);
    app.component('leaflet-layers-control', LeafletLayersControl);
    app.component('leaflet-control-group', LeafletControlGroup);

    // базовые слои
    app.component('leaflet-tile-layer', LeafletTileLayer);
    // app.component('leaflet-yandex-layer', LeafletYandexLayer);
    app.component('leaflet-base-layer-group', LeafletBaseLayerGroup);

    // маркеры
    app.component('leaflet-marker', LeafletMarker);
    app.component('stop-marker', StopMarker);
    app.component('stopstation-marker', StopstationMarker);
    app.component('stop-pavilion-marker', StopPavilionMarker);
    app.component('graph-node-marker', GraphNodeMarker);
    app.component('vehicle-marker', VehicleMarker);

    // примитивы
    app.component('leaflet-circle', LeafletCircle);
    app.component('leaflet-circle-marker', LeafletCircleMarker);
    app.component('leaflet-polyline', LeafletPolyline);
    app.component('leaflet-polyline-decorator', LeafletPolylineDecorator);
    app.component('leaflet-polygon', LeafletPolygon);

    // // группы
    // app.component('leaflet-layer-group', LeafletLayerGroup);
    // app.component('leaflet-feature-group', LeafletFeatureGroup);
    // app.component('leaflet-marker-cluster-group', LeafletMarkerClusterGroup);

    // полилинии
    app.component('base-polyline', BasePolyline);
    app.component('base-line-polyline', BaseLinePolyline);
    app.component('trip-type-line-polyline', TripTypeLinePolyline);
    app.component('routing-line-polyline', RoutingLinePolyline);

    // элементы зоны
    app.component('polygon-zone-element', PolygonZoneElement);
    app.component('circle-zone-element', CircleZoneElement);
    app.component('polyline-zone-element', PolylineZoneElement);
    app.component('point-zone-element', PointZoneElement);
    app.component('base-zone', BaseZone);
}
