import RegisterLeaflet from "@/components/ui/leaflet/RegisterLeaflet";
import RegisterBootstrap from "@/components/ui/bootstrap/RegisterBootstrap";

export default function (app) {

    // регистрируем Leaflet-компоненты
    RegisterLeaflet(app);

    // регистрируем Bootstrap-компоненты
    RegisterBootstrap(app);

}