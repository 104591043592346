<template>
  <div class="tab-pane" :class="{active: isActive}">
    <slot></slot>
  </div>
</template>

<script>
import ActiveMixin from "@/components/ui/bootstrap/mixins/ActiveMixin";
import ChildMixin from "@/components/ui/bootstrap/mixins/ChildMixin";

export default {
  mixins: [ActiveMixin, ChildMixin]
}
</script>
