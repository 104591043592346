// ОБЕСПЕЧИВАЕТ ДОСТУП К КАРТЕ ИЗ ПРОЧИХ ОБЪЕКТОВ

import {point} from "leaflet";
import {latLng} from "leaflet/dist/leaflet-src.esm";

export default {
    methods: {
        // возвращает ближайшего родителя
        getParent() {
            let c = this.$parent;
            while (c) {
                if (c.parentReady) {
                    return c
                }
                c = c.$parent;
            }
            return null
        },
        // возвращает ближайший групповой слой
        getGroupLayer(parent_list) {
            return parent_list.findLast((obj) => obj.origin && obj.origin.addLayer);
        },
        // возвращает ближайший элемент управления слоями
        getLayerControl(parent_list) {
            return parent_list.findLast((obj) => obj.leafletObjectType === 'LeafletLayersControl')
        },
        // возвращает карту
        getMap(parent_list) {
            return parent_list[0]
        },
        // сверяет геогрфические координаты точек
        isLatLngEqual(lt1, lt2) {
            // если оба объекта не заданы
            if (lt1 == lt2) return true;
            // если один из объектов не задан
            if (!lt1 || !lt2) return false;
            // проверяем
            return latLng(lt1).equals(latLng(lt2))
        },
        // сверяет массив географиеческих кординат
        isLatLngsEqual(list1, list2) {
            // если оба объекта не заданы
            if (list1 == list2) return true;
            // если один из объектов не задан
            if (!list1 || !list2) return false;
            // проверяем
            return list1.length === list2.length && list1.every((value, index) => this.isLatLngEqual(value, list2[index]))
        },
        // сверяет координаты точки
        isPointEqual(p1, p2) {
            // если оба объекта не заданы
            if (p1 == p2) return true;
            // если один из объектов не задан
            if (!p1 || !p2) return false;

            return point(p1).equals(point(p2));
        },
        // сверяет два опции двух tooltips
        isTooltipOptionEqual(t1, t2) {
            // чтобы отличить null от object
            if (t1 === null) t1 = undefined;
            if (t2 === null) t2 = undefined;

            // два пустых объекта равны
            if (t1 === undefined && t2 === undefined) return true;
            // объекты разных типов не равны
            if (typeof(t1) !== typeof(t2)) return false;
            // если это не объекты - то опции не отличаются
            if (typeof(t1) !== 'object') return true;

            const o1 = t1.options;
            const o2 = t2.options;

            return o1['pane'] == o2['pane'] &&
                this.isPointEqual(o1['offset'], o2['offset']) &&
                o1['direction'] == o2['direction'] &&
                o1['permanent'] == o2['permanent'] &&
                o1['sticky'] == o2['sticky'] &&
                o1['interactive'] == o2['interactive'] &&
                o1['opacity'] == o2['opacity'] &&
                o1['className'] == o2['className'] &&
                o1['attribution'] == o2['attribution']
        },
        // сверяет содержимое двух tooltips
        isTooltipContentEqual(t1, t2) {
            // чтобы отличить null от object
            if (t1 === null) t1 = undefined;
            if (t2 === null) t2 = undefined;

            // два пустых объекта равны
            if (t1 === undefined && t2 === undefined) return true;
            // если это не объекты - то сравниваем по содержимому
            if (typeof(t1) !== 'object') return t1 == t2;
            // сравниваем контент
            return t1.getContent() == t2.getContent()
        },
        // сверяет иконки
        isIconEqual(i1, i2) {
            // если оба объекта не заданы
            if (i1 == i2) return true;
            // если один из объектов не задан
            if (!i1 || !i2) return false;

            const o1 = i1.options;
            const o2 = i2.options;
            return o1['iconUrl'] == o2['iconUrl'] &&
                o1['iconRetinaUrl'] == o2['iconRetinaUrl'] &&
                this.isPointEqual(o1['iconSize'], o2['iconSize']) &&
                this.isPointEqual(o1['iconAnchor'], o2['iconAnchor']) &&
                this.isPointEqual(o1['popupAnchor'], o2['popupAnchor']) &&
                this.isPointEqual(o1['tooltipAnchor'], o2['tooltipAnchor']) &&
                o1['shadowUrl'] == o2['shadowUrl'] &&
                o1['shadowRetinaUrl'] == o2['shadowRetinaUrl'] &&
                this.isPointEqual(o1['shadowSize'], o2['shadowSize']) &&
                this.isPointEqual(o1['shadowAnchor'], o2['shadowAnchor']) &&
                o1['className'] == o2['className'] &&
                o1['html'] == o2['html'] &&
                this.isPointEqual(o1['bgPos'], o2['bgPos']);
        }
    }
}
