import axios from 'axios';
import store from './Store';

export async function DoPost(
    // url для отправки (относительно корня)
    url,
    // передаваемые данные
    json,
    // мьютейшен
    mutation,
    // заголовок сообщения
    caption,
) {
    try {
        // логируем
        if (process.env.NODE_ENV === 'development') {
            console.log(caption)
        }
        // базовый адрес
        //const baseUrl = store.getters['settings/getBaseUrl'];
        // отправляем запрос

        const response = await postRequest(
            url,
            json,
            store.getters.getTokenId
        );
        console.log('DoPost:',response);

        // if (response.data == '') {
        //     return true;
        // }

        // парсируем JSON
        if (typeof (response.data) === 'string') {
            try {
                response.data = JSON.parse(response.data);
            } catch {
                return response.data;
                //console.log('Ошибка парсирования JSON-документа');
                //throw new Error('Ошибка парсирования JSON-документа');
            }
        }

        // проверяем ответ на корректность
        if (response.data && typeof(response.data) !== 'undefined') {
            // запоминаем новую строку
            if (mutation) {
                store.commit(mutation, response.data);
            }
            // фиксируем успех
            return response.data;
        } else {
            // выходим с ошибкой
            return false;
        }
    } catch (e) {
        // выходим с ошибкой
        return false;
    }
}


// Выполнение post-запроса
export async function postRequest(url, json, token) {

    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};

    if (token) {
        headers.token = `${token}`
    }
    //console.log('PostRequest: before', json, url);
    try {
        // выполняем запрос
        const response = await axios.post(url, json, {
            headers
        });

        // определяем коды
        const httpCode = parseInt(response.status);
        const httpStatus = response.statusText;

        // логируем
        if (process.env.NODE_ENV === 'development') {
            console.debug('-----')
            console.debug(`${httpCode} ${httpStatus}`)
            console.debug(response.data)
        }

        // возвращаем результат
        return {
            httpCode,
            httpStatus,
            data: response.data
        }
    } catch (e) {
        console.log(e);
        // вернулся ответ с ошибкой
        if (e.response) {
            // запрашиваем статусы http
            const httpCode = parseInt(e.response.status);
            const httpStatus = e.response.statusText;

            // определяем внутренние ошибки
            let error = 0;
            let errorMessage = '';
            if (e.response.data && e.response.data['error'] && e.response.data['error_message']) {
                error = e.response.data['error'];
                errorMessage = e.response.data['error_message'];
            }

            // логируем
            if (process.env.NODE_ENV === 'development') {
                console.debug('-----')
                console.debug(`${httpCode} ${httpStatus}`)
                console.debug(e.response.data)
            }

            // если сервер вернул - требуется аутентификация
            if (httpCode == 401) {
                // запоминаем откуда уходим
                await store.dispatch('doSetLoadedUrl', {
                    //!!!loadedUrl: router.currentRoute.value
                });
                // очищаем данные аутентификации
                await store.commit('clearAuth');
                // переходим на страницу авторизации
                //!!!await router.replace('/auth');
            }

            // формируем ошибку
            const newError = new Error(errorMessage || httpStatus);
            newError.httpCode = httpCode;
            newError.httpStatus = httpStatus;
            newError.error = error;
            newError.errorMessage = errorMessage;
            throw newError;
        }
        // вернулся ответ без ошибки
        else {
            // формируем ошибку
            const newError = new Error('Ошибка сети');
            newError.httpCode = 0;
            newError.httpStatus = '';
            newError.error = 0;
            newError.errorMessage = '';
            throw newError;
        }
    }
}

