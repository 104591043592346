<template>
  <leaflet-marker
      v-if="isCorrect"
      :latlng="latlng"
      :events="events + ' dragend'"
      :icon="icon"
      :options="options"
      :targetComponent="this"
      :logTitle="logTitle"
      @onLeafletEvent="onLeafletEvent($event)"
  ></leaflet-marker>
</template>

<script>

// УЗЕЛ ГРАФА

// interface GRAPH_NODE {
//   node_id: number;
//   latitude: number;
//   longitude: number;
// }

import icons from "@/components/ui/leaflet/layers/markers/graph_node/GraphNodeIcon";
import LeafletMapMixin from "@/components/ui/leaflet/mixins/LeafletMapMixin";
import LeafletEventedMixin from "@/components/ui/leaflet/mixins/LeafletEventedMixin";

export default {
  mixins: [LeafletMapMixin, LeafletEventedMixin],
  emits: [
    // вызывается при изменении узла
    'onGraphNodeChanged'
  ],
  props: {
    // состояния узла графа: original - обычное, active - активное, edit - редактируемое, disabled - отключенное
    state: {
      type: String,
      default: 'original'
    },
    // описание узла графа
    graphNode: {
      type: Object,
    },
    // опции маркера в обычном состоянии
    originalOptions: {
      type: Object,
      default: () => {
        return {
          draggable: false,
          clickable: true
        }
      }
    },
    // опции маркера в активном состоянии
    activeOptions: {
      type: Object,
      default: () => {
        return {
          draggable: true,
          clickable: true,
          zIndexOffset: 1000
        }
      }
    },
    // опции маркера в редактируемом состоянии
    editOptions: {
      type: Object,
      default: () => {
        return {
          draggable: true,
          clickable: true,
          zIndexOffset: 1010
        }
      }
    },
    // опции маркера в отключенном состоянии
    disabledOptions: {
      type: Object,
      default: () => {
        return {
          draggable: false,
          clickable: false
        }
      }
    },
  },
  data() {
    return {
      // тип объекта
      leafletObjectType: 'GraphNodeMarker',
    }
  },
  computed: {
    // логирование
    logTitle() {
      return `GraphNode(#${this.graphNode.node_id})`
    },
    // корректность данных
    isCorrect() {
      return this.graphNode && Number.isFinite(this.graphNode.latitude) && Number.isFinite(this.graphNode.longitude)
    },
    // координаты узла
    latlng() {
      return [this.graphNode.latitude, this.graphNode.longitude];
    },

    // индекс по оси Х
    xIndex() {
      if (this.state === 'disabled') return 3
      else if (this.state === 'edit') return 2
      else if (this.state === 'active') return 1
      else return 0;
    },

    // иконка
    icon() {
      return icons[this.xIndex]
    },

    // опции
    options() {
      if (this.state === 'disabled') return this.disabledOptions
      else if (this.state === 'edit') return this.editOptions
      else if (this.state === 'active') return this.activeOptions
      else return this.originalOptions;
    },

    // признак режима редактирования
    isEdited() {
      return this.state === 'edit';
    }
  },
  methods: {
    // события маркера
    onLeafletEvent(event) {
      // завершение перетаскивание
      if (event.type === 'dragend') {
        const latlng = event.target.getLatLng()
        // информируем об изменении
        this.$emit('onGraphNodeChanged', {
          ...this.graphNode,
          latitude: latlng.lat,
          longitude: latlng.lng,
        });
      }

      // проверяем и вызываем внешние события
      if (this.eventsToArray(this.events).includes(event.type)) {
        this.$emit('onLeafletEvent', event);
      }
    },
  },
};

</script>

<style>
/* Иконка узла графа */
.graph-node-icon {
  border: 0px;
  margin: 0px;
  padding: 0px;
  background: url("/src/components/ui/leaflet/img/graph-node.png");
}
</style>
