import LeafletMapMixin from "@/components/ui/leaflet/mixins/LeafletMapMixin";
import {toRaw} from "vue";

// УПРАВЛЕНИЕ ЭЛЕМЕНТАМИ УПРАВЛЕНИЯ

export default {
    mixins: [LeafletMapMixin],
    props: {
        // опции элемента
        options: {
            type: Object,
            default: () => {}
        },
        // произвольные данные - добавляются к leaflet-компоненту в поле __meta
        meta: {
            required: false
        },
        // заголовк элемента (для отладки)
        logTitle: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            // тип объекта
            leafletObjectType: 'LeafletControl',
            // орининальный объект (проксированный)
            proxyOrigin: null,
            // карта
            leafletMap: null,
            // таймер для пересоздания
            reCreateTimer: null
        }
    },
    computed: {
        // орининальный объект
        origin() {
            return toRaw(this.proxyOrigin)
        }
    },
    methods: {
        // создание элемента (реализуется в потомках)
        createControl() {},
        // уничтожения элемента  (реализуется в потомках)
        destroyControl() {},
        // установить видимость элемента
        setVisibility(visible) {
            // отображаем на карте
            if (visible && !this.origin) {
                // создаем элемент
                const ctl = this.createControl()
                // вставляем элемент на карту
                if (ctl) {
                    this.leafletMap.origin.addControl(ctl);
                    // фиксируем скрытия элемент с карты
                    //console.log('Создан элемент управления <'+this.leafletObjectType+'>: '+this.logTitle);
                }
                // фиксируем запуск элемента (для гуппы)
                this.$nextTick(() => {
                    this.isGroupReady = true;
                })
            }
            // скрываем с карты
            else if (!visible && this.origin) {
                // удаляем элемент из карты
                this.leafletMap.origin.removeControl(this.origin);
                // удаляем элемент
                this.destroyControl();
                // логируем
                //console.log('Удален элемент управления <'+this.leafletObjectType+'>: '+this.logTitle);
            }
        },
        // пересоздание элемента управления
        reCreateControl() {
            // таймер уже создан
            if (this.reCreateTimer) return;

            this.reCreateTimer = setTimeout(() => {
                // скрываем элемент
                this.setVisibility(false);
                this.reCreateTimer = setTimeout(() => {
                    // отображаем элемент заново
                    this.setVisibility(true)
                    this.reCreateTimer = null
                }, 0)
            }, 0)
        },
    },
    // монтируем элемент
    mounted() {
        // ждем создание родителя
        this.getParent().parentReady().then((parent_list) => {
            // ищем карту
            this.leafletMap = this.getMap(parent_list);

            // отображаем слой
            this.setVisibility(true)

            // наблюдатель за опциями
            this.$watch(() => this.options, () => {
                //console.log('Требуется изменить опции элемента управления <'+this.leafletObjectType+'>: '+ this.logTitle)
                this.reCreateControl()
            }, {deep: false});
        })
    },
    // демонтируем элемент
    unmounted() {
        // отменяем таймер перерисовки
        if (this.reCreateTimer) {
            clearTimeout(this.reCreateTimer);
            this.reCreateTimer = null
        }
        // скрываем слой
        this.setVisibility(false)
    },
    // формируем пустой шаблон
    render() {
        return null
    }
}
