// ХРАНИЛИЩЕ ТС
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            ts_arr: [],
            // список объектов изначальный
            ts_arr_original: [],
        }
    },
    getters: {
        // вернуть список объектов
        getTS(state) {
            return state.ts_arr;
        },

        // вернуть изначальный список объектов
        getTSOriginal(state) {
            return state.ts_arr_original;
        },
    },
    mutations: {
        // установить новый список объектов
        setTS(state, payload) {
            state.ts_arr = payload;
            state.ts_arr.map(value => {
                state.ts_arr_original.push({...value})
            })
        },
    },
    actions: {
        // добавление объекта
        async doGetActiveASMPPTSByPark(state,payload) {
            //формируем параметра запроса
            //const curPark = localStorage.getItem('curPark');
            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.curPark));

            return await DoPost(store.getters['settings/getBaseUrl'] +
                '/TSRestController/getActiveAsmppTsRemote',
                params.toString(),
                'umkr/setTS',
                'Получение списка ТС парка'
            );
        },
    }
}