<template>
  <li class="nav-item">
    <slot></slot>
  </li>
</template>

<script>
export default {
  data() {
    return {
      name: 'NavItem'
    }
  }
}
</script>
