import {divIcon} from "leaflet";

export default [
    divIcon({
        html: '<span></span>',
        iconSize: [8, 8],
        iconAnchor: [4, 4],
        tooltipAnchor: [1, -4],
        shadowSize: [0, 0],
        shadowAnchor: [0, 0],
        popupAnchor: [4, 1],
        bgPos: [0, 0],
        className: 'graph-node-icon'
    }),
    divIcon({
        html: '<span></span>',
        iconSize: [8, 8],
        iconAnchor: [4, 4],
        tooltipAnchor: [1, -4],
        shadowSize: [0, 0],
        shadowAnchor: [0, 0],
        popupAnchor: [4, 1],
        bgPos: [8, 0],
        className: 'graph-node-icon'
    }),
    divIcon({
        html: '<span></span>',
        iconSize: [8, 8],
        iconAnchor: [4, 4],
        tooltipAnchor: [1, -4],
        shadowSize: [0, 0],
        shadowAnchor: [0, 0],
        popupAnchor: [4, 1],
        bgPos: [16, 0],
        className: 'graph-node-icon'
    }),
    divIcon({
        html: '<span></span>',
        iconSize: [8, 8],
        iconAnchor: [4, 4],
        tooltipAnchor: [1, -4],
        shadowSize: [0, 0],
        shadowAnchor: [0, 0],
        popupAnchor: [4, 1],
        bgPos: [24, 0],
        className: 'graph-node-icon'
    }),
]
