<template>
  <align-container>
    <align-panel align="all" style="overflow-y: auto" :gap="3">
      <marsh-table
          :selectedRow="selectedMarsh"
          @setSelectedRow="setSelectedMarsh"
          @setDblClick="setSelectedMarshForTab"
      >
      </marsh-table>
    </align-panel>
    <align-panel align="right" style="overflow-x: hidden; overflow-y: hidden;">
      <marsh-dialog
          style="overflow-y: auto"
          :item="selectedMarsh ? {...selectedMarsh} : null"
          @onSave="onSaveMarsh"
          @onEdit="onEditMarsh"
          @onDelete="onDeleteMarsh"
      >
      </marsh-dialog>
    </align-panel>
  </align-container>
</template>

<script>

import MarshDialog from "@/components/references/marsh/MarshDialog";
import MarshTable from "@/components/references/marsh/MarshTable";

export default {
  name: "MarshPanel",
  components: {MarshTable, MarshDialog},
  emits: ['setSelectedDir'],
  computed: {

    // выбранный маршрут
    selectedMarsh() {
      return this.$store.getters['ref/getSelectedMarsh'];
    },

  },
  methods: {

    // сохраняем в стор выбранный маршрут
    setSelectedMarsh(value) {
      this.$store.commit('ref/setSelectedMarsh', value);
    },

    // по даблклику на таблице Маршрута делаем запрос Периодов действия
    async setSelectedMarshForTab(marsh) {
      this.$store.commit('ref/setSelectedMarshForTab', marsh);
      this.$emit('setSelectedDir', 'marshVariants');
      // запрос периодов действия по маршруту
      await this.$store.dispatch('ref/doGetMarshVariants', {
        marsh: marsh,
      })
    },

    async onSaveMarsh(marsh) {
      try {
        this.isLoading = true;
        let tr_type = this.$store.getters['ref/getTransTypes'].find(tr_t => tr_t.tt_id == marsh.transportTypeId)
        // если можно удалить маршрут, удаляем его
        await this.$store.dispatch('ref/doAddMarsh', {
          marsh: marsh,
          transportType: tr_type
        });

      } finally {
        this.isLoading = false;
      }
    },

    async onEditMarsh(marsh) {
      try {
        this.isLoading = true;
        let tr_type = this.$store.getters['ref/getTransTypes'].find(tr_t => tr_t.tt_id == marsh.transportTypeId)
        // если можно удалить маршрут, удаляем его
        await this.$store.dispatch('ref/doEditMarsh', {
          marsh: marsh,
          transportType: tr_type
        });

      } finally {
        this.isLoading = false;
      }
    },

    async onDeleteMarsh() {
      try {
        this.isLoading = true;
        //проверяем, есть ли ссылки на другие объекты
        let isExist = await this.$store.dispatch('ref/isExistsLinks', {
          marsh: this.selectedMarsh,
        });
        if (isExist) {
          this.errorMessageText = this.$t("nsi.MarshPanelErrorText")
          return;
        }
        let tr_type = this.$store.getters['ref/getTransTypes'].find(tr_t => tr_t.tt_id == this.selectedMarsh.transportTypeId)
        // если можно удалить маршрут, удаляем его
        await this.$store.dispatch('ref/doDeleteMarsh', {
          marsh: this.selectedMarsh,
          transportType: tr_type
        });

      } finally {
        this.isLoading = false;
      }
    },

  }
}
</script>

<style scoped>

</style>