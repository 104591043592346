import en from "./en.json";
import en_nsi from "./en_nsi.json";
import ru from "./ru.json";
import ru_nsi from "./ru_nsi.json";

const message = {

}

message["en"] = en
message["en"]["nsi"] = en_nsi
message["ru"] = ru
message["ru"]["nsi"] = ru_nsi

// export default {
//     en,
//     ru
// }

export default message;