<template>
  <div class="align-container">
    <slot></slot>
  </div>
</template>

<script>

export default {

  data() {
    return {
      // признак контейнера
      isAlignContainer: true,
      // корневой контейнер
      rootContainer: null,
      // вложенные контейнеры
      subContainers: [],
      // список панелей
      panels: [],
      // флаг обновления
      updateFlag: false,
    }
  },

  props: {
    name: {
      type: String,
      default: 'container'
    }
  },

  methods: {

    // регистрируем контейнер
    registerContainer(cnt) {
      this.subContainers.push(cnt);
      // заставляем обновиться
      if (!this.rootContainer) this.updateFlag = true;
        else this.rootContainer.updateFlag = true;
    },

    // убирает регистрацию контейнеров
    unregisterContainer(cnt) {
      const index = this.subContainers.indexOf(cnt);
      if (index >= 0) {
        this.subContainers.splice(index, 1);
      }
      // заставляем обновиться
      if (!this.rootContainer) this.updateFlag = true;
        else this.rootContainer.updateFlag = true;
    },

    // регистрирует панель
    registerPanel(panel) {
      this.panels.push(panel);
      // заставляем обновиться
      if (!this.rootContainer) this.updateFlag = true;
        else this.rootContainer.updateFlag = true;
    },

    // убирает регистрацию панели
    unregisterPanel(panel) {
      const index = this.panels.indexOf(panel);
      if (index >= 0) {
        this.panels.splice(index, 1);
      }
      // заставляем обновиться
      if (!this.rootContainer) this.updateFlag = true;
        else this.rootContainer.updateFlag = true;
    },

    // фиксирует обновление панели
    updatePanel() {
      // заставляем обновиться
      if (!this.rootContainer) this.updateFlag = true;
        else this.rootContainer.updateFlag = true;
    },

    // возвращает режим выравнивания
    getAlignMode() {
      let horzMode = false;
      let vertMode = false;
      let allCount = 0;
      this.panels.forEach( panel => {
        if (panel.align === "left")
          horzMode = true;
        else if (panel.align === "right")
          horzMode = true;
        else if (panel.align === "top")
          vertMode = true;
        else if (panel.align === "bottom")
          vertMode = true;
        else if (panel.align === "all")
          allCount++;
        else
          throw new Error('Неизвестный тип выравнивания: '+panel.align);
      });
      if (horzMode && vertMode)
        throw new Error('Выравнивать можно либо по горизонтали, либо или вертикали');
      if (allCount > 1)
        throw new Error('Допускается только одна панель с типом выравнивания "all"');
      // режим выравнивания
      if (horzMode) return 'horz'
        else return 'vert';
    },

    // ожидаем перерисовку DOM
    getDOMParams(panel) {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          resolve({
            offsetLeft: panel.$el.offsetLeft,
            offsetWidth: panel.$el.offsetWidth,
            offsetTop: panel.$el.offsetTop,
            offsetHeight: panel.$el.offsetHeight,
          });
        });
      })
    },

    // выравниваем по левому краю
    async alignLeft() {
      let leftValue = 0;

      for (let i = 0; i < this.panels.length; i++) {
        // очередная панель
        let panel = this.panels[i];
        // пропускаем лишние
        if (panel.align !== 'left') continue;
        // ширина должна быть указана
        if (!panel.width) throw new Error('У панели не указана ширина');

        // устанавливаем свойства
        panel.style.left = leftValue+'px';
        panel.style.top = '0px';
        panel.style.right = null;
        panel.style.bottom = '0px';
        panel.style.width = panel.width;
        panel.style.height = null;

        // запрашиваем новые значения
        const {offsetLeft, offsetWidth} = await this.getDOMParams(panel);

        // смещаем значения
        leftValue = offsetLeft + offsetWidth + panel.gap;
      }

      return leftValue;
    },

    // выравниваем по правому краю
    async alignRight() {
      let rightValue = 0;
      const parentWidth = this.$el.clientWidth;
      for (let i = 0; i < this.panels.length; i++) {
        // очередная панель
        let panel = this.panels[i];
        // пропускаем лишние
        if (panel.align !== 'right') continue;
        // ширина должна быть указана
        if (!panel.width) throw new Error('У панели не указана ширина');

        // устанавливаем свойства
        panel.style.right = rightValue+'px';
        panel.style.top = '0px';
        panel.style.left = null;
        panel.style.bottom = '0px';
        panel.style.width = panel.width;
        panel.style.height = null;

        // запрашиваем новые значения
        const {offsetLeft} = await this.getDOMParams(panel);

        // смещаем значения
        rightValue = parentWidth - offsetLeft + panel.gap;
      }

      return rightValue;
    },

    // выравниваем серединку для горизонтального выравнивания
    async alignLeftRightAll(leftValue, rightValue) {
      for (let i = 0; i < this.panels.length; i++) {
        // очередная панель
        let panel = this.panels[i];
        // пропускаем лишние
        if (panel.align !== 'all') continue;

        // устанавливаем свойства
        panel.style.left = leftValue + 'px';
        panel.style.top = '0px';
        panel.style.right = rightValue + 'px';
        panel.style.bottom = '0px';
        panel.style.width = null;
        panel.style.height = null;

        return;
      }
    },

    // выравниваем по верхнему краю
    async alignTop() {
      let topValue = 0;

      for (let i = 0; i < this.panels.length; i++) {
        // очередная панель
        let panel = this.panels[i];
        // пропускаем лишние
        if (panel.align !== 'top') continue;
        // ширина должна быть указана
        if (!panel.height) throw new Error('У панели не указана высота');

        // устанавливаем свойства
        panel.style.left = '0px';
        panel.style.top = topValue + 'px';
        panel.style.right = '0px';
        panel.style.bottom = null;
        panel.style.width = null;
        panel.style.height = panel.height;

        // запрашиваем новые значения
        const {offsetTop, offsetHeight} = await this.getDOMParams(panel);

        // смещаем значения
        topValue = offsetTop + offsetHeight + panel.gap;
      }

      return topValue;
    },

    // выравниваем по нижнему краю
    async alignBottom() {
      let bottomValue = 0;
      const parentHeight = this.$el.clientHeight;

      for (let i = 0; i < this.panels.length; i++) {
        // очередная панель
        let panel = this.panels[i];
        // пропускаем лишние
        if (panel.align !== 'bottom') continue;
        // высота должна быть указана
        if (!panel.height) throw new Error('У панели не указана высота');

        // устанавливаем свойства
        panel.style.right = '0px';
        panel.style.top = null;
        panel.style.left = '0px';
        panel.style.bottom = bottomValue + 'px';
        panel.style.width = null;
        panel.style.height = panel.height;

        // запрашиваем новые значения
        const {offsetTop} = await this.getDOMParams(panel);

        // смещаем значения
        bottomValue = parentHeight - offsetTop + panel.gap;
      }

      return bottomValue;
    },

    // выравниваем серединку для вертикального выравнивания
    async alignTopBottomAll(topValue, bottomValue) {
      for (let i = 0; i < this.panels.length; i++) {
        // очередная панель
        let panel = this.panels[i];
        // пропускаем лишние
        if (panel.align !== 'all') continue;

        // устанавливаем свойства
        panel.style.left = '0px';
        panel.style.top = topValue + 'px';
        panel.style.right = '0px';
        panel.style.bottom = bottomValue + 'px';
        panel.style.width = null;
        panel.style.height = null;

        return;
      }
    },

    // обновляем панели
    async updatePanels() {
      // панели отсуствуют
      if (this.panels.length === 0) return;

      const alignMode = this.getAlignMode()
      if (alignMode === 'horz') {
        const leftValue = await this.alignLeft();
        const rightValue = await this.alignRight();
        await this.alignLeftRightAll(leftValue, rightValue);
      }
      else {
        const topValue = await this.alignTop();
        const bottomValue = await this.alignBottom();
        await this.alignTopBottomAll(topValue, bottomValue);
      }
    },

    // ищем корневой контейнер
    findRootContainer() {
      let cur = this.$parent;
      while (cur) {
        if (cur.isAlignContainer) {
          this.rootContainer = cur
        }
        cur = cur.$parent;
      }
    },
  },

  created() {
    // ищем корневой контейнер
    this.findRootContainer();
    // корневой контейнер нашли - регистрируемся в нем
    if (this.rootContainer) {
      // регистрируемся
      this.rootContainer.registerContainer(this);
    }
  },

  mounted() {
    // если мы не корневые - ничего не делаем
    if (this.rootContainer) return;
    // заставляем обновиться
    this.updateFlag = true;
    // следим за изменением флага перерисовки
    this.$watch(() => this.updateFlag, async(value) => {
      if (value) {
        try {
          // обновляем свои панели
          await this.updatePanels();
          // обновляем вложенные панели
          for (let i = 0; i < this.subContainers.length; i++) {
            await this.subContainers[i].updatePanels();
          }
        }
        finally {
          // сбрасываем флаг обновления
          this.updateFlag = 0;
        }
      }
    }, {flush: "post", immediate: "true"})

    // обновляем панели при изменении размера окна
    window.onresize = () => {
      // заставляем обновиться
      this.updateFlag = true;
    };
  },

  unmounted() {
    // снимаем регистрацию контейнера
    if (this.rootContainer) {
      this.rootContainer.unregisterContainer(this);
    }
  },
}
</script>

<style scoped>
  .align-container {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    padding: 0px;
    margin: 0px;
    border: 0px;
  }
</style>
