<template>

  <align-container style="height: 100%;">

    <align-panel align="top" width="3rem">
      <nav-menu class="nav-tabs" mode="single">
        <nav-item>
          <nav-link target-ref="pane1" target-method="select" active @click="tab = 0">{{ $t("nsi.RaceDialog.ParamsLabel") }}
          </nav-link>
        </nav-item>
        <nav-item>
          <nav-link target-ref="pane2" target-method="select" @click="tab = 1">{{ $t("nsi.RaceDialog.CompRaceLabel") }}</nav-link>
        </nav-item>
      </nav-menu>
    </align-panel>

    <align-panel align="all" width="3rem">
      <tab-content mode="single" style="height: 100%">
        <tab-pane active ref="pane1" class="p-2" style="height: 100%; padding:0 !important;">
          <div class="card bg-light"
               style="overflow-x: hidden; overflow-y: hidden; height: 100%;">
            <form style="height: 95%; overflow-x: hidden; overflow-y: auto;">
              <div class="form-group row">
                <div class="col" style="margin-left:10px; margin-top:10px;">
                  <input type="radio" class="green-radio" name="inlineRadioOptions" id="inlineRadio1" value="add"
                         v-model="selectedMode"
                         style="height:16px; width:16px; vertical-align: middle; margin-right:5px">
                  <label for="inlineRadio1" style="margin-bottom: 0; margin-top: 5px">{{ $t("nsi.NsiRadioButtonAdd") }}</label>
                </div>
                <div class="col" style="text-align: right; margin-right:10px; margin-top:10px">
                  <input type="radio" class="green-radio" name="inlineRadioOptions" id="inlineRadio2" value="edit"
                         v-model="selectedMode"
                         style="height:16px; width:16px; vertical-align: middle; margin-right:5px">
                  <label for="inlineRadio2" style="margin-bottom: 0; margin-top: 5px">{{ $t("nsi.NsiRadioButtonEdit") }}</label>
                </div>
              </div>

              <div class="form-group" style="padding-right: 20px; padding-left: 20px">
                <label for="raceTitle">{{ $t("nsi.RaceDialog.RaceTitleLabel") }}</label>
                <input-string
                    id="raceTitle"
                    class="form-control"
                    v-model="raceTitle"
                ></input-string>
              </div>

              <div class="row" style="padding-left: 15px">
                <div class="form-group col" style="padding-left: 20px; width: 80px; padding-right: 0">
                  <label for="direction">{{ $t("nsi.RaceDialog.DirectionLabel") }}</label>
                  <universal-selector
                      id="direction"
                      class="form-control"
                      v-model="dir"
                      :itemList="[
                          // {
                          //   id: '',
                          //   name:''
                          // },
                          {
                            id: 'A',
                            name:'A'
                          },
                          {
                            id: 'B',
                            name:'B'
                          }
                        ]"
                      style="width: 100px !important"
                  ></universal-selector>
                </div>

                <div class="form-group col" style="padding-right: 34px; padding-left: 0">
                  <label for="raceLengthAsKm">{{ $t("nsi.RaceDialog.RaceLengthLabel") }}</label>
                  <input-string
                      id="raceLengthAsKm"
                      class="form-control"
                      v-model="raceLengthAsKm"
                      disabled
                  ></input-string>
                </div>
              </div>

              <div class="card bg-light"
                   style="overflow-x: hidden; overflow-y: hidden; margin-right: 10px; margin-left: 10px; ">
                <div class="form-group" style="font-weight: bold; padding-left: 10px">
                  {{ $t("nsi.RaceDialog.BalanceTypeLabel") }}
                </div>

                <div class="form-group" style="padding-right: 10px; padding-left: 10px; margin-bottom: 0">
                  <input-checkbox
                      id="RingTrip"
                      v-model="isRingTrip"
                      style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px"
                  >{{ $t("nsi.RaceDialog.CircleRaceLabel") }}
                  </input-checkbox>
                </div>

                <div class="row" style="padding-left: 15px">
                  <div class="form-group col" style="padding-left: 10px; width: 80px; padding-right: 0">
                    <label for="direction">{{ $t("nsi.RaceDialog.DirStartOutLabel") }}</label>
                    <universal-selector
                        id="direction"
                        class="form-control"
                        v-model="dir_start_out"
                        :itemList="[
                          {
                            id: '',
                            name:''
                          },
                          {
                            id: 'A',
                            name:'A'
                          },
                          {
                            id: 'B',
                            name:'B'
                          }
                        ]"
                        style="width: 100px !important"
                    ></universal-selector>
                  </div>

                  <div class="form-group col" style="padding-right: 24px; padding-left: 0">
                    <label for="dist">{{ $t("nsi.RaceDialog.DirOutOrderLabel") }}</label>
                    <input-string
                        id="dist"
                        class="form-control"
                        v-model="start_out"
                    ></input-string>
                  </div>
                </div>

                <div class="row" style="padding-left: 15px">
                  <div class="form-group col" style="padding-left: 10px; width: 80px; padding-right: 0">
                    <label for="direction">{{ $t("nsi.RaceDialog.DirEndOutLabel") }}</label>
                    <universal-selector
                        id="direction"
                        class="form-control"
                        v-model="dir_end_out"
                        :itemList="[
                          {
                            id: '',
                            name:''
                          },
                          {
                            id: 'A',
                            name:'A'
                          },
                          {
                            id: 'B',
                            name:'B'
                          }
                        ]"
                        style="width: 100px !important"
                    ></universal-selector>
                  </div>

                  <div class="form-group col" style="padding-right: 24px; padding-left: 0">
                    <label for="dist">{{ $t("nsi.RaceDialog.DirOutOrderLabel") }}</label>
                    <input-string
                        id="dist"
                        class="form-control"
                        v-model="end_out"
                    ></input-string>
                  </div>
                </div>

                <div class="form-group" style="padding-right: 10px; padding-left: 10px">
                  <input-checkbox
                      id="del_out_on_start"
                      v-model="del_out_on_start"
                      style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px; max-width: 300px"
                  >{{ $t("nsi.RaceDialog.DeleleteOutOnStart") }}
                  </input-checkbox>
                </div>

                <div class="form-group" style="padding-right: 10px; padding-left: 10px">
                  <input-checkbox
                      id="del_in_on_end"
                      v-model="del_in_on_end"
                      style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px; max-width: 300px"
                  >{{ $t("nsi.RaceDialog.DeleleteInOnEndLabel") }}
                  </input-checkbox>
                </div>

              </div>

              <div class="form-group" style="padding-right: 20px; padding-left: 20px">
                <input-checkbox
                    id="notActive"
                    v-model="notActive"
                    style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px"
                >{{ $t("nsi.RaceDialog.CheckboxNotActiveRace") }}
                </input-checkbox>
              </div>

              <div class="form-group" style="padding-right: 20px; padding-left: 20px">
                <label for="raceNote">{{ $t("nsi.RaceDialog.RaceNote") }}</label>
                <input-string
                    id="raceNote"
                    class="form-control"
                    v-model="raceNote"
                ></input-string>
              </div>

            </form>
            <div class="form-group row">
              <div class="col" style="margin-left: 20px">
                <button @click="onSave" class="btn btn-info">{{ $t("nsi.NsiButtonSave") }}</button>
              </div>
              <div class="col" style="text-align: right; margin-right:20px">
                <button @click="onDelete" class="btn btn-info" :disabled="isDeleteButtonDisabled">{{ $t("nsi.NsiButtonDelete") }}</button>
              </div>
            </div>
          </div>
        </tab-pane>

        <tab-pane ref="pane2" class="p-2" style="height: 100%; padding:0 !important;">
          <div class="card bg-light"
               style="overflow-x: hidden; overflow-y: hidden; height: 100%;">
            <form style="height: 95%; overflow-x: hidden; overflow-y: auto;">

              <div class="form-group" style="font-weight: bold; padding-left: 10px">
                На доработке
              </div>

              <div class="form-group" style="padding-right: 20px; padding-left: 20px; margin-top: 20px">
                <label for="raceNote">Трассы</label>
                <universal-selector
                    id="races"
                    class="form-control"
                    v-model="race"
                    :itemList="[]"
                ></universal-selector>
              </div>

              <div class="form-group row">
                <div class="col" style="margin-right: 20px; text-align: right;">
                  <button @click="onSave" class="btn btn-info">Добавить</button>
                </div>
              </div>

              <table class="table">
                <thead class="thead-dark">
                <tr style="background-color: #17a2b8 !important;">
                  <th style="background-color: #17a2b8" scope="col">№ п/п</th>
                  <th style="background-color: #17a2b8" scope="col">Тип рейса</th>
                  <th style="background-color: #17a2b8" scope="col">Направление</th>
                </tr>
                </thead>
                <tbody>
                  <tr
                      v-for="(race, index) in composite_races"
                      :key="index"
                  >
                    <td>{{ index }}</td>
                    <td>{{ race.raceTitle }}</td>
                    <td>{{ race.raceType }}</td>
                  </tr>
                </tbody>
              </table>

              <div class="form-group row">
                <div class="col" style="margin-left: 20px; text-align: right; margin-right: 20px">
                  <button @click="onSave" class="btn btn-info">Удалить</button>
                </div>
              </div>

            </form>
          </div>
        </tab-pane>
      </tab-content>
    </align-panel>

  </align-container>
</template>

<script>

import InputString from "@/components/ui/custom/InputString";
import UniversalSelector from "@/components/ui/custom/UniversalSelector";
import InputCheckbox from "@/components/ui/custom/InputCheckbox";
import AlignContainer from "@/components/ui/custom/AlignContainer";

export default {
  components: {AlignContainer, InputCheckbox, UniversalSelector, InputString},
  props: ['item', 'races', 'selectedMarshVar'],
  emits: ['onSave', 'onEdit', 'onDelete'],
  computed: {

    isDeleteButtonDisabled() {
      return this.item ? false : true;
    }

  },
  watch: {

    item(newValue) {
      if (newValue) {
        this.selectedMode = 'edit'
      } else {
        this.selectedMode = 'add'
      }
      this.raceTitle = newValue.raceTitle;
      this.dir = newValue.raceType;
      this.raceNote = newValue.raceNote;
      this.dir_start_out = newValue.dir_start_out;
      this.start_out = newValue.start_out;
      this.dir_end_out = newValue.dir_end_out;
      this.end_out = newValue.end_out;
      this.fl_composit = newValue.fl_composit;
      this.del_out_on_start = newValue.del_out_on_start;
      this.del_in_on_end = newValue.del_in_on_end;
      this.notActive = newValue.notActive;

      this.raceLengthAsKm = newValue.raceLengthAsKm;

    }

  },
  data() {
    return {
      tab: 0,
      selectedMode: 'add',
      composite_races: null,
      isRingTrip: false,
      race: null,

      raceTitle: '',
      dir: '',
      raceNote: null,
      dir_start_out: '',
      start_out: '',
      dir_end_out: '',
      end_out: '',
      fl_composit: false,
      del_out_on_start: false,
      del_in_on_end: false,
      notActive: false,

      raceLengthAsKm: 0,
    }
  },

  methods: {

    onSave() {
      if (this.selectedMode == 'add') {
        this.$emit('onSave', {
          raceId: 0,
          routeVariantId: this.selectedMarshVar.routeVariantId,
          raceTitle: this.raceTitle,
          raceType: this.dir == "'" ? '-' : this.dir ,
          raceLengthAsKm: 0,
          raceNote: this.raceNote,
          dir_start_out: this.dir_start_out,
          start_out: this.start_out,
          dir_end_out: this.dir_end_out,
          end_out: this.end_out,
          fl_composit: false,
          del_out_on_start: this.del_out_on_start,
          del_in_on_end: this.del_in_on_end,
          ermRegNum: 0,
          notActive: this.notActive,
          identity: this.$store.getters['ref/getParks'].find(value => value.parkId == this.$store.getters['ref/getUserPark']).identity,
        })
      } else {
        this.$emit('onEdit', {
          raceId: this.item.raceId,
          routeVariantId: this.item.routeVariantId,
          raceTitle: this.raceTitle,
          raceType: this.dir == "'" ? '-' : this.dir ,
          raceLengthAsKm: this.item.raceLengthAsKm,
          raceNote: this.raceNote,
          dir_start_out: this.dir_start_out,
          start_out: this.start_out,
          dir_end_out: this.dir_end_out,
          end_out: this.end_out,
          fl_composit: false,
          del_out_on_start: this.del_out_on_start,
          del_in_on_end: this.del_in_on_end,
          ermRegNum: this.item.ermRegNum,
          notActive: this.notActive,
          identity: this.item.identity,
        })
      }
    },

    onDelete() {
      this.$emit('onDelete');
      this.notSynchronize = null;
      this.routeVariantIndexBegin = '';
      this.routeVariantIndexEnd = '';
      this.routeVariantNote = '';
    }

  }
}
</script>

<style scoped>

</style>