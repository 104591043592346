<template>
 <universal-selector v-model="value" :caption="this.$t('nsi.CapacitySelectorCaption')" :itemList="itemList"></universal-selector>
</template>

<script>
import UniversalSelector from "@/components/ui/custom/UniversalSelector";
export default {
  name: "CapacitySelector",
  components: {UniversalSelector},
  props: ['model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },

    itemList() {
      return this.$store.getters['ref/getCapacities'].map(item => {
        return {
          id: item.cap_id,
          name: item.cap_name
        }
      });
    }
  },
}
</script>

<style scoped>

</style>