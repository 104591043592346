<template>
<table-component :columnList="columnList" :rowList="rowList"></table-component>
</template>

<script>
import TableComponent from "@/components/ui/custom/TableComponent";
import {getDateWitoutTimeZone, getStatTimeFormat, getTimeWithSecondsFormat, padStr} from "@/lib";
export default {
  name: "ProcessingResultReisesTable",
  components: {TableComponent},
  computed: {
    // колонки таблицы
    columnList(){
      return [
        {
          columnName: "__isSave",
          columnTitle: this.$t("ProcessingResultReisesTable.Ex"), //"Иск.",
          isVisible: true
        },
        {
          columnName: "__rowNum",
          columnTitle: this.$t("ProcessingResultReisesTable.Order"), //"№п/п",
          isVisible: true
        },
        {
          columnName: "routeTitle",
          columnTitle: this.$t("ProcessingResultReisesTable.Marsh"), //"Марш.",
          isVisible: true
        },
        {
          columnName: "raceTitle",
          columnTitle: this.$t("ProcessingResultReisesTable.Race"), //"Рейс",
          isVisible: true
        },
        {
          columnName: "direction",
          columnTitle: this.$t("ProcessingResultReisesTable.Dir"), //"Напр.",
          isVisible: true
        },
        {
          columnName: "__tm_start_end",
          columnTitle: this.$t("ProcessingResultReisesTable.Begin"), //"Начало",
          isVisible: true
        },
        {
          columnName: "__tm_finish_bgn",
          columnTitle: this.$t("ProcessingResultReisesTable.End"), //"Конец",
          isVisible: true
        },
        {
          columnName: "duration",
          columnTitle: this.$t("ProcessingResultReisesTable.Duration"), //"Длит.",
          isVisible: true
        },
        {
          columnName: "mileage",
          columnTitle: this.$t("ProcessingResultReisesTable.Milage"), //"Проб.",
          isVisible: true
        },
        {
          columnName: "transportation",
          columnTitle: this.$t("ProcessingResultReisesTable.Transportation"), //"Перев.",
          isVisible: true
        },
        {
          columnName: "disbal",
          columnTitle: this.$t("ProcessingResultReisesTable.Disb"), //"Дисб.%",
          isVisible: true
        },
        {
          columnName: "irmabugnumber",
          columnTitle: this.$t("ProcessingResultReisesTable.Error"), //"Ош.",
          isVisible: true
        },
        {
          columnName: "passedThroughCount",
          columnTitle: this.$t("ProcessingResultReisesTable.PastBy"), //"Мимо",
          isVisible: true
        },
      ]
    },
    // строки таблицы
    rowList(){
      let procStatistic = this.$store.getters['proc/getProcStatistic']['0'];
      let rows = [];
      if(procStatistic){
        procStatistic.statistic.resultReisList.map(value=>{
          rows.push(value);
        })
      }
      let orderNum = 0;
      return rows.map(value => {
        value.__id = orderNum;
        if(value.routeTitle == '*'){
          value.__rowNum = "";
          value.__isSave =  value.isSave ? "" : this.$t("ProcessingResultReisesTable.routeTitleExclude"); //"Исключить";
          value.__rowStyle = 'background: #bebebe'
        } else {
          value.__rowNum = value.rowNum
        }
        let startDateWithoutTimeZone = getDateWitoutTimeZone(new Date(value.tm_start_end))
        let endDateWithoutTimeZone = getDateWitoutTimeZone(new Date(value.tm_finish_bgn))
        value.__tm_start_end = getStatTimeFormat(startDateWithoutTimeZone);
        value.__tm_finish_bgn = padStr(endDateWithoutTimeZone.getHours()) + ':' +  padStr(endDateWithoutTimeZone.getMinutes());
        if((value.tm_finish_bgn - value.tm_start_end) > 3599000) {
          value.duration = getTimeWithSecondsFormat((value.tm_finish_bgn - value.tm_start_end)/1000);
        } else {
          value.duration = getTimeWithSecondsFormat((value.tm_finish_bgn - value.tm_start_end) / 1000).substring(3);
        }
        if (value.rowNum == 0) {
          value.mileage =  "";
        } else {
          value.mileage =  Number(value.mileageAsKmEnd - value.mileageAsKmBegin).toFixed(1).replace(/\.0$/, '');
        }
        let passedThroughCount = 0;
        value.stations.forEach(value => {
          if(value.passedThrough){
            passedThroughCount++;
          }
        })
        value.passedThroughCount = passedThroughCount;
        orderNum++;
        return value;
      })
    }
  }
}
</script>

<style scoped>

</style>