<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-info text-white">
          <modal-title>{{ $t("nsi.RsNumDialog.TitleLabel") }}</modal-title>
          <button-close class="text-white" @click="$emit('onCancelRsNumDialog')"></button-close>
        </modal-header>
        <modal-body style="padding: 0; overflow-y: hidden">
          <div class="form-group" style="padding-right: 20px; padding-left: 20px; padding-top: 10px">
            <label for="rs_type_id">{{ $t("nsi.RsNumDialog.RsTypeLabel") }}</label>
            <rs-type-selector
                id="rs_type_id"
                class="form-control"
                v-model="rs_type"
            >
            </rs-type-selector>
          </div>

          <div class="form-group" style="padding-right: 20px; padding-left: 20px">
            <label>{{ $t("nsi.RsNumDialog.RsNumLabel") }}</label>
            <input-integer
                class="form-control"
                id="rs_num"
                v-model="rs_num"
            />
          </div>

        </modal-body>
        <modal-footer>
          <button @click="onSave" class="btn btn-info">{{ $t("nsi.NsiButtonSave") }}</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>

  <error-message-box
      v-if="errorMessageText != ''"
      @onOK="onCloseErrorMessageBox"
  >{{ errorMessageText }}
  </error-message-box>

</template>

<script>

import InputInteger from "@/components/ui/custom/InputInteger";
import RsTypeSelector from "@/components/references/rs_num/RsTypeSelector";
import ErrorMessageBox from "@/components/ui/custom/ErrorMessageBox";

export default {
  components: {InputInteger, RsTypeSelector, ErrorMessageBox},
  emits: ['onSaveRsNum', 'onCancelRsNumDialog'],
  data() {
    return {
      rs_num: null,
      rs_type: null,

      errorMessageText: '',
    }
  },


  methods: {

    // очищаем текст ошибки
    onCloseErrorMessageBox() {
      this.errorMessageText = '';
    },

    async onSave() {
      let new_rs_num = {
        rs_stationnum: this.rs_num,
        rst_stationtype: this.rs_type,
        pk_id: this.$store.getters['ref/getUserPark'],
        rs_channel: 0,
        rs_phonenum: '',
        rst_typename: this.$store.getters['ref/getRsTypes'].find(value => value.rst_stationtype == this.rs_type).rst_title,
        identity: this.$store.getters['ref/getParks'].find(value => value.parkId == [this.$store.getters['ref/getUserPark']]).identity
      };
      //проверяем наличие р/с
      let park = await this.$store.dispatch('ref/doCheckRSNum', {
        rs_num: new_rs_num.rs_stationnum,
        rs_type: new_rs_num.rst_stationtype,
        formation: this.$store.getters['ref/getFormations'].find(value => value.tw_id == this.$store.getters['ref/getUserFormation'])
      });

      if (park) {
        this.errorMessageText = this.$t("nsi.RsNumDialog.ErrorMessageText") + park.parkTitleLong;
        return;
      }

      // добавляем р/с
      this.$emit('onSaveRsNum', new_rs_num);
    },
  }
}
</script>

<style scoped>

</style>