// РОДИТЕЛЬСКИЙ КОМПОНЕНТ
export default {
    props: {
        // режим работы mode:
        //   'single' - выбран только один элемент
        //   'toggle' - переключение элемента
        //   'active' - активация элемента
        mode: {
            type: String,
        }
    },
    data() {
        return {
            // признак родительского компонента
            isChildContent: true,
            // список детей
            children: []
        }
    },
    methods: {
        // регистрирует дочерний компонент
        registerChild(child) {
            this.children.push(child)
        },
        // разрегистрируем дочерний компонент
        unregisterChild(child) {
            const index = this.children.indexOf(child);
            if (index >= 0) {
                this.children.slice(index, 1);
            }
        },
        // выбран дочерний компонент
        childSelected(child) {
            // только один активный
            if (this.mode === 'single') {
                this.children.forEach(ch => {
                    if (child === ch) {
                        ch.activate()
                    }
                    else {
                        ch.deactivate()
                    }
                });
                return true;
            }
            // переключение элемента
            else if (this.mode === 'toggle') {
                child.toggle();
                return true;
            }
            // активация элемента
            else if (this.mode === 'active') {
                child.activate();
                return true;
            }
            return false;
        },
    }
}
