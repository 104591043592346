<template>
  <align-container>
    <align-panel align="top" :gap="3" height="35%" style="overflow-y: auto;">
      <park-station-table
          :selectedRow="selectedParkStation"
          @setSelectedRow="setSelectedParkStation"
      ></park-station-table>
    </align-panel>
    <align-panel align="all">
      <park-stations-map
          :activeStop="selectedParkStation"
          @onMapCreated="onMapCreated"
          @openAddDialog="openAddDialog"
          @openEditDialog="openParkStationEditDialog"
          @editCoordinates="onEditParkStation"
          @deleteParkStation="deleteParkStation"
      >
      </park-stations-map>
    </align-panel>
  </align-container>

  <park-station-dialog
      v-if="isParkStationDialogVisible"
      :item="dialogParkStatioValue"
      @onCancel="closeParkStationDialog"
      @onSave="onSaveParkStation"
      @onEdit="onEditParkStation"
  >
  </park-station-dialog>

  <confirm-message-box
      v-if="isParkStationDeleteDialogVisible"
      @onCancel="onRejectDelete"
      @onOK="onDeleteParkStation"
  >
    {{ $t('nsi.ParkStationsPanel.ConfirmMessage_1') }}
    {{ dialogParkStatioValue.ss_longname }}
    {{ $t('nsi.ParkStationsPanel.ConfirmMessage_2') }}
    {{ dialogParkStatioValue.ss_id }}?
  </confirm-message-box>
</template>

<script>
import ParkStationsMap from "@/components/references/park_station/ParkStationsMap";
import ParkStationTable from "@/components/references/park_station/ParkStationTable";
import {latLngBounds} from "leaflet";
import ParkStationDialog from "@/components/references/park_station/ParkStationDialog";
import ConfirmMessageBox from "@/components/ui/custom/ConfirmMessageBox";

export default {
  name: "ParkStationsPanel",
  components: {ConfirmMessageBox, ParkStationDialog, ParkStationTable, ParkStationsMap},
  data() {
    return {
      // карта
      leaflet: null,

      // видимость диалога добавления
      isParkStationDialogVisible: false,
      // видимость диалога удаления остановки
      isParkStationDeleteDialogVisible: false,
      // данные для диалогов редактирования и удаления parkStation
      dialogParkStatioValue: null,
    }
  },
  computed: {
    // выбранная остановка трассы
    selectedParkStation() {
      return this.$store.getters['ref/getSelectedParkStation'];
    },

    parkStationsLength() {
      return this.$store.getters['ref/getParkStations'].length;
    },
  },

  watch: {

    parkStationsLength(newValue, oldValue) {
      if (newValue != 0 && oldValue == 0)
        this.correctingMapPosition();
    },

    selectedParkStation(newValue) {
      if (newValue) {
        this.leaflet.setView([newValue.ss_latitude, newValue.ss_longitude], this.$store.getters['settings/getDefaultVehicleZoom']);
      }
    },

  },

  methods: {

    // создана карта
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    // делаем остановку выбранной
    setSelectedParkStation(value) {
      this.$store.commit('ref/setSelectedParkStation', value);
    },

    correctingMapPosition() {
      let stops = this.$store.getters['ref/getParkStations'];
      if (stops.length === 1) {
        this.leaflet.setView([stops[0].ss_latitude, stops[0].ss_longitude], this.$store.getters['settings/getDefaultVehicleZoom']);
      } else if (stops.length > 1) {
        const bounds = latLngBounds(
            stops.map(vehicle => [vehicle.ss_latitude, vehicle.ss_longitude])
        )
        this.leaflet.fitBounds(bounds);
      }
    },

    openAddDialog(latlng) {
      this.isParkStationDialogVisible = true;
      this.dialogParkStatioValue = {
        selectedMode: 'add',
        ss_latitude: latlng[0],
        ss_longitude: latlng[1],
      };
    },


    deleteParkStation(stop) {
      this.isParkStationDeleteDialogVisible = true;
      this.dialogParkStatioValue = stop;
    },

    async onDeleteParkStation() {
      try {
        this.isLoading = true;
        let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.$store.getters['ref/getUserPark']);
        // //проверяем, есть ли ссылки на другие объекты
        let isExist = await this.$store.dispatch('ref/isExistsParkStationLinks', {
          parkStation: this.dialogParkStatioValue,
        });
        if (isExist) {
          this.errorMessageText = this.$t('nsi.ParkStationsPanel.ErrorMessageText');
          return;
        }
        // если можно удалить трассу, удаляем его
        await this.$store.dispatch('ref/doDeleteParkStation', {
          park: park,
          parkStation: this.dialogParkStatioValue,
        });
      } finally {
        this.isLoading = false;
        this.$store.commit('ref/setSelectedParkStation', null);
        this.onRejectDelete();
      }
    },

    onRejectDelete(){
      this.isParkStationDeleteDialogVisible = false;
      this.dialogParkStatioValue = null;
    },


    async onSaveParkStation(value) {
      try {
        this.isLoading = true;
        let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.$store.getters['ref/getUserPark']);
        // добавляем трассу
        await this.$store.dispatch('ref/doAddParkStation', {
          parkStation: value,
          park: park,
        });

      } finally {
        this.isLoading = false;
        this.closeParkStationDialog();
      }
    },

    async onEditParkStation(value) {
      try {
        this.isLoading = true;
        let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.$store.getters['ref/getUserPark']);
        // добавляем трассу
        await this.$store.dispatch('ref/doEditParkStation', {
          parkStation: value,
          park: park,
        });
        this.$store.commit('ref/setSelectedParkStation', null);
      } finally {
        this.isLoading = false;
        this.closeParkStationDialog();
      }
    },

    openParkStationEditDialog(stop) {
      this.isParkStationDialogVisible = true;
      stop.selectedMode = 'edit';
      this.dialogParkStatioValue = stop;
    },

    closeParkStationDialog(){
      this.isParkStationDialogVisible = false;
      this.dialogParkStatioValue = null;
    },
  },

}
</script>

<style scoped>

</style>