<template>
<table-component :columnList="columnList" :rowList="rowList"></table-component>
</template>

<script>
import TableComponent from "@/components/ui/custom/TableComponent";

export default {
  components: {TableComponent},
  computed: {
    // колонки таблицы
    columnList(){
      return [
        {
          columnName: "ss_longname",
          columnTitle: this.$t('nsi.ParkStationTable.LongName'), //"Название",
          isVisible: true
        },
        {
          columnName: "ss_longitude",
          columnTitle: this.$t('nsi.ParkStationTable.Longitude'), //"Долгота",
          isVisible: true
        },
        {
          columnName: "ss_latitude",
          columnTitle: this.$t('nsi.ParkStationTable.Latitude'), //"Широта",
          isVisible: true
        },
      ]
    },
    // строки таблицы
    rowList(){
      let raceOrderBy = 1;
      return this.$store.getters['ref/getParkStations'].map(value => {
        value.__id = raceOrderBy;
        raceOrderBy++;
        return value;
      });
    }
  }
}
</script>

<style scoped>

</style>