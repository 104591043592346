// ХРАНИЛИЩЕ ТИПОВ ВМЕСТИМОСТИ
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            capacities: [],
        }
    },
    getters: {
        // вернуть список парков
        getCapacities(state) {
            return state.capacities;
        },
    },
    mutations: {
        // установить новый список объектов
        setCapacities(state, payload) {
            state.capacities = payload;
        },

    },
    actions: {

        // добавление объектов
        async doGetCapacities(context, payload) {

            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.park));

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/CapacityRestController/getCapacityRemote',
                params.toString(),
                'ref/setCapacities',
                'Получение типов вместимости'
             );

        },
    }
}
