<script>
import * as L from 'leaflet'
import LeafletPathMixin from "@/components/ui/leaflet/mixins/LeafletPathMixin";
import {toRaw} from "vue";

// ДЕКОР ПО ЛИНИИ

export default {
  mixins: [LeafletPathMixin],
  props: {
    // точки линии
    latlngs: {
      type: Array,
      required: true
    },
    // шаблоны декоратора
    patterns: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      // тип объекта
      leafletObjectType: 'LeafletPolylineDecorator',
    }
  },
  methods: {
    // создание слоя
    createLayer() {
      const layer = new L.PolylineDecorator(toRaw(this.latlngs), {
        patterns: toRaw(this.patterns)
      });
      layer.__meta = this.meta;
      // всплывающая подсказка
      if (this.tooltip) {
        layer.bindTooltip(toRaw(this.tooltip));
      }
      this.subscribe(layer);
      this.proxyOrigin = layer;
      return layer;
    },
    destroyLayer() {
      // удаляем подписку на события
      this.unsubscribe(this.origin);
      // отключаем подсказку от слоя
      this.origin.unbindTooltip();
      // убиваем ссылку
      this.proxyOrigin = null;
    },
  },
  // монтируем слой
  mounted() {
    this.getParent().parentReady().then(() => {

      // наблюдатель за координатами
      this.$watch(() => this.latlngs, (after, before) => {
        if (this.reCreateTimer) return;
        if (this.isLatLngsEqual(before, after)) return;
        //console.log('Требуется изменить точки декоратора <'+this.curComponent.leafletObjectType+'>: '+this.logTitle)
        this.origin.setPaths(toRaw(this.latlngs));
      }, {deep: false});

      // наблюдатель за шаблонами
      this.$watch(() => this.patterns, () => {
        if (this.reCreateTimer) return;
        //console.log('Требуется изменить шаблоны декоратора <'+this.curComponent.leafletObjectType+'>: '+this.logTitle)
        this.origin.setPatterns(toRaw(this.patterns));
      }, {deep: false});

    });
  },
}
</script>
