// ХРАНИЛИЩЕ ОСТАНОВОК
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            parkStations: [],
        }
    },
    getters: {
        // вернуть список парков
        getParkStations(state) {
            return state.parkStations;
        },
    },
    mutations: {
        // установить новый список объектов
        setParkStations(state, payload) {
            state.parkStations = payload;
        },

        // добавить объект
        addParkStation(state, payload) {
            state.parkStations.unshift(payload)
        },

        // изменить объект
        editParkStation(state, payload) {
            const index = state.parkStations.findIndex(depot => depot.ss_id == payload.ss_id);
            if (index >= 0) {
                state.parkStations[index] = payload;
            }
        },

        deleteParkStation(state, payload) {
            const index = state.parkStations.findIndex(depot => depot.ss_id == payload.ss_id);
            state.parkStations.splice(index, 1);
            // console.log('state.parkStations',state.parkStations)
        },
    },
    actions: {
        // добавление объекта
        async doGetParkStations(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.park));
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/ParkStationRestController/getParkStationsRemote',
                params.toString(),
                'ref/setParkStations',
                'Получение остановок по парку'
             );
        },

        // добавление объекта
        async doAddParkStation(context, payload) {

            const params = new URLSearchParams();
            params.append("jsonParkStation", JSON.stringify(payload.parkStation));
            params.append("jsonPark", JSON.stringify(payload.park));
            const encoded = encodeURI(params.toString())
            let id = await DoPost(
                store.getters['settings/getBaseUrl'] + '/ParkStationRestController/insertParkStationRemote',
                encoded,
                null,
                'Добавление остановки'
            );
            payload.parkStation.ss_id = id;
            context.commit('addParkStation', payload.parkStation);
        },

        // изменение объекта
        async doEditParkStation(context, payload) {

            const params = new URLSearchParams();
            params.append("jsonParkStation", JSON.stringify(payload.parkStation));
            params.append("jsonPark", JSON.stringify(payload.park));
            const encoded = encodeURI(params.toString())
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/ParkStationRestController/updateParkStationRemote',
                encoded,
                null,
                'Изменение остановки'
            );
            context.commit('editParkStation', payload.parkStation);
        },

        // удаление объекта
        async doDeleteParkStation(context, payload) {

            const params = new URLSearchParams();
            params.append("jsonParkStation", JSON.stringify(payload.parkStation));
            params.append("jsonPark", JSON.stringify(payload.park));

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/ParkStationRestController/deleteParkStationRemote',
                params.toString(),
                null,
                'Удаление остановки'
            );
            context.commit('deleteParkStation', payload.parkStation);
        },

        async isExistsParkStationLinks(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonParkStation", JSON.stringify(payload.parkStation));

            return await DoPost(
                store.getters['settings/getBaseUrl'] + '/ParkStationRestController/isExistChildRemote',
                params.toString(),
                null,
                'Проверяем ссылки на другие объекты'
            );
        },

    }
}
