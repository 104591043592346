// РАБОТА С СОБЫТИЯМИ

export default {
    emits: ['onLeafletEvent'],
    props: {
        // список событий на который подписаться (через пробел)
        events: {
            type: String,
            default: ''
        },
        // указаывает, кто является источником события
        targetComponent: {
            type: Object
        }
    },
    computed: {
        // возвращает текущий компонент
        curComponent() {
            return this.targetComponent || this;
        },
    },
    methods: {
        // подписаться на события
        subscribe(origin) {
            const items = this.eventsToArray(this.events);
            if (items.length > 0) {
                // избавляемся от дубликатов
                const newItems = [...new Set(items)];
                // подписываемся на события
                origin.on(newItems.join(' '), (event) => {
                    event['targetComponent'] = this.targetComponent || this;
                    this.$emit('onLeafletEvent', event);
                });
            }
        },
        // отписаться от событий
        unsubscribe(origin) {
            origin.off();
        },
        // превращает спсок событий в массив
        eventsToArray(events) {
            const items = events.trim().split(/\s+/);
            if (items.length > 0 && items[0]!=='') {
                return items
            }
            else {
                return []
            }
        },
        // превращает массив в список событий
        arrayToEvents(arr) {
            return arr.join(' ')
        },
    }
}
