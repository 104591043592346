<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable"
                  style="max-width: 84% !important; max-height: 96% !important;">
      <modal-content style=" height: 100%">
        <modal-header class="bg-primary text-white">
          <modal-title>{{ $t("InspReisesByParksDialog.DialogTitle") }}</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body style="padding: 0; overflow-y: hidden; overflow-x: hidden">
          <div class="row" style="margin-left: 10px;">
<!--            :placeholder="$t('InspReisesByParksDialog.Placeholder')"-->
            <input
                type="text"
                v-model="filterTextNaryad"
                class="form-control"
                id="filterTextNaryad"
                :placeholder="$t('InspReisesByParksDialog.PlaceholderScheduleOrder')"
                autocomplete="off"
                style="width: 20%; min-width: 100px; height: 30px; margin-right: 10px; margin-top: 10px"
            />
            <input
                type="text"
                v-model="filterTextDate"
                class="form-control"
                id="filterTextDate"
                :placeholder="$t('InspReisesByParksDialog.PlaceholderDate')"
                autocomplete="off"
                style="width: 20%; min-width: 100px; height: 30px; margin-right: 10px; margin-top: 10px"
            />
            <input
                type="text"
                v-model="filterTextTs"
                class="form-control"
                id="filterTextTs"
                :placeholder="$t('InspReisesByParksDialog.PlaceholderTS')"
                autocomplete="off"
                style="width: 20%; min-width: 100px; height: 30px; margin-top: 10px"
            />
          </div>

          <align-container style="margin-top: 50px">
            <align-panel align="all" style="overflow-y: auto">
              <insp-reises-by-parks-table
                  :selectedRow="selectedRow"
                  :rowList="filteredRows"
                  @setSelectedRow="setSelectedRow"
              >
              </insp-reises-by-parks-table>
            </align-panel>
          </align-container>
        </modal-body>
        <modal-footer style="justify-content: flex-start !important;">
          <b>{{ $t("InspReisesByParksDialog.FooterTitle") }}</b>

          <div class="input-group mb-3"
               style="width: 165px; margin-bottom: 0px !important; margin-top: 0px !important;">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-line-time">{{ $t("InspReisesByParksDialog.UMKR") }}</span>
            </div>
            <input disabled="true" style="width: 75px !important;" type="text" class="form-control"
                   aria-describedby="inputGroup-line-time" v-model="umkrSumValue">
          </div>

          <div class="input-group mb-3"
               style="width: 220px; margin-bottom: 0px !important; margin-top: 0px !important;">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-line-time">{{
                  $t("InspReisesByParksDialog.Processing")
                }}</span>
            </div>
            <input disabled="true" style="width: 75px" type="text" class="form-control"
                   aria-describedby="inputGroup-line-time" v-model="processingSumValue">
          </div>

          <div class="input-group mb-3"
               style="width: 165px; margin-bottom: 0px !important; margin-top: 0px !important;">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-line-time">{{ $t("InspReisesByParksDialog.ASOP") }}</span>
            </div>
            <input disabled="true" style="width: 75px" type="text" class="form-control"
                   aria-describedby="inputGroup-line-time" v-model="asopSumValue">
          </div>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>


import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import InspReisesByParksTable from "@/components/umkr/InspReisesByParksTable";
import {dateToND, dateToString, ndToDate, quickSort, statInitFn, stringToDate} from "@/lib";

export default {
  components: {
    InspReisesByParksTable,
    AlignPanel,
    AlignContainer,

  },
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  data() {
    return {
      selectedRow: null,
      umkrSumValue: 0,
      processingSumValue: 0,
      asopSumValue: 0,
      rowList: [],
      filterTextNaryad: '',
      filterTextDate: '',
      filterTextTs: '',
    };
  },

  watch: {

    filterTextNaryad() {
      this.umkrSumValue = 0;
      this.processingSumValue = 0;
      this.asopSumValue = 0;
    },

    filterTextDate() {
      this.umkrSumValue = 0;
      this.processingSumValue = 0;
      this.asopSumValue = 0;
    },

    filterTextTs() {
      this.umkrSumValue = 0;
      this.processingSumValue = 0;
      this.asopSumValue = 0;
    }


  },

  computed: {

    filteredRows() {
      let arr = [];
      let arr2 = [];
      let arr3 = [];
      this.rowList.forEach(value => {
        if (this.filterTextNaryad != '') {
          let str1 = value.naryad.toLowerCase();
          if (str1.includes(this.filterTextNaryad.toLowerCase())) {
            arr.push(value);
          }
        } else {
          arr.push(value);
        }
      });
      // фильтруем по дате
      arr.forEach(value => {
        if (this.filterTextDate != '') {
          let str1 = value.date_as_str.toLowerCase();
          if (str1.includes(this.filterTextDate.toLowerCase())) {
            arr2.push(value);
          }
        } else {
          arr2.push(value);
        }
      });
      // фильтруем по ТС
      arr2.forEach(value => {
        if (this.filterTextTs != '') {
          let str1 = value.ts_name.toLowerCase();
          if (str1.includes(this.filterTextTs.toLowerCase())) {
            arr3.push(value);
          }
        } else {
          arr3.push(value);
        }
      });
      // считаем суммы
      arr3.forEach(value => {
        if (value.psgrs_umkr)
          this.umkrSumValue += value.psgrs_umkr;
        if (value.psgrs_processed)
          this.processingSumValue += value.psgrs_processed;
        if (value.psgrs_asop)
          this.asopSumValue += value.psgrs_asop;
      });


      return arr3;
    }

  },

  methods: {

    setSelectedRow(row) {
      this.selectedRow = row;
    },

    getPercent(umkr, processed) {
      if (umkr != 0) {
        let f = umkr - processed;
        let flt = ((f) / umkr) * 100;
        return Number(flt).toFixed(2);
      } else if (umkr == 0 && processed == 0) {
        return "0";
      } else {
        return "-100.00";
      }
    },

    onFilterChanged(newValue) {
      this.filterText = newValue;
    }

  },
  created() {

    let resultArr = [];
    // обрабатываем данные УМКР
    this.$store.getters['umkr/getStatInitItog'].forEach(val => {
      Object.keys(val.info).forEach(infoKey => {

        if (dateToND(stringToDate(this.$store.getters['ref/getUserStartDate'])) <= infoKey && infoKey <= dateToND(stringToDate(this.$store.getters['ref/getUserEndDate']))) {
          resultArr.push({
            date_as_int: infoKey,
            date_as_str: dateToString(ndToDate(infoKey)),
            ts_id: val.ts_id,
            ts_garagnum: val.ts_garagnum,
            ts_statenum: val.ts_statenum,
            ts_name: val.ts_garagnum + ' / ' + val.ts_statenum,
            naryad: val.info[infoKey].comm,
            psgrs_umkr: val.info[infoKey].psgrs_count ? val.info[infoKey].psgrs_count : 0,
            psgrs_asop: val.info[infoKey].asop ? val.info[infoKey].asop : 0,
            difference_psgrs_percent: this.getPercent(val.info[infoKey].psgrs_count, val.info[infoKey].asop),
            difference_psgrs: val.info[infoKey].psgrs_count - val.info[infoKey].asop,

            psgrs_processed: 0,
            difference_processed_percent: 0,
            difference_processed: 0,
          });
        }
      });
    });
    // обрабатываем загруженные данные

    this.$store.getters['umkr/getStatInitInspReisesByParks'].forEach(val => {
      val[1].forEach(v => {
        let result = resultArr.find(resVal => v.dispatchdate_asInt == resVal.date_as_int && resVal.ts_id == v.tsunique_id);
        if (result) {
          if (!result.psgrs_processed) {
            result.psgrs_processed = 0;
          }
          result.psgrs_processed += v.sumpsgrs;

          result.difference_processed_percent = this.getPercent(result.psgrs_umkr, result.psgrs_processed);
          result.difference_processed = result.psgrs_umkr - result.psgrs_processed;

        }
      })
    });

    // сортируем
    quickSort(resultArr, (leftItem, rightItem) => {
      return 1 * statInitFn(leftItem, rightItem);
    });

    this.rowList = resultArr;
  }
};
</script>

<style scoped>
form label {
  font-weight: bold;
}

</style>