
import stat_init_store from "@/store/umkr/stat_init_store";
import system_params_store from "@/store/umkr/system_params_store";
import calc_car_UMKR_statistic_store from "@/store/umkr/calc_car_UMKR_statistic_store";
import nav_flow_items_store from "@/store/umkr/nav_flow_items_store";
import ts_store from "@/store/umkr/ts_store";
import insp_reises_by_parks_store from "@/store/umkr/insp_reises_by_parks_store";


export default {
    namespaced: true,
    modules: {
        stat_init_store,
        system_params_store,
        calc_car_UMKR_statistic_store,
        nav_flow_items_store,
        ts_store,
        insp_reises_by_parks_store,
    }
}