<template>
  <div class="card bg-light"
       style="height: 100%; overflow-x: hidden; overflow-y: hidden;">
    <form style="height: 95%; overflow-x: hidden; overflow-y: auto;">
      <div class="form-group row">
        <div class="col" style="margin-left:10px; margin-top:10px;">
          <input type="radio" class="green-radio" name="inlineRadioOptions" id="inlineRadio1" value="add"
                 v-model="selectedMode" style="height:16px; width:16px; vertical-align: middle; margin-right:5px;">
          <label for="inlineRadio1" style="margin-bottom: 0; margin-top: 5px">{{ $t("nsi.NsiRadioButtonAdd") }}</label>
        </div>
        <div class="col" style="text-align: right; margin-right:10px; margin-top:10px">
          <input type="radio" class="green-radio" name="inlineRadioOptions" id="inlineRadio2" value="edit"
                 v-model="selectedMode" style="height:16px; width:16px; vertical-align: middle; margin-right:5px">
          <label for="inlineRadio2" style="margin-bottom: 0; margin-top: 5px">{{ $t("nsi.NsiRadioButtonEdit") }}</label>
        </div>
      </div>

      <div class="form-group" style="padding-right: 20px; padding-left: 20px">
        <label for="transport_type">{{ $t("nsi.MarshDialog.TransportTypeLabel") }}</label>
        <transport-type-selector
            id="transport_type"
            class="form-control"
            v-model="transport_type"
        ></transport-type-selector>
      </div>

      <div class="form-group" style="padding-right: 20px; padding-left: 20px">
        <label>{{ $t("nsi.MarshDialog.MarshNameLabel") }}</label>
        <input-string
            class="form-control"
            id="marshName"
            v-model="marshName"
        />
      </div>

      <div class="form-group" style="padding-right: 20px; padding-left: 20px">
        <label>{{ $t("nsi.MarshDialog.PaySystNameLabel") }}</label>
        <input-string
            class="form-control"
            id="paySystName"
            v-model="paySystName"
        />
      </div>

      <div class="form-group" style="padding-right: 20px; padding-left: 20px">
        <label for="transport_type">{{ $t('nsi.MarshDialog.ExternalSystemLabel') }}</label>
        <universal-selector
            class="form-control"
            v-model="externalSystem"
            :caption="this.$t('nsi.MarshDialog.ExternalSystemCaption')"
            :itemList="
            [
              {
              id: 'ASUN',
              name: this.$t('nsi.MarshDialog.ExternalSystemASDU')
              },
              {
              id: 'KVR',
              name: this.$t('nsi.MarshDialog.ExternalSystemKVR')
              },
            ]"
        >
        </universal-selector>
      </div>
    </form>
    <div class="form-group row">
      <div class="col" style="margin-left: 20px">
        <button @click="onSave" class="btn btn-info">{{ $t("nsi.NsiButtonSave") }}</button>
      </div>
      <div class="col" style="text-align: right; margin-right:20px">
        <button @click="onDelete" class="btn btn-info" :disabled="isDeleteButtonDisabled">{{ $t("nsi.NsiButtonDelete") }}</button>
      </div>
    </div>

  </div>
</template>

<script>
import InputString from "@/components/ui/custom/InputString";
import UniversalSelector from "@/components/ui/custom/UniversalSelector";
import TransportTypeSelector from "@/components/references/trans_type/TransportTypeSelector";

export default {
  name: "MarshDialog",
  components: {UniversalSelector, TransportTypeSelector, InputString},
  props: ['item'],
  emits:['onSave', 'onEdit', 'onDelete'],
  computed: {

    isDeleteButtonDisabled() {
      return this.item ? false : true;
    }

  },
  watch: {

    item(newValue) {
      if(newValue){
        this.selectedMode = 'edit'
      } else {
        this.selectedMode = 'add'
      }
      this.externalSystem = newValue.externalSystem
      this.marshName = newValue.routeName
      this.paySystName = newValue.paySystName
      this.transport_type = newValue.transportTypeId
    }

  },
  data() {
    return {
      selectedMode: 'add',
      externalSystem: null,
      marshName: '',
      paySystName: '',
      transport_type: null,
    }
  },

  methods: {

    onSave() {
      if(this.selectedMode == 'add'){
        this.$emit('onSave', {
          routeId: 0,
          routeName: this.marshName,
          transportTypeId: this.transport_type,
          formationId: this.$store.getters['ref/getUserFormation'],
          parkId: this.$store.getters['ref/getUserPark'],
          ermRegNum: 0,
          externalSystem: this.externalSystem,
          paySystName: this.paySystName == '' ? this.marshName : this.paySystName,
          identity: this.$store.getters['ref/getParks'][this.$store.getters['ref/getUserPark']].identity,
        })
      } else {
        this.$emit('onEdit', {
          routeId: this.item.routeId,
          routeName: this.marshName,
          transportTypeId: this.transport_type,
          formationId: this.item.formationId,
          parkId: this.item.parkId,
          externalSystem: this.externalSystem,
          ermRegNum: this.item.ermRegNum,
          paySystName: this.paySystName,
          identity: this.item.identity,
        })
      }
    },

    onDelete() {
      this.$emit('onDelete');
      this.externalSystem = null;
      this.marshName = '';
      this.paySystName = '';
      this.transport_type = null;
    }

  }
}
</script>

<style scoped>

</style>