<template>
  <table class="table table-hover table-bordered">
    <thead class="thead-custom">
    <tr>
      <th scope="col" v-for="(col) in visibleColumnList"
          :key="col.columnName"
          :title="col.columnTooltip"
          @click.right.prevent=" headerContextMenu($event)"
          @contextmenu.prevent
      >{{ col.columnTitle }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr class="data-row" v-for="(row, rowIndex) in rowList" :key="row.__id" @click="setSelectedRow(row)"
        @click.right.prevent=" onContextMenu($event, row)" @dblclick="setDblClick(row)" @contextmenu.prevent :style="setRowStyle(row)">
      <template v-for="(col) in visibleColumnList" :key="col.columnName">
        <td v-if="col.columnName==='#'"> {{ rowIndex + 1 }}</td>
        <td v-if="col.type === 'boolean'" class="text-nowrap overflow-hidden text-center" >
          <span v-if="row[col.columnName] == true" v-html="col.displayFn(row, rowIndex)"></span>
        </td>
        <td v-else-if="col.type === 'html'" class="text-nowrap overflow-hidden text-right" >
          <span v-html="col.displayFn(row, rowIndex)"></span>
        </td>
        <td v-else-if="(!!col.subName)"> {{ row[col.columnName][col.subName] }}</td>
        <td v-else> {{ row[col.columnName] }}</td>
      </template>
    </tr>
    </tbody>
  </table>
</template>

<script>

export default {
  components: {},
  props: {
    //Список названий колонок
    columnList: {
      type: Array,
      default: () => []
    },
    //Список строк (массив данных)
    rowList: {
      type: Array,
      default: () => []
    },
    // Активная строка
    selectedRow: {
     type: Object,
     default: null
    },
    // Массив строк для выпадающего списка при клике на строку таблицы
    contextMenuItems: {
      type: Array,
      default: () => []
    },
    // Массив строк для выпадающего списка при клике на строку таблицы
    headerContextMenuItems: {
      type: Array,
      default: () => []
    }
  },
  emits: [
    'setSelectedRow',
    'setDblClick'
  ],
  computed: {
    visibleColumnList() {
      let arr = this.columnList.filter(h => {
        return h.isVisible;
      });
      return arr;
    },

    tableColumnVariant() {
      return this.$store.getters['ref/getUserInspPassFlowTableColumnVariant'];
    },
  },

  methods: {

    //задаем выбранную строчку
    setSelectedRow(row) {
      this.$emit('setSelectedRow', row);
    },

    // двойной клик на строку
    setDblClick(row){
      this.$emit('setDblClick', row);
    },

    //формируем стиль строки
    setRowStyle(row) {
      let style = "";
      if(row == this.selectedRow) {
        style = 'background: #76bafc !important;'
      }
      if (row.__rowStyle) {
        style = style + row.__rowStyle
      }
      return style;
    },

    // открываем контекстное меню
    onContextMenu(e, row) {
      this.$emit('setSelectedRow', row);
      // передаем в перент строку для которой открыли контекстное меню
      this.$contextmenu({
        x: e.x,
        y: e.y,
        items: this.contextMenuItems,
      });
    },

    // открываем контекстное меню по клику на заголовок
    headerContextMenu(e) {
      // передаем в перент строку для которой открыли контекстное меню
      this.$contextmenu({
        x: e.x,
        y: e.y,
        items: this.headerContextMenuItems,
      });
    },

    async returnToPreviousTable() {
      // берем предыдущий payload из "массива"
      let payload = this.$store.getters['ref/getHistoryArr'][this.$store.getters['ref/getHistoryArr'].length - 2];
      // удаляем последнюю запись из массива
      this.$store.commit('ref/deleteHistoryArrStep');
      await this.$store.dispatch('rep/doGetInspReisesPassFlowIndicators', payload);
    },

  }

}
</script>

<style scoped>

.table {
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.8rem;
  margin-bottom: 0;
}

.table .thead-custom th {
  color: #fff;
  background-color: #17a2b8;
  border: 1px solid #dee2e6;
  background-clip: padding-box;
  text-align: center;
  vertical-align: middle;
  position: sticky;
  top: 0px;
}

td {
  padding: 0 6px;
  height: 20px;
  cursor: default;
}


</style>