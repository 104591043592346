// ОБРАБОТКА УМКР
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {

    actions: {

        // расчет
        async doRunNavLinkProc(state,payload) {
            //формируем параметра запроса
            const params = new URLSearchParams();
            params.append("jsonTS", JSON.stringify(payload.jsonTS));
            params.append("jsonPark", JSON.stringify(payload.jsonPark));
            params.append("dateIndexMin", JSON.stringify(payload.dateIndexMin));
            params.append("dateIndexMax", String(payload.dateIndexMax));
            params.append("isDemo", JSON.stringify(payload.isDemo));
            params.append("jsonProcUserParams", JSON.stringify(payload.jsonProcUserParams));
            params.append("procByNariad", String(payload.procByNariad));
            // кодируем данные для корректного отображения русского текста на сервере
            const encoded = encodeURI(params.toString());
            return await DoPost(store.getters['settings/getBaseUrl'] +
                '/ProcessingRestController/runNavLinkProcRemote',
                encoded,
                null,
                ''
            );
        },

    }
}
