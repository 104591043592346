<template>
  <div>
    <universal-selector
        v-model="value"
        :itemList="formationsArr"
        :caption="this.$t('FormationSelector')"
    ></universal-selector>
  </div>
</template>

<script>

import UniversalSelector from "./UniversalSelector.vue";

export default {
  components: {
    UniversalSelector
  },

  props: [
      'modelValue'
  ],

  //объявление переменных объекта
  // data() {
  //   return {
      // // выбранное значение
      // selectedFormation: null,
  //   }
  // },

  emits: ['update:modelValue'],

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$store.commit('ref/setUserFormation', value);
        // this.$store.commit('ref/setStatInit', []);
        // this.$store.commit('ref/setStatInitItog', []);
        // this.$store.commit('rep/setInspReisesPassFlowIndicators', []);
        this.$emit('update:modelValue', value);
      }
    },

    formationsArr(){
      return this.$store.getters['ref/getFormations'].map(value => {
        return {
          id: value.tw_id,
          name: value.tw_name
        }
      })
    },

    // getFormationCaption() {
    //   let variants = this.$store.getters['getValues']
    //   if (variants != null) {
    //     return variants.formationCaption
    //   } else {
    //     return ''
    //   }
    // },

  }
}
</script>

<style scoped>

</style>