<template>
  <modal>
    <modal-dialog class="modal-dialog-centered">
      <modal-content>
        <modal-header>
          <modal-title>
            <slot name="title">{{ $t("ConfirmMessageBoxTitle") }}</slot>
          </modal-title>
        </modal-header>
        <modal-body>
          <p>
            <slot></slot>
          </p>
        </modal-body>
        <modal-footer>
          <span tabindex="0" aria-hidden="true" @focus="startOfDialogFocusTrap"></span>
          <button class="btn btn-info" @click="$emit('onCancel')" ref="cancelButton">Отмена</button>
          <button class="btn btn-danger" @click="$emit('onOK')" ref="okButton">Да</button>
          <span tabindex="0" aria-hidden="true" @focus="endOfDialogFocusTrap"></span>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
export default {
  emits: ['onOK', 'onCancel'],
  data() {
    return {
      onEscape: null,
      onTab: null,
      onFocus: false,
    }
  },
  methods: {

    // фокус уходит с последнего элемента, попадает на "ловушку" (последний невидимый элемент модального диалога)
    // и фокус переносится обратно на предпоследний элемент диалога - последний видимый - кнопку "Ок"
    startOfDialogFocusTrap() {
      this.$refs.cancelButton.focus();
    },

    // фокус идет в обратном направлении (Shift + Tab) с первого видимого элемента (второй элемент на страницы),
    // попадает на "ловушку" (первый невидимый элемент модального диалога)
    // и фокус переносится обратно на первый видимый элемент диалога - кнопку "Отмена"
    endOfDialogFocusTrap() {
      this.$refs.okButton.focus();
    },

  },

  // диалог начинает закрываться при нажатии кнопки ESC
  mounted() {
    this.onEscape = (e) => {
      if (e.keyCode === 27) {
        this.$emit('onCancel');
      }
    }
    document.addEventListener('keydown', this.onEscape)
    // первый элемент диалога кнопка "Отмена" получает фокус
    this.$refs.cancelButton.focus();
  },

  unmounted() {
    document.removeEventListener('keydown', this.onEscape)
  },

}
</script>
