<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>{{ $t('nsi.NsiRadioButtonAdd') }}</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body style="padding: 0; overflow-y: hidden">
          <div class="form-group" style="padding-right: 20px; padding-left: 20px; padding-top: 10px">
            <label>{{ $t('nsi.ParkStationDialog.StationLongNameLabel') }}</label>
            <input-string
                class="form-control"
                id="ss_longname"
                v-model="ss_longname"
            />
          </div>

          <div class="form-group" style="padding-right: 20px; padding-left: 20px">
            <label>{{ $t('nsi.ParkStationDialog.StationShortNameLabel') }}</label>
            <input-string
                class="form-control"
                id="ss_shortname"
                v-model="ss_shortname"
            />
          </div>

          <div class="form-group" style="padding-right: 20px; padding-left: 20px; ">
            <label>{{ $t('nsi.ParkStationDialog.CpNormLabel') }}</label>
            <universal-selector
                class="form-control"
                v-model="cp_norm"
                :itemList="
            [
              {
                id: '0',
                name: this.$t('nsi.ParkStationDialog.CpNormOrdinary')
              },
              {
                id: '1',
                name: this.$t('nsi.ParkStationDialog.CpNormCP')
              },
            ]"
            >
            </universal-selector>
          </div>

          <div class="card bg-light"
               style="overflow-x: hidden; overflow-y: hidden; margin-right: 10px; margin-left: 10px; margin-bottom: 20px ">
            <div class="form-group" style="font-weight: bold; padding-left: 10px">
              {{ $t('nsi.ParkStationDialog.StationCoordinate') }}
            </div>

            <div class="form-group" style="padding-right: 10px; padding-left: 10px">
              <label>{{ $t('nsi.ParkStationDialog.Latitude') }}</label>
              <input-string
                  class="form-control"
                  id="ss_latitude"
                  v-model="ss_latitude"
                  disabled
              />
            </div>

            <div class="form-group" style="padding-right: 10px; padding-left: 10px">
              <label>{{ $t('nsi.ParkStationDialog.Longitude') }}</label>
              <input-string
                  class="form-control"
                  id="ss_longitude"
                  v-model="ss_longitude"
                  disabled
              />
            </div>
          </div>

        </modal-body>
        <modal-footer>
          <button @click="onSave" class="btn btn-primary">{{ $t('nsi.NsiButtonSave') }}</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>

</template>

<script>

import UniversalSelector from "@/components/ui/custom/UniversalSelector";
import InputString from "@/components/ui/custom/InputString";

export default {
  components: {InputString, UniversalSelector},
  props: ['item'],
  emits: ['onSave', 'onEdit', 'onDelete', 'onCancel'],
  data() {
    return {
      coordChooserVisible: false,
      selectedMode: (this.item && this.item.selectedMode) ? this.item.selectedMode : '',
      ss_longname: (this.item && this.item.ss_longname) ? this.item.ss_longname : '',
      ss_shortname: (this.item && this.item.ss_shortname) ? this.item.ss_shortname : '',
      ss_longitude: (this.item && this.item.ss_longitude) ? this.item.ss_longitude : '',
      ss_latitude: (this.item && this.item.ss_latitude) ? this.item.ss_latitude : '',
      cp_norm: (this.item && this.item.cp_norm) ? this.item.cp_norm : 0,
    }
  },

  computed: {
    activeStop() {
      if (this.ss_longitude && this.ss_latitude) {
        return {
          ss_longitude: this.ss_longitude,
          ss_latitude: this.ss_latitude,
        }
      }
      return null;
    }
  },

  methods: {

    onSave() {

      if (this.selectedMode == 'add') {
        this.$emit('onSave', {
          ss_id: 0,
          pk_id: this.$store.getters['ref/getUserPark'],
          ss_shortname: this.ss_shortname,
          ss_longname: this.ss_longname,
          ss_longitude: Number(this.ss_longitude),
          ss_latitude: Number(this.ss_latitude),
          cp_norm: this.cp_norm,
          ss_trtype: -1,
          erm_regnum: -1,
          identity: this.$store.getters['ref/getParks'].find(value => value.parkId == this.$store.getters['ref/getUserPark']).identity,
        })
      } else {
        let item = this.item;
        delete item.selectedMode;
        item.ss_shortname = this.ss_shortname;
        item.ss_longname = this.ss_longname;
        item.ss_longitude = Number(this.ss_longitude);
        item.ss_latitude = Number(this.ss_latitude);
        item.cp_norm = this.cp_norm;
        this.$emit('onEdit', item);
      }
    },

    onDelete() {
      this.$emit('onDelete');
    },

  }
}
</script>

<style scoped>

</style>