<template>
<table-component :columnList="columnList" :rowList="rowList"></table-component>
</template>

<script>
import TableComponent from "@/components/ui/custom/TableComponent";

export default {
  components: {TableComponent},
  computed: {
    // колонки таблицы
    columnList(){
      return [
        {
          columnName: "raceTitle",
          columnTitle: this.$t("nsi.RaceTable.RaceTitle"),
          isVisible: true
        },
        {
          columnName: "raceType",
          columnTitle: this.$t("nsi.RaceTable.RaceType"),
          isVisible: true
        },
        {
          columnName: "raceLengthAsKm",
          columnTitle: this.$t("nsi.RaceTable.RaceLengthAsKm"),
          isVisible: true
        },
      ]
    },
    // строки таблицы
    rowList(){
      return this.$store.getters['ref/getRaces'].map(value => {
        return value;
      })
    }
  }
}
</script>

<style scoped>

</style>