<template>
  <align-container>
    <align-panel align="top" height="50%" :gap="3" style="overflow: auto">
      <table class="table">
        <thead class="thead-custom">
          <tr>
            <th scope="col" class="fix-head-cell-index" v-for="(value,index) in columns" :key="index"
                :class=" index== 0 ? 'first-col' : ( index== 1 ? 'second-col' : ( index== 2 ? 'third-col' : ( index== 3 ? 'fourth-col' :
                        ( index== 4 ? 'fifth-col' : ( index== 5 ? 'sixth-col' : '')))))"
                :style=" index== 0 ? 'padding-right: 1px; padding-left: 1px;':''">{{ value }}
            </th>
            <th scope="col" v-for="value in getColums" :key="value">{{
                getDateFormat(value)
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in getRows" :key="index">
            <td class="first-col fix-cell-index"
                :style="selectedValue == value ? 'background: #76bafc' : ''">
              {{ index + 1 }}
            </td>
            <td class="second-col fix-cell-index"
                :style="selectedValue == value ? 'background: #76bafc' : ''">
              {{ value.ts_garagnum }}
            </td>
            <td class="third-col fix-cell-index"
                :style="selectedValue == value ? 'background: #76bafc' : ''">
              {{ value.ts_statenum }}
            </td>
            <td class="fourth-col fix-cell-index"
                :style="selectedValue == value ? 'background: #76bafc;' : ''">
              {{ value.mk_name }}
            </td>
            <td class="fifth-col fix-cell-index"
                :style="selectedValue == value ? 'background: #76bafc;' : ''">
              {{ value.rsNum.rs_stationnum }}
            </td>
            <td class="sixth-col fix-cell-index"
                :style="selectedValue == value ? 'background: #76bafc;' : ''">
              {{ value.apcTypeName }}
            </td>
            <td
                v-for="(date, index) in getColums"
                :key="index"
                :style="[this.selectedValue == value && this.selectedDate == date ? 'background: #76bafc;' : ''] + 'color:' + returnColor(value,date)+ ';'"
                @click="setSelectedCell(value, date)">
              {{ getValueByViewMode(value, date) }}
            </td>
          </tr>
        </tbody>
      </table>
    </align-panel>

    <align-panel align="all" style="overflow: auto">
      <table class="table">
        <thead class="thead-custom">
        <tr>
          <th scope="col" class="text-center" v-for="(value, index) in bottomColumns" :key="index">
            {{ value }}
          </th>
        </tr>
        </thead>
        <tbody v-if="selectedDate">
        <tr v-for="(value) in getRows" :key="'ts_'+ value.ts_id" :id="'ts_'+ value.ts_id"
            :style="value.ts_id == this.selectedValue.ts_id ? 'background-color: #76bafc;' : ''">
          <td>
            {{ value.ts_garagnum }}
          </td>
          <td>
            {{ getDiagnosticMsg(value) }}
          </td>
          <td>
            {{ getCommProc(value, selectedDate) }}
          </td>
        </tr>
        </tbody>
      </table>
    </align-panel>
  </align-container>
</template>

<script>
import {dateToND, secondsToDate, stringToDate} from "@/lib";

export default {

  data() {
    return {
      // выбранная дата
      // selectedDate: null,
      // выбранное значение
      // selectedValue: null,

      PSGRS_COUNT_IN_UNDETECTED_REISES: 30,
      DISB_PSGRS: 2,
    }
  },

  computed: {
    // выбранная дата
    selectedDate(){
      return this.$store.getters['ref/getSelectedProcessingDateIndex'];
    },

    // выбранное значение
    selectedValue() {
      return this.$store.getters['ref/getSelectedProcessingValue'];
    },

    columns() {
      return [
        this.$t("ProcessingPage.OrderNum"), //"№",
        this.$t("ProcessingPage.GaragNum"), //"Гар.№",
        this.$t("ProcessingPage.StateNum"), //"Гос.№",
        this.$t("ProcessingPage.Model"), //"Модель",
        this.$t("ProcessingPage.RadioStation"), //"№р/с",
        this.$t("ProcessingPage.Equipment"), //"Оборуд."
      ]
    },

    bottomColumns() {
      return [
        this.$t("ProcessingPage.Ts"), //"ТС",
        this.$t("ProcessingPage.QualityOfInputData"), //"Качество исходных данных",
        this.$t("ProcessingPage.ProcessingOfInputData"), //"Обработка исходных данных"
      ]
    },

    getColums() {
      let resultArr = [];
      let startDate = dateToND(stringToDate(this.$store.getters['ref/getUserStartDate']));
      let endDate = dateToND(stringToDate(this.$store.getters['ref/getUserEndDate']));
      for (let i = startDate; i <= endDate; i++) {
        resultArr.push(i);
      }
      return resultArr;
    },

    getRows() {
      let ts = this.$store.getters['umkr/getTS'];
      let statInitAr = this.$store.getters['umkr/getStatInit'];
      let statInitArByTs = this.$store.getters['umkr/getStatInitByTs'];
      let resultArr = [];
      let startDate = dateToND(stringToDate(this.$store.getters['ref/getUserStartDate']));
      let endDate = dateToND(stringToDate(this.$store.getters['ref/getUserEndDate']));
      if (statInitAr.length != 0 || statInitArByTs != 0) {
        ts.map(value => {
          let statInit = statInitAr.find(statInit => value.ts_id == statInit.tsID);
          if (statInit) {
            value['info'] = statInit['info'];
          }
          let statInitByArr = statInitArByTs.find(statInit => value.ts_id == statInit.ts_id);
          if(statInitByArr && value['info']) {
            for (let i = startDate; i <=endDate ; i++) {
              if (!value.info[i] && statInitByArr.info[i]) {
                value.info[i] = statInitByArr.info[i];
              }
            }
          } else if (statInitByArr) {
            value['info'] = statInitByArr['info'];
          }

          let apcType = this.$store.getters['rep/getApcTypes'].find(apc => apc.apcType_id == value.apcTypeID);
          if (apcType) {
            value.apcTypeName = apcType.apcTypeName;
          } else {
            value.apcTypeName = '-';
          }
          resultArr.push(value);
        })
      }
      return resultArr;
    },
  },


  methods: {

    getDiagnosticMsg(value) {
      if (value.info && value.info[this.selectedDate]) {
        return value.info[this.selectedDate].diagnostic_msg;
      }
      return '';
    },

    getDateFormat(value) {
      let date = secondsToDate(value * 60 * 60 * 24).substring(5);
      let month = date.substring(0, 2);
      let day = date.substring(3);
      return day + '.' + month;
    },

    setSelectedCell(value, date) {
      // this.selectedValue = value;
      // this.selectedDate = date;
      // сетаем значения в store, для отработки кнопки
      this.$store.commit('ref/setSelectedProcessingDateIndex', date);
      this.$store.commit('ref/setSelectedProcessingValue', value);
      this.$nextTick(() =>{
        let el = document.getElementById('ts_' + value.ts_id);
        if (el) {
          // Use el.scrollIntoView() to instantly scroll to the element
          el.scrollIntoView({behavior: 'auto', block: 'center'});
        }
      })
    },

    returnColor(value, date) {
      if (value && value.info && value.info[date]) {
        let comm = this.getCommProc(value, date);
        if (comm && comm != '') {
          return '#ff0000';
        } else if (value.info[date].comm || value.info[date].comm == '') {
          return '#2d36f6';
        } else {
          return '';
        }
      }
    },

    getValueByViewMode(value, date) {
      if (value && value.info && value.info[date]) {
        let info = value.info[date];
        let viewMode = this.$store.getters['ref/getUserTableProcessingViewVariant'];
        if (viewMode == 'Nariad') {
          if (info['marsh'] == "" || info['marsh'] == null ) {
            let comm = info['comm'];
            return comm == '' ? 'X' : comm;
          } else {
            return info['marsh'];
          }
        } else if (viewMode == 'UndetectedReisCount') {
          return info['undetected_reis_count'];
        } else if (viewMode == 'PsrgsInUndetectedReises') {
          let passCountInUndetectedReises = '';
          let reis_psgrs_count = '';
          if (info['passCountInUndetectedReises']) {
            passCountInUndetectedReises = info['passCountInUndetectedReises'];
          }
          if (info['reis_psgrs_count']) {
            reis_psgrs_count = info['reis_psgrs_count'];
          }
          if (passCountInUndetectedReises == '' && reis_psgrs_count == '') {
            return '';
          }
          return passCountInUndetectedReises + '/' + reis_psgrs_count;
        }
      } else {
        return '';
      }
    },

    // получаем диагностическое сообщение
    getCommProc(value, date) {
      let comm = '';
      let ERROR1_TITLE = 'UNBINED_PASSENGERS_IN_TRIPS_ERROR';
      let ERROR2_TITLE = 'POSITIVE_DISBALANCE_ERROR';
      let ERROR3_TITLE = 'NEGATIVE_DISBALANCE_ERROR';
      let ERROR1_VALUE = this.$t("ProcessingPage.Error1_value"); //'Кол-во пасс. в нерасп. рейсах: %s (>%d)';
      let ERROR2_VALUE = this.$t("ProcessingPage.Error2_value"); //'Положит./отриц. дисбаланс: %s/%d (%f>%g*%h)';
      let ERROR3_VALUE = this.$t("ProcessingPage.Error3_value"); //'Положит./отриц. дисбаланс: %s/%d (%f>%g*%h)';
      if (value.info && value.info[date] && value.info[date].proc_diagn_msgs) {
        let statistic = value.info[date];
        let arr = Object.entries(statistic.proc_diagn_msgs);
        for (let i = 0; i < arr.length; i++) {
          if(arr[i][1] == ERROR1_TITLE) {
            comm += ERROR1_VALUE.replace(/%s/,statistic.passCountInUndetectedReises).replace(/%d/, this.PSGRS_COUNT_IN_UNDETECTED_REISES);
            comm += '; '
          }
          if(arr[i][1] == ERROR2_TITLE) {
            comm += ERROR2_VALUE.replace(/%s/, statistic.avg_reis_disb_pos).replace(/%d/, statistic.avg_reis_disb_neg).replace(/%f/,statistic.avg_reis_disb_pos)
                .replace(/%g/, this.DISB_PSGRS).replace(/%h/, statistic.avg_reis_disb_neg);
          } else if (arr[i][1] == ERROR3_TITLE) {
            comm += ERROR3_VALUE.replace(/%s/, statistic.avg_reis_disb_pos).replace(/%d/, statistic.avg_reis_disb_neg).replace(/%f/,statistic.avg_reis_disb_neg)
                .replace(/%g/, this.DISB_PSGRS).replace(/%h/, statistic.avg_reis_disb_pos);
          }
        }
      }
      return comm;
    },

  }
}
</script>

<style scoped>

.table {
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.8rem;
}

.table .thead-custom th {
  color: #fff;
  background-color: #17a2b8;
  border: 1px solid #dee2e6;
  background-clip: padding-box;
  text-align: center;
  vertical-align: middle;
  position: sticky;
  top: 0px;
}

td {
  border: 1px solid #dee2e6;
  padding: 0 6px;
  cursor: default;
}

.fix-head-cell-index {
  /*position: sticky;*/
  z-index: 10;
}

.fix-cell-index {
  position: sticky;
  z-index: 5;
  border: 1px solid #dee2e6;
  background-clip: padding-box;
  background-color: #fff;
}

.first-col {
  left: 0px;
  max-width: 26px;
  min-width: 26px;
}

.second-col {
  left: 26px;
  max-width: 60px;
  min-width: 60px;
}

.third-col {
  left: 86px;
  max-width: 80px;
  min-width: 80px;
}

.fourth-col {
  left: 166px;
  max-width: 112px;
  min-width: 112px;
}

.fifth-col {
  left: 278px;
  max-width: 80px;
  min-width: 80px;
}

.sixth-col {
  left: 358px;
  max-width: 90px;
  min-width: 90px;
}

/* фиксация колонки ТС у нижней таблицы */
.fix-ts-cell {
  position: sticky;
  z-index: 5;
  border: 1px solid #dee2e6;
  background-clip: padding-box;
}

.ts-col {
  left: 0px;
  max-width: 60px;
  min-width: 60px;
}
</style>