<template>
 <universal-selector v-model="value" :itemList="itemList"></universal-selector>
</template>

<script>
import UniversalSelector from "@/components/ui/custom/UniversalSelector";
export default {
  components: {UniversalSelector},
  props: ['model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },

    itemList() {
      let arr = [];
      arr.push({
        id: 0,
        name: 'Нет'
      })
      this.$store.getters['ref/getTarifZones'].map(item => {
        return {
          id: item.id,
          name: item.comm
        }
      });
      return arr;
    }
  },
}
</script>

<style scoped>

</style>