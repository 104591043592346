<template>

  <!-- Окружность -->
  <leaflet-circle
      v-if="isCorrect"
      :latlng="point"
      :radius="radius"
      :options="options"
      :contextMenu="contextMenu"
      :tooltip="tooltip"
      :events="events + ' drag dragend'"
      :targetComponent="this"
      :logTitle="logTitle"
      @onLeafletEvent="onLeafletCircleEvent($event)"
  >
  </leaflet-circle>

  <!--  Точки редактирования -->
  <leaflet-marker
      v-if="isCorrect && isEdited"
      :latlng="editPoint"
      :icon="editPointIcon"
      :options="editPointOptions"
      events="drag dragend"
      :logTitle="logTitle+'-RadiusEditPoint'"
      @onLeafletEvent="onLeafletMarkerEvent($event)"
  >
  </leaflet-marker>

</template>

<script>

// ЭЛЕМЕНТ ЗОНЫ В ВИДЕ КРУГА

// interface ZONE_ELEMENT {
//   zone_element_id: number;
//   zone_element_type_id: number;
//   zone_id: number;
//   order_num: number;
//   parameter_size: number;
//   points: ZONE_ELEMENT_POINT[];
// }

// interface ZONE_ELEMENT_POINT {
//   zone_element_id: number;
//   order_num: number;
//   latitude: number;
//   longitude: number;
// }

// опции линии
import LeafletMapMixin from "@/components/ui/leaflet/mixins/LeafletMapMixin";
import {divIcon} from "leaflet";
import LeafletEventedMixin from "@/components/ui/leaflet/mixins/LeafletEventedMixin";
import {addLong, toLatLongArr} from "@/components/ui/leaflet/LeafletHelpers";
import {toLatLng} from "leaflet/src/geo/LatLng";

export default {
  mixins: [LeafletMapMixin, LeafletEventedMixin],
  emits: ['onZoneElementChanged'],
  props: {
    // состояние элемента зоны: original - обычное, active - активное, edit - редактируемое, disabled - отключенное
    state: {
      type: String,
      default: 'original'
    },
    // описание элемента зоны
    zoneElement: {
      required: true,
    },
    // всплывающая подсказка слоя
    tooltip: {
      type: String
    },
    // контекстное меню слоя
    contextMenu: {
      type: Object
    },
    // опции элемента зоны в стандартном режиме
    originalOptions: {
      type: Object,
      default: () => {
        return {
          color: 'red',
          weight: 0.0,
          opacity: 0.0,
          fill: true,
          fillColor: 'red',
          fillOpacity: 0.3,
          draggable: false
        }
      }
    },
    // опции элемента заны в активном режиме
    activeOptions: {
      type: Object,
      default: () => {
        return {
          color: 'black',
          weight: 1.0,
          opacity: 1.0,
          fill: true,
          fillColor: 'red',
          fillOpacity: 0.6,
          draggable: false
        }
      }
    },
    // опции элемента зоны в режиме редактирования
    editOptions: {
      type: Object,
      default: () => {
        return {
          color: 'black',
          weight: 2.0,
          opacity: 1.0,
          fill: true,
          fillColor: 'red',
          fillOpacity: 0.6,
          draggable: true
        }
      }
    },
    // опции элемента заны в отключенном режиме
    disabledOptions: {
      type: Object,
      default: () => {
        return {
          color: 'grey',
          weight: 0.0,
          opacity: 0.0,
          fill: true,
          fillColor: 'grey',
          fillOpacity: 0.3,
          draggable: false
        }
      }
    },
    // иконка маркеров редактирования
    editPointIcon: {
      type: Object,
      default: () => {
        return divIcon({
          html: '<div style="width: 100%; height: 100%; border: 0px black solid; margin : 0px; padding: 0px; background-color: white"></div>',
          iconSize: [10, 10],
          iconAnchor: [5, 5]
        })
      }
    },
    // опции маркеров редактирования
    editPointOptions: {
      type: Object,
      default: () => {
        return {
          draggable: true,
          clickable: true
        }
      }
    },
  },

  data() {
    return {
      // тип объекта
      leafletObjectType: 'CircleZoneElement',
    }
  },

  computed: {
    // логирование
    logTitle() {
      return `CircleZoneElement(#${this.zoneElement.zone_element_id})`
    },
    // корректность данных
    isCorrect() {
      return this.zoneElement &&
          this.zoneElement.points &&
          this.zoneElement.points[0] &&
          Number.isFinite(this.zoneElement.parameter_size) &&
          this.zoneElement.zone_element_type_id === 1
    },
    // цент круга
    point() {
      return [this.zoneElement.points[0].latitude,this.zoneElement.points[0].longitude];
    },
    // точка редактирования
    editPoint() {
      return addLong(this.point, this.radius)
    },
    // радиус
    radius() {
      return this.zoneElement.parameter_size;
    },
    // опции элемента зоны
    options() {
      if (this.state === 'disabled') return this.disabledOptions
        else if (this.state === 'edit') return this.editOptions
          else if (this.state === 'active') return this.activeOptions
            else return this.originalOptions;
    },
    // признак режима редактирования
    isEdited() {
      return this.state === 'edit';
    }
  },

  methods: {
    // события от маркеров редактирования
    onLeafletMarkerEvent(event) {
      // конец перемещения
      if (event.type === 'drag' && this.isEdited) {
        let radius = event.target.getLatLng().distanceTo(toLatLng(this.point));
        if (radius < 1) radius = 1;
        // информируем об изменении
        this.$emit('onZoneElementChanged', this.getZoneElement(null, radius), false);
      }
      // конец перемещения
      else if (event.type === 'dragend' && this.isEdited) {
        let radius = event.target.getLatLng().distanceTo(toLatLng(this.point));
        if (radius < 1) radius = 1;
        // информируем об изменении
        this.$emit('onZoneElementChanged', this.getZoneElement(null, radius), true);
      }
    },

    // события от самого элемента зоны
    onLeafletCircleEvent(event) {
      // перетаскивание фигуры
      if (event.type === 'drag' && this.isEdited) {
        // информируем об изменении
        this.$emit('onZoneElementChanged', this.getZoneElement(toLatLongArr(event.target.getLatLng())), false);
      }
      else if (event.type === 'dragend' && this.isEdited) {
        // информируем об изменении
        this.$emit('onZoneElementChanged', this.getZoneElement(toLatLongArr(event.target.getLatLng())), true);
      }

      // проверяем и вызываем внешние события
      if (this.eventsToArray(this.events).includes(event.type)) {
        this.$emit('onLeafletEvent', event);
      }
    },

    // возвращает структуру элемента зоны
    getZoneElement(point, radius) {
      if (!point) point = this.point;
      if (!radius) radius = this.radius;

      return {
        zone_element_id: this.zoneElement.zone_element_id,
        zone_element_type_id: 1,
        zone_id: this.zoneElement.zone_id,
        order_num: this.zoneElement.order_num,
        parameter_size: Math.round(radius),
        points: [
          {
            zone_element_id: this.zoneElement.zone_element_id,
            order_num: 1,
            latitude: Number(point[0]),
            longitude: Number(point[1]),
          }
        ]
      }
    }
  },
}
</script>
