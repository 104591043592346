<template>
  <teleport to="body">
    <div class="modal show" style="display: block">
      <slot></slot>
    </div>
    <div
        v-if="backdropType !== 'none'"
        :style="backdropStyle"
        class="modal-backdrop show"
    ></div>
  </teleport>
</template>

<script>
export default {
  props: [
    // тип задника: none - отсутсвует, transparent - прозрачный, по умолчанию - черный
    'backdrop-type'
  ],
  computed: {
    backdropStyle() {
      if (this.backdropType === 'transparent')
        return {
          opacity: '0'
        }
      else
        return {}
    }
  }
}
</script>
