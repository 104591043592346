<template>
  <universal-selector
      v-model="value"
      :caption="this.$t('nsi.RsTypeSelectorCaption')"
      :itemList="itemList"
  ></universal-selector>
</template>

<script>
import UniversalSelector from "@/components/ui/custom/UniversalSelector";
export default {
  name: "RsTypeSelector",
  components: {UniversalSelector},
  props: ['modelValue'],

  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },

    itemList() {
      return this.$store.getters['ref/getRsTypes'].map(item => {
        return {
          id: item.rst_stationtype,
          name: item.rst_title
        }
      });
    }
  },
}
</script>

<style scoped>

</style>