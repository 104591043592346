<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-info text-white">
          <modal-title>{{ $t("nsi.NsiRadioButtonAdd") }}</modal-title>
          <button-close class="text-white" @click="$emit('onCancelModel')"></button-close>
        </modal-header>
        <modal-body style="padding: 0; overflow-y: hidden">
          <div class="form-group" style="padding-right: 20px; padding-left: 20px; padding-top: 10px">
            <label>{{ $t("nsi.ModelDialog.ModelTitleLabel") }}</label>
            <input-string
                class="form-control"
                id="md_title"
                v-model="md_title"
            />
          </div>

          <div class="form-group" style="padding-right: 20px; padding-left: 20px">
            <label>{{ $t("nsi.ModelDialog.ModelPassengerLabel") }}</label>
            <input-float
                class="form-control"
                id="md_passenger"
                v-model="md_passenger"
            />
          </div>

          <div class="form-group" style="padding-right: 20px; padding-left: 20px">
            <label>{{ $t("nsi.ModelDialog.SeatCountLabel") }}</label>
            <input-integer
                class="form-control"
                id="seatCount"
                v-model="seatCount"
            />
          </div>

          <div class="form-group" style="padding-right: 20px; padding-left: 20px; ">
            <label>{{ $t("nsi.ModelDialog.DoorCountLabel") }}</label>
            <universal-selector
                class="form-control"
                id="doorCount"
                v-model="doorCount"
                :itemList="
            [
              {
                id: '1',
                name: '1'
              },
              {
                id: '2',
                name: '2'
              },
              {
                id: '3',
                name: '3'
              },
              {
                id: '4',
                name: '4'
              },
            ]"
            >
            </universal-selector>
          </div>

        </modal-body>
        <modal-footer>
          <button @click="onSave" class="btn btn-info">{{ $t("nsi.NsiButtonSave") }}</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>

</template>

<script>

import UniversalSelector from "@/components/ui/custom/UniversalSelector";
import InputString from "@/components/ui/custom/InputString";
import InputInteger from "@/components/ui/custom/InputInteger";
import InputFloat from "@/components/ui/custom/InputFloat";

export default {
  components: {InputFloat, InputInteger, InputString, UniversalSelector},
  props: ['cap_id', 'tt_id'],
  emits: ['onSaveModel', 'onCancelModel'],
  data() {
    return {
      md_id: 0,
      md_title: '',
      md_passenger: '',
      seatCount: '',
      doorCount: '1',
    }
  },


  methods: {

    onSave() {
      this.$emit('onSaveModel', {
        md_id: 0,
        md_title: this.md_title,
        md_passenger: this.md_passenger,
        pk_id: this.$store.getters['ref/getUserPark'],
        cap_id: this.cap_id,
        tt_id: this.tt_id,
        identity: this.$store.getters['ref/getParks'].find(value => value.parkId == [this.$store.getters['ref/getUserPark']]).identity,
        seatCount: this.seatCount,
        doorCount: this.doorCount,
      })
    },
  }
}
</script>

<style scoped>

</style>