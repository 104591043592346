<template>
  <div>
    <universal-selector
        v-model="value"
        :itemList="parksArr"
        :caption="this.$t('ParkSelector')"
    ></universal-selector>
  </div>
</template>

<script>

import UniversalSelector from "./UniversalSelector.vue";

export default {
  props: [
      'modelValue'
  ],
  components: {
    UniversalSelector
  },

  //объявление переменных объекта
  // data() {
  //   return {
  //     // выбранное значение
  //     selectedPark: null,
  //   }
  // },

  computed: {

    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$store.commit('ref/setUserPark', value);
        // this.$store.commit('ref/setStatInit', []);
        // this.$store.commit('ref/setStatInitItog', []);
        // this.$store.commit('repf/setInspReisesPassFlowIndicators', []);
        this.$emit('update:model-value', value);
      }
    },

    parksArr() {
      return this.$store.getters['ref/getParks'].map(value => {
        return {
          id: value.parkId,
          name: value.parkTitleLong,
        }
      })
    },

    // getParkCaption() {
    //   let variants = this.$store.getters['getValues']
    //   if (variants != null) {
    //       return variants.parkCaption
    //   } else {
    //     return ''
    //   }
    // },

  },

}

</script>

<style scoped>

</style>