import {createStore} from "vuex";
import ref_store from "./ref/ref_store"
// import rawdata_store from "./raw/data_from_file_store"
import MainStore from './main/MainStore';
import SettingsStore from "@/store/settings/SettingsStore";
import MessageStore from './message/MessageStore';
import proc_store from "@/store/proc/proc_store";
import umkr_store from "@/store/umkr/umkr_store";
import rep_store from "@/store/rep/rep_store";
import user_params_store from "@/store/main/user_params_store";

export default createStore({
    modules:{
        ref: ref_store,
        proc: proc_store,
        umkr: umkr_store,
        rep: rep_store,
        // raw:rawdata_store,
        MainStore,
        settings: SettingsStore,
        MessageStore,
        user_params_store,

    }
})