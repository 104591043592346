// ХРАНИЛИЩЕ СТАТИСТИКИ
import {DoPost} from "@/store/Requests";
import store from '@/store/Store';

export default {
    state() {
        return {
            // список строк
            procStatistic: [],
        }
    },
    getters: {
        // вернуть список объектов
        getProcStatistic(state) {
            return state.procStatistic;
        },
    },
    mutations: {
        // установить новый список объектов
        setProcStatistic(state, payload) {
            state.procStatistic = payload;
        },
    },
    actions: {

        // получаем статистику
        async doGetProcStatistic() {
            // кодируем данные для корректного отображения русского текста на сервере
            return await DoPost(store.getters['settings/getBaseUrl'] +
                '/ProcessingRestController/getProcStatisticRemote',
                null,
                'proc/setProcStatistic',
                '',
            );
        },

        // удаляем сохраненную на сервере статистику
        async doReleaseSessionInfo() {
            // кодируем данные для корректного отображения русского текста на сервере
            return await DoPost(store.getters['settings/getBaseUrl'] +
                '/ProcessingRestController/releaseSessionInfoRemote',
                null,
                null,
                '',
            );
        },

    }
}
