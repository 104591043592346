<template>
 <universal-selector v-model="value" :caption="this.$t('nsi.TransportTypeSelectorCaption')" :itemList="itemList"></universal-selector>
</template>

<script>
import UniversalSelector from "@/components/ui/custom/UniversalSelector";
export default {
  name: "TransportTypeSelector",
  components: {UniversalSelector},
  emits: ['change', 'update:model-value'],
  props: ['model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
        this.$emit('change', value)
      }
    },

    itemList() {
      return this.$store.getters['ref/getTransTypes'].map(item => {
        return {
          id: item.tt_id,
          name: item.tt_title
        }
      });
    }
  },
}
</script>

<style scoped>

</style>