<template>
  <align-container style="height: 100%;">
    <align-panel align="top" width="3rem">
      <nav-menu class="nav-tabs" mode="single">
        <nav-item>
          <nav-link target-ref="pane1" target-method="select" active @click="tab = 0">
            {{ $t("nsi.TsDialog.TabTitle_1") }}
          </nav-link>
        </nav-item>
        <nav-item>
          <nav-link target-ref="pane2" target-method="select" @click="tab = 1">
            {{ $t("nsi.TsDialog.TabTitle_2") }}
          </nav-link>
        </nav-item>
      </nav-menu>
    </align-panel>

    <align-panel align="all" width="3rem">
      <tab-content mode="single" style="height: 100%">
        <tab-pane active ref="pane1" class="p-2" style="height: 100%; padding:0 !important;">
          <div class="card bg-light"
               style="height: 100%; overflow-x: hidden; overflow-y: hidden;">
            <form style="height: 95%; overflow-x: hidden; overflow-y: auto;">
              <div class="form-group row">
                <div class="col" style="margin-left:10px; margin-top:10px;">
                  <input type="radio" class="green-radio" name="inlineRadioOptions" id="inlineRadio1" value="add"
                         v-model="selectedMode" style="height:16px; width:16px; vertical-align: middle; margin-right:5px">
                  <label for="inlineRadio1" style="margin-bottom: 0; margin-top: 5px">{{ $t("nsi.NsiRadioButtonAdd") }}</label>
                </div>
                <div class="col" style="text-align: right; margin-right:10px; margin-top:10px">
                  <input type="radio" class="green-radio" name="inlineRadioOptions" id="inlineRadio2" value="edit"
                         v-model="selectedMode" style="height:16px; width:16px; vertical-align: middle; margin-right:5px">
                  <label for="inlineRadio2" style="margin-bottom: 0; margin-top: 5px">{{ $t("nsi.NsiRadioButtonEdit") }}</label>
                </div>
              </div>

              <div class="form-group" style="padding-right: 20px; padding-left: 20px; margin-bottom: 0px">
                <label for="cap_id">{{ $t("nsi.TsDialog.CapacityLabel") }}</label>
                <capacity-selector
                    id="cap_id"
                    class="form-control"
                    v-model="cap_id"
                    @change="getModelsByNewCap"
                >
                </capacity-selector>
              </div>

              <label for="md_id" style="margin-left: 20px">{{ $t("nsi.TsDialog.ModelLabel") }}</label>
              <div class="form-group row" style="padding-right: 20px; padding-left: 36px; margin-bottom: 0px">
                <div class="col-0" style="margin: 0;width: 273px">
                  <model-selector
                      id="md_id"
                      class="form-control"
                      v-model="md_id"
                  >
                  </model-selector>
                </div>
                <div class="col-0" style="margin: 0 0 0 5px;">
                  <button
                      type="button"
                      class="btn btn-info"
                      :title="this.$t('nsi.TsDialog.ButtonAddModelTitle')"
                      @click="setModelDialogVisible"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>


              <div class="form-group" style="padding-right: 20px; padding-left: 20px; margin: 0px">
                <label>{{ $t("nsi.TsDialog.GaragNumLabel") }}</label>
                <input-integer
                    class="form-control"
                    id="gar_num"
                    v-model="gar_num"
                />
              </div>

              <div class="form-group" style="padding-right: 20px; padding-left: 20px; margin: 0px">
                <label>{{ $t("nsi.TsDialog.StateNumLabel") }}</label>
                <input-string
                    class="form-control"
                    id="stateNum"
                    v-model="stateNum"
                />
              </div>

              <div class="form-group" style="padding-right: 20px; padding-left: 20px; margin: 0px">
                <label for="balance">{{ $t("nsi.TsDialog.BalanceTypeLabel") }}</label>
                <universal-selector
                    id="balance"
                    class="form-control"
                    v-model="balance"
                    :caption="this.$t('nsi.TsDialog.BalanceTypeCaption')"
                    :itemList="
            [
              // BALANCE_BY_MIN
              {
              id: '1',
              name: this.$t('nsi.TsDialog.BalanceByMin')
              },
              // BALANCE_BY_AVG
              {
              id: '2',
              name: this.$t('nsi.TsDialog.BalanceByAvg')
              },
              // BALANCE_BY_MAX
              {
              id: '3',
              name: this.$t('nsi.TsDialog.BalanceByMax')
              },
            ]"
                >
                </universal-selector>
              </div>

              <!--      <div class="form-group" style="padding-right: 20px; padding-left: 20px; margin: 0px">-->
              <!--        <label for="dist_type">Тип расчета пробега</label>-->
              <!--        <universal-selector-->
              <!--            id="dist_type"-->
              <!--            class="form-control"-->
              <!--            v-model="dist_type"-->
              <!--            caption="Выберите тип расчета пробега..."-->
              <!--            :itemList="-->
              <!--            [-->
              <!--              {-->
              <!--              id: 'Одометр',-->
              <!--              name: 'Одометр'-->
              <!--              },-->
              <!--              {-->
              <!--              id: 'Навигация',-->
              <!--              name: 'Навигация'-->
              <!--              },-->
              <!--              {-->
              <!--              id: 'Граф',-->
              <!--              name: 'Граф'-->
              <!--              },-->
              <!--            ]"-->
              <!--        >-->
              <!--        </universal-selector>-->
              <!--      </div>-->

              <div class="form-group row" style="padding-right: 20px; padding-left: 20px; margin-top: 8px; margin-bottom: 8px">
                <div class="form-group col" style="margin: 0">
                  <input-checkbox
                      id="isTailSelected"
                      v-model="isTailSelected"
                      style="margin-bottom: 0px; margin-left: 5px; margin-top: 5px"
                      @change="changeTailId"
                  >{{ $t("nsi.TsDialog.TailLabel") }}
                  </input-checkbox>
                </div>
                <div class="form-group col" style="padding-left: 0; margin-bottom: 0px">
                  <t-s-selector
                      class="form-control"
                      id="tail_id"
                      v-model="tail_id"
                      :disabled="!isTailSelected"
                      :selectedTs="item"
                      style="padding: 0"
                  >
                  </t-s-selector>
                </div>
              </div>

              <div class="card bg-light"
                   style="overflow-x: hidden; overflow-y: hidden; margin-right: 10px; margin-left: 10px; ">
                <div class="form-group" style="font-weight: bold; padding-left: 10px; margin-bottom: 8px">
                  {{ $t("nsi.TsDialog.BalanceParamsLabel") }}
                </div>

                <div class="form-group row" style="padding-right: 20px; padding-left: 20px; margin: 0px; height: 20px">
                  <div class="form-group col" style="margin: 0px;">
                  </div>
                  <div class="form-group col" style="padding-left: 0; margin: 0px;">
                    <label>{{ $t("nsi.TsDialog.Door1Label") }}</label>
                  </div>
                  <div class="form-group col" style="padding-left: 0; margin: 0px;">
                    <label>{{ $t("nsi.TsDialog.Door2Label") }}</label>
                  </div>
                  <div class="form-group col" style="padding-left: 0; margin: 0px;">
                    <label>{{ $t("nsi.TsDialog.Door3Label") }}</label>
                  </div>
                  <div class="form-group col" style="padding-left: 0; margin: 0px;">
                    <label>{{ $t("nsi.TsDialog.Door4Label") }}</label>
                  </div>
                </div>

                <div class="form-group row" style="padding-right: 20px; padding-left: 20px; margin: 0px; height: 24px;">
                  <div class="form-group col" style="margin: 0px; padding: 0">
                    <label style="width: 75px">{{ $t("nsi.TsDialog.PsgrsInLabel") }}</label>
                  </div>
                  <div class="form-group col" style="padding-left: 0; margin: 0px;">
                    <input-checkbox
                        id="d1In"
                        v-model="d1In"
                    >
                    </input-checkbox>
                  </div>
                  <div class="form-group col" style="padding-left: 0; margin: 0px;">
                    <input-checkbox
                        id="d2In"
                        v-model="d2In"
                    >
                    </input-checkbox>
                  </div>
                  <div class="form-group col" style="padding-left: 0; margin: 0px;">
                    <input-checkbox
                        id="d3In"
                        v-model="d3In"
                    >
                    </input-checkbox>
                  </div>
                  <div class="form-group col" style="padding-left: 0; margin: 0px;">
                    <input-checkbox
                        id="d4In"
                        v-model="d4In"
                    >
                    </input-checkbox>
                  </div>
                </div>

                <div class="form-group row" style="padding-right: 20px; padding-left: 20px; margin: 0px; height: 24px">
                  <div class="form-group col" style="margin: 0px; padding: 0;">
                    <label style="width: 75px">{{ $t("nsi.TsDialog.PsgrsOutLabel") }}</label>
                  </div>
                  <div class="form-group col" style="padding-left: 0; margin: 0px;">
                    <input-checkbox
                        id="d1Out"
                        v-model="d1Out"
                    >
                    </input-checkbox>
                  </div>
                  <div class="form-group col" style="padding-left: 0; margin: 0px;">
                    <input-checkbox
                        id="d2Out"
                        v-model="d2Out"
                    >
                    </input-checkbox>
                  </div>
                  <div class="form-group col" style="padding-left: 0; margin: 0px;">
                    <input-checkbox
                        id="d3Out"
                        v-model="d3Out"
                    >
                    </input-checkbox>
                  </div>
                  <div class="form-group col" style="padding-left: 0; margin: 0px;">
                    <input-checkbox
                        id="d4Out"
                        v-model="d4Out"
                    >
                    </input-checkbox>
                  </div>
                </div>

                <div class="form-group" style="padding-right: 10px; padding-left: 10px; margin: 0px">
                  <input-checkbox
                      id="changeDoorModeByAllTs"
                      v-model="changeDoorModeByAllTs"
                      style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px; max-width: 300px"
                  >{{ $t("nsi.TsDialog.ChangeDoorModeByAllTs") }}
                  </input-checkbox>
                </div>

              </div>

              <div class="form-group" style="padding-right: 20px; padding-left: 20px; margin-bottom: 8px">
                <label for="apcTypeID">{{ $t("nsi.TsDialog.ApcTypeLabel") }}</label>
                <universal-selector
                    class="form-control"
                    id="apcTypeID"
                    v-model="apcTypeID"
                    :caption="this.$t('nsi.TsDialog.ChooseApcTypeCaption')"
                    :itemList="
                    [
                      {
                      id: '0',
                      name: '-'
                      },
                    ]"
                >
                </universal-selector>
              </div>

              <!--      <div class="form-group" style="padding-right: 10px; padding-left: 10px; margin-bottom: 0px">-->
              <!--        <input-checkbox-->
              <!--            id="marsh_fl"-->
              <!--            v-model="marsh_fl"-->
              <!--            style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px; max-width: 300px"-->
              <!--        >Маршрутизированное движение-->
              <!--        </input-checkbox>-->
              <!--      </div>-->

              <!--      <div class="form-group" style="padding-right: 10px; padding-left: 10px; margin-bottom: 0px">-->
              <!--        <input-checkbox-->
              <!--            id="asmpp_fl"-->
              <!--            v-model="asmpp_fl"-->
              <!--            style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px; max-width: 300px"-->
              <!--        >АСМ-ПП-->
              <!--        </input-checkbox>-->
              <!--      </div>-->

              <!--      <div class="form-group" style="padding-right: 10px; padding-left: 10px; margin-bottom: 0px">-->
              <!--        <input-checkbox-->
              <!--            id="onedir_fl"-->
              <!--            v-model="onedir_fl"-->
              <!--            style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px; max-width: 300px"-->
              <!--        >Однонаправленный подсчет-->
              <!--        </input-checkbox>-->
              <!--      </div>-->

              <div class="form-group" style="padding-right: 10px; padding-left: 10px; margin-bottom: 0px">
                <input-checkbox
                    id="tsdel_fl"
                    v-model="tsdel_fl"
                    style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px; max-width: 300px"
                >{{ $t("nsi.TsDialog.CheckboxTsDelete") }}
                </input-checkbox>
              </div>

              <!--      <div class="form-group" style="padding-right: 10px; padding-left: 10px; margin-bottom: 0px">-->
              <!--        <input-checkbox-->
              <!--            id="isOldHiton"-->
              <!--            v-model="isOldHiton"-->
              <!--            style="margin-bottom: 0px; margin-left: 5px; margin-top: 0px; max-width: 300px"-->
              <!--        >АСМ-ПП типа Advanced-->
              <!--        </input-checkbox>-->
              <!--      </div>-->

            </form>
            <div class="form-group row">
              <div class="col" style="margin-left: 20px">
                <button @click="onSave" class="btn btn-info">{{ $t("nsi.NsiButtonSave") }}</button>
              </div>
            </div>
          </div>
        </tab-pane>

        <tab-pane ref="pane2" class="p-2" style="height: 100%; padding:0 !important;">
          <div class="card bg-light"
               style="height: 100%; overflow-x: hidden; overflow-y: hidden;">
            <form style="height: 95%; overflow-x: hidden; overflow-y: auto;">

              <label for="rs_num_id" style="margin-left: 20px; margin-top: 10px;">{{ $t("nsi.TsDialog.RadiostationLabel") }}</label>
              <div class="form-group row">
                <div class="col" style="margin-left: 20px; padding-right: 0px;">
                  <rs-num-selector
                      id="rs_num_id"
                      class="form-control"
                      v-model="rs_num_id"
                  >
                  </rs-num-selector>
                </div>
                <div class="col-2" style="text-align: right; margin-right: 20px">
                  <button
                      type="button"
                      class="btn btn-info"
                      :title="this.$t('nsi.TsDialog.ButtonAddRsTitle')"
                      @click="setRsNumDialogVisible"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>


            </form>
            <div class="form-group row">
              <div class="col" style="margin-left: 20px">
                <button @click="onLinkRsToTs" class="btn btn-info">{{ $t("nsi.TsDialog.ButtonLinkTitle") }}</button>
              </div>
              <div class="col" style="text-align: right; margin-right: 20px">
                <button @click="onDelLinkRsToTs" class="btn btn-info">{{ $t("nsi.TsDialog.ButtonDelLinkTitle") }}</button>
              </div>
            </div>
          </div>
        </tab-pane>
      </tab-content>
    </align-panel>

  </align-container>



  <model-dialog
      v-if="isModelDialogVisible"
      :cap_id="cap_id"
      :tt_id="selectedTransportTypeId"
      @onCancelModel="setModelDialogInvisible"
      @onSaveModel="addModel"
  >
  </model-dialog>

  <rs-num-dialog
      v-if="isRsNumDialogVisible"
      @onCancelRsNumDialog="setRsNumDialogInvisible"
      @onSaveRsNum="addRsNum"
  >
  </rs-num-dialog>

  <error-message-box
      v-if="errorMessage != ''"
      @onOK="closeErrorMessage"
  > {{ errorMessage }}
  </error-message-box>

</template>

<script>

import InputString from "@/components/ui/custom/InputString";
import UniversalSelector from "@/components/ui/custom/UniversalSelector";
import InputCheckbox from "@/components/ui/custom/InputCheckbox";
import InputInteger from "@/components/ui/custom/InputInteger";
import ModelSelector from "@/components/references/model/ModelSelector";
import CapacitySelector from "@/components/references/capacity/CapacitySelector";
import TSSelector from "@/components/references/ts/TSSelector";
import ModelDialog from "@/components/references/model/ModelDialog";
import ErrorMessageBox from "@/components/ui/custom/ErrorMessageBox";
import RsNumDialog from "@/components/references/rs_num/RsNumDialog";
import RsNumSelector from "@/components/references/rs_num/RsNumSelector";

export default {
  name: "TsDialog",
  components: {
    RsNumDialog,
    ErrorMessageBox,
    ModelDialog,
    TSSelector,
    CapacitySelector,
    ModelSelector, RsNumSelector,
    InputInteger,
    InputCheckbox, UniversalSelector, InputString
  },
  props: ['item'],
  emits: ['onSave', 'onEdit', 'onLinkRs', 'onDeleteLinkRs'],
  computed: {

    getMaxDoorCount() {
      if (this.getDoorValue(this.d4In, this.d4Out) != 0) {
        return 4;
      } else if (this.getDoorValue(this.d3In, this.d3Out) != 0) {
        return 3;
      } else if (this.getDoorValue(this.d2In, this.d2Out) != 0) {
        return 2;
      } else if (this.getDoorValue(this.d1In, this.d1Out) != 0) {
        return 1;
      } else {
        return 0;
      }
    },

    selectedTransportTypeId() {
      return this.$store.getters['ref/getSelectedTransportType'];
    }

  },
  watch: {

    item(newValue) {
      if (newValue) {
        this.selectedMode = 'edit'
      } else {
        this.selectedMode = 'add'
      }

        this.tt_id = newValue ? newValue.tt_id : null;

        this.gar_num = newValue ? newValue.ts_garagnum : null;
        this.stateNum = newValue ? newValue.ts_statenum : '';
        this.cap_id =  newValue ?newValue.cap_id : null;
        this.md_id =  newValue ? newValue.md_id : null;
        this.getModels();

        this.balance = newValue ? newValue.balance : null;
        this.dist_type = newValue ? newValue.distance_type : null;
        this.tail_id = newValue ? newValue.tail_id : null;
        if (newValue && (newValue.tail_id == 0 || newValue.tail_id == null)) {
          this.tail_id = null;
          this.isTailSelected = false;
        } else {
          this.isTailSelected = true;
        }

        this.d1In = newValue ? this.getInDoorValue(newValue.door1) : false;
        this.d1Out = newValue ? this.getOutDoorValue(newValue.door1) : false;
        this.d2In = newValue ? this.getInDoorValue(newValue.door2) : false;
        this.d2Out = newValue ? this.getOutDoorValue(newValue.door2) : false;
        this.d3In = newValue ? this.getInDoorValue(newValue.door3) : false;
        this.d3Out = newValue ? this.getOutDoorValue(newValue.door3) : false;
        this.d4In = newValue ? this.getInDoorValue(newValue.door4) : false;
        this.d4Out = newValue ? this.getOutDoorValue(newValue.door4) : false;

        this.changeDoorModeByAllTs = newValue ? newValue.changeDoorModeByAllTs : false;
        this.apcTypeID = newValue ? newValue.apcTypeID : null;
        this.marsh_fl = newValue ? newValue.marsh_fl : null;
        this.asmpp_fl = newValue ? newValue.asmpp_fl : null;
        this.onedir_fl = newValue ? newValue.onedir_fl : null;
        this.tsdel_fl = newValue ? newValue.ts_deleted : null;
        this.isOldHiton = newValue ? newValue.isOldHiton : null;

        let rsNum = this.$store.getters['ref/getRsNums'].find(value => (value.rs_stationnum == newValue.rsNum.rs_stationnum && value.rst_typename == newValue.rsNum.rst_typename));
        this.rs_num_id = rsNum ? rsNum.id : null;
    }

  },

  data() {
    return {
      isModelDialogVisible: false,
      errorMessage: '',

      isRsNumDialogVisible: false,

      selectedMode: 'add',
      gar_num: null,
      stateNum: null,
      cap_id: null,
      md_id: null,
      balance: null,
      dist_type: 'Навигация',

      isTailSelected: null,
      tail_id: null,

      d1In: false,
      d1Out: false,
      d2In: false,
      d2Out: false,
      d3In: false,
      d3Out: false,
      d4In: false,
      d4Out: false,
      changeDoorModeByAllTs: false,

      apcTypeID: null,
      marsh_fl: true,
      asmpp_fl: true,
      onedir_fl: false,
      tsdel_fl: false,
      isOldHiton: false,

      tt_id: null,

      rs_num_id: null,
    }
  },

  methods: {

    changeTailId() {
      if (this.isTailSelected == false) {
        this.tail_id = null;
      }
    },

    closeErrorMessage() {
      this.errorMessage = '';
    },

    setModelDialogInvisible() {
      this.isModelDialogVisible = false;
    },

    setModelDialogVisible() {
      this.isModelDialogVisible = true;
    },

    setRsNumDialogInvisible() {
      this.isRsNumDialogVisible = false;
    },

    setRsNumDialogVisible() {
      this.isRsNumDialogVisible = true;
    },

    async addModel(mark) {
      try {
        let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.$store.getters['ref/getUserPark']);
        let id = await this.$store.dispatch('ref/doAddModel', {
          park: park,
          mark: mark,
        })
        this.md_id = id;
      } finally {
        this.setModelDialogInvisible();
      }
    },

    async getModelsByNewCap() {
      this.md_id = null;
      this.tt_id = null;
      this.$store.commit('ref/setModels', []);
      await this.getModels();
    },

    async getModels() {
      this.$store.commit('ref/setSelectedCapacity', this.cap_id);
      let md = this.$store.getters['ref/getModels'].find(model => model.md_id == this.md_id);
      if(!md) {
        await this.$store.dispatch('ref/doGetModels', {
          park: this.$store.getters['ref/getParks'].find(park => park.parkId == this.$store.getters['ref/getUserPark']),
          trans_type: this.$store.getters['ref/getTransTypes'].find(value => value.tt_id == (this.tt_id ? this.tt_id : this.$store.getters['ref/getSelectedTransportType'])),
          capacity: this.$store.getters['ref/getCapacities'].find(value => value.cap_id == this.cap_id),
        });
      }
    },

    onSave() {

      if (!this.$store.getters['ref/getSelectedTransportType'] && this.selectedMode == 'add') {
        this.errorMessage = "Необходимо выбрать тип транспорта"
        return
      }

      // проверяем количество дверей
      let model = this.$store.getters['ref/getModels'].find(values => values.md_id == this.md_id);
      if (this.getMaxDoorCount != Number(model.doorCount)) {
        this.errorMessage =
            this.$t("nsi.TsDialog.DoorCountErrorMessage_1")
            + this.getMaxDoorCount
            + this.$t("nsi.TsDialog.DoorCountErrorMessage_2")
            + (Number(model.doorCount))
            + this.$t("nsi.TsDialog.DoorCountErrorMessage_3")
        return
      }

      if (this.isTailSelected && !this.tail_id) {
        this.errorMessage = this.$t("nsi.TsDialog.TailErrorMessage")
        return
      }


      if (this.selectedMode == 'add') {
        this.$emit('onSave', {
          ts_garagnum: this.gar_num,
          ts_statenum: this.stateNum,
          md_id: this.md_id,
          cap_id: this.cap_id,
          tt_id: this.$store.getters['ref/getSelectedTransportType'],
          pk_id: this.$store.getters['ref/getUserPark'],
          balance: this.balance,
          door1: this.getDoorValue(this.d1In, this.d1Out),
          door2: this.getDoorValue(this.d2In, this.d2Out),
          door3: this.getDoorValue(this.d3In, this.d3Out),
          door4: this.getDoorValue(this.d4In, this.d4Out),
          tail_id: this.tail_id,
          marsh_fl: this.marsh_fl,
          asmpp_fl: this.asmpp_fl,
          isOneDirCount: this.onedir_fl,
          ts_deleted: this.tsdel_fl,

          // добавляем заглушку радиостанции
          rsNum: {
            identity: 0,
            pk_id: 0,
            rs_channel: 0,
            rs_phonenum: "",
            rs_sim: "",
            rs_stationnum: -1,
            rst_stationtype: 0,
            rst_typename: "",
          },

          cap_name: this.$store.getters['ref/getCapacities'].find(value => value.cap_id == this.cap_id).cap_name,
          mk_name: this.$store.getters['ref/getModels'].find(value => value.md_id == this.md_id).md_title,
          ts_oldHiton: this.isOldHiton,
          distance_type: 'Навигация',
          distance_mileage: 0,

          distance_date: new Date(),
          distance_odometer: 0,
          door_open_code: 0,
          apcTypeID: this.apcTypeID,
          identity: this.$store.getters['ref/getParks'].find(value => value.parkId == [this.$store.getters['ref/getUserPark']]).identity,
        })
      } else {
        this.$emit('onEdit', {
          ts_id: this.item.ts_id,
          ts_garagnum: this.gar_num,
          ts_statenum: this.stateNum,
          md_id: this.md_id,
          cap_id: this.cap_id,
          tt_id: this.item.tt_id,
          pk_id: this.item.pk_id,
          balance: this.balance,
          door1: this.getDoorValue(this.d1In, this.d1Out),
          door2: this.getDoorValue(this.d2In, this.d2Out),
          door3: this.getDoorValue(this.d3In, this.d3Out),
          door4: this.getDoorValue(this.d4In, this.d4Out),
          tail_id: this.tail_id,
          marsh_fl: this.marsh_fl,
          asmpp_fl: this.asmpp_fl,
          isOneDirCount: this.onedir_fl,
          ts_deleted: this.tsdel_fl,

          // добавляем заглушку радиостанции
          rsNum: {
            identity: 0,
            pk_id: 0,
            rs_channel: 0,
            rs_phonenum: "",
            rs_sim: "",
            rs_stationnum: -1,
            rst_stationtype: 0,
            rst_typename: "",
          },

          cap_name: this.$store.getters['ref/getCapacities'].find(value => value.cap_id == this.cap_id).cap_name,
          mk_name: this.$store.getters['ref/getModels'].find(value => value.md_id == this.md_id).md_title,
          ts_oldHiton: this.isOldHiton,
          distance_type: this.dist_type,
          distance_mileage: this.item.distance_mileage,

          distance_date: this.item.distance_date,
          distance_odometer: this.item.distance_odometer,
          door_open_code: this.item.door_open_code,
          apcTypeID: this.apcTypeID,
          identity: this.item.identity,

          ts_time_zone_offset: this.item.ts_time_zone_offset,
          dispTsId: this.item.dispTsId,
          apiMgtID: this.item.apiMgtID,
        })
      }
    },

    getDoorValue(drIn, drOut) {
      let dr = 0;
      if (drIn == false && drOut == true) {
        dr = 1;
      }
      if (drIn == true && drOut == false) {
        dr = 2;
      }
      if (drIn == true && drOut == true) {
        dr = 3;
      }
      return dr
    },

    getInDoorValue(value) {
      if (value == 0 || value == 1) {
        return false;
      } else {
        return true;
      }
    },

    getOutDoorValue(value) {
      if (value == 0 || value == 2) {
        return false;
      } else {
        return true;
      }
    },

    async addRsNum(rsnum) {
      try {
        let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.$store.getters['ref/getUserPark']);
        await this.$store.dispatch('ref/doAddRsNum', {
          rs_num: rsnum,
          park: park
        })
      } finally {
        this.setRsNumDialogInvisible();
      }
    },

    // добавление связи тс - р/с
    async onLinkRsToTs() {
      // check insert availability
      if (this.item.rsNum.rs_stationnum > 0 ) {
        this.errorMessage = this.$t("nsi.TsDialog.LinkTsErrorMessage");
        return;
      }

      let rsNum = this.$store.getters['ref/getRsNums'].find(value => value.id == this.rs_num_id);
      let isInsertAvailable = await this.$store.dispatch('ref/doIsInsertRadioTransLinkAvailable', {
        rs_num: rsNum.rs_stationnum,
        rs_type: rsNum.rst_stationtype
      });

      if (!isInsertAvailable) {
        this.errorMessage = this.$t("nsi.TsDialog.RadioStationErrorMessage");
        return;
      }

      this.$emit('onLinkRs', {
        ts: this.item,
        rs_num: rsNum
      })
    },

    // удаление связи тс - р/с
    async onDelLinkRsToTs() {
      if (!this.item) {
        this.errorMessage = this.$t("nsi.TsDialog.ChooseTsMessage");
        return;
      }
      if (this.item.rsNum.rs_stationnum < 0 ) {
        this.errorMessage = this.$t("nsi.TsDialog.RadioTsErrorMessage");
        return;
      }

      this.$emit('onDeleteLinkRs', this.item);
    },

  }
}
</script>

<style scoped>

</style>