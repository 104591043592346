// ХРАНИЛИЩЕ ОСТАНОВОК ТРАССЫ
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            raceCards: [],
        }
    },
    getters: {
        // вернуть список парков
        getRaceCards(state) {
            return state.raceCards;
        },
    },
    mutations: {
        // установить новый список объектов
        setRaceCards(state, payload) {
            state.raceCards = payload;
        },

        // добавить остановки в список объектов
        addRaceCards(state, payload) {
            payload.forEach(value => {
                state.raceCards.push(value);
            })
        },

        // добавить объект
        addRaceCard(state, payload) {
            state.raceCards.push(payload)
        },

        // редактировать объект
        editRaceCard(state, payload) {
            let index = state.raceCards.findIndex(value => value.raceCardId == payload.raceCardId);
            if (index >= 0) {
                state.raceCards[index] = payload;
            }
        },

        // редактировать объект
        deleteRaceCard(state, payload) {
            let index = state.raceCards.findIndex(value => value.raceCardOrderby == payload.raceCardOrderby);
            if (index >= 0) {
                state.raceCards.splice(index,1);
            }
        },

        // редактировать объект
        deleteAllRaceCard(state) {
            state.raceCards = [];
        }
    },
    actions: {
        // добавление объекта
        async doGetAllRaceCards(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.park));
            params.append("jsonRace", JSON.stringify(payload.race));
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/RaceCardRestController/getCardsRemote',
                params.toString(),
                'ref/setRaceCards',
                'Получение остановок трассы'
             );
        },

        // получение объекта и добавление его в список
        async doGetRaceCards(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.park));
            params.append("jsonRace", JSON.stringify(payload.race));
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/RaceCardRestController/getCardsRemote',
                params.toString(),
                'ref/addRaceCards',
                'Получение остановок трассы'
            );
        },

        // получение объекта и добавление его в список
        async doAddRaceCard(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonRaceCardArr", JSON.stringify(payload.raceCards));
            params.append("jsonRace", JSON.stringify(payload.race));

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/RaceCardRestController/insertRaceCardsRemote',
                params.toString(),
                null,
                'Добавление остановки трассы'
            );

            context.commit('addRaceCard', payload.raceCards[0]);
        },

        // получение объекта и добавление его в список
        async doAddRaceCards(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonRaceCardArr", JSON.stringify(payload.raceCards));
            params.append("jsonRace", JSON.stringify(payload.race));
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/RaceCardRestController/insertRaceCardsRemote',
                params.toString(),
                null,
                'Добавление остановок трассы'
            );
            context.commit('setRaceCards', payload.raceCards);
        },

        async doEditRaceCard(context, payload) {
            const params = new URLSearchParams();
            params.append('jsonRaceCard', JSON.stringify(payload.raceCard));
            params.append('jsonRace', JSON.stringify(payload.race));

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/RaceCardRestController/updateCardRemote',
                params.toString(),
                null,
                'Редактирование остановки трассы'
            );

            context.commit('editRaceCard', payload.raceCard);
        },

        async doDeleteRaceCards(context, payload) {
            const params = new URLSearchParams();
            params.append('jsonRace', JSON.stringify(payload.race));

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/RaceCardRestController/deleteRaceCardsRemote',
                params.toString(),
                null,
                'Удаление остановок трассы'
            );

            if(payload.raceCard) {
                context.commit('deleteRaceCard', payload.raceCard);
            } else {
                context.commit('deleteAllRaceCard');
            }

        }

    }
}
