<template>
  <align-container>
    <align-panel align="all" style="overflow-y: auto" :gap="3">
      <marsh-variant-table
          :selectedRow="selectedMarshVariant"
          @setSelectedRow="setSelectedMarshVariant"
          @setDblClick="setSelectedMarshVariantForTab"
      >
      </marsh-variant-table>
    </align-panel>
    <align-panel align="right" style="min-width: 350px !important; overflow-x: hidden; overflow-y: hidden;">
      <marsh-variant-dialog
          style="overflow-y: auto"
          :item="selectedMarshVariant ? {...selectedMarshVariant} : null"
          :selectedMarsh="selectedMarsh"
          @onSave="onSaveMarshVariant"
          @onEdit="onEditMarshVariant"
          @onDelete="onDeleteMarshVariant"
      >
      </marsh-variant-dialog>
    </align-panel>
  </align-container>
</template>

<script>



import MarshVariantTable from "@/components/references/marsh_variant/MarshVariantTable";
import MarshVariantDialog from "@/components/references/marsh_variant/MarshVariantDialog";
export default {
  name: "MarshVariantPanel",
  components: {MarshVariantDialog, MarshVariantTable},
  emits: ['setSelectedDir'],
  computed: {

    // выбранный маршрут
    selectedMarsh() {
      return this.$store.getters['ref/getSelectedMarsh'];
    },

    // выбранный период действия
    selectedMarshVariant() {
      return this.$store.getters['ref/getSelectedMarshVariant'];
    },

  },
  methods: {

    setSelectedMarshVariant(value) {
      this.$store.commit('ref/setSelectedMarshVariant', value);
    },

    // по даблклику на таблице Периодов действия делаем запрос Трасс
    async setSelectedMarshVariantForTab(marshVariant) {
      this.$store.commit('ref/setSelectedMarshVariantForTab', marshVariant);
      this.$emit('setSelectedDir', 'races');
      let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.$store.getters['ref/getUserPark']);
      // запрос трасс по периоду действия
      await this.$store.dispatch('ref/doGetRaces', {
        marshVariant: marshVariant,
        marsh: this.selectedMarsh,
        park: park,
      })
    },

    async onSaveMarshVariant(marshVariant) {
      try {
        this.isLoading = true;
        // добавляем маршрут
        await this.$store.dispatch('ref/doAddMarshVariant', {
          marsh: this.selectedMarsh,
          marshVariant: marshVariant,
        });

      } finally {
        this.isLoading = false;
      }
    },

    async onEditMarshVariant(marshVariant) {
      try {
        this.isLoading = true;
        // редактируем периоды действия
        await this.$store.dispatch('ref/doEditMarshVariant', {
          marsh: this.selectedMarsh,
          marshVariant: marshVariant
        });

      } finally {
        this.isLoading = false;
      }
    },

    async onDeleteMarshVariant() {
      try {
        this.isLoading = true;
        //проверяем, есть ли ссылки на другие объекты
        let isExist = await this.$store.dispatch('ref/isExistsMarshVariantsLinks', {
          marshVariant: this.selectedMarshVariant,
        });
        if (isExist) {
          this.errorMessageText = this.$t("nsi.MarshVariantPanelErrorText");
          return;
        }
        // если можно удалить маршрут, удаляем его
        await this.$store.dispatch('ref/doDeleteMarshVariant', {
          marshVariant: this.selectedMarshVariant,
          marsh: this.selectedMarsh,
        });

      } finally {
        this.isLoading = false;
      }
    },

  }
}
</script>

<style scoped>

</style>