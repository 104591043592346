import {divIcon} from "leaflet";

export default [
    [
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [0, 0],
            className: 'stop-icon'
        }),
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [16, 0],
            className: 'stop-icon'
        }),
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [32, 0],
            className: 'stop-icon'
        }),
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [48, 0],
            className: 'stop-icon'
        }),
    ],
    [
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [0, 16],
            className: 'stop-icon'
        }),
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [16, 16],
            className: 'stop-icon'
        }),
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [32, 16],
            className: 'stop-icon'
        }),
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [48, 16],
            className: 'stop-icon'
        }),
    ],
    [
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [0, 32],
            className: 'stop-icon'
        }),
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [16, 32],
            className: 'stop-icon'
        }),
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [32, 32],
            className: 'stop-icon'
        }),
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [48, 32],
            className: 'stop-icon'
        }),
    ],
    [
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [0, 48],
            className: 'stop-icon'
        }),
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [16, 48],
            className: 'stop-icon'
        }),
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [32, 48],
            className: 'stop-icon'
        }),
        divIcon({
            html: '<span></span>',
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            tooltipAnchor: [8, 0],
            shadowSize: [0, 0],
            shadowAnchor: [0, 0],
            popupAnchor: [0, -11],
            bgPos: [48, 48],
            className: 'stop-icon'
        }),
    ],
]
