<template>
  <bootstrap-modal backdrop-type="transparent">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-body text-center bg-bg-light border rounded">
          <div class="spinner-border text-primary"></div>
            <h6 class="text-primary" style="margin:0px">{{ $t("LoadingModalTitle") }}</h6>
            <h6 class="text-primary" style="margin:0px">
              {{ loadingText }}
            </h6>
        </div>
      </div>
    </div>
  </bootstrap-modal>
</template>

<script>

import BootstrapModal from "@/components/ui/bootstrap/modal/BootstrapModal";

export default {

  props: ['loadingText'],

  components: {
    BootstrapModal,
  },

}

</script>
