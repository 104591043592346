// ХРАНИЛИЩЕ ОСТАНОВОК ТРАССЫ
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            tsArr: [],
            // // список тер. образований
            tsArrCache: null,
            // время жизни кэша в мс (null - бесконечно)
            tsArrCacheTimeout: null
        }
    },
    getters: {
        // вернуть список ТС
        getTsArr(state) {
            return state.tsArr;
        },
    },
    mutations: {
        // установить новый список объектов
        setTsArr(state, payload) {
            state.tsArr = payload;
        },

        // добавить остановки в список объектов
        addTsArr(state, payload) {
            payload.forEach(value => {
                state.tsArr.push(value);
            })
        },

        // добавить объект
        addTS(state, payload) {
            state.tsArr.push(payload)
        },

        // редактировать объект
        editTS(state, payload) {
            let index = state.tsArr.findIndex(value => value.ts_id == payload.ts_id);
            if (index >= 0) {
                state.tsArr[index] = payload;
            }
        },

    },
    actions: {
        // добавление объекта
        async doGetAllTsArr(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.park));
            params.append("jsonTransType", payload.tr_type ? JSON.stringify(payload.tr_type) : null);
            params.append("jsonCapacity", null);
            params.append("jsonMark", null);
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/TSRestController/getTSRemote',
                params.toString(),
                'ref/setTsArr',
                'Получение ТС'
             );
        },

        // получение объекта и добавление его в список
        async doAddTS(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.park));
            params.append("jsonTS", JSON.stringify(payload.ts));
            params.append("app_user",  store.getters.getLogin);
            params.append("app_pwd", store.getters.getPassword);
            const encoded = encodeURI(params.toString())
            let id = await DoPost(
                store.getters['settings/getBaseUrl'] + '/TSRestController/insertTSRemote',
                encoded,
                null,
                'Добавление ТС'
            );

            payload.ts.ts_id = id;
            context.commit('addTS', payload.ts);
        },

        async doEditTS(context, payload) {
            const params = new URLSearchParams();
            params.append('jsonTS', JSON.stringify(payload.ts));
            const encoded = encodeURI(params.toString())
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/TSRestController/updateTSRemote',
                encoded,
                null,
                'Редактирование ТС'
            );

            context.commit('editTS', payload.ts);
        },

        async doDeleteTS(context, payload) {
            const params = new URLSearchParams();
            params.append('jsonRace', JSON.stringify(payload.race));

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/RaceCardRestController/deleteTsArrRemote',
                params.toString(),
                null,
                'Удаление ТС'
            );

            if(payload.raceCard) {
                context.commit('deleteRaceCard', payload.raceCard);
            } else {
                context.commit('deleteAllRaceCard');
            }
        },

        // получение объекта и добавление его в список
        async doIsInsertTSAvailable(context, payload) {
            const params = new URLSearchParams();
            params.append("dbIdentity", JSON.stringify(payload.identity));
            return await DoPost(
                store.getters['settings/getBaseUrl'] + '/TSRestController/isInsertTSAvailableRemote',
                params.toString(),
                null,
                'Доступность добавления ТС'
            );
        },


        // добавление связи ТС - р/с
        async doInsertRadioTransLink(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonTS", JSON.stringify(payload.ts));
            params.append("jsonRSNum", JSON.stringify(payload.rs_num));
            params.append("jsonPark", JSON.stringify(payload.park));
            params.append("app_user",  store.getters.getLogin);
            params.append("app_pwd", store.getters.getPassword);
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/TSRestController/insertRadioTransLinkRemote',
                params.toString(),
                null,
                'Привязка ТС - р/с'
            );
        },

        async doIsInsertRadioTransLinkAvailable(context, payload) {
            const params = new URLSearchParams();
            params.append("rsNum", JSON.stringify(payload.rs_num));
            params.append("rsType", JSON.stringify(payload.rs_type));
            return await DoPost(
                store.getters['settings/getBaseUrl'] + '/TSRestController/isInsertRadioTransLinkAvailableRemote',
                params.toString(),
                null,
                'Доступность добавления связи ТС - р/с'
            );
        },

        async doDeleteRadioTransLink(context, payload) {
            const params = new URLSearchParams();
            params.append("jsonTS", JSON.stringify(payload.ts));
            params.append("jsonRSNum", JSON.stringify(payload.rs_num));
            params.append("jsonPark", JSON.stringify(payload.park));
            params.append("app_user", store.getters.getLogin);
            params.append("app_pwd", store.getters.getPassword);
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/TSRestController/deleteRadioTransLinkRemote',
                params.toString(),
                null,
                'Удаление связи ТС - р/с'
            );
        },
    }
}
