// ХРАНИЛИЩЕ STAT_INIT
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            statInit_arr: [],
        }
    },
    getters: {
        // вернуть список объектов
        getStatInitInspReisesByParks(state) {
            return state.statInit_arr;
        },

    },
    mutations: {
        // установить новый список объектов
        setInspReisesByParks(state, payload) {
            state.statInit_arr = payload;
        },

        // добавить в список объектов
        addInspReisesByParks(state, payload) {
            state.statInit_arr.push(payload);
        },

    },
    actions: {

        // получение объектов
        async doFetchInspReisesByParks(context,payload) {
            //формируем параметра запроса
            const params = new URLSearchParams();
            params.append("jsonParksArr", JSON.stringify(payload.parksArr));
            params.append("dateIndex", String(payload.dateIndex));

            let arr = await DoPost(store.getters['settings/getBaseUrl'] +
                '/InspReisRestController/getInspReisesByParksRemote',
                params.toString(),
                null,
                ''
            );

            if (arr && arr[0]) {
                context.commit('addInspReisesByParks', arr[0]);
            }
        },


    }
}