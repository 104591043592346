// ХРАНИЛИЩЕ ТИПОВ РС
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            rsTypes: [],
            // // список р/с
            rsTypesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            rsTypesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список р/с
        getRsTypes(state) {
            return state.rsTypes;
        },
    },
    mutations: {
        // установить новый список объектов
        setRsTypes(state, payload) {
            state.rsTypes = payload;
            // state.rsNumsCacheTimeout = new Date().getTime()
            // console.log('setParks', state.parks)
        },

    },
    actions: {

        // получение объектов
        async doGetRsTypes(context, payload) {

            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.park));
            await DoPost(
                store.getters['settings/getBaseUrl'] + '/RSRestController/getRSTypeRemote',
                params.toString(),
                'ref/setRsTypes',
                'Получение типов р/с'
            );

        },
    }
}
