// ХРАНИЛИЩЕ МОДЕЛЕЙ
import {DoPost} from "@/store/Requests";
import store from "@/store/Store";

export default {
    state() {
        return {
            // список объектов
            models: [],
        }
    },
    getters: {
        // вернуть список парков
        getModels(state) {
            return state.models;
        },
    },
    mutations: {
        // установить новый список объектов
        setModels(state, payload) {
            state.models = payload;
        },

        // установить новый список объектов
        addModel(state, payload) {
            state.models.push(payload);
        },

    },
    actions: {

        // получение объекта
        async doGetModels(context, payload) {

            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.park));
            params.append("jsonFilterTransType", JSON.stringify(payload.trans_type));
            params.append("jsonFilterCapacity", JSON.stringify(payload.capacity));

            await DoPost(
                store.getters['settings/getBaseUrl'] + '/ModelRestController/getMarksRemote',
                params.toString(),
                'ref/setModels',
                'Получение моделей'
            );
        },

        // добавление объекта
        async doAddModel(context, payload) {

            const params = new URLSearchParams();
            params.append("jsonPark", JSON.stringify(payload.park));
            params.append("jsonMark", JSON.stringify(payload.mark));
            const encoded = encodeURI(params.toString())
            let id = await DoPost(
                store.getters['settings/getBaseUrl'] + '/ModelRestController/insertMarkRemote',
                encoded,
                null,
                'Добавление модели'
            );

            payload.mark.md_id = id;
            context.commit('addModel', payload.mark);
            return id;
        },
    }
}
