<template>
  <align-container style="overflow-x: hidden; overflow-y: hidden">

    <align-panel align="all">
      <align-container>
        <!-- Панель со списком отчетов  -->
        <align-panel align="left" width="15%" style="overflow-y: auto">
          <div class="card bg-light" style="height: 100%">
            <!--    подраздел "Оперативные"-->
            <div class="form">
              <div :class="selectedDir == 'marshes' ? 'form-group selected-row' : 'form-group row'"
                   @click="setSelectedDir('marshes')">
                <div class="form-group col" style="overflow-x: hidden; margin: 0;  ">
                  {{ $t("nsi.ReferencesPage.Marsh") }} {{ textForMarshTab }}
                </div>
              </div>
              <div :class="selectedDir == 'marshVariants' ? 'form-group selected-row' : 'form-group row'"
                   @click="setSelectedDir('marshVariants')">
                <div class="form-group col" style="overflow-x: hidden; margin: 0; vertical-align: top;">
                  {{ $t("nsi.ReferencesPage.MarshVariant") }} <br>
                  {{ textForMarshVariantsTab }}
                </div>
              </div>
              <div :class="selectedDir == 'races' ? 'form-group selected-row' : 'form-group row'"
                   @click="setSelectedDir('races')">
                <div class="form-group col" style="overflow-x: hidden; margin: 0; vertical-align: top;">
                  {{ $t("nsi.ReferencesPage.Race") }} {{ textForRacesTab }}
                </div>
              </div>
              <div :class="selectedDir == 'raceCards' ? 'form-group selected-row' : 'form-group row'"
                   @click="setSelectedDir('raceCards')">
                <div class="form-group col" style="overflow-x: hidden; margin: 0; vertical-align: top;">
                  {{ $t("nsi.ReferencesPage.RaceCard") }}
                </div>
              </div>
              <div :class="selectedDir == 'parkStations' ? 'form-group selected-row' : 'form-group row'"
                   @click="setSelectedDir('parkStations')">
                <div class="form-group col" style="overflow-x: hidden; margin: 0; vertical-align: top;">
                  {{ $t("nsi.ReferencesPage.ParkStation") }}
                </div>
              </div>
              <div :class="selectedDir == 'ts' ? 'form-group selected-row' : 'form-group row'"
                   @click="setSelectedDir('ts')">
                <div class="form-group col" style="overflow-x: hidden; margin: 0; vertical-align: top;">
                  {{ $t("nsi.ReferencesPage.TS") }}
                </div>
              </div>
            </div>
          </div>
        </align-panel>

        <!-- Правая панель  -->
        <align-panel align="all" :gap="3">
          <align-container>
            <align-panel align="all" style="overflow-y: auto" :gap="3">
<!--              панелька маршрутов    -->
              <marsh-panel
                  v-if="selectedDir == 'marshes'"
                  @setSelectedDir="setSelectedDir"
              >
              </marsh-panel>
<!--              панелька периодов действия    -->
              <marsh-variant-panel
                  v-else-if="selectedDir == 'marshVariants'"
                  @setSelectedDir="setSelectedDir"
              >
              </marsh-variant-panel>
<!--              панелька трасс    -->
              <race-panel
                  v-else-if="selectedDir == 'races'"
                  @setSelectedDir="setSelectedDir"
              >
              </race-panel>
<!--              панелька остановок трасс -->
              <race-card-panel
                  v-else-if="selectedDir == 'raceCards'"
              >
              </race-card-panel>
<!--              панелька всех остановок -->
              <park-stations-panel
                  v-else-if="selectedDir == 'parkStations'"
              ></park-stations-panel>
              <ts-panel
                  v-else-if="selectedDir == 'ts'"
              >
              </ts-panel>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>

    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading-modal
      v-if="isLoading"
      :loadingText="this.$t('nsi.ReferencesPage.ModalLoadingText')"
  ></loading-modal>

  <!-- Оповещение о том, Что выбрано больше 1 предприятия -->
  <error-message-box
      v-if="errorMessageText != ''"
      @onOK="onCloseErrorMessageBox"
  >{{ errorMessageText }}
  </error-message-box>



</template>

<script>

import AlignPanel from "@/components/ui/custom/AlignPanel";
import AlignContainer from "@/components/ui/custom/AlignContainer";
import ErrorMessageBox from "@/components/ui/custom/ErrorMessageBox";
import LoadingModal from "@/components/ui/custom/LoadingModal";
import {dateToString, ndToDate} from "@/lib";
import ParkStationsPanel from "@/components/references/park_station/ParkStationsPanel";
import RaceCardPanel from "@/components/references/race_card/RaceCardPanel";
import MarshPanel from "@/components/references/marsh/MarshPanel";
import MarshVariantPanel from "@/components/references/marsh_variant/MarshVariantPanel";
import RacePanel from "@/components/references/race/RacePanel";
import TsPanel from "@/components/references/ts/TsPanel";

export default {

  components: {
    TsPanel,
    RacePanel,
    MarshVariantPanel,
    MarshPanel,
    RaceCardPanel,
    ParkStationsPanel,
    LoadingModal,
    ErrorMessageBox,
    AlignContainer,
    AlignPanel,
  },

  props: [
    // идентификатор выбранного отчета
    "id"
  ],

  data() {
    return {
      // карта
      leaflet: null,
      errorMessageText: '',
      isLoading: false,


    }
  },

  computed: {

    // выбранный справочник
    selectedDir() {
      return this.$store.getters['ref/getSelectedDir'];
    },

    // выбранный маршрут для вкладки слева
    selectedMarshForTab() {
      return this.$store.getters['ref/getSelectedMarshForTab'];
    },

    // выбранный период действия для вкладки слева
    selectedMarshVariantForTab() {
      return this.$store.getters['ref/getSelectedMarshVariantForTab'];
    },

    // выбранная трасса
    selectedRaceForTab() {
      return this.$store.getters['ref/getSelectedRaceForTab'];
    },

    textForMarshTab() {
      if (this.selectedMarshForTab != null) {
        return '№' + this.selectedMarshForTab.routeName;
      } else {
        return ''
      }
    },

    textForMarshVariantsTab() {
      if (this.selectedMarshVariantForTab != null) {
        return '(' + dateToString(ndToDate(this.selectedMarshVariantForTab.routeVariantIndexBegin)) + ' - '
            + dateToString(ndToDate(this.selectedMarshVariantForTab.routeVariantIndexEnd)) + ')';
      } else {
        return ''
      }
    },

    textForRacesTab() {
      if (this.selectedRaceForTab != null) {
        return this.selectedRaceForTab.raceTitle + '(' + this.selectedRaceForTab.raceType + ')';
      } else {
        return ''
      }
    },


  },

  methods: {

    // сохраняем в сторе выбранный справочник
    async setSelectedDir(value) {
      this.$store.commit('ref/setSelectedDir', value);

      if (value == 'parkStations' || value == 'raceCards' ) {
        this.leaflet = null;
        this.isLoading = true;
        this.$store.commit('ref/setParkStations', []);
        // получаем id выбранного парка
        let parkId = this.$store.getters['ref/getUserPark'];
        // получаем выбранный парк
        let park = this.$store.getters['ref/getParks'].find(value => value.parkId == parkId);
        // запрос остановок по парку
        await this.$store.dispatch('ref/doGetParkStations', {
          park: park,
        });
        //
        this.isLoading = false;
      } else if (value == 'ts') {
        // получаем id выбранного парка
        let parkId = this.$store.getters['ref/getUserPark'];
        // получаем выбранный парк
        let park = this.$store.getters['ref/getParks'].find(value => value.parkId == parkId);
        // запрос типов транспорта по парку
        await this.$store.dispatch('ref/doGetTransTypes', {
          park: park,
        });
        // запрос типов вместимости
        await this.$store.dispatch('ref/doGetCapacities', {
          park: park,
        });
        // запрос ТС
        await this.$store.dispatch('ref/doGetAllTsArr', {
          park: park,
        });
        // запрос оборудования подсчета пассажиров
        await this.$store.dispatch('rep/doGetApcTypes', {
          curPark: park
        });
        // запрос типов оборудования
        await this.$store.dispatch('ref/doGetRsTypes', {
          park: park
        });
        // запрос радиостанций
        await this.$store.dispatch('ref/doGetRsNums', {
          park: park
        });
      }
    },

    // очищаем текст ошибки
    onCloseErrorMessageBox() {
      this.errorMessageText = '';
    },

    // correctingMapPosition() {
    //   let stops = this.$store.getters['ref/getParkStations'];
    //   if (stops.length === 1) {
    //     this.leaflet.setView([stops[0].ss_latitude, stops[0].ss_longitude], this.$store.getters['settings/getDefaultVehicleZoom']);
    //   } else if (stops.length > 1) {
    //     const bounds = latLngBounds(
    //         stops.map(vehicle => [vehicle.ss_latitude, vehicle.ss_longitude])
    //     )
    //     this.leaflet.fitBounds(bounds);
    //   }
    // },
  },

  // вызывается при создании компонента
  // async created() {
  //   // перезапрашиваются данные
  //   // await this.refreshData();
  // },
}

</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  border-bottom: 1px solid #bdbdbd;
  /*#817e7e;*/
  cursor: pointer;
  height: 50px;
  vertical-align: middle
}

.selected-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  border-bottom: 1px solid #bdbdbd;
  background-color: #9adef5;
  cursor: pointer;
  height: 50px;
  vertical-align: middle
}

.col {
  align-items: center;
  display: flex
}

</style>
