<template>
  <leaflet-marker
      v-if="isCorrect"
      :latlng="latlng"
      :events="events + ' dragend'"
      :icon="icon"
      :options="options"
      :targetComponent="this"
      :logTitle="stopPavilion.long_name + '(#'+stopPavilion.stop_pavilion_id+')'"
      @onLeafletEvent="onLeafletEvent($event)"
  ></leaflet-marker>
</template>

<script>

// МАРКЕР ОСТАНОВОЧНОГО ПАВИЛЬОНА

// interface STOP_PAVILION {
//   stop_pavilion_id: number;
//   stop_id: number;
//   stop_pavilion_kind_id: number;
//   stop_pavilion_type_id: number;
//   short_name: string;
//   long_name: string;
//   latitude: number;
//   longitude: number;
//   length: number;
//   width: number;
//   height: number;
//   inside_area_useful: number;
//   total_area: number;
//   is_video_record_system: boolean;
//   is_multimedia: boolean;
//   is_eboard: boolean;
//   is_handicapped: boolean;
// }

import icons from "@/components/ui/leaflet/layers/markers/stop_pavilions/StopPavilionIcon";
import LeafletMapMixin from "@/components/ui/leaflet/mixins/LeafletMapMixin";
import LeafletEventedMixin from "@/components/ui/leaflet/mixins/LeafletEventedMixin";

export default {
  mixins: [LeafletMapMixin, LeafletEventedMixin],
  emits: [
    // вызывается при изменении остановочного павильона
    'onStopPavilionChanged'
  ],
  props: {
    // тип павильона: 0 - тип 0 (автобусы - по-умолчанию), 1 - тип 1 (троллейбусы), 2 - тип 2 (трамваи), 3 - тип 3 (прочее)
    type: {
      default: 4
    },
    // состояния павильона: original - обычное, active - активное, edit - редактируемое, disabled - отключенное
    state: {
      type: String,
      default: 'original'
    },
    // описание остановочного павильона
    stopPavilion: {
      type: Object,
    },
    // опции маркера в обычном состоянии
    originalOptions: {
      type: Object,
      default: () => {
        return {
          draggable: false,
          clickable: true
        }
      }
    },
    // опции маркера в активном состоянии
    activeOptions: {
      type: Object,
      default: () => {
        return {
          draggable: false,
          clickable: true,
          zIndexOffset: 1000
        }
      }
    },
    // опции маркера в редактируемом состоянии
    editOptions: {
      type: Object,
      default: () => {
        return {
          draggable: true,
          clickable: true,
          zIndexOffset: 1010
        }
      }
    },
    // опции маркера в отключенном состоянии
    disabledOptions: {
      type: Object,
      default: () => {
        return {
          draggable: false,
          clickable: false
        }
      }
    },
  },
  data() {
    return {
      // тип объекта
      leafletObjectType: 'StopPavilionMarker',
    }
  },
  computed: {
    isCorrect() {
      return this.stopPavilion && Number.isFinite(this.stopPavilion.latitude) && Number.isFinite(this.stopPavilion.longitude)
    },
    latlng() {
      return [this.stopPavilion.latitude, this.stopPavilion.longitude];
    },
    yIndex() {
      if (this.type == 1) return 1
      else if (this.type == 2) return 2
      else if (this.type == 3) return 3
      else if (this.type == 4) return 4
      else return 4
    },
    xIndex() {
      if (this.state === 'disabled') return 3
      else if (this.state === 'edit') return 2
      else if (this.state === 'active') return 1
      else return 0;
    },
    // иконка остановочного павильона
    icon() {
      return icons[this.yIndex][this.xIndex]
    },
    // опции остановочного павильона
    options() {
      if (this.state === 'disabled') return this.disabledOptions
      else if (this.state === 'edit') return this.editOptions
      else if (this.state === 'active') return this.activeOptions
      else return this.originalOptions;
    },
    // признак режима редактирования
    isEdited() {
      return this.state === 'edit';
    }
  },
  methods: {
    // события маркера
    onLeafletEvent(event) {
      // завершение перетаскивание
      if (event.type === 'dragend' && this.isEdited) {
        const latlng = event.target.getLatLng()
        // информируем об изменении
        this.$emit('onStopPavilionChanged', {
          ...this.stopPavilion,
          latitude: latlng.lat,
          longitude: latlng.lng,
        });
      }

      // проверяем и вызываем внешние события
      if (this.eventsToArray(this.events).includes(event.type)) {
        this.$emit('onLeafletEvent', event);
      }
    },
  },
};
</script>

<style>
  /* Иконка остановочного павильона */
  .stop-pavilion-icon {
    border      : 0px;
    margin      : 0px;
    padding     : 0px;
    background  : url("/src/components/ui/leaflet/img/stopPavilion.png");
  }
</style>
